import {action, observable} from "mobx";
import React from "react";
import Validation from "../Utils/Validation";
import {IErrorsObject} from "../Interfaces/OtherInterfaces";
import AuthService from "../Services/AuthService";



class PasswordRecoveryStore {

    @observable resetPasswordEmail: string = '';
    @observable inlinePasswordRecoveryErrors: IErrorsObject = {};
    @observable isLoading: boolean = false;

    @action changeResetEmail = (value: string) =>
        this.resetPasswordEmail = value;

    @action resetPassword = async () => {
        try {
            this.isLoading = true;
            await AuthService.passwordRecovery(this.resetPasswordEmail);
            this.isLoading = false;
            this.resetPasswordEmail = '';
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    @action inputFieldsValidation = (event: React.FocusEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        return this.inlinePasswordRecoveryErrors[name] = !Validation[name](value);
    };

}

export default new PasswordRecoveryStore();