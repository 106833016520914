import { action, observable, reaction } from "mobx";
import ProfileService from "../Services/ProfileService";
import { defaultCreditCardBeforeSave, ICreditCardAfterSave } from "../Interfaces/CreditCardsInterfaces";
import { ReactStripeElements } from "react-stripe-elements";
import ModalStore from "./ModalStore";
import mixpanel from "mixpanel-browser";
import { IPaymentContact, ICreditCard } from "../Interfaces/PaymentContactInterface";
import BookingStore from "./BookingStore";
import StorageService from "../Services/StorageService";

class PaymentStore {
    
    @observable paymentSettings: ICreditCardAfterSave[] = [];
    @observable defaultPaymentMethod: ICreditCardAfterSave = defaultCreditCardBeforeSave;
    @observable defaultPaymentId: number = 0;
    @observable token: ReactStripeElements.PatchedTokenResponse = {};

    @observable isLoading: boolean = false;
    @observable errorsOnCardAdd = '';
    @observable errorOnComponent = {
        modalError: ''
    }
    @observable isRedirect = false;
    /* */
    @observable paymentContacts: IPaymentContact = { first_name: "", last_name: "", email: "",  phone: "", address: ""};
    @observable creditCard: ICreditCard = { card_number: "", card_holder: "", card_type: 0, exp_date: "", cvc: "", promo_code: ""};

    @observable cardEmptyInfo = { card_number: true, exp_date: true, cvc: true}

    @action setRedirect = (bool: boolean) => {
        this.isRedirect = bool;
    }

    constructor(){

        StorageService.getItem('defaultPayment')
            .then((response: number) => (this.defaultPaymentId = response))
            .catch(() => (this.defaultPaymentId = 0));

        reaction(
            () => this.defaultPaymentMethod,
            (paymentObject: ICreditCardAfterSave) => {
                StorageService.setItem('defaultPayment', paymentObject.id);
            }
        );
    }

    @action getCreditCards = async () => {
        try {
            this.isLoading = true;
            const response: any = await ProfileService.getCreditCards();
            this.paymentSettings = response;
            if (this.defaultPaymentId === 0){
                this.defaultPaymentMethod = this.paymentSettings.length ? this.paymentSettings[0] : defaultCreditCardBeforeSave
            }else{
                const paymentObject = this.paymentSettings.find(
                    (payment: ICreditCardAfterSave) => payment.id === this.defaultPaymentId
                  );
                if (paymentObject) {
                    this.defaultPaymentMethod = paymentObject;
                }
            }
            BookingStore.bookingData.payment_info = this.defaultPaymentMethod
        } catch (e) {
            throw new Error(e.message);
        } finally {
            this.isLoading = false;
        }
    };

    @action changeToken = (token: ReactStripeElements.PatchedTokenResponse) => this.token = token;

    @action changeDefaultCard = async (payment: ICreditCardAfterSave) => {
        this.defaultPaymentMethod = payment;
    };

    @action deleteCreditCard = async (id: number) => {
        try {
            this.isLoading = true;
            await ProfileService.deleteCreditCard(id);
            this.paymentSettings = this.paymentSettings.filter((cc: ICreditCardAfterSave) =>
                cc.id !== id);
            if (this.defaultPaymentMethod.id === id) {
                this.defaultPaymentMethod = this.paymentSettings.length ? this.paymentSettings[0] : defaultCreditCardBeforeSave;
            }
        } catch (e) {
            console.log(e);
            this.errorOnComponent.modalError = e.message.detail
            ModalStore.toggleModal("paymentError", true);
        } finally {
            this.isLoading = false;
        }

    };

    @action savePaymentInfo = async (payment_info: any) => {
        try {
            this.isLoading = true;
            if (this.token && this.token.token && this.token.token.card) {
                const card = this.token.token.card;

                const payment = {
                    token: this.token.token.id,
                    first_name: payment_info.first_name,
                    last_name: payment_info.last_name,
                    email: payment_info.email,
                    phone: payment_info.phone
                }
                const response: any = await ProfileService.saveCreditCard(payment);

                this.paymentSettings.push(
                    {
                        card_number: '************' + card.last4,
                        last_4: response.last_4,
                        card_type: card.brand,
                        date: { exp_month: card.exp_month + '', exp_year: card.exp_year + '' },
                        cvc: '***',
                        id: response.id,
                        first_name: payment_info.first_name,
                        last_name: payment_info.last_name,
                        email: payment_info.email,
                        phone: payment_info.phone
                    }
                );
                this.defaultPaymentMethod = response;
                mixpanel.track("Payments added Successful");
            }
        } catch (e) {
            console.log(e);
            this.errorOnComponent.modalError = e.message.detail;
            ModalStore.toggleModal("paymentError", true);
            mixpanel.track("Payments added Error", {
                errorMessage: e.message.detail,
                error: e
            });
            throw new Error(e)
        } finally {
            this.isLoading = false;
        }


    };

   

    @action updatePaymentInfo = async (payment_info: any) => {
        try {
            this.isLoading = true;

            const payment = {
                id: payment_info.id,
                first_name: payment_info.first_name,
                last_name: payment_info.last_name,
                email: payment_info.email,
                phone: payment_info.phone
            }
            const response: any = await ProfileService.editCreditCard(payment);

            const idx = this.paymentSettings.findIndex((obj => obj.id === response.id));
            if (idx > -1){
                this.paymentSettings[idx].first_name = payment_info.first_name;
                this.paymentSettings[idx].last_name = payment_info.last_name;
                this.paymentSettings[idx].email = payment_info.email;
                this.paymentSettings[idx].phone = payment_info.phone;
            }

            this.defaultPaymentMethod = response;
            mixpanel.track("Payments updated Successful");
        } catch (e) {
            console.log(e);
            this.errorOnComponent.modalError = e.message.detail;
            ModalStore.toggleModal("paymentError", true);
            mixpanel.track("Payments updated Error", {
                errorMessage: e.message.detail,
                error: e
            });
            throw new Error(e.message.detail)
        } finally {
            this.isLoading = false;
        }


    };

    @action clear = () => {
        this.paymentSettings = [];
        this.defaultPaymentMethod = defaultCreditCardBeforeSave;
        /** */
        this.paymentContacts = { first_name: "", last_name: "", email: "",  phone: "", address: ""};
        this.creditCard = { card_number: "", card_holder: "", card_type: 0, exp_date: "", cvc: "", promo_code: ""};
    }
    /***/
    @action changeCreditInfo = async (id: string, value: number | string) => {
        try {
            this.isLoading = true;
            if( id in this.creditCard) this.creditCard[id] = value;            
            mixpanel.track("Credit updated Successful");

        } catch (e) {
            console.log(e);
            this.errorOnComponent.modalError = e.message.detail;
            ModalStore.toggleModal("paymentError", true);
            mixpanel.track("Payments updated Error", {
                errorMessage: e.message.detail,
                error: e
            });
            throw new Error(e.message.detail)
        } finally {
            this.isLoading = false;
        }
    };

    @action changePaymentInfo = async (id: string, value: string | boolean) => {
        try {
            this.isLoading = true;
            if( id in this.paymentContacts) this.paymentContacts[id] = value;
            mixpanel.track("Payments updated Successful");
        } catch (e) {
            console.log(e);
            this.errorOnComponent.modalError = e.message.detail;
            ModalStore.toggleModal("paymentError", true);
            mixpanel.track("Payments updated Error", {
                errorMessage: e.message.detail,
                error: e
            });
            throw new Error(e.message.detail)
        } finally {
            this.isLoading = false;
        }
    };
}

export default new PaymentStore();