import { ICreditCardAfterSave } from './CreditCardsInterfaces';
import { IFeedback } from './OtherInterfaces';
import { IAddress } from './AddressesInterface';

export interface IAvatar {
  url: string;
  file?: File;
}

export interface INotifications {
  sms: boolean;
  email: boolean;
  push: boolean;

  [key: string]: boolean;
}

export const defaultNotifications = {
  sms: false,
  email: false,
  push: false,
};

export interface ISubscription {
  id: number;
  subscription_type: string;
  price: string;
  city: string;
  total_price: string;
  is_deprecated: boolean;
  days: number; // days count
  appointments_left: number; // appointments count
  save_percent: number;
  subscription?: any;
  is_active?: boolean;
  cancellation_date: string | null;
}

export const defaultSubscription = {
  id: 0,
  subscription_type: '',
  price: '0',
  city: 'Los Angeles',
  save_percent: 5,
};

export const defaultProfile = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  password: '',
  avatar_url: require('../assets/human.svg'),
  notification_email: true,
  notification_push: false,
  notification_sms: false,
  notification_text_magic: true,
  client_subscriptions: null,
  referal_code: null,
};

export interface IProfile {
  id: number;
  first_name: string;
  last_name: string;
  password: string;
  email: string;
  phone: string;
  avatar_url: string;
  referal_code: string | null;
  notification_email: boolean;
  notification_push: boolean;
  notification_sms: boolean;
  notification_text_magic: boolean;
  client_subscriptions: ISubscription | null;
  [key: string]:
    | string
    | boolean
    | number
    | IAvatar
    | ISubscription
    | undefined
    | null;
}

export interface IApiProfile {
  address: IAddress[];
  avatar_url: string;
  email: string;
  feedback: IFeedback[];
  first_name: string;
  id: number | null;
  last_name: string;
  notification_email: boolean;
  notification_push: boolean;
  notification_sms: boolean;
  payments: ICreditCardAfterSave[];
  phone: string;
  rating: number;
  valet_experience?: string;
  user_type: number;
  last_login?: string;
}

export const defaultApiProfile: IApiProfile = {
  address: [],
  avatar_url: '',
  email: '',
  feedback: [],
  first_name: '',
  id: null,
  last_name: '',
  notification_email: false,
  notification_push: false,
  notification_sms: false,
  payments: [],
  phone: '',
  rating: 0,
  valet_experience: '',
  user_type: 2,
};

export interface IChangePasswordObject {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  [key: string]: string;
}

export const defaultChangePasswordObject = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

export interface IUserSubscription {
  id: number;
  appointments_left: number;
  client: number;
  date_created: string;
  is_active: boolean;
  payment: number;
  subscription: ISubscription;
  unused_appointments: number;
  cancellation_date: string | Date;
}
