import config from "../config";
import http from "./http";

interface IDataLogin {
    email: string;
    password: string;
}
interface ISignData{
    email: string;
    phone: string;
    password1?: string;
    user_type: number;
}

class AuthService {
    public static postLogin(data: IDataLogin) {
        return http.post('/auth/client/login/', data);
    }

    public static signIn(signData:ISignData){
        return http.post('/rest-auth/registration/', signData)
    }

    public static passwordRecovery(email:string){
        return http.post('/auth/password/reset/', { email })
    };

    public static logOut(){
        return http.post('/auth/client/logout/')
    }
    public static googleLoginUrl(){
        return http.get('/google-login/url/')
    }
    public static googleLogin(code:any){
        return http.post('/google-login/code/', {code, user_type:2} )
    }
    public static facebookLogin(data:any){
        return http.post('/social-login/facebook/',data )
    }

    public static guestLogin(){
        return http.get('/guest-user/')
    }

    public static verifyRecaptcha(token: string){
        return http.post('/verify-recaptcha/', {token})
    }
}
export default AuthService;