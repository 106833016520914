import React, { Component } from 'react'
import queryString from 'query-string';
import SpinnerComponent from '../../Shared/Spinner';
import AuthStore from  '../../Stores/AuthStore'
import Popup from '../Popups/Popup';
import { observer } from 'mobx-react';

@observer
export default class GoogleCallbackPage extends Component {
    async componentDidMount(){
        const parsed = queryString.parse(window.location.search);
        if(parsed.code) await AuthStore.googleLogin(parsed.code);

    }
    render() {
        return (
            <div>
              <SpinnerComponent variant={'warning'} size={'lg'}/>
              <Popup
                title='ERROR!'
                content={AuthStore.errorsAfterRequest}
                show={!!AuthStore.errorsAfterRequest}
                id="error_after_google_login"
                onHide={() => window.location.href = '/login'}
              />
            </div>
        )
    }
}
