import React, { Component, FormEvent } from "react";
import SubNavbar from "../../Shared/Navbars/SubNavbar";
import Form from "react-bootstrap/Form";
import YellowButton from "../../Shared/Buttons/YellowButton";
import ProfileStore from "../../Stores/ProfileStore";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/helpers";
import { FormControlProps } from "react-bootstrap";
import AuthStore from "../../Stores/AuthStore";
import { Redirect } from "react-router";
import { observer } from "mobx-react";

@observer
export default class PasswordSettings extends Component {
  changeHandler = (
    event: FormEvent<
      ReplaceProps<"input", BsPrefixProps<"input"> & FormControlProps>
    >
  ) => {
    const { id, value = "" } = event.currentTarget;
    if (id) {
      ProfileStore.changePasswordObject(id, value);
    }
  };

  componentDidMount(): any {
    if (!AuthStore.isLogged) return <Redirect to={"/"} />;
  }
  render() {
    const {
      currentPassword,
      newPassword,
      confirmNewPassword
    } = ProfileStore.passwordObject;
    return (
      <div className={"settings_container"}>
        <SubNavbar active={1} />
        <div className="container password_settings">
          <h1>Password</h1>
          <Form>
            <Form.Group controlId="currentPassword">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="current password"
                value={currentPassword}
                onChange={this.changeHandler}
                onBlur={ProfileStore.passwordValidation}
              />
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="new password"
                value={newPassword}
                onChange={this.changeHandler}
                onBlur={ProfileStore.passwordValidation}
              />
            </Form.Group>
            <Form.Group controlId="confirmNewPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="new password"
                value={confirmNewPassword}
                onChange={this.changeHandler}
                onBlur={ProfileStore.passwordValidation}
              />
            </Form.Group>

            {ProfileStore.passwordChangeError && (
              <Form.Group>
                <span className={`inline_error`}>
                  {ProfileStore.passwordChangeError}
                </span>
              </Form.Group>
            )}
            {ProfileStore.success && (
              <Form.Group>
                <span className={`inline_message`}>
                  Your password has been changed successfully.{" "}
                </span>
              </Form.Group>
            )}
          </Form>

          <YellowButton
            onClick={ProfileStore.changePassword}
            value={"reset password"}
            additionalClass={"w-100"}
            spinner={ProfileStore.isLoading}
          />
        </div>
      </div>
    );
  }
}
