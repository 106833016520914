import {action, observable, reaction} from "mobx";
import ValetService from "../Services/ValetService";
import {defaultValet, IValet} from "../Interfaces/ValetInterfaces";
import ProfileStore from "./ProfileStore";
import {IFeedback} from "../Interfaces/OtherInterfaces";
import Axios from "axios";
import TimeStore from "./TimeStore";

class ValetsStore {
  @observable isFavoriteOnly: boolean = false;
  @observable listOfValetsInCity: IValet[] = [];
  @observable listOfValets: IValet[] = [];
  @observable isLoading: boolean = false;
  @observable feedbackObject: IFeedback = {
    text: "",
    author: 0,
    user: 0
  };

  @observable isUserHasOnlyValet: boolean = false;
  @observable isUserHasFavoriteValet: boolean = false;
  @observable selectedCityForGroup: string = ""
  @observable selectedFavoriteOnly: boolean = false;

  source: any = null;

  constructor() {
    reaction(
      () => this.listOfValets,
      (valets: IValet[]) => {
        if (valets.length) {
          this.isUserHasOnlyValet = valets.some((valet: IValet) => valet.only);
          this.isUserHasFavoriteValet = valets.some(
            (valet: IValet) => valet.favorite || valet.preferred
          );
        }
      }
    );
    reaction(
      () => this.isUserHasOnlyValet,
      (isUserHasOnlyValet: boolean) => {
        TimeStore.showAllValet = !isUserHasOnlyValet;
      }
    );
  }

  @action removeValet = async (id: number | null) => {
    if (!id) return;
    try {
      this.isLoading = true;
      const userId = ProfileStore.profile.id;
      await ValetService.deleteValet(userId, id);
      this.listOfValets = this.listOfValets.filter(
        (valet: IValet) => valet.valet.id !== id
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action getListOfUserValets = async (status: string = "") => {
    try {
      this.isLoading = true;
      const userId = ProfileStore.profile.id;
      const response: any = await ValetService.getListOfUserValets(
        userId,
        status
      );
      if (!response) return null;
      this.listOfValets = response;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action getValetsGroupedByCity = async (city: string, isFavoriteOnly: boolean) => {
    const selectedCity = city === "MIAMI" ? "Miami" : city === "LOS ANGELES" ? "Los Angeles" : "Las Vegas"
    try {
      this.isLoading = true;
      const userId = ProfileStore.profile.id;
      const response: any = await ValetService.getValetsGroupedByCity(isFavoriteOnly)
      if (!response) return null;
      const filteredValets = response.filter((obj: Object) => obj.hasOwnProperty(selectedCity)).map((obj: any) => obj[selectedCity]);

      if (filteredValets.length > 0){
        const valets = filteredValets[0].map((obj: any) => {
          return {
            valet: obj,
            favorite : obj["favorite"]
          }
        });
        this.listOfValetsInCity = valets;
      }else{
        this.listOfValetsInCity = [];
      }
    
      this.selectedCityForGroup = selectedCity
      
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  @action markValetInCity = async (
    id: number | null,
    status: string,
    value: boolean
  ) => {
    if (!id) return;
    try {
      this.isLoading = true;
      const params = {
        [status]: value,
        valet: id
      };
      const response: any = await ValetService.changeValetStatus(params);
      if (!response) return null;
      this.listOfValetsInCity = this.listOfValetsInCity.map((valet: IValet) => {
        if (valet.valet.id === response.valet) {
          return {
            ...valet,
            only: response.only,
            preferred: response.preferred,
            favorite: response.favorite
          };
        }
        return valet;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action markValet = async (
    id: number | null,
    status: string,
    value: boolean
  ) => {
    if (!id) return;
    try {
      this.isLoading = true;
      const params = {
        [status]: value,
        valet: id
      };
      const response: any = await ValetService.changeValetStatus(params);
      if (!response) return null;
      this.listOfValets = this.listOfValets.map((valet: IValet) => {
        if (valet.valet.id === response.valet) {
          return {
            ...valet,
            only: response.only,
            preferred: response.preferred,
            favorite: response.favorite
          };
        }
        return valet;
      });

      if (status === "only") this.isUserHasOnlyValet = value;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };
  @action
  changeFeedBack = (name: string, value: string | number) =>
    (this.feedbackObject[name] = value);

  @action
  sendFeedback = async (valetId: number | null, appointment: number) => {
    if (!valetId) return;
    try {
      this.isLoading = true;
      const userId = ProfileStore.profile.id;
      const feedback = {
        ...this.feedbackObject,
        author: userId,
        appointment,
        user: valetId
      };
      const response: any = await ValetService.addFeedback(feedback);
      if (!response) return null;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  getUserValet = async (id: number) => {
    try {
      this.isLoading = true;
      const cancelToken = Axios.CancelToken;
      const source = cancelToken.source();
      this.source = source;
      const response: any = await ValetService.getUserValet(id, source);
      if (!response) return null;
      return response;
    } catch (e) {
      console.log(e);
      if (e.code === 500) {
        const valet: any = await this.getValet(id);
        return {
          valet,
          favorite: false
        };
      }
      return defaultValet;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  getValet = async (id: number) => {
    try {
      this.isLoading = true;
      const cancelToken = Axios.CancelToken;
      const source = cancelToken.source();
      this.source = source;
      const response: any = ValetService.getValet(id, source);
      if (!response) return null;
      return response;
    } catch (e) {
      console.log(e);
      return defaultValet;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  cancelRequest = () => this.source.cancel("operation canceled");
}

export default new ValetsStore();