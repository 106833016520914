import React, { Component, RefObject } from 'react';
import { Form } from 'react-bootstrap';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import PaymentStore from "../Stores/PaymentStore";


interface IProps extends ReactStripeElements.InjectedStripeProps {
  ref: RefObject<any>;
  showError: (error?: string) => void;
  inputComplete?: (value: boolean) => void;
  valueForm?: {first_name: string, last_name: string}
}

class CardForm extends Component<IProps> {
  saveCard = async () => {
    const token = this.props.stripe && await this.props.stripe.createToken({
      type: 'card',
      name: `${this.props.valueForm ? this.props.valueForm.first_name : ''} ${this.props.valueForm ? this.props.valueForm.last_name : ''}`
    });

    if (token && token.token) {
      PaymentStore.changeToken(token);
    }
    if (token && token.error) {
      this.props.showError(token.error.decline_code)
      return {
        error: token.error.decline_code
      }
    }
  };

  render() {
    return (
      <Form
        className={"card_container"}
        onSubmit={this.saveCard}
        ref={this.props.ref}
      >
        <CardElement
          onChange={(e: ReactStripeElements.ElementChangeResponse) =>
            this.props.inputComplete &&
            this.props.inputComplete(e.complete)
          }
          onReady={el => el.focus()}
          className={"form"}
          classes={{ base: "login_input", empty: "error" }}
        />
      </Form>
    );
  }
}

export default injectStripe(CardForm, { withRef: true });

