import mixpanel from "mixpanel-browser";
import config from "./config";

const oneSignal =  () => {
    var OneSignal = window.OneSignal || [];
      OneSignal.push(function() {
        OneSignal.init({
          appId: config.ONE_SIGNAL_TOKEN
        });
});}
const hotJar = `
(function(h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function() {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 1671946, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");`;

const init = () => {
  mixpanel.init(config.MIXPANEL_ID);
  try {
    oneSignal();
    if (config.isProd) {
      const hotjarScript = document.createElement("script");
      hotjarScript.innerHTML = hotJar;
      document.body.appendChild(hotjarScript);
    }
  } catch (error) {
    console.log(error)
  }
};

export default init;
