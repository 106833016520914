import React from 'react';
import Table from 'react-bootstrap/Table';

export const PopupContent = {
         lateNight:
           "This time is during our late night hours. Late night booking is an extra fee.",
         bookingDisabled:
           "We are experiencing technical difficulties. Meantime, you can book an appointment by contacting +1(310) 993-7461 or send email to support@sprayvalet.com",
         ToSAndPrivacy: () => {
           return (
             <>
               <ol className="text-left">
                 <li>
                   You can reschedule your appointment anytime before the{" "}
                   <b>4</b> hour mark.
                   <p>
                     - If you reschedule it from late hours to daytime hours -
                     difference in sum will be sent to you as a refund in{" "}
                     <b>5</b> business days period.
                   </p>
                   <p>
                     - If you reschedule it from daytime to night hours - you
                     will need to pay the price difference in order to proceed.
                   </p>
                 </li>
                 <li>
                   You can cancel pending and upcoming appointments <b>24</b>{" "}
                   hours before your appointment for a full refund.
                   <p>
                     - Cancelled within the <b>24</b> hours will refund <b>½</b>{" "}
                     of the payment.
                   </p>
                   <p>
                     {" "}
                     - On-site (or no show) is <b>0</b> refund
                   </p>
                 </li>
               </ol>
             </>
           );
         },
         dontServiceArea:
           "Make sure you hit the drop down address. We may not service your area - to double check email us at support@sprayvalet.com.",
         firstTimePromoSuccess:(<div>
         Your Promo Code is FIRSTTIME<br/>Please do not forget to use it on your first purchase to receive discount. thank you!
       </div>),
         firstTimePromoFailed:
           "Your email address was already registered.",  
         extraFee:
           "You are currently out of our service area there will be a small fee",
         clientReschedule:
           "Rescheduling can not happen within 4 hours of your appointment",
         subscriptionSuccess: "Membership is applied successfully!",
         subscriptionCanceled: "Your membership has been canceled.",
         cancelBooking: (diffTime: number) =>
           diffTime < 4 ? `There are less than 4 hours until the appointment. A full charge will be applied if you cancel now.` : "You will be charged 50% of appointment price if you cancel now",
          valetOnMyWay: () =>
           `Valet is already on the way. A full charge will be applied if canceled.`,
         cancelSuccess: "Your booking has been cancelled successfully.",
         editBooking: (full: boolean) =>
           `Cancellation fee of ${
             full ? "full " : "1/2 "
           } refund will be applied.`,
         changeCityOfSubscription: (oldCity: string, newCity: string) => (
           <div>
             <div>
               You have an active subscription in <b>{oldCity}</b>,are you sure
               you want to subscribe in <b>{newCity}</b>?
             </div>
             <div>
               You will need to cancel your other subscription in order to
               proceed
             </div>
           </div>
         ),
         subscriptions: (
           <div className="d-flex flex-column">
             <div className=" pb-4 text-align-center black-text-color">
               Subscriptions are packages of tans that cover the base fee of a
               tan and let you save up to 25-30% based on your location! Compare
               the prices:
             </div>
             <Table bordered striped>
               <thead>
                 <tr>
                   <th>Los Angeles:</th>
                   <th>Las Vegas:</th>
                 </tr>
               </thead>
               <tbody>
                 <tr>
                   <td>Weekly: $60 per tan</td>
                   <td>Weekly: $50 per tan</td>
                 </tr>
                 <tr>
                   <td>10 days: $65 per tan</td>
                   <td>10 days: $55 per tan</td>
                 </tr>
                 <tr>
                   <td>2x a month: $70 per tan</td>
                   <td>2x a month: $60 per tan</td>
                 </tr>
               </tbody>
             </Table>
           </div>
         ),
         match_zip_code_and_address : "Your address doesn't match the initial zip code provided. If you change the zip code, you'll need to start again",
         no_valets_available: "No Valets available for your application now. Please try again with different times.",
         booking_time_out: "You booking time out, will restart your booking.",
         choose_address_suggestions: "Please choose an address from the suggestions",
         add_phone_number: "Please add your phone number to your profile to book an appointment. We will only use it to send you important information about your booking.",
         no_service_area: "Sorry, we don't service this area."
       };
