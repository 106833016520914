import React, {Component} from 'react'
import Modal from "react-bootstrap/Modal";
import YellowButton from "../../Shared/Buttons/YellowButton";


interface IProps {
    content: string ;
    dynamicContent?:()=>JSX.Element;
    id:string;
    onClick?: () => void;
    showConfirmation?: boolean;
    onHide: () => void;
    show: boolean;
    size?:'sm'|'lg'|'xl'|undefined;
    disabled?:boolean;
    leftButtonText?: string;
    rightButtonText?: string;
}

export default class CustomPopup extends Component<IProps> {


    render(){
        const {content, onClick=()=>null, showConfirmation, show, onHide, size='sm', dynamicContent, disabled, leftButtonText = 'Yes', rightButtonText = 'No'} = this.props;
        return (
            <Modal
                show={show}
                size={size}
                aria-labelledby="contained-modal-title-vcenter"
                keyboard
                backdrop
                onHide={onHide}
            >
                <Modal.Body>
                    <div className={"modal-text"}>
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    {/* {dynamicContent && dynamicContent()} */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {showConfirmation ?
                        (<><YellowButton disabled={disabled}  onClick={onClick} value={leftButtonText}
                                         additionalClass={'w-100 without_box_shadow'}/>
                            <YellowButton disabled={disabled} onClick={onHide} value={rightButtonText}
                                          additionalClass={'white_background w-100 without_box_shadow'}/></>)
                        :
                        <YellowButton disabled={disabled} onClick={onHide} value={'ok'}
                                      additionalClass={'w-100'}/>
                    }
                </Modal.Footer>
            </Modal>

        );
    }
}