export default {
  booking: {
    notes: {
      toLong: "The current note exceed the max size of 1024 characters",
    },
  },
  address: {
    note_parking: "The current note exceed the max size of 200 characters",
  },
  myBookings: {
    rescheduling:
      "Rescheduling can not happen within 4 hours of your appointment",
  },
};