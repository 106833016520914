const PROD = {
  apiUrl: 'https://be.sprayvalet.com',
  baseUrlForNavLinks: 'https://sprayvalet.com',
  MIXPANEL_ID: '83bd3f714c931536c53cc398c7312c77',
  STRIPE_TOKEN: 'pk_live_XN7RgIrQCBpPuauYsTXuGRrn00A2zqKChZ',
  ONE_SIGNAL_TOKEN: 'fd2715a0-5749-4e0e-82e2-24373337ea92',
  REACT_APP_FACEBOOK_PIXEL_ID: '528069954582507',
  REACT_APP_FACEBOOK_ACCESS_TOKEN: 'EAAwEzNIZA9WMBAGTOS9m18uCSplJzZBYpg5hIH0F3jmAEEtTZAlVJ2D89RyrUSlf8xnZBVy6AVOspUhZB8QKLkX8ZCEKkUmblQs910HuVMK1lFKxLvh3LIZCOZCvBiaGZAo7scCcFJmWTJepjD84IzYlwnmOZCBi06K4xjtZCZBKBdM0SP1MpY3QlWwRjMsF7OI6CosZD',
  isProd: true,
  chat_url: 'wss://asgi.sprayvalet.com/ws/chat/',
  RECAPTCHA_SITE_KEY: '6LeAiW4nAAAAAG0W98summjtJcDSE_JkNiv5Q-99',
};

// Current config DEV
// console.log('CONFIG STAGE!');

const DEV = {
  apiUrl: 'https://sprayvalet-be.westlinkclient.com',
  baseUrlForNavLinks: 'https://sprayvalet.westlinkclient.com',
  MIXPANEL_ID: '01d59568b4b80e5339f9e1ea1f4b194d',
  // STRIPE_TOKEN: 'pk_test_HxqcwU5YDHhNtaBM9I4Ppvdm00mYde1YUi',
  STRIPE_TOKEN:
    'pk_test_51FOnM8Agb0De3lamSnFxDF6hjfRW4TWPboOZlkKSgEMLMiTlCP5wYB7C53oZMrzb8UTJy4BW2m3vlX7WH2uG6A7E00NUgkwtOh',  
  ONE_SIGNAL_TOKEN: '82e717f8-3b45-470e-a9fc-870fcd915dd0',
  REACT_APP_FACEBOOK_PIXEL_ID: '',
  REACT_APP_FACEBOOK_ACCESS_TOKEN: '',
  isProd: false,
  chat_url: 'wss://sprayvalet-asgi.westlinkclient.com/ws/chat/',
  RECAPTCHA_SITE_KEY: '6LeAiW4nAAAAAG0W98summjtJcDSE_JkNiv5Q-99',
};

// const STAGE = {
//   apiUrl: 'https://staging-be.sprayvalet.com',
//   baseUrlForNavLinks: 'https://staging.sprayvalet.com',
//   MIXPANEL_ID: '83bd3f714c931536c53cc398c7312c77',
//   STRIPE_TOKEN: 'pk_live_XN7RgIrQCBpPuauYsTXuGRrn00A2zqKChZ',
//   ONE_SIGNAL_TOKEN: '82e717f8-3b45-470e-a9fc-870fcd915dd0',
//   REACT_APP_FACEBOOK_PIXEL_ID: '',
//   isProd: false,
//   chat_url: 'wss://staging-asgi.sprayvalet.com/ws/chat/',
// };

// const STAGE = {
//   apiUrl: 'https://staging-be.sprayvalet.com',
//   baseUrlForNavLinks: 'https://staging.sprayvalet.com',
//   MIXPANEL_ID: '83bd3f714c931536c53cc398c7312c77',
//   STRIPE_TOKEN: 'pk_live_XN7RgIrQCBpPuauYsTXuGRrn00A2zqKChZ',
//   ONE_SIGNAL_TOKEN: '82e717f8-3b45-470e-a9fc-870fcd915dd0',
//   REACT_APP_FACEBOOK_PIXEL_ID: '',
//   isProd: false,
//   chat_url: 'wss://staging-asgi.sprayvalet.com/ws/chat/',
// };
const config = PROD;
export default config;