import http from "./http";
import { ITimeRequestParams} from "../Interfaces/BookingInterfaces";

interface IAnyParamsObject{
    [key:string]: string|boolean|number;
}

class BookingService{
    public static getAvailableTimes = (id:number | null, params:ITimeRequestParams) => {
        return http.get(`/available-times/${id?id+'':''}`, { params })
    };

    public static getAvailableTimesByZipCode = (params: IAnyParamsObject) =>{
        return http.get('/available-times/by-zip-code/', {params})
    };

    public static getValetAvailableTime = (valetId:number, params:ITimeRequestParams) => {
        return http.get(`/valet-available-times/${valetId}/`,{params})
    };

    public static getBookingsList = (params:IAnyParamsObject) => {
        return http.get('/appointments/', { params })
    };

    public static editBooking = (bookingObject:any) => {
        return http.put(`/appointments/${bookingObject.id}/`, { ...bookingObject })
    };

    public static cancelBooking = (id:number) => {
        return http.patch(`/appointments/${id}/`, {status: 'Cancelled'})
    };

    public static approveBooking = (id:number, params:IAnyParamsObject)=>{
        return http.put(`/appointments/${id}/confirm/`, params)
    };

    public static savePromo = (id:number, params:IAnyParamsObject) => {
        return http.put(`/booking/${id}/check-discount/`, params)
    };

    // PATCH /booking/{id}/set-notes/
    public static setNotes = (id: number, params: IAnyParamsObject) => {
        return http.patch(`/booking/${id}/set-notes/`, params)
    };
// {
//   "notes": "test"
// }

    public static createBookingStep1 = (data:any) => {
        return http.post(`/booking/`, data)
    };

    public static createBookingStep2 = (id:number, data:any) => {
        return http.put(`/booking/${id}/`, data)
    };

    public static getPricesForBooking = (id:number, address:number, date:any='', promocode: string, gift_card: string) => {
        const additionalData = date ? { date } : { address };
        const data = { promocode, gift_card, ...additionalData }
        return http.put(`/booking/${id}/price/`,data)
    }

    public static getReschedulePricesForBooking = (id:number, date:any='') =>{
        return http.put(`/booking/${id}/price/`, {date})
    }
    
    public static rescheduleItem = (id:number,date:any='') => {
        return http.put(`/appointments/${id}/request-to-reschedule/`,{date})
    }

    public static checkZipCode = (params:IAnyParamsObject) => {
        return http.get(`/public-prices/check-zip-code/`, { params })
    };
}

export default BookingService;