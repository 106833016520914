import React, { Component } from "react";
import "./ArrowButton.css";
import { Button } from "react-bootstrap";

interface IProps {
    direct: 'right' | 'left';
    disabled?: boolean;
    onClick: () => void;
}

export default class ArrowButton extends Component<IProps> {
    render() {
        const { direct, disabled = false, onClick } = this.props
        return (
            <button className={ `arrow-btn ${direct} ${disabled ?  'disabled': ''}` } onClick={onClick}></button>
        );
    }
}