import http from "./http";

class YelpService {
    public static getYelpInfo = () => {
        return http.get(`/yelp/`);
    };

    public static getAppointment = (id:number) =>{
        return http.get(`/appointments/${id}/`)
    }
}

export default YelpService;