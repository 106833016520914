import React from 'react';
import Button from "react-bootstrap/Button";


const RoundButton = (props:{value:any, _onClick?: () => void}) => {
    return (
        <Button onClick={props._onClick} type={'button'} className={'round_button'}>{props.value}</Button>
     );
}

export default RoundButton;