import React, {Component} from 'react'
import Modal from "react-bootstrap/Modal";
import YellowButton from "../../Shared/Buttons/YellowButton";


interface IProps {
    title: string;
    content: string|JSX.Element;
    dynamicContent?:()=>JSX.Element;
    id:string;
    onClick?: () => void;
    showConfirmation?: boolean;
    onHide: () => void;
    show: boolean;
    size?:'sm'|'lg'|'xl'|undefined;
    disabled?:boolean;
}

 class Popup extends Component<IProps> {


    render(){
        const {title, content, onClick=()=>null, showConfirmation, show, onHide, size='sm', dynamicContent, disabled} = this.props;
        return (
            <Modal
                show={show}
                size={size}
                aria-labelledby="contained-modal-title-vcenter"
                keyboard
                backdrop
                onHide={onHide}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className={'modal-title'}>{title}</h4>
                    <div className={"modal-text"}>
                        {content}
                        {dynamicContent && dynamicContent()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {showConfirmation ?
                        (<><YellowButton disabled={disabled}  onClick={onClick} value={'yes'}
                                         additionalClass={'w-100 without_box_shadow'}/>
                            <YellowButton disabled={disabled} onClick={onHide} value={'no'}
                                          additionalClass={'white_background w-100 without_box_shadow'}/></>)
                        :
                        <YellowButton disabled={disabled} onClick={onHide} value={'ok'}
                                      additionalClass={'w-100'}/>
                    }
                </Modal.Footer>
            </Modal>

        );
    }
}

export default Popup;