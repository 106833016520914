import http from "./http";
import config from "../config";

  type EventName = 'Purchase' | 'PageView' | 'AppDownload' | 'StartBooking' | 'Initial Checkout' | 'Add to cart';

  interface EventParams {
      access_token: string;
      pixelId: string;
      eventName: string;
      data: EventData;
    }

  interface EventData {
      [key: string]: any;
  }

  interface RequestEventData {
    event_name: string;
    action_source: string;
    event_source_url: string;
    user_data?: {
      em?: string;
      ph?: string;
      fn?: string;
      ln?: string;
      dob?: {
        day: number;
        month: number;
        year: number;
      };
      ct?: string;
      st?: string;
      zp?: string;
      country?: string;
      external_id?: string;
      client_ip_address?: string;
      client_user_agent?: string;
      fbc?: string;
      fbp?: string;
      subscription_id?: string;
    };
    custom_data?: {
      content_name?: string;
      content_category?: string;
      content_ids?: string[];
      contents?: any[];
      value?: number;
      currency?: string;
      num_items?: number;
      search_string?: string;
      status?: string;
      predicted_ltv?: number;
      order_id?: string;
      item_number?: string;
    };
    event_time: number;
    test_event_code?: string;
  };

  const access_token = config.REACT_APP_FACEBOOK_ACCESS_TOKEN;
  const pixelId = config.REACT_APP_FACEBOOK_PIXEL_ID;

  export default class FacebookConversionApiService {
    public static async trackPurchase({ data }: EventData) {
      const eventName:EventName = 'Purchase';
      await FacebookConversionApiService.sendEvent({ access_token, pixelId, eventName, data });
    }

    public static async trackAddToCart({ data }: EventData) {
      const eventName:EventName = 'Add to cart';
      await FacebookConversionApiService.sendEvent({ access_token, pixelId, eventName, data });
    }

    public static async trackInitialCheckout({ data }: EventData) {
      const eventName:EventName = 'Initial Checkout';
      await FacebookConversionApiService.sendEvent({ access_token, pixelId, eventName, data });
    }
  
    public static async trackPageView({ data }: EventData) {
      const eventName:EventName = 'PageView';
      await FacebookConversionApiService.sendEvent({ access_token, pixelId, eventName, data });
    }

    public static async trackStartBooking({ data }: EventData) {
      const eventName:EventName = 'StartBooking';
      await FacebookConversionApiService.sendEvent({ access_token, pixelId, eventName, data });
    }
  
    public static async trackAppDownload({ data }: EventData) {
      const eventName:EventName = 'AppDownload';
      await FacebookConversionApiService.sendEvent({ access_token, pixelId, eventName, data });
    }
  
    private static async sendEvent({ access_token, pixelId, eventName, data }: EventParams) {
      const url = `https://graph.facebook.com/v16.0/${pixelId}/events?access_token=${access_token}`;
      console.log(data);

      let dataObj:RequestEventData = {
        event_name: eventName,
        action_source: 'website',
        event_source_url: window.location.href,
        event_time: Math.floor(Date.now() / 1000),
      };

      if(data.user_data) {
        dataObj.user_data = {
          ...data.user_data,
        } 
      };

      if(data.custom_data) {
        dataObj.custom_data = {
          ...data.custom_data,
        } 
      };

      const payload = {
        data: [dataObj]
      };

      await http.post(url, payload);
    }
  }
