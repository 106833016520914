import React, { Component } from "react";
import Logo from "../../assets/WhiteLogo.svg";
import WhiteButton from "../../Shared/Buttons/WhiteButton";
import { RouterProps } from "react-router";
import { Link } from "react-router-dom";

export default class ResetPassword extends Component<RouterProps> {
  componentDidMount(): void {
    const navbar = document.querySelector("nav");
    if (navbar) navbar.classList.add("hide");
  }
  componentWillUnmount(): void {
    const navbar = document.querySelector("nav");
    if (navbar) navbar.classList.remove("hide");
  }
  render() {
    return (
      <div id="reset_password" className={"min-vh-100 d-flex justify-content-center align-items-center"} >
        <div className={"logo_container"}>
          <Link to={"/"}>
            <img src={Logo} alt={"Logo"} />
          </Link>
        </div>
        <div className="content_container">
            <h1>Done!</h1>
            <ul>
                <li className={'d-flex'}>
                    <div className={'icon'}><img src={require('../../assets/icons/2.svg')} alt={'house'}/>
                    </div>
                    <div className={'instruction'}>
                      We emailed you the instructions on how to reset your password.<br/>Check your inbox!<br/>
                      If the email address is not valid or not associated to a valid account, no email will be sent.
                    </div>
                </li>
                <WhiteButton
                  onClick={() => this.props.history.push("/Login")}
                  value={"ok"} addClass={'w-70'}
                />
            </ul>
        </div>
      </div>
    );
  }
}
