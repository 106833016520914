import React from 'react';

const TooltipsContent = (isPayAsYouGo: boolean, prices: any = {}, isSubscribed: boolean) => {
  const base_fee = isPayAsYouGo ? prices.base_fee : prices.members_base_fee;
  const tips = isPayAsYouGo
    ? prices.pay_as_you_go_tips
    : prices.subscription_tips;
  return (
    <div className="tooltip_container">
      <div className="d-flex flex-column w-100">
        {base_fee !== null ? (
          <span className="d-flex justify-content-between">
            Base price{" "}
            <p className="tooltip_price">
              ${base_fee + prices.service_area_fee}
            </p>
          </span>
        ) : null}
        {!!prices.hotel_base_fee ? (
          <span className="d-flex justify-content-between">
            Hotel base price{" "}
            <p className="tooltip_price">
              ${prices.hotel_base_fee + prices.service_area_fee}
            </p>
          </span>
        ) : null}
        {!isSubscribed && !!prices.night_base_fee ? (
          <span className="d-flex justify-content-between">
            Night base price{" "}
            <p className="tooltip_price">
              ${prices.night_base_fee + prices.service_area_fee}
            </p>
          </span>
        ) : null}
        {!!prices.hotel_night_base_fee ? (
          <span className="d-flex justify-content-between">
            Hotel night base price{" "}
            <p className="tooltip_price">
              ${prices.hotel_night_base_fee + prices.service_area_fee}
            </p>
          </span>
        ) : null}
        {!!prices.parking_fee_for_hotels ? (
          <span className="d-flex justify-content-between">
            Parking fee{" "}
            <p className="tooltip_price">${prices.parking_fee_for_hotels}</p>
          </span>
        ) : null}
        {!!prices.multiple_people_appointment ? (
          <span className="with_top_line d-flex justify-content-between">
            More than 1 person per tan{" "}
            <p className="tooltip_price">
              ${prices.multiple_people_appointment}
            </p>
          </span>
        ) : null}
        {!!tips ? (
          <span className="with_top_line d-flex justify-content-between">
            <p>
              <p>20%</p> tip{" "}
            </p>
            <p className="tooltip_price">${tips}</p>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default TooltipsContent;
