import React, { Component, ElementType, FormEvent } from "react";
import "./FormInput.css";
import Form from "react-bootstrap/Form";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/helpers";
import { observer } from "mobx-react";
import { FormControlProps } from "react-bootstrap";
import BookingStore from "../Stores/BookingStore";
import PromoStore from "../Stores/PromoStore";
import CloseBtn from "./../assets/icons/closeIcon.svg";

interface IState {
  percent: null | number;
  showInputPromo: boolean;
  promoSuccess: boolean;
  promo: string;
  promoFailed: boolean;
  type: string;
  discount: null | number;
	showRightIcon?: boolean,
	rightButtonText?: string;
	showRightCloseButton: boolean;
}

interface IProps {
	controlId: string;
	additionClassName?: string;
	placeholder?: string;
	value?: string;
	leftIconSrc?: any;
	leftIconAlt?: string;
	rightIconSrc?: any;
	rightIconAlt?: string
	isValid?: boolean;
	invalidMsg?: string;
	// onChange: (event: FormEvent<
	// 	ReplaceProps<
	// 	  ElementType<any>,
	// 	  BsPrefixProps<ElementType<any>> & FormControlProps
	// 	>
	//   >) => void;
}
@observer
export default class FormInput extends Component<IProps, IState> {

		state = {				
			percent: null,
			showInputPromo: false,
			promoSuccess: false,
			promo: "",
			promoFailed: false,
			type: "promocode",
			discount: null,
			showRightIcon: false,
			rightButtonText: 'APPLY',
			showRightCloseButton: false,
		};

		async componentDidMount() {
			let state: any = { type: "", discount: null };
			if (BookingStore.bookingData.promocode) {
				state = {
					type: "promocode",
					promo: BookingStore.bookingData.promocode
				};
			}
			if (BookingStore.bookingData.gift_card) {
				state = {
					type: "gift_card",
					promo: BookingStore.bookingData.gift_card
				};
			}
			// if (state.discount) {
			// 	PromoStore.type = state.type;
			// 	PromoStore.promo = state.promo;
			// 	await this.checkPromo();
			// }
		}
	
		checkPromo = async () => {
			try {
				const promo = await BookingStore.checkPromo_new(
					PromoStore.promo,
					PromoStore.type
				);
				if (promo.promocode_full || promo.gift_card) {
					PromoStore.promoSuccess = true;
					PromoStore.discount = BookingStore.discount.value;
	
					PromoStore.showInputPromo = false;
	
					PromoStore.percent = null;
					PromoStore.promoFailed = false;
					this.setState({showRightIcon: true, rightButtonText: ''})
				} else {
					throw new Error(`Invalid ${PromoStore.type}`);
				}
			} catch (e) {
				PromoStore.promoFailed = true;
				this.setState({showRightIcon: false, rightButtonText: 'APPLY'});
			}
		};

		closePromo = async () => {
			this.setState({showRightIcon: false, rightButtonText: 'APPLY'});
			BookingStore.destroyPromocode_new();
		};
	
		promoCode = () => {
			if (BookingStore.bookingData_new.purchase_method !== "Subscription") {
				PromoStore.promo = ''
				PromoStore.showInputPromo = !PromoStore.showInputPromo;
			} else {
				PromoStore.promo = ' ' // for error You can't use promo code or gift card if you use your ...
			}
		};
	
		changeType = (event: React.ChangeEvent<HTMLInputElement>) => {
			const { id } = event.currentTarget;
			PromoStore.type = id;
		};
	
		getCodeValue() {
			let { discount } = PromoStore;
			if (BookingStore.bookingData_new.purchase_method === "Subscription") {
				discount = 0;
			}
			return BookingStore.discount.type === "percent"
				? `${discount} %`
				: `$${discount} `;
		}

		render() {
			const { value, controlId, additionClassName, placeholder, leftIconSrc = null, leftIconAlt = '', rightIconSrc = null, rightIconAlt = '', isValid = true, invalidMsg = 'Fill in this field' } = this.props;
			const { showRightIcon , rightButtonText } = this.state;

			const addClass = `${(value === '' && isValid ) ? 'invalid' : ''}  ${!additionClassName ? '' : additionClassName}`;
			const {
				// showInputPromo,
				promo,
				promoFailed,
				// promoSuccess,
				// type
			} = PromoStore;
			// const { discountValue } = BookingStore;			
			var styles: React.CSSProperties = {
				paddingRight: '20px',
				paddingLeft: '20px',
			};
			if (leftIconSrc) {
				styles = {
					...styles,
					paddingLeft: '40px'
				};
			}
			if (rightIconSrc && showRightIcon) {
				styles = {
					...styles,
					paddingRight: '40px'
				};
			}
			if (rightButtonText) {
				styles = {
					...styles,
					paddingRight: '82px'
				};
			}
			return (
				<Form.Group controlId={ controlId } className={`form-input ${addClass}`}>
					<Form.Control
						type={"text"}
						placeholder={ placeholder }
						onChange={(
							event: FormEvent<
								ReplaceProps<
									"input",
									BsPrefixProps<"input"> & FormControlProps
								>
							>
						) => (PromoStore.promo = event.currentTarget.value || "")}
						value={promo}
						style={ styles }
					/>
					{leftIconSrc && (
						<img
							src={leftIconSrc}
							alt={leftIconAlt}
							className={'left-icon'}
						/>
					)}
					{rightIconSrc && showRightIcon && (
						<>
							<img
								src={CloseBtn}
								onClick={this.closePromo}
								className={'right-close-x-btn'}
							/>
							<img
								src={rightIconSrc}
								alt={rightIconAlt}
								className={'right-icon'}
							/>
						</>
					)}
					{rightButtonText && (
						<span className="right-btn" onClick={this.checkPromo}>
							{rightButtonText}
						</span>
					)}
					{ promoFailed ? <span className="error-msg" style={{display: 'block'}}>Invalid Promo Code</span> : <></> }
					{/* { promoSuccess ? 
						<div className="promo_success">
							<h6>
								<i className={"fas fa-check-circle"} /> you got a {discountValue}{" "}
								discount
							</h6>
							<div
								className="inline_button"
								onClick={async () => {
									BookingStore.destroyPromocode();
									BookingStore.bookingData.gift_card = "";
									BookingStore.bookingData.promocode = "";
									BookingStore.discount.value = 0;
									PromoStore.promoSuccess = false;
									PromoStore.showInputPromo = true;
								}}
							>
								edit
							</div>
						</div> : <></>						
					} */}
				</Form.Group>
			);
		}
}