import React, { Component, RefObject } from "react";
import changeActiveItem from "../../../Utils/changeActiveItem";
import { observer } from "mobx-react";
import { ICreditCardAfterSave } from "../../../Interfaces/CreditCardsInterfaces";
import PaymentStore from "../../../Stores/PaymentStore";

interface IProps {
  img: string;
  value: ICreditCardAfterSave;
  editItem: (id: ICreditCardAfterSave) => void;
  deleteItem: (id: number) => void;
  hideControl?: boolean;
}

@observer
class PaymentSettingsItem extends Component<IProps> {
  itemRef: RefObject<HTMLDivElement> = React.createRef();

  render() {
    const { img, value, editItem, deleteItem, hideControl } = this.props;
    const makeActive = async (event: React.MouseEvent<HTMLDivElement>) => {
      await PaymentStore.changeDefaultCard(value);
      if (this.itemRef.current) {
        changeActiveItem(this.itemRef.current, "active");
      }
    };
    const isDefault = value.id === PaymentStore.defaultPaymentMethod.id;
    const defaultClassName =
      "profile_item_container d-flex flex-column justify-content-between";
    const containerClassName = isDefault ? `${defaultClassName} active` : defaultClassName;
    return (
      <div
        className={containerClassName}
        onClick={makeActive}
        ref={this.itemRef}
      >
        <div className={"d-inline-flex flex-grow-1 align-items-center"}>
          <div className={`round`} />
          <i className={img} />
          <div className="profile_item">{`${value.card_type} **${value.last_4}`}</div>
        </div>
        {!hideControl && (
          <div
            className={"inline_buttons_container d-flex justify-content-end "}
          >
            <div className={"inline_button"} onClick={() => editItem(value)}>
              Edit
            </div>
            <div
              className={"inline_button"}
              onClick={() => deleteItem(value.id)}
            >
              Delete
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PaymentSettingsItem;
