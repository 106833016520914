import React, {Component} from 'react';
import DateFormatting from "../../../Utils/DateFormatting";
import moment from 'moment';
import changeActiveItem from "../../../Utils/changeActiveItem";
import BookingStore from "../../../Stores/BookingStore";
import {observer} from "mobx-react";
import SpinnerComponent from "../../../Shared/Spinner";
import TimeStore from "../../../Stores/TimeStore";
import DayCard from './DayCard';

interface IState {
    dayToDisplay: moment.Moment[];
}

@observer
export default class Calendar extends Component {
    state: IState = {
        dayToDisplay: DateFormatting.week(BookingStore.bookingData.date)
    };
    clickDay = async (event: React.MouseEvent<HTMLDivElement>, day: moment.Moment) => {
        if (moment().diff(day, 'day') <= 0) {
            changeActiveItem(event.currentTarget, 'active');
            TimeStore.resetReserveTime();
            await BookingStore.changeBookingData('date', day);
        }
    };
    clickBack = () => {
        this.setState({dayToDisplay: DateFormatting.subtractWeek(this.state.dayToDisplay[0])})
    };
    clickForward = () => {
        this.setState({dayToDisplay: DateFormatting.week(this.state.dayToDisplay[1])})
    };

    render() {
        return (
            <div className={'d-flex flex-nowrap calendar'}>
                <div className={'calendar_block arrows'} onClick={this.clickBack}><i className={'fas fa-arrow-left'}/>
                </div>
                {this.state.dayToDisplay.map((day: moment.Moment, i: number) => {
                    const today = day.isSame(moment(), 'day');
                    const activeDate = day.isSame(BookingStore.bookingData.date, 'day');

                    return (
                        <div
                            className={`calendar_block calendar_item d-flex flex-column ${today ? 'today' : ''} ${activeDate ? 'active' : ''}`}
                            key={`${i}-${day.toISOString()}`}
                            onClick={(event) => this.clickDay(event, day)}>
                            {TimeStore.isLoading && activeDate ?
                                <SpinnerComponent/> : 
                                <DayCard day={day.format('DD')} weekDay={day.format('ddd')}/>
                            }
                        </div>)
                })}
                <div className={'calendar_block arrows'} onClick={this.clickForward}><i
                    className={'fas fa-arrow-right'}/></div>
            </div>
        );
    }
}