import React, { Component } from "react";
import SubNavbar from "../../../Shared/Navbars/SubNavbar";
import OutlineButton from "../../../Shared/Buttons/OutlineButton";
import { Redirect, RouterProps } from "react-router";
import PaymentSettingsItem from "./PaymentSettingsItem";
import { observer } from "mobx-react";
import PaymentStore from "../../../Stores/PaymentStore";
import AuthStore from "../../../Stores/AuthStore";
import { ICreditCardAfterSave } from "../../../Interfaces/CreditCardsInterfaces";
import Popup from "../../Popups/Popup";
import ModalStore from "../../../Stores/ModalStore";

@observer
export default class PaymentSettings extends Component<RouterProps> {
  deleteItem = (id: number) => PaymentStore.deleteCreditCard(id);
  editItem = (id: ICreditCardAfterSave) => {
    this.props.history.push("/add_new_card", { title: "edit credit card", id: id });
  };
  componentDidMount(): any {
    if (!AuthStore.isLogged) return <Redirect to={"/"} />;
  }
  render() {
    return (
      <div className={"settings_container"}>
        <SubNavbar active={2} />
        <div className="container payment_settings">
          <h1>payments</h1>
          {!PaymentStore.paymentSettings.length ? (
            <div className="mt-5 mb-5 text-center">
              Add a bank card to start booking appointments
            </div>
          ) : (
            <>
              <h4>Credit Card</h4>
              <div className={"items_container"}>
                {PaymentStore.paymentSettings.map(
                  (creditCard: ICreditCardAfterSave, i: number) => (
                    <PaymentSettingsItem
                      value={creditCard}
                      img={"fas fa-credit-card"}
                      deleteItem={this.deleteItem}
                      editItem={this.editItem}
                      key={`${i}-${creditCard.card_number}`}
                    />
                  )
                )}
              </div>
            </>
          )}
          <OutlineButton
            value={"+ add new credit card"}
            onClick={() =>
              this.props.history.push("/add_new_card", {
                title: "add credit card"
              })
            }
          />
        </div>
        <Popup
          title={"Error!"}
          content={PaymentStore.errorOnComponent.modalError}
          id={"paymentError"}
          onHide={() => ModalStore.toggleModal("paymentError", false)}
          show={ModalStore.paymentError}
        />
      </div>
    );
  }
}
