import React, { Component } from "react";
import { RouterProps } from "react-router";
import BookingStore from "../../../Stores/BookingStore";
import StorageService from '../../../Services/StorageService';
import { observer } from "mobx-react";
import Dropdown from 'react-bootstrap/Dropdown';
import CustomNavBar from "../../../Shared/Navbars/CustomNavBar";
import default_avatar from './../../../assets/icons/default_avatar.png';
import AddressesStore from "../../../Stores/AddressesStore";
@observer
class SeeYouPage extends Component<RouterProps> {
  state = { e: '' };
  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  week_of_days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
  handleBackButtonEvent = (e: PopStateEvent) => {    
    e.preventDefault();
    window.location.href = "/";
    AddressesStore.clear();
    BookingStore.bookingData_new = null;
  };

  componentWillUnmount(): void {
    window.removeEventListener('popstate', this.handleBackButtonEvent);
  }
  
  componentDidMount() {
    window.addEventListener('popstate', this.handleBackButtonEvent);
    const image: any = document.getElementById('valet_avatar');
    if (image) {
      image.addEventListener('load', function() {
      });

      image.addEventListener('error', function() {
          console.error('Error loading the image.');
          image.src = default_avatar;
      });
    }
	}
  constructor(props: RouterProps) {
    super(props);
    StorageService.getItem('zip_code')
      .then((response: string) => {
        if (!response) {
          this.props.history.push('/');
        }
      })
  }
  render() {
    const { number_of_people, address, booking_date, booking_time, valet_avatar, valet_first_name, location_type, location_unit, direct_comment, hotel_name } = BookingStore.bookingData_new;
    const date = new Date(booking_date);
    const template_date = (typeof(booking_date) !== 'undefined') ? `${this.week_of_days[date.getDay()]}, ${this.months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}` : '';
    return (
      <div>
        <CustomNavBar redirction={false} />
        <div className={'thank_you_page'}>
          <div className={'thank_you_form center-text'}>
            <div className="d-flex justify-content-center logo-container">
              <img
                src={require("../../../assets/icons/thankyou_logo.svg")}
                alt={'thankyou_logo'}            
              />
            </div>
            
            <div>
              <p style={{fontSize: 21, marginBottom: 40}}><strong>YOUR APPOINTMENT IS <span style={{color: '#219653'}}>CONFIRMED.</span></strong></p>
              { number_of_people === 1 ? <p>Individual</p> : <p>Group</p>}
              <p>{template_date}</p>
              <p>{booking_time}</p>
              <p style={{textAlign: 'center', margin: 'auto', width: '335px', marginBottom: 10}}>{address}</p>
              <p>{(location_type && location_type !== '' && location_type !== 'Hotel') ? location_type : ''}{location_type === 'Hotel' ? hotel_name : ''}{(location_type && location_type !== '' && location_unit && location_unit !== '') ? ', ' : ''}{(location_unit && location_unit !== '') ? location_unit : ''}</p>
              <p style={{textAlign: 'center', margin: 'auto', width: '335px', marginBottom: 10}}>{direct_comment}</p>
              <Dropdown.Divider style={{ marginTop: 20, marginBottom: 30}} />
              <p style={{color: '#828282'}}>Your valet</p>
              <img
                id="valet_avatar"
                src={valet_avatar ? valet_avatar : default_avatar}
                alt="Your_Valet_Avatar"
                style={{width: 80, height: 80, borderRadius: '50%', marginBottom: 10, objectFit: 'cover'}} 
              />
              <p style={{color: '#1A1A1A'}}>{valet_first_name}</p>
            </div>
          </div>
          <div className={'see_you_form center-text'} style={{paddingBottom: '50px'}}>
            <p style={{fontSize: 26}}><strong>SEE YOU SOON!</strong></p>
            <div>
              <img
                src={require("../../../assets/icons/favorite.svg")}
                alt={'favorite_logo'}  
                className={'logo_container'}                      
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SeeYouPage;
