import React, {Component, FormEvent} from 'react'
import Modal from "react-bootstrap/Modal";
import YellowButton from "../../Shared/Buttons/YellowButton";


interface IProps {
    onHide: () => void;
    onGoTo: () => void;
    show: boolean;
}

class AlreadyRegisteredPopup extends Component<IProps> {
    render(){
        const {show, onHide, onGoTo} = this.props;
        return (
            <Modal
                className='already-registered-popup-modal'
                show={show}
                size={"sm"}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop
                onHide={onHide}
            >
                <Modal.Body>
                    <h4 className={"modal-text"}>We already have an account registered with this email address. Go to <span onClick={onGoTo}>Login</span></h4>
                </Modal.Body>
                <Modal.Footer>
                    <YellowButton additionalClass={'w-100'}
                        onClick={onHide}
                        value={'OK'}
                    />
                </Modal.Footer>
            </Modal>

        );
    }
}

export default AlreadyRegisteredPopup;