import React, { Component, RefObject } from 'react';
import CustomInputNumber from '../../../Shared/InputTypeNumber';
import DateFormatting from '../../../Utils/DateFormatting';
import TimeButtonsComponent from './TimeButtonsComponent';
import YellowButton from '../../../Shared/Buttons/YellowButton';
import Calendar from './Calendar';
import BookingStore from '../../../Stores/BookingStore';
import { observer } from 'mobx-react';
import StepsComponent from '../../../Shared/StepsComponent';
import { RouterProps } from 'react-router';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import AuthStore from '../../../Stores/AuthStore';
import Popup from '../../Popups/Popup';
import { PopupContent } from '../../../Constants/PopupContent';
import TooltipComponent from '../../../Shared/Tooltip';
import TimeStore from '../../../Stores/TimeStore';
import ValetsStore from '../../../Stores/ValetsStore';
import queryString from 'query-string';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import FacebookConversionApiService from '../../../Services/FacebookConversionApiService';
import config from '../../../config';

import PaymentStore from "../../../Stores/PaymentStore";
import moment from 'moment';
import CustomStepsComponent from '../../../Shared/CustomStepsComponent';
import RadioButton from '../../../Shared/Buttons/RadioButton';
import CheckBoxButtonInBooking from '../../../Shared/Buttons/CheckBoxButtonInBooking';
import CustomLocationButton from '../../../Shared/Buttons/CustomLocationButton';
import CheckBoxImgButton from '../../../Shared/Buttons/CheckBoxImgButton';

import { FormControlProps, Image } from 'react-bootstrap';
import { OptionItem } from '../../../Shared/DropDown.type';
import { RadioItem } from '../../../Shared/Buttons/RadioButton.type';
import DropDown from '../../../Shared/DropDown';
import CustomCalendar from '../../../Shared/CustomCalendar';

import hotelIcon from '../../../assets/icons/hotel.svg';
import hotelIconActive from '../../../assets/icons/hotel-1.svg';
import aptIcon from '../../../assets/icons/apt.svg';
import aptIconActive from '../../../assets/icons/apt-1.svg';
import homeIcon from '../../../assets/icons/home.svg';
import homeIconActive from '../../../assets/icons/home-1.svg';
import otherIcon from '../../../assets/icons/other.svg';
import otherIconActive from '../../../assets/icons/other-1.svg';
import moon_icon from '../../../assets/icons/moon1.svg';
import CustomNavBar from '../../../Shared/Navbars/CustomNavBar';
import StorageService from '../../../Services/StorageService';
import AddressAutocomplete from '../../../Utils/AutoCompleteAddress';
import AddressesStore from '../../../Stores/AddressesStore';
import { IAddress } from '../../../Interfaces/AddressesInterface';
import CustomPopup from '../../Popups/CustomPopup';
import BookingService from '../../../Services/BookingService';
import checkAddress from '../../../Utils/checkAddress';
import BookingDetails from './BookingDetails';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface IProps extends RouterProps {
  groupBook: boolean;
  location: any;
}

interface IState {
  showModal: boolean;
  showNVModal: boolean;
  showChooseModal: boolean;
  showTooltip: boolean;
  selectedOption: boolean;
  checkedId: any;
  checkedImgBtn: any;
  checkedRadio: any;
  selectNumber: any;
  selectDate: any;
  selectTime: any;
  inputHotelName: any;
  inputApt: any;
  inputComment: any;
  directComment: any;
  errorMessage: string;
  errorAddress: string;

  query_zip_code: string;
  prefilledTime: string;
}

@observer
export default class CustomBookingDetails extends Component<IProps, IState, RouterProps> {
  // zip_code: string = '';
  state = {
    showModal: false,
    showTooltip: false,
    showChooseModal: false,
    selectedOption: true,
    checkedId: '',
    checkedImgBtn: 'imgBtn_home',
    checkedRadio: 'rbtn_individual',
    selectNumber: 1,
    inputHotelName: '',
    inputApt: '',
    inputComment: '',
    directComment: '',
    selectDate: { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth(), day: new Date().getUTCDate(), month_name: monthNames[new Date().getMonth()]},
    selectTime: { label: '', value: -1, isIcon: false},
    prefilledTime: '',
    showNVModal: false,
    errorMessage: '',
    errorAddress: '',

    query_zip_code: '',
  };

  target: RefObject<any> = React.createRef();
  address: IAddress = AddressesStore.addressObject; 
  zip_code: string = '';
	constructor(props: IProps) {
    super(props);
    StorageService.getItem('zip_code')
      .then((response: string) => {
        if (response === '' || !response) {
          this.props.history.push('/');
        } else {
          this.zip_code = response;
          this.changeCalendar(this.state.selectDate.year, this.state.selectDate.month, this.state.selectDate.day, this.state.selectDate.month_name)
        }
      })
      .catch(() => (this.zip_code = ''));
  }

  convertTime(time: string, checkIsNight = false): string | boolean {
    const hour = +time.split(':')[0];
    const minutes = time.split(':')[1];
    let period = hour == 0 ? 'AM' : hour == 12 ? 'PM' : hour > 12 ? 'PM' : 'AM';

    if (checkIsNight) {
      if ((period === 'AM' && hour <= 8) || (period === 'PM' && hour >= 9 && hour <= 11)) {
        return true;
      } else {
        return false;
      }
    }

    if (hour == 0) {
      return `12:${minutes} ${period}`;
    }

    if (hour == 12) {
      return `12:${minutes} ${period}`;
    }

    if (hour > 12) {
      const toUSATime = String(hour - 12);
      return toUSATime.length === 1 ? `0${toUSATime}:${minutes} ${period}` : `${toUSATime}:${minutes} ${period}`;
    }
    return `${hour}:${minutes} ${period}`;
  }
  
  componentDidMount(): void {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const zipCode = searchParams.get('zip_code') || '';
    const time = searchParams.get('time') || '';
    const date = searchParams.get('date') || '';

    if (zipCode || time || date) {
      this.setState({
        query_zip_code: zipCode || '',
        selectDate: date ? {
          year: new Date(date).getFullYear(),
          month: new Date(date).getMonth(),
          day: new Date(date).getDate(),
          month_name: monthNames[new Date(date).getMonth()]
        } : this.state.selectDate,
        selectTime: time ? {
          label: this.convertTime(time),
          value: this.convertTime(time),
          isIcon: this.convertTime(time, true),
        } : this.state.selectTime,
        prefilledTime: time ? this.convertTime(time) as string : '',
      }, () => {
      });
    }
    
    if (config.isProd) {
      ReactGA.event({
        category: 'form-submission',
        action: 'WebApp_location_completed',
        label: 'Location Step Completed',
      });
    }
    if (config.REACT_APP_FACEBOOK_PIXEL_ID) {
      ReactPixel.trackCustom('Initial Checkout', {event_time: Math.floor(Date.now() / 1000)});

      const event_data = {
        data: {
          user_data: {
            client_user_agent: window.navigator.userAgent,
            fbp: ''
          }
        }
      };

      let fbpRes = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);

      if ((fbpRes && fbpRes[1])) {
        event_data.data.user_data.fbp = fbpRes[1];
        FacebookConversionApiService.trackInitialCheckout(event_data);
      }
    }
    const input = document.querySelector('#address') as HTMLInputElement;
    if (input) {
      AddressAutocomplete(input, AddressesStore.changeAddressObject);
    }
    if (BookingStore.bookingData_new) {
      const { number_of_people, booking_date, booking_time, booking_time_value, location_type, location_unit, park_type, input_comment, direct_comment, hotel_name } = BookingStore.bookingData_new;
      if (number_of_people && number_of_people >= 2) {
        this.setState({
          checkedRadio: 'rbtn_group',
          selectNumber: parseInt(number_of_people)
        });
      }
      if (booking_date) {
        const temp_date = booking_date.split('/');
        this.setState((previousState) => {
          return {
            ...previousState,
            selectDate: {
              year: parseInt(temp_date[0]),
              month: parseInt(temp_date[1]) - 1,
              day: parseInt(temp_date[2]),
              month_name: previousState.selectDate.month_name
            }
          };
        });
      }
      if (booking_time && booking_time_value) {
        this.setState({
          selectTime: {
            label: booking_time,
            value: booking_time_value
          }
        });
      }
      if (location_type) {
        let temp_location_type = 'imgBtn_other';
        switch (location_type) {
          case 'House':
            temp_location_type = 'imgBtn_home';
            break;
          case 'Apartment':
            temp_location_type = 'imgBtn_apt';
            break;
          case 'Hotel':
            temp_location_type = 'imgBtn_hotel';
            break;
          default:
        }
        this.setState({
          checkedImgBtn: temp_location_type
        });
      }
      if (hotel_name) {
        this.setState({
          inputHotelName: hotel_name
        });
      }
      if (location_unit) {
        this.setState({
          inputApt: location_unit
        });
      }
      if (park_type) {
        let temp_park_type = '';
        switch (park_type) {
          case 'street':
            temp_park_type = 'cbtn_street';
            break;
          case 'garage':
            temp_park_type = 'cbtn_garage';
            break;
          case 'valet':
            temp_park_type = 'cbtn_valet';
            break;
          case 'metered':
            temp_park_type = 'cbtn_metered';
            break;
          case '':
            temp_park_type = '';
            break;
          default:
            temp_park_type = 'cbtn_other';
        }
        this.setState({
          checkedId: temp_park_type
        });
      }
      if (input_comment) {
        this.setState({
          inputComment: input_comment
        });
      }
      if (direct_comment) {
        this.setState({
          directComment: direct_comment
        });
      }      
    }
  }

  changeData = (
    event: any
  ) => {
    const { id, value = '' } = event.currentTarget;
    this.setState({ query_zip_code: value })

    if (id) {
      if (value !== '') {
        this.setState({errorAddress: ''});
      }
      
      AddressesStore.changeAddressObject(id, value);
      AddressesStore.addressObject.zip_code = '';
    }
  };

  getRescheduleCard = () => {
    const creditCard = PaymentStore.paymentSettings.find(
      e => e.id === BookingStore.bookingData.payments
    );
    return creditCard;
  };

  continueBooking = async () => {
    const input = document.querySelector('#address') as HTMLInputElement;
    if (!this.address.address || this.address.address === '') {
      this.setState({errorAddress: 'This field is required.'});
      input.focus();
      return;
    } else {
      this.setState({errorAddress: ''});
    }
    try {
      await checkAddress(this.address.address);
    } catch (error) {
      if (error.message.includes('Invalid')) {
        this.setState({ errorAddress: PopupContent.choose_address_suggestions});
      } else {
        this.setState({ errorAddress: error.message });
      }
      input.focus();
      return;
    }

    // if (this.zip_code !== this.address.zip_code) {
    if (!this.address.address.includes(this.zip_code)) {
      this.setState({ showModal: true });
      input.focus();
      return;
    }

    await BookingService.checkZipCode({zip_code: this.address.zip_code})
    .then((response: any) => {
    })
    .catch((error: any) => {
      if (error.code === 400 || error.code === 404) {
        this.setState({
          showNVModal: true,
          errorMessage: PopupContent.no_service_area
        });
        input.focus();
      }
    });
    const { selectNumber, selectDate, selectTime, checkedId, checkedImgBtn, inputHotelName, inputApt, inputComment, directComment } = this.state;
    
    AddressesStore.addressObject.apartment = checkedImgBtn === 'imgBtn_home' ? 'home' : checkedImgBtn === 'imgBtn_apt' ? 'apt' :
      checkedImgBtn === 'imgBtn_hotel' ? 'hotel' : 'other';
    AddressesStore.addressObject.hotel_name = inputHotelName;
    AddressesStore.addressObject.is_hotel = checkedImgBtn === 'imgBtn_hotel';
    AddressesStore.addressObject.note_parking = directComment;
    AddressesStore.addressObject.room_number = inputApt;
    AddressesStore.addressObject.selected_parking_option = checkedId === 'cbtn_street' ? 'street' : checkedId === 'cbtn_garage' ? 'garage' : checkedId === 'cbtn_valet' ? 'valet' :
      checkedId === 'cbtn_metered' ? 'metered' : 'other';
    if (this.state.showNVModal) {
      return;
    }
    try {
      await AddressesStore.saveNewAddressObjectData();
    } catch (e) {
      this.setState({ errorAddress: e.message });
      return;
    }    
    if (selectTime.value === -1) {
      this.setState({
        showNVModal: true,
        errorMessage: 'Please select time.'
      });
      return;
    }
    const booking_data = {
      selectNumber : selectNumber,
      selectDate : `${selectDate.year}/${selectDate.month + 1}/${selectDate.day}`,
      selectTime : selectTime.label,
      selectTimeValue : selectTime.value,
      location_type : checkedImgBtn === 'imgBtn_home' ? 'home' : checkedImgBtn === 'imgBtn_apt' ? 'apt' :
        checkedImgBtn === 'imgBtn_hotel' ? 'hotel' : 'other',
      hotem_name: inputHotelName,
      apartment : inputApt,
      park_type : checkedId === 'cbtn_street' ? 'street' : checkedId === 'cbtn_garage' ? 'garage' : checkedId === 'cbtn_valet' ? 'valet' : 
        checkedId === 'cbtn_metered' ? 'metered' : inputComment,
      inputComment: inputComment,
      directComment: directComment,
      address: AddressesStore.defaultAddressId      
    };
    try {
      await BookingStore.createNewBooking(undefined, selectNumber);
      await BookingStore.createBookingStep1_new(booking_data);
      BookingStore.setBookingToStorage_new();
      this.props.history.push('/payment_details_booking');
    } catch (e) {
      if (typeof (e.message) !== 'undefined') {
        this.setState({
          showNVModal: true,
          errorMessage: e.message
        });
      }
      // this.setState({ showNVModal: true, errorMessage: BookingStore.errorsOnForm !== undefined ? BookingStore.errorsOnForm.firstStep : 'Something is wrong, please try again.' });        
    }
  };

  showModal = (state: boolean) => this.setState({ showModal: state });

  changeHotelName = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({ inputHotelName: event.target.value})
  }

  changeAPT = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({ inputApt: event.target.value})
  }

  changeComment = (event: React.ChangeEvent<HTMLTextAreaElement>) =>{
    this.setState({ inputComment: event.target.value })
  }

  changeDirectComment = (event: React.ChangeEvent<HTMLTextAreaElement>) =>{
    this.setState({ directComment: event.target.value })
  }

  handleCheckboxChange = (id: string) => {
    if ( id !== 'cbtn_other') 
      this.setState({ inputComment: '', checkedId: id });  
    else 
      this.setState({ checkedId: id });
  }

  handleCheckboxImgBtnChange = (id: string) => {
    this.setState({ checkedImgBtn: id });
  }

  changeRadio = (radio: RadioItem) => {
    if(radio.value === 'rbtn_individual')
      this.setState({ checkedRadio: radio.value, selectNumber: 1 });
    else 
      this.setState({ checkedRadio: radio.value, selectNumber: 2});
  }

  changeNumbers = (option: OptionItem) => {
    this.setState({ selectNumber: option.value });
  }

  changeTime = (option: OptionItem) => {
    this.setState({ selectTime: option });
  }

  changeCalendar = async (year: number, month: number, day: number, month_name: string) => {
    const dateString = `${year}-${month+1}-${day}`;
    const lastDate = moment(dateString, 'YYYY-MM-DD');
    await TimeStore.getAvailableTimeByZipCode(lastDate, this.zip_code);
    if (this.state.selectDate.year != year || this.state.selectDate.month != month || this.state.selectDate.day != day || this.state.selectDate.month_name != month_name){
      await this.setState({
        selectDate: { year, month, day, month_name },
        selectTime: { label: '', value: -1}
      });
    }
  }

  render() {
    const { checkedId, selectNumber, inputHotelName, inputApt, inputComment, directComment, showModal, showNVModal, showChooseModal, errorAddress  } = this.state;
    const { showAllValet, timeStructure } = TimeStore;
    const times = Object.keys(timeStructure);

    var hasAvailableTime = false;

    times.map((time: string, i: number) => {      
      if (timeStructure[time].available) hasAvailableTime = true;
    });

    var timeOptions: OptionItem[] = [];
    times.map((time: string, index: number) => {
      if (timeStructure[time].available){
        var isNight = false;
        var Hours = parseInt(time.slice(0, 2));
        var Mins = time.slice(3, 5);
        var Periods = time.slice(-2);
        if ((Periods === 'AM' && Hours <= 8) || (Periods === 'PM' && Hours >= 9 && Hours <= 11)) {
          isNight = true;
        }
        if (Periods === 'AM' && Hours === 0) {
          Hours = 12;
        }
        timeOptions.push({
          label: `${Hours}:${Mins} ${Periods}`,
          value: time,
          isIcon: isNight
        });
      }
    });

    const isPrefilledAvailable = timeOptions.some((time: any) => time.value == this.state.prefilledTime || time.label == this.state.prefilledTime);

    const radioButtons: RadioItem[] = [
      {
        id: 'rbtn_individual',
        name: 'rbtn_group',
        label: 'Individual',
        value: 'rbtn_individual',
      },
      {
        id: 'rbtn_group',
        name: 'rbtn_group',
        label: 'Group',
        value: 'rbtn_group'
      }
    ];

    const numberOptions: OptionItem[] = Array.from({ length: 99 }, (_, i) => {
      return {
        label: i + 2,
        value: i + 2,
      }
    });

    const { address, zip_code } = this.address;    
    return (
      <>
        {
          <div className="gray_background unreg_booking booking-details">
            <CustomNavBar/>
            <CustomStepsComponent
              steps={'bookingSteps'}
              active={1}
              onBack={this.props.history.goBack}
              done={[0]}
            />
            <div className="booking-form">
              <div className="container d-flex flex-column custom_inner_container" style={{paddingBottom:'20px'}}>
                <div className='align-items-center'>
                  <h1 className='location_heading'>BOOKING DETAILS</h1>
                  <p style={{fontSize:'16px', fontWeight:'bold', textAlign:'center',marginTop:'30px'}}>
                    Individual or group appointment?
                  </p>
                </div>
                <div style={{marginTop:'20px'}}>
                  <RadioButton
                    radios={radioButtons}
                    handleChange={this.changeRadio}
                    value={this.state.checkedRadio}
                  />
                </div>
                {this.state.checkedRadio === 'rbtn_group' && (
                  <DropDown
                    id='people_number'
                    label='HOW MANY PEOPLE?'
                    placeholder='Select please'
                    options={numberOptions}
                    handleChange={this.changeNumbers}
                    value={selectNumber}
                  />
                )}
              </div>

              <div className='d-flex custom_inner_container' style={{borderTop:'1px solid #CFB12B',marginLeft:'auto', marginRight:'auto',opacity:'0.1'}}></div>

              <div className="container d-flex flex-column custom_inner_container" >
                <div className='align-items-center'>
                  <p style={{fontSize:'16px', fontWeight:'bold', textAlign:'center'}}>
                    Select your date and time
                  </p>
                </div>

                <CustomCalendar
                  id='custom_calendar'
                  year={this.state.selectDate.year}
                  month={this.state.selectDate.month}
                  day={this.state.selectDate.day}
                  handleChange={this.changeCalendar}
                />
                <div style={{marginTop:'20px'}}>
                  <DropDown
                    id='time'
                    label='TIME'
                    value={this.state.selectTime.value || this.state.selectTime.label}
                    placeholder='Select time'
                    options={timeOptions}
                    isNight={this.state.selectTime.isIcon}
                    imgSrc={moon_icon}
                    handleChange={this.changeTime}
                    prefilledValue={isPrefilledAvailable ? String(this.state.selectTime.label) : undefined}
                  />
                </div>
                {!hasAvailableTime && (
                  <span className={'inline_error'}>
                    <br/>No valet is available for this day or all available time slots have been booked already.<br/>Please choose another day to book an appointment.
                  </span>
                )}
                {(this.state.selectTime && (this.state.selectTime as OptionItem).isIcon) && (
                  <div style={{textAlign:'center', marginTop:'20px'}}>
                    <img src={require("../../../assets/icons/moon1.svg")} alt={"location"} style={{opacity:'0.4'}}></img>
                    <p style={{fontSize:'13px', textAlign:'center', opacity:'1',color:'#828282', marginTop:'10px'}}>
                      This time falls within late-night hours. An upcharge will be applied at checkout.
                    </p>
                  </div>
                )}
                <div className='booking_date'>
                  SELECTED DATE AND TIME
                  <p style={{color:'#1A1A1A',fontSize:'16px',marginTop:'5px'}}>
                    {this.state.selectDate.month_name} {this.state.selectDate.day} <span style={{marginLeft:'10px'}}>
                      {
                        !isPrefilledAvailable && (this.state.prefilledTime != this.state.selectTime.label)
                        || isPrefilledAvailable && this.state.prefilledTime ?
                        this.state.selectTime.label : null
                      }
                    </span>
                  </p>
                </div>
              </div>

              <div className='d-flex custom_inner_container' style={{borderTop:'1px solid #CFB12B',marginLeft:'auto', marginRight:'auto',opacity:'0.1'}}></div>

              <div className="container d-flex flex-column custom_inner_container" >
                <div className='align-items-center'>
                  <p style={{fontSize:'16px', fontWeight:'bold', textAlign:'center'}}>
                    Please finish some location details
                  </p>
                </div>

                <p style={{fontSize:'12px', color:'#828282', marginBottom:'5px', marginLeft:'20px'}}>
                    ADDRESS
                </p>
                <div style={{display:'flex',padding:'5px',height:'40px',border: errorAddress !== '' ? '1px solid #EB5757' : '1px solid #E0E0E0'}}>
                  <img
                      src={require("../../../assets/icons/location2.svg")}
                      alt={'location'}
                      style={{margin:'0px 5px'}}
                  />
                  <input 
                    className='custom_location_input'
                    id='address'
                    value={address || this.state.query_zip_code}
                    placeholder='Address'
                    style={{fontSize:'16px'}}
                    onChange={this.changeData}></input>
                </div>
                {errorAddress && (
                  <p className="text-danger" id="text-danger" style={{fontSize: '12px', marginLeft: '20px', marginTop: '5px', fontWeight: 'normal'}}>
                    {errorAddress}
                  </p>
                )}

                <div className='d-flex justify-content-around' style={{marginTop:'20px'}}>
                  <CheckBoxImgButton
                    id={'imgBtn_home'}
                    name={'imgBtn_group'}
                    text={'Home'}
                    value={'imgBtn_home'}
                    onCheckedChange={this.handleCheckboxImgBtnChange}
                    checked={this.state.checkedImgBtn === 'imgBtn_home'}
                    icon={homeIcon}
                    iconActive={homeIconActive}
                  />
                  <CheckBoxImgButton
                    id={'imgBtn_apt'}
                    name={'imgBtn_group'}
                    text={'Apt'}
                    value={'imgBtn_apt'}
                    onCheckedChange={this.handleCheckboxImgBtnChange}
                    checked={this.state.checkedImgBtn === 'imgBtn_apt'}
                    icon={aptIcon}
                    iconActive={aptIconActive}
                  />
                  <CheckBoxImgButton
                    id={'imgBtn_hotel'}
                    name={'imgBtn_group'}
                    text={'Hotel'}
                    value={'imgBtn_hotel'}
                    onCheckedChange={this.handleCheckboxImgBtnChange}
                    checked={this.state.checkedImgBtn === 'imgBtn_hotel'}
                    icon={hotelIcon}
                    iconActive={hotelIconActive}
                  />
                  <CheckBoxImgButton
                    id={'imgBtn_other'}
                    name={'imgBtn_group'}
                    text={'Other'}
                    value={'imgBtn_other'}
                    onCheckedChange={this.handleCheckboxImgBtnChange}
                    checked={this.state.checkedImgBtn === 'imgBtn_other'}
                    icon={otherIcon}
                    iconActive={otherIconActive}
                  />
                </div>

                {this.state.checkedImgBtn === 'imgBtn_hotel' && (<><p style={{fontSize:'12px', color:'#828282', margin:'10px 20px 5px'}}>
                    HOTEL NAME
                </p>
                <div style={{display:'flex',padding:'5px',height:'40px',border:'1px solid #E0E0E0',paddingLeft:'20px'}}>
                  <input 
                    className='custom_location_input'
                    style={{fontSize:'16px'}}
                    value={inputHotelName}
                    onChange={this.changeHotelName}></input>
                </div></>)
                }

                <p style={{fontSize:'12px', color:'#828282', margin:'10px 20px 5px'}}>
                  {this.state.checkedImgBtn === 'imgBtn_hotel' ? 'ROOM NUMBER' : 'APT, UNIT, ROOM'}
                </p>
                <div style={{display:'flex',padding:'5px',height:'40px',border:'1px solid #E0E0E0',paddingLeft:'20px'}}>
                  <input 
                    className='custom_location_input'
                    style={{fontSize:'16px'}}
                    value={inputApt}
                    onChange={this.changeAPT}></input>
                </div>

                {(this.state.checkedImgBtn === 'imgBtn_hotel') && (<p style={{fontSize:'13px', textAlign:'center', opacity:'1',color:'#828282', marginTop:'20px'}}>
                  A hotel visit requires an upcharge, which will be applied at checkout.
                </p>)}

                <div className='align-items-center'>
                  <p style={{fontSize:'16px', fontWeight:'bold', textAlign:'center', margin:'20px 0px 30px'}}>
                    Where should we park?
                  </p>
                </div>
                <div>
                  <CheckBoxButtonInBooking
                    id={'cbtn_street'}
                    name={'cbtn_group'}
                    text={'Street parking'}
                    value={'cbtn_street'}
                    onCheckedChange={this.handleCheckboxChange}
                    checked={checkedId === 'cbtn_street'}
                  />
                  <CheckBoxButtonInBooking
                    id={'cbtn_garage'}
                    name={'cbtn_group'}
                    text={'Garage'}
                    value={'cbtn_garage'}
                    onCheckedChange={this.handleCheckboxChange}
                    checked={checkedId === 'cbtn_garage'}
                  />
                  <CheckBoxButtonInBooking
                    id={'cbtn_valet'}
                    name={'cbtn_group'}
                    text={'Valet'}
                    value={'cbtn_valet'}
                    onCheckedChange={this.handleCheckboxChange}
                    checked={this.state.checkedId === 'cbtn_valet'}
                  />
                  <CheckBoxButtonInBooking
                    id={'cbtn_metered'}
                    name={'cbtn_group'}
                    text={'Metered parking'}
                    value={'cbtn_metered'}
                    onCheckedChange={this.handleCheckboxChange}
                    checked={this.state.checkedId === 'cbtn_metered'}
                  />
                  {/* <CheckBoxButtonInBooking
                    id={'cbtn_other'}
                    name={'cbtn_group'}
                    text={'Other (comment)'}
                    value={'cbtn_other'}
                    onCheckedChange={this.handleCheckboxChange}
                    checked={this.state.checkedId === 'cbtn_other'}
                  /> */}
                </div>
                {/* {this.state.checkedId === 'cbtn_other' && (
                  <div>
                    <p style={{fontSize:'12px', color:'#828282', margin:'10px 20px 5px'}}>
                        COMMENT
                    </p>
                    <div style={{display:'flex',padding:'5px',height:'80px',border:'1px solid #E0E0E0',paddingLeft:'20px'}}>
                      <textarea 
                        className='custom_location_input'
                        style={{fontSize:'16px'}}
                        value={inputComment}
                        onChange={this.changeComment}
                      ></textarea>
                    </div>
                  </div>
                )} */}

              </div>

              <div className='d-flex custom_inner_container' style={{borderTop:'1px solid #CFB12B',marginLeft:'auto', marginRight:'auto',opacity:'0.1'}}></div>

              <div className="container d-flex flex-column custom_inner_container" >
                <div className='align-items-center'>
                  <p style={{fontSize:'16px', fontWeight:'bold', textAlign:'center'}}>
                    Any special directions?
                  </p>
                </div>
                <p style={{fontSize:'12px', color:'#828282', margin:'30px 20px 5px'}}>
                  COMMENT
                </p>
                <div style={{display:'flex',padding:'5px',height:'80px',border:'1px solid #E0E0E0',paddingLeft:'20px'}}>
                  <textarea 
                    className='custom_location_input'
                    style={{fontSize:'16px'}}
                    value={directComment}
                    onChange={this.changeDirectComment}
                  ></textarea>
                </div>
                <div style={{marginTop:'20px'}}>
                  <CustomLocationButton
                    onClick={() => this.continueBooking()}
                    value={'CONTINUE TO CHECKOUT'}
                    additionalClass={'w-100'}
                  />
                </div>
              </div>
            </div>
            <CustomPopup
              id={'firstTimePromoSuccess'}
              content={PopupContent.match_zip_code_and_address}
              onClick={() => this.props.history.push('/')}
              onHide={() => this.setState({showModal: false})}
              show={showModal}
              showConfirmation={true}
              leftButtonText={'Restart Booking'}
              rightButtonText={'Cancel'}
            />
            <CustomPopup
              id={'no_valets_availalbe'}
              content={this.state.errorMessage}
              onHide={() => this.setState({showNVModal: false})}
              show={showNVModal}
            />
            <CustomPopup
              id={'chooseAddress'}
              content={PopupContent.choose_address_suggestions}
              onHide={() => this.setState({showChooseModal: false})}
              show={showChooseModal}
            />
          </div>          
        }
      </>      
    );
  }
}
