import React, { Component } from 'react';
import StepsComponent from '../../../Shared/StepsComponent';
import LocationForm from '../../../Components/LocationForm';
import { RouterProps } from 'react-router';
import AuthStore from '../../../Stores/AuthStore';
import { observer } from 'mobx-react';
import AddressesStore from '../../../Stores/AddressesStore';
import moment from 'moment';
import TimeStore from '../../../Stores/TimeStore';
import ReactGA from 'react-ga4';
import config from '../../../config';
import GuestBookingStepsComponent from '../../../Shared/GuestBookingStepsComponent';

@observer
export default class LocationInformation extends Component<RouterProps> {
  state = { e: '' };
  hotelInformation = () => this.props.history.push('/hotel_information');
  nextStep = () => this.props.history.push('/booking_valet');
  continue = async () => {
    this.setState({ e: '' });
    try {
      if (!AuthStore.isLogged){
        await AuthStore.guestLogin();
      }
      await AddressesStore.saveAddressObjectData();
      if (Object.values(AddressesStore.modals).includes(true)) {
        return;
      }
      if (!AuthStore.isLogged) {
        const address =
          AddressesStore.addressesSettings.find(
            (e) => e.id === AddressesStore.defaultAddressId
          ) || null;
        // await TimeStore.getAvailableTime(
        //   moment(),
        //   null,
        //   address && address.city
        // );
        this.nextStep();
        return;
      }
      this.props.history.goBack();
    } catch (e) {
      console.log(e);
      this.setState({ e: e.message });
      const errorCont = document.getElementById('text-danger');
      if (errorCont) {
        errorCont.scrollIntoView();
      }
    }
  };

  // continue = async () => {
  //   this.setState({ e: '' });
  //   try {

  //     if (!AuthStore.isLogged) {

  //       await AddressesStore.checkAddressObjectData();
  //       if (Object.values(AddressesStore.modals).includes(true)) {
  //         return;
  //       }

  //       const address = AddressesStore.addressObject;
        
  //       await TimeStore.getAvailableTime(
  //         moment(),
  //         null,
  //         address && address.city
  //       );
  //       this.nextStep();
  //       return;
  //     }

  //     await AddressesStore.saveAddressObjectData();
  //     if (Object.values(AddressesStore.modals).includes(true)) {
  //       return;
  //     }
      
  //     this.props.history.goBack();
  //   } catch (e) {
  //     console.log(e);
  //     this.setState({ e: e.message });
  //     const errorCont = document.getElementById('text-danger');
  //     if (errorCont) {
  //       errorCont.scrollIntoView();
  //     }
  //   }
  // };

  componentDidMount() {
    // if (!AuthStore.jwt.token) {
    //   this.props.history.push('/');
    // }
    if (!AuthStore.jwt.token) {
      // StorageService.clear();
      AddressesStore.clear();
      // PaymentStore.clear();
    }
    
    if (config.isProd) {
      ReactGA.event({
        category: 'form-submission',
        action: 'book-appoitment-form',
        label: 'location',
      });
    }
  }

  render() {
    return (
      <div className="gray_background location_information">
        {/* <Helmet>
          {
            '<script> ga(‘send’, ‘event’,  form-submission, book-appoitment-form, location);</script>'
          }
        </Helmet> */}
        {AuthStore.isLogged ? (
          <StepsComponent
            steps={'bookingSteps'}
            active={0}
            onBack={this.props.history.goBack}
          />
        ) : (
          <GuestBookingStepsComponent
            steps={'bookingSteps'}
            active={0}
            onBack={this.props.history.goBack}
          />
        )}

        <div className={'small_container '}>
          <LocationForm
            nextStep={this.continue}
            hotelClick={this.hotelInformation}
            buttonValue={'continue'}
            value={AddressesStore.addressObject}
            onChange={AddressesStore.changeAddressObject}
            goNext={this.nextStep}
            error={this.state.e}
          />
        </div>
      </div>
    );
  }
}
