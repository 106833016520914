import React, { Component } from 'react';
import { RouterProps } from 'react-router';
import AuthStore from '../../../Stores/AuthStore';
import { observer } from 'mobx-react';
import CustomStepsComponent from '../../../Shared/CustomStepsComponent';
import CustomLocationForm from '../../../Components/CustomLocationForm';
import CustomNavBar from '../../../Shared/Navbars/CustomNavBar';
import AddressesStore from '../../../Stores/AddressesStore';
import config from '../../../config';
import ReactGA from 'react-ga4';

@observer
export default class Location extends Component<RouterProps> {
  state = { e: '' };
  nextStep = (query?: string) => {
    this.props.history.push('/booking' + `${query ? query : '/'}`);
  }
  continue = async (query?: string) => {
    this.setState({ e: '' });
    try {
      if (!AuthStore.isLogged){
        await AuthStore.guestLogin();        
        // await TimeStore.getAvailableTime(
        //   moment(),
        //   null,
        //   null
        // );
      }
      // await AddressesStore.saveAddressObjectData();
      if (Object.values(AddressesStore.modals).includes(true)) {
        return;
      }
      this.props.history.push('/booking' + `${query ? query : '/'}`);
      this.nextStep(query);
    } catch (e) {
      console.log(e);
      this.setState({ e: e.message });
      const errorCont = document.getElementById('text-danger');
      if (errorCont) {
        errorCont.scrollIntoView();
      }
    }
  };

  componentDidMount() {
    if (config.isProd) {
      ReactGA.event({
        category: 'form-start',
        action: 'WebApp_form_start',
        label: 'WebApp Form Start',
      });
    }
  }

  render() {
    return (
      <div className="gray_background custom_location_information">
        <CustomNavBar />
        <CustomStepsComponent
          steps={'bookingSteps'}
          active={0}
          onBack={this.props.history.goBack}
        />

        <div className={'custom_small_container '}>
          <CustomLocationForm
            nextStep={this.continue}
            buttonValue={'continue'}
            error={this.state.e}
            value={AddressesStore.addressObject}
            onChange={AddressesStore.changeAddressObject}
          />
        </div>
      </div>
    );
  }
}
