import { action, computed, observable, reaction } from 'mobx';
import moment, { Moment } from 'moment';
import { IErrorsObject } from '../Interfaces/OtherInterfaces';
import React from 'react';
import Validation from '../Utils/Validation';
import ProfileStore from './ProfileStore';
import AddressesStore from './AddressesStore';
import PaymentStore from './PaymentStore';
import BookingService from '../Services/BookingService';
import DateFormatting from '../Utils/DateFormatting';
import StorageService from '../Services/StorageService';
import ReactPixel from 'react-facebook-pixel';
import CryptoJS from 'crypto-js';
import FacebookConversionApiService from '../Services/FacebookConversionApiService';
import ValetsStore from './ValetsStore';
import {
  defaultBookingData,
  IBookingData
} from '../Interfaces/BookingInterfaces';
import {
  defaultLocationInfo,
  IAddress,
} from '../Interfaces/AddressesInterface';
import { defaultCreditCardBeforeSave, ICreditCardAfterSave, ICreditCardBeforeSave } from '../Interfaces/CreditCardsInterfaces';
import { defaultValet, IValet } from '../Interfaces/ValetInterfaces';
import TimeStore from './TimeStore';
import { IApiProfile } from '../Interfaces/ProfileInterfaces';
import PromoStore from './PromoStore';
import mixpanel from 'mixpanel-browser';
import errorsMessage from '../Constants/errorsMessage';
import config from '../config';
import ModalStore from './ModalStore';
import AuthStore from './AuthStore';
import { history } from '../App';
import ReactGA from 'react-ga4';

class BookingStore {
  @observable bookingData: IBookingData = {
    ...defaultBookingData,
  };

  @observable bookingReadySteps = [];

  @observable bookingData_new: any = {}; //NEW

  @observable inlineErrors: IErrorsObject = {};
  @observable sendSuccess: boolean = false;
  @observable isLoading: boolean = false;
  @observable isBookingDataActual: boolean = false;
  @observable error: string = '';
  @observable errorsOnForm: { [key: string]: string } = {
    firstStep: '',
    secondStep: '',
    notesError: '',
  };

  @observable availableTimes: string[] = [];
  @observable discount: {
    type: string;
    value: number;
    promocode: string;
    gift_card: string;
  } = {
    type: 'value',
    value: 0,
    promocode: '',
    gift_card: '',
  };
  @observable bookingDisabled: boolean = false;

  constructor() {
    reaction(
      () => PaymentStore.defaultPaymentMethod,
      (info: ICreditCardAfterSave) => (this.bookingData.payment_info = info)
    );
    reaction(
      () => this.bookingData.date,
      (date) => {
        if (date && AuthStore.isLogged) {
          TimeStore.getAvailableTime(date);
        }
      }
    );
    StorageService.getItem('currentBooking').catch(
      (e) => (this.bookingData = defaultBookingData)
    );
  }

  @action setBookingToStorage = async () => {
    try {
      await StorageService.setItem('currentBooking', this.bookingData);
    } catch (e) {
      console.dir(e);
      throw new Error(e.message.join());
    }
  };

  @action setBookingToStorage_new = async () => {
    try {
      await StorageService.setItem('currentNewBooking', this.bookingData_new);
    } catch (e) {
      console.dir(e);
      throw new Error(e.message.join());
    }
  };

  @action createNewBooking = async (
    valet_id: number | null = null,
    quantity: number = 1,
    isFromSelectedCity: boolean = false,
  ) => {
    TimeStore.resetReserveTime();
    const addressInStore = AddressesStore.addressesSettings.find(
      (address: IAddress) => address.id === AddressesStore.defaultAddressId
    );
    const address =
      addressInStore ||
      AddressesStore.addressesSettings[0] ||
      defaultLocationInfo;

    const paymentSettingsFromBe =
      !!PaymentStore.paymentSettings.length &&
      PaymentStore.paymentSettings[0];
    const payment_info =
      PaymentStore.defaultPaymentMethod ||
      paymentSettingsFromBe ||
      defaultCreditCardBeforeSave;

    const valet = isFromSelectedCity ? ValetsStore.listOfValetsInCity.find((valet: IValet) => {
      return valet.valet ? valet.valet.id === valet_id : false;
    }) || defaultValet :
      ValetsStore.listOfValets.find((valet: IValet) => {
        return valet.valet ? valet.valet.id === valet_id : false;
      }) || defaultValet;

    this.bookingData_new = {
      ...defaultBookingData,
      address,
      payment_info,
      quantity,
      valet: valet.valet,
    };
    await this.setBookingToStorage();
  };

  @action validateTextArea = (value: string) =>
    value.length >= 1024
      ? (this.errorsOnForm.notesError = errorsMessage.booking.notes.toLong)
      : (this.errorsOnForm.notesError = '');

  @action changeBookingData = (
    name: string,
    value: string | number | boolean | IApiProfile | Moment
  ) => {
    if (name === 'notes') this.validateTextArea(value.toString());
    this.bookingData[name] = value;
  };

  @action createBookingStep1_new = async ( userBookingData : any) => {
    try {
      this.isLoading = true;
      this.errorsOnForm.firstStep = '';
      const userId = ProfileStore.profile.id;
      const data = {
        client: userId,
        date: DateFormatting.dateAndTime(
          userBookingData.selectDate,
          userBookingData.selectTime 
        ),
        number_of_people: userBookingData.selectNumber,
        address: userBookingData.address,        
        location_type: userBookingData.location_type,
        apartment: userBookingData.apartment,
        park_type: userBookingData.park_type,
        way_notification: true
      };
      const response: any = await BookingService.createBookingStep1(data);
      let temp_location_type = '';
      switch (userBookingData.location_type) {
        case 'home':
          temp_location_type = 'House';
          break;
        case 'apt':
          temp_location_type = 'Apartment';
          break;
        case 'hotel':
          temp_location_type = 'Hotel';
          break;
        case 'other':
          temp_location_type = '';
          break;
      }
      if (response.enable) {        
        delete response.address;
        this.bookingData_new = {
          ...this.bookingData_new,
          date: data.date,
          booking_date: userBookingData.selectDate,
          booking_time: userBookingData.selectTime,
          booking_time_value: userBookingData.selectTimeValue,
          number_of_people: userBookingData.selectNumber,
          id: response.appointment_id,
          location_type: temp_location_type,
          location_unit: userBookingData.apartment,
          address: AddressesStore.addressObject.address,
          addressId: AddressesStore.addressObject.id,
          park_type: userBookingData.park_type,
          hotel_name: userBookingData.hotem_name,
          input_comment: userBookingData.inputComment,
          direct_comment: userBookingData.directComment,
          valet: response.valet,
          valet_address: response.valet && response.valet.address[0] ? response.valet.address[0].address : null,
          valet_avatar: response.valet ? response.valet.avatar_url : null,
          valet_first_name: response.valet ? response.valet.first_name : null,
          ...response
        };        
        mixpanel.track('Booking Init', this.bookingData_new);       
        await this.getNewBookingPrice();
      } else {
        this.bookingDisabled = true;
        return null;
      }
    } catch (e) {
      this.errorsOnForm.firstStep = e.message.detail;
      mixpanel.track('Booking Init Error', {
        bookingData: this.bookingData_new,
        errorMessage: e.message.detail,
        error: e,
      });
      throw new Error(e.message.detail);
    } finally {
      this.isLoading = false;
    }     
  };

  @action getNewBookingPrice = async () => {
    try {
      this.isLoading = true;
      const id = this.bookingData_new.appointment_id || this.bookingData_new.id;
      const date = this.bookingData_new.date

      var prices: any;
      
      prices = await BookingService.getPricesForBooking(
        id,
        this.bookingData_new.address.id,
        date,
        // "",
        // ""
        this.discount.promocode,
        this.discount.gift_card
      );
      
      if (!prices) return null;

      this.bookingData_new = {
        ...this.bookingData_new,
        ...prices,
        valet: this.bookingData_new.valet,
        date: date,
      };

      const detailsPrice: any = this.formatPriceDetails(prices);

      this.bookingData_new = {
        ...this.bookingData_new,
        ...prices,
        detailsPrice,
        date: date,
      };
      this.bookingData_new.pay_as_you_go_price = Number(
        this.bookingData_new.pay_as_you_go_price
      );
      this.bookingData_new.subscription_price = this.bookingData_new.subscription_price;      
    } catch (e) {
      this.errorsOnForm.secondStep = e.message.detail;
      throw new Error(e.message.detail);
    } finally {
      this.isLoading = false;
    }
  };

  @action createBookingStep2_new = async ( paymentContact : any, creditCard : any) => {
    // try {
      this.inlineErrors = { ...this.inlineErrors, serverError: false };
      const any = ValetsStore.isUserHasOnlyValet
        ? TimeStore.showAllValet
        : !ValetsStore.isUserHasFavoriteValet;
      if (this.bookingData_new.addressId === 0 || PaymentStore.paymentSettings.length === 0) return null;
      console.log("BN:", this.bookingData_new);
      const bookingData = {
        address: this.bookingData_new.addressId,
        promocode: this.bookingData_new.promocode,
        gift_card: this.bookingData_new.gift_card,
        valet: this.bookingData_new.valet.id ? this.bookingData_new.valet.id : null,
        purchase_method: this.bookingData_new.purchase_method,
        payments: PaymentStore.paymentSettings[PaymentStore.paymentSettings.length - 1].id,
        any,
      };
      try {
        this.isLoading = true;
        this.sendSuccess = false;
        this.errorsOnForm.secondStep = '';

        const id = this.bookingData_new.appointment_id || this.bookingData_new.id;
        const response = await BookingService.createBookingStep2(
          id,
          bookingData
        );
        if (config.isProd){
          let totalPrice = 0;

          if (this.bookingData_new.purchase_method.toLowerCase() == 'subscription') {
            totalPrice = this.bookingData_new.subscription_price;
          } else if (this.bookingData_new.purchase_method.toLowerCase() == 'pay as you go') {
            totalPrice = this.bookingData_new.pay_as_you_go_price
          } else {
            totalPrice = 0;
          }
          ReactGA.event("purchase", {
            transaction_id: id,
            currency: "USD",
            value: totalPrice
          })
        }
        if (config.REACT_APP_FACEBOOK_PIXEL_ID) {
          let totalPrice = 0;

          if (this.bookingData_new.purchase_method.toLowerCase() == 'subscription') {
            totalPrice = this.bookingData_new.subscription_price;
          } else if (this.bookingData_new.purchase_method.toLowerCase() == 'pay as you go') {
            totalPrice = this.bookingData_new.pay_as_you_go_price
          } else {
            totalPrice = 0;
          }

          const location = AddressesStore.addressObject;

          console.log('AddressesStore.addressObject', location);
          // ReactPixel.init(config.REACT_APP_FACEBOOK_PIXEL_ID);
          ReactPixel.track('Purchase', {
            ph: paymentContact.phone,
            fn: paymentContact.first_name.toLowerCase(),
            ln: paymentContact.last_name.toLowerCase(),
            em: paymentContact.email,
            zp: location ? String(location.zip_code) : null,
            ct: location ? location.city : null,
            st: location ? location.state : null,
            value: totalPrice,
            currency: 'USD',
          });        

          const userData = {
            em: CryptoJS.SHA256(paymentContact.email).toString(CryptoJS.enc.Hex),
            ph: CryptoJS.SHA256(paymentContact.phone).toString(CryptoJS.enc.Hex),
            fn: CryptoJS.SHA256(paymentContact.first_name.toLowerCase()).toString(CryptoJS.enc.Hex),
            ln: CryptoJS.SHA256(paymentContact.last_name.toLowerCase()).toString(CryptoJS.enc.Hex),
            zp: location ? CryptoJS.SHA256(String(location.zip_code)).toString(CryptoJS.enc.Hex): null,
            ct: location ? CryptoJS.SHA256(location.city).toString(CryptoJS.enc.Hex): null,
            st: location && location.state ? CryptoJS.SHA256(location.state).toString(CryptoJS.enc.Hex) : null,
            client_user_agent: window.navigator.userAgent,
            fbp: ''
          };

          const content = {
            id: this.bookingData_new.id,
            quantity: this.bookingData_new.quantity,
            delivery_category: 'home_delivery'
          };

          const customData = {
            contents: [content],
            currency: 'usd',
            value: totalPrice
          };

          const serverEvent = {
            user_data: userData,
            custom_data: customData
          };

          const eventsData = {
            data: serverEvent
          };

          let fbpRes = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
          if ((fbpRes && fbpRes[1])) {
            eventsData.data.user_data.fbp = fbpRes[1];
            FacebookConversionApiService.trackPurchase(eventsData);
          }

        } else {
          console.log('Disabled FB');
        }
        if (!response) return null;
        this.sendSuccess = true;
        mixpanel.track('Booking Done Success', bookingData);
          PromoStore.clear();
          this.discount = {
            type: 'discount',
            value: 0,
            promocode: '',
            gift_card: '',
          };
      } catch (e) {
        console.log(e);
        mixpanel.track('Booking Done Error', {
          bookingData,
          errorMessage: e.message.detail,
          error: e,
        });
        const error = e.message.detail || 'Internal Server Error';
        this.inlineErrors = { ...this.inlineErrors, serverError: true };
        this.error = error;
        this.errorsOnForm.secondStep = error;
        throw new Error(e.message.detail);
      } finally {
        this.isLoading = false;
      }
    // } catch (err) {
    //   console.log(err);
    //   this.error = 'Your card was declined. Please try another one.';
    //   this.isLoading = false;
    // }
  };

  @action setBookingNote = async ( notes: string ) => {
    const bookingNote = {
      notes: notes
    };
    try {
      await BookingService.setNotes(this.bookingData_new.appointment_id, bookingNote);
      return true;
    } catch (err) {
      console.log(err);      
      return false;
    } 
    return false;   
  };

  @action createBookingStep1 = async (valetID: number | null = null) => {
    try {
      this.isLoading = true;
      this.errorsOnForm.firstStep = '';
      const userId = ProfileStore.profile.id;
      // CHECK IF USER HAS SUBSCRIPTION
      const purchase_method = ProfileStore.profile.client_subscriptions
        ? 'Subscription'
        : 'Pay As You Go';

      const any = ValetsStore.isUserHasOnlyValet
        ? TimeStore.showAllValet
        : !ValetsStore.isUserHasFavoriteValet;
      const data = {
        client: userId,
        date: DateFormatting.dateAndTime(
          this.bookingData.date,
          TimeStore.appointmentStartTime 
        ),
        number_of_people: this.bookingData.quantity,
        notes: this.bookingData.notes,
        way_notification: this.bookingData.tell_me_on_way,
        valet: TimeStore.showAllValet ? null : valetID ? valetID : null,
        any,
        address: this.bookingData.address.id,
      };

      const response: any = await BookingService.createBookingStep1(data);

      if (response.enable) {
        delete response.address;
        this.bookingData = {
          ...this.bookingData,
          date: data.date,
          id: response.appointment_id,
          ...response,
          purchase_method,
        };
        mixpanel.track('Booking Init', this.bookingData);
        await this.editPrice('', valetID ? false : true);
      } else {
        this.bookingDisabled = true;
        return null;
      }
    } catch (e) {
      this.errorsOnForm.firstStep = e.message.detail;
      mixpanel.track('Booking Init Error', {
        bookingData: this.bookingData,
        errorMessage: e.message.detail,
        error: e,
      });
      throw new Error(e.message.detail);
    } finally {
      this.isLoading = false;
    }
  };

  formatPriceDetails = (prices: any) => {
    const detailsPrice: any = {};
    if (prices.price_details) {
      if (prices.price_details.base_fee) {
        detailsPrice['BaseFee'] = {
          price: prices.price_details.base_fee,
          tittle: 'Base Fee',
        };
      }
      if (prices.price_details.hotel_base_fee) {
        detailsPrice['BaseFee'] = {
          price: prices.price_details.hotel_base_fee,
          tittle: 'Hotel Base Fee',
        };
      }

      if (prices.price_details.members_base_fee) {
        detailsPrice['SubBaseFee'] = {
          price: prices.price_details.members_base_fee,
          tittle: 'Base Fee',
        };
      }
      if (prices.price_details.night_base_fee) {
        detailsPrice['BaseFee'] = {
          price: prices.price_details.night_base_fee,
          tittle: 'Night Base Fee',
        };
        detailsPrice['SubBaseFee'] = {
          price: prices.price_details.night_base_fee,
          tittle: 'Subscription Night Base Fee',
        };
      }
      if (prices.price_details.hotel_night_base_fee) {
        detailsPrice['BaseFee'] = {
          price: prices.price_details.hotel_night_base_fee,
          tittle: 'Hotel Night Base Fee',
        };
      }
      if (prices.price_details.pay_as_you_go_tips) {
        detailsPrice['PayAsYouGoTips'] = {
          price: prices.price_details.pay_as_you_go_tips,
          tittle: 'Pay As You Go tips',
        };
      }
      if (prices.price_details.subscription_tips) {
        detailsPrice['SubscriptionTips'] = {
          price: prices.price_details.subscription_tips,
          tittle: 'Subscription tips',
        };
      }
      detailsPrice['AreaFee'] = {
        price: prices.price_details.service_area_fee,
        tittle: 'Out of area fee',
      };
    }
    detailsPrice.payAsYouGo = {
      base: detailsPrice['BaseFee'],
      tips: detailsPrice['PayAsYouGoTips'],
      area: detailsPrice['AreaFee'],
    };
    detailsPrice.Subscription = {
      base: detailsPrice['SubBaseFee'],
      tips: detailsPrice['SubscriptionTips'],
      area: detailsPrice['AreaFee'],
    };

    return detailsPrice;
  };

  @action editPrice = async (withDate: any = '', updateValet: boolean = false) => {
    try {
      this.isLoading = true;
      const id = this.bookingData.appointment_id || this.bookingData.id;
      if (!this.bookingData.address.id) {
        AddressesStore.defaultAddressId =
          AddressesStore.addressesSettings[0].id;
      }
      const formattedDate = DateFormatting.dateAndTime(
        this.bookingData.date,
        TimeStore.appointmentStartTime
      );
      const date =formattedDate;

      // const prices: any = await BookingService.getPricesForBooking(
      //   id,
      //   this.bookingData.address.id,
      //   date,
      //   this.discount.promocode,
      //   this.discount.gift_card
      // );

      var prices: any;
      if (withDate){
        prices = await BookingService.getReschedulePricesForBooking(
          id,
          date
        );
      }else{
        prices = await BookingService.getPricesForBooking(
          id,
          this.bookingData.address.id,
          date,
          this.discount.promocode,
          this.discount.gift_card
        );
      }
      
      if (!prices) return null;

      this.bookingData = {
        ...this.bookingData,
        ...prices,
        valet: updateValet ? prices['valet_predict'] : this.bookingData.valet,
        date: formattedDate,
      };

      const detailsPrice: any = this.formatPriceDetails(prices);

      this.bookingData = {
        ...this.bookingData,
        ...prices,
        detailsPrice,
        date: formattedDate,
      };
      this.bookingData.pay_as_you_go_price = Number(
        this.bookingData.pay_as_you_go_price
      );
      this.bookingData.subscription_price = this.bookingData.subscription_price;
    } catch (e) {
      this.errorsOnForm.secondStep = e.message.detail;
      throw new Error(e.message.detail);
    } finally {
      this.isLoading = false;
    }
  };

  @action createBookingStep2 = async () => {
    try {
      this.inlineErrors = { ...this.inlineErrors, serverError: false };
      const any = ValetsStore.isUserHasOnlyValet
        ? TimeStore.showAllValet
        : !ValetsStore.isUserHasFavoriteValet;
      const bookingData = {
        address: this.bookingData.address.id,
        promocode: this.bookingData.promocode,
        gift_card: this.bookingData.gift_card,
        valet: this.bookingData.valet.id ? this.bookingData.valet.id : null,
        purchase_method: this.bookingData.purchase_method,
        payments: this.bookingData.payment_info.id,
        any,
      };
      try {
        this.isLoading = true;
        this.sendSuccess = false;
        this.errorsOnForm.secondStep = '';

        const id = this.bookingData.appointment_id || this.bookingData.id;

        const response = await BookingService.createBookingStep2(
          id,
          bookingData
        );
        if (config.REACT_APP_FACEBOOK_PIXEL_ID) {
          // ReactPixel.init(config.REACT_APP_FACEBOOK_PIXEL_ID);
          ReactPixel.track('Purchase', {
            zp: String(this.bookingData.address.zip_code),
            ph: this.bookingData.valet.phone,
            fn:this.bookingData.valet.first_name.toLowerCase(),
            ln: this.bookingData.valet.last_name.toLowerCase(),
            em: this.bookingData.valet.email,
            ct: this.bookingData.address.city,
            st: this.bookingData.address.state,
            value: this.totalPrice,
            currency: 'USD',
          });        

          const userData = {
            em: CryptoJS.SHA256(this.bookingData.valet.email).toString(CryptoJS.enc.Hex),
            ph: CryptoJS.SHA256(this.bookingData.valet.phone).toString(CryptoJS.enc.Hex),
            fn: CryptoJS.SHA256(this.bookingData.valet.first_name.toLowerCase()).toString(CryptoJS.enc.Hex),
            ln: CryptoJS.SHA256(this.bookingData.valet.last_name.toLowerCase()).toString(CryptoJS.enc.Hex),
            zp: CryptoJS.SHA256(String(this.bookingData.address.zip_code)).toString(CryptoJS.enc.Hex),
            ct: CryptoJS.SHA256(this.bookingData.address.city).toString(CryptoJS.enc.Hex),
            st: this.bookingData.address.state ? CryptoJS.SHA256(this.bookingData.address.state).toString(CryptoJS.enc.Hex) : null,
            client_user_agent: window.navigator.userAgent,
            fbp: ''
          };

          const content = {
            id: this.bookingData.id,
            quantity: this.bookingData.quantity,
            delivery_category: 'home_delivery'
          };

          const customData = {
            contents: [content],
            currency: 'usd',
            value: this.totalPrice
          };

          const serverEvent = {
            user_data: userData,
            custom_data: customData
          };

          const eventsData = {
            data: serverEvent
          };

          let fbpRes = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
          if ((fbpRes && fbpRes[1])) {
            eventsData.data.user_data.fbp = fbpRes[1];
            FacebookConversionApiService.trackPurchase(eventsData);
          }

        } else {
          console.log('Disabled FB');
        }
        this.sendSuccess = true;
        if (!response) return null;
        this.bookingData = { ...defaultBookingData };
        mixpanel.track('Booking Done Success', bookingData);
        // if (!AuthStore.isLogged) {
        //   history.push('/additional_booking_signin');
        // } else {
          this.bookingData = { ...defaultBookingData };
          PromoStore.clear();
          this.discount = {
            type: 'discount',
            value: 0,
            promocode: '',
            gift_card: '',
          };
        // }
      } catch (e) {
        mixpanel.track('Booking Done Error', {
          bookingData,
          errorMessage: e.message.detail,
          error: e,
        });
        const error = e.message.detail || 'Internal Server Error';
        this.inlineErrors = { ...this.inlineErrors, serverError: true };
        this.error = error;
        this.errorsOnForm.secondStep = error;
      } finally {
        this.isLoading = false;
      }
    } catch (err) {
      this.error = 'Your card was declined. Please try another one.';
      this.isLoading = false;
    }
  };

  @action inputFieldsValidation = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    let { id, value } = event.target;
    return (this.inlineErrors[id] = !Validation[id](value));
  };

  @computed get totalPrice() {
    switch (this.bookingData.purchase_method.toLowerCase()) {
      case 'subscription':
        return this.bookingData.subscription_price;
      case 'pay as you go':
        console.log("Discount Price = ", this.discount.value)
        console.log("Pay_As_You_Go_Price = ", this.bookingData.pay_as_you_go_price)
        return this.bookingData.pay_as_you_go_price;
      default:
        return 0;
    }
  }

  @computed get discountValue() {
    let { discount } = PromoStore;
    if (!discount) discount = 0;
    if (this.bookingData.purchase_method === 'Subscription') {
      discount = 0;
    } else {
    }

    return this.discount.type === 'percent' ? `${discount} %` : `$${discount} `;
  }
  @action checkPromo = async (
    promo: string,
    type: string,
    destroy?: boolean
  ) => {
    try {
      this.discount = {
        type: 'discount',
        value: 0,
        promocode: '',
        gift_card: '',
      };
      this.isLoading = true;
      const params = {
        [type]: promo,
      };
      const { appointment_id } = this.bookingData;
      const response: any = await BookingService.savePromo(
        appointment_id,
        params
      );
      if (!response) return null;

      if (type === 'promocode') {
        if (response.promocode_full.id && !response.promocode_full.active) {
          if (response.promocode_full.code_type === 'discount')
            this.discount = {
              type: 'discount',
              value: response.promocode_full.value,
              promocode: response.promocode_full.id,
              gift_card: '',
            };
          if (response.promocode_full.code_type === 'percent')
            this.discount = {
              type: 'percent',
              value: response.promocode_full.value,
              promocode: response.promocode_full.id,
              gift_card: '',
            };
        } else {
          throw new Error('Promocode Disabled');
        }
      }
      if (type === 'gift_card') {
        if (
          response.gift_card.giftCardNumber &&
          response.gift_card.giftCardStatus !== 'disabled'
        ) {
          this.discount = {
            type: 'discount',
            value: response.gift_card.giftCardAmount,
            promocode: '',
            gift_card: response.gift_card.giftCardNumber,
          };
        } else throw new Error('GiftCard Disabled');
      }
      this.bookingData[type] =
        type === 'gift_card'
          ? response.gift_card.giftCardNumber
          : response.promocode_full.id;
      await this.editPrice(``, false);
      return response;
    } catch (e) {
      if (destroy) {
        await this.editPrice(``, false);
      }
      this.discount.value = 0;
      if (!destroy) throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action checkPromo_new = async (
    promo: string,
    type: string,
    destroy?: boolean
  ) => {
    try {
      this.discount = {
        type: 'discount',
        value: 0,
        promocode: '',
        gift_card: '',
      };
      this.isLoading = true;
      const params = {
        [type]: promo,
      };
      const { appointment_id } = this.bookingData_new;
      const response: any = await BookingService.savePromo(
        appointment_id,
        params
      );
      if (!response) return null;

      if (type === 'promocode') {
        if (response.promocode_full.id && response.promocode_full.is_active) {
          if (response.promocode_full.code_type === 'discount')
            this.discount = {
              type: 'discount',
              value: response.promocode_full.value,
              promocode: response.promocode_full.id,
              gift_card: '',
            };
          if (response.promocode_full.code_type === 'percent')
            this.discount = {
              type: 'percent',
              value: response.promocode_full.value,
              promocode: response.promocode_full.id,
              gift_card: '',
            };
        } else {
          throw new Error('Promocode Disabled');
        }
      }
      if (type === 'gift_card') {
        if (
          response.gift_card.giftCardNumber &&
          response.gift_card.giftCardStatus !== 'disabled'
        ) {
          this.discount = {
            type: 'discount',
            value: response.gift_card.giftCardAmount,
            promocode: '',
            gift_card: response.gift_card.giftCardNumber,
          };
        } else throw new Error('GiftCard Disabled');
      }
      this.bookingData_new[type] =
        type === 'gift_card'
          ? response.gift_card.giftCardNumber
          : response.promocode_full.id;
      await this.getNewBookingPrice();
      return response;
    } catch (e) {
      if (destroy) {
        await this.getNewBookingPrice();
      }
      this.discount.value = 0;
      if (!destroy) throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action destroyPromocode = () => {
    this.checkPromo('', '', true);
    PromoStore.clear();
  };

  @action destroyPromocode_new = () => {
    this.checkPromo_new('', '', true);
    PromoStore.clear();
  };

  @action setError = (keyError: string, error: string) => {
    this.errorsOnForm[keyError] = error;
  };

  @action rescheduleItem = async (id: number) => {
    this.isLoading = true;
    this.errorsOnForm.secondStep = '';
    try {
      await BookingService.rescheduleItem(
        id,
        DateFormatting.dateAndTime(
          this.bookingData.date,
          TimeStore.appointmentStartTime
        )
      );
    } catch (e) {
      const error = e.message.detail || 'Internal Server Error';
      PaymentStore.errorOnComponent.modalError = error;
      ModalStore.toggleModal('paymentError', true);
      throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
  };
}

const bookingStore = new BookingStore();

reaction(
  () => bookingStore.bookingData.purchase_method,
  () => { 
    if (bookingStore.bookingData.purchase_method === 'Subscription') {
      bookingStore.destroyPromocode();
      bookingStore.bookingData.gift_card = "";
      bookingStore.bookingData.promocode = "";
      bookingStore.discount.value = 0;
    }
  }
);

export default bookingStore;
