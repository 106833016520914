import React, {Component} from "react";
import StepsComponent from "../../../Shared/StepsComponent";
import HotelForm from "../../../Components/HotelForm";
import {RouterProps} from "react-router";
import AuthStore from "../../../Stores/AuthStore";
import {observer} from "mobx-react";
import AddressesStore from "../../../Stores/AddressesStore";


@observer
export default class HotelInformationForBooking extends Component<RouterProps>{
    save = async () => {
        await AddressesStore.saveAddressObjectData();
        if (Object.values(AddressesStore.modals).includes(true)) {
          return;
        }
        this.props.history.push("/booking_address");
    };
    
    render(){
        return (
            <div className="gray_background hotel_information_container">
                {AuthStore.isLogged ?
                    <StepsComponent steps={'bookingSteps'} active={1} done={[0]}
                                    onBack={()=>this.props.history.goBack()}/> :
                    <StepsComponent steps={'bookingStepsNotReg'} active={2} done={[0, 1]}
                                    onBack={()=>this.props.history.goBack()}/>
                }
                <HotelForm  saveCard={this.save} onChange={AddressesStore.changeAddressObject}/>
            </div>
        );
    }
}