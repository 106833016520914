import React, {Component, RefObject} from 'react';
import Button from "react-bootstrap/Button";
import SpinnerComponent from "../Spinner";


export interface IButtonProps {
    onClick: () => void;
    value: string;
    disabled?: boolean;
    additionalClass?: string;
    refLink?: RefObject<any>;
    spinner?: boolean;
}

export default class YellowButton extends Component<IButtonProps> {
    render() {
        const {onClick, value, disabled, additionalClass, refLink, spinner = false} = this.props;
        return (
            <div className={'button_container'}>
                <Button
                    onClick={onClick}
                    className={`yellow_button ${additionalClass}`}
                    disabled={disabled || spinner}
                    ref={refLink}
                >
                    {spinner ?
                        <SpinnerComponent />
                        :
                        value
                    }
                </Button>
            </div>
        )
    }
}