import React, { Component } from "react";
import PaymentStore from "../../../Stores/PaymentStore";
import { RouterProps } from "react-router";
import StepsComponent from "../../../Shared/StepsComponent";
import { ICreditCardAfterSave } from "../../../Interfaces/CreditCardsInterfaces";
import OutlineButton from "../../../Shared/Buttons/OutlineButton";
import PaymentSettingsItem from "../../ProfilePages/Payment/PaymentSettingsItem";
import YellowButton from "../../../Shared/Buttons/YellowButton";
import { observer } from "mobx-react";
import BookingStore from "../../../Stores/BookingStore";
import queryString from "query-string";
import Popup from "../../Popups/Popup";
import ModalStore from "../../../Stores/ModalStore";
// import { getParsedCommandLineOfConfigFile } from "typescript";
@observer
class PaymentChoosePage extends Component<RouterProps> {
  componentDidMount() {
    if (!BookingStore.bookingData.id) this.props.history.push("/");
    PaymentStore.getCreditCards();
  }

  deleteItem = (id: number) => PaymentStore.deleteCreditCard(id);

  editItem = async (id: ICreditCardAfterSave) => {
    this.props.history.push("/add_new_card", { title: "edit credit card", id: id });
  };

  rescheduleAppointment = async (rescheduleItem: number) => {
    try {
      await BookingStore.rescheduleItem(rescheduleItem);
      this.props.history.push('/booking_done_step1');
    } catch (error) {
      BookingStore.setError('secondStep', error.message.detail);
    }
  };

  continue = () => {
    BookingStore.setBookingToStorage();
    const parsed: any = queryString.parse(window.location.search);
    if (parsed.rescheduleItem) {
      this.props.history.push(
        `/payment_details?rescheduleItem=${parsed.rescheduleItem}`
      );

      // this.rescheduleAppointment(parsed.rescheduleItem)

    } else {
      this.props.history.push("/payment_details");
    }
  };

  getRescheduleCard = () => {
    const creditCard = PaymentStore.paymentSettings.find(
      e => e.id === BookingStore.bookingData.payments
    );
    return creditCard ? [creditCard] : [];
  };

  render() {
    const parsed: any = queryString.parse(window.location.search);
    const cards = parsed.rescheduleItem
      ? this.getRescheduleCard()
      : PaymentStore.paymentSettings;
    return (
      <div className={"settings_container payment_choose"}>
        <StepsComponent
          steps={"bookingSteps"}
          active={2}
          done={[0, 1]}
          onBack={this.props.history.goBack}
        />{" "}
        :
        <div className="container payment_settings">
          <h1>payments</h1>
          <h4>Credit Card</h4>
          <div className={"items_container"}>
            {cards.map((creditCard: ICreditCardAfterSave, i: number) => (
              <PaymentSettingsItem
                value={creditCard}
                img={"fas fa-credit-card"}
                deleteItem={this.deleteItem}
                editItem={this.editItem}
                hideControl={parsed.rescheduleItem}
                key={`${i}-${creditCard.card_number}`}
              />
            ))}
          </div>
          {!parsed.rescheduleItem && (
            <OutlineButton
              value={"+ add new credit card"}
              onClick={() =>
                this.props.history.push("/add_new_card", {
                  title: "add credit card"
                })
              }
            />
          )}
          <YellowButton
            onClick={this.continue}
            value={parsed.rescheduleItem ? "book" : "continue"}
            additionalClass={"w-100"}
            disabled={cards.length === 0 ? true : false}
          />
        </div>
        <Popup
          title={"Error!"}
          content={PaymentStore.errorOnComponent.modalError}
          id={"paymentError"}
          onHide={() => ModalStore.toggleModal("paymentError", false)}
          show={ModalStore.paymentError}
        />
      </div>
    );
  }
}
export default PaymentChoosePage;
