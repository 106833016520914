import React, { Component } from "react";
import ProfileStore from "../../../Stores/ProfileStore";
import { observer } from "mobx-react";
import done_icon from "../../../assets/icons/done.svg";

@observer
class SubscriptionDetail extends Component {
    render() {
        const { selectedSubscriptionItem } = ProfileStore;
        return (
          <>
            { selectedSubscriptionItem ? (
              <div className="subscription_detail_container">
                <img src={done_icon} alt="." />
                <p>
                  ${selectedSubscriptionItem.price}/ea, Billed as $
                  {selectedSubscriptionItem.total_price}/month
                </p>
              </div>
            ) : null}
          </>
        );
    }
}

export default SubscriptionDetail;