import React, {Component} from 'react'
import WhiteButton from "../../../Shared/Buttons/WhiteButton";
import {RouterProps} from "react-router";

export default class BookingDone2 extends Component <RouterProps>{
    componentDidMount(): void {
        const navbar = document.querySelector('nav');
        if (navbar) navbar.classList.add('hide');
    }

    componentWillUnmount(): void {
        const navbar = document.querySelector('nav');
        if (navbar) navbar.classList.remove('hide');
    }

    nextPage = () => {
        const search = this.props.history.location.search;
        const url = search? `/${search.split('=')[1]}` : '/';
        this.props.history.push(url);
    };

    render() {
        return (
            <div className={'booking_done'}>
                <div className={'logo_container'}>
                    <img src={require('../../../assets/Logo.svg')} alt={'logo'} width={300}/>
                </div>
                <div className={'background_container d-flex align-items-center justify-content-center'}>
                    <div className="content_container">
                        <h1>After My Spray Tan</h1>
                        <ul>
                            <li className={'d-flex'}>
                                <div className={'icon'}><img src={require('../../../assets/icons/1.svg')} alt={'shower'}/>
                                </div>
                                <div className={'instruction'}>Do not shower, sweat, mess with water or wear tight
                                    clothing for 4 hours following your appointment.
                                </div>

                            </li>
                            <li className={'d-flex'}>
                                <div className={'icon'}><img src={require('../../../assets/icons/2.svg')} alt={'house'}/>
                                </div>
                                <div className={'instruction'}>The bronzer may move and marks may show up on your skin.
                                    This is ok and normal. The clear tan that develops will not be affected. Once your
                                    shower everything will be even.
                                </div>

                            </li>
                            <li className={'d-flex'}>
                                <div className={'icon'}><img src={require('../../../assets/icons/3.svg')} alt={'outlet'}/>
                                </div>
                                <div className={'instruction'}>Shower anytime after 4 hours. Do not use soap your first
                                    shower. You will see the bronzer wash off, this is normal. Make sure all bronzer is off.
                                    Do not scrub. If your color goes away don't worry within the next few hours the color will start to show up.
                                </div>

                            </li>
                            <WhiteButton onClick={this.nextPage} value={'ok'} addClass={'w-70'}/>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}