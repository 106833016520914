import React from "react";
import ValetsStore from "../../../Stores/ValetsStore";

interface IProps {
    sign: string;
    property: string;
    text: string;
    valetId: number | null;
    isActive: boolean|undefined;
    onClick?:()=>void;
}

const ValetStatusComponent = (props:IProps) => {
    const onClick = async () => {
        await ValetsStore.markValet(props.valetId, props.property, !props.isActive);
        await ValetsStore.getListOfUserValets();
        if (props.onClick){
            props.onClick();
        }
    };
    return (
        <div
            className={`valet_status_component d-flex ${props.isActive?'active':''}`}
            onClick={onClick}
        >
            <div className={'sign'}>
                <i className={`fas fa-${props.sign}`}/>
            </div>
            <div className={'text'}>{props.text}</div>
        </div>
    );
}

export default ValetStatusComponent;