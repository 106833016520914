import {action, observable} from "mobx";


// TODO: remove modals to store

class ModalStore {
  @observable subscriptionChangeCity: boolean = false;
  @observable subscriptionSuccess: boolean = false;
  @observable subscriptionCancel: boolean = false;
  @observable paymentError: boolean = false;
  @observable notificationError: boolean = false;
  @observable myBookingsError: boolean = false;
  @observable errorOnSubscriptions: boolean = false;
  @observable errorOnReferral: boolean = false;
  @observable referralSuccess: boolean = false;

  @observable ShowTOS: boolean = false;

  [key: string]: boolean | ((name: string, state: boolean) => void);

  @action toggleModal = (name: string, state: boolean) => (this[name] = state);
}

export default new ModalStore();