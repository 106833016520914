import React, { Component } from 'react';
import BookingStore from '../../../Stores/BookingStore';
import { RouteComponentProps, withRouter } from 'react-router';
import ValetsStore from '../../../Stores/ValetsStore';
import ValetStatusComponent from './ValetStatusComponent';
import TimeStore from '../../../Stores/TimeStore';
import { IValet } from '../../../Interfaces/ValetInterfaces';
import { defaultAvatar } from '../../../Interfaces/OtherInterfaces';
import moment from 'moment';

interface IProps extends RouteComponentProps {
  valet: IValet | null;
}

class ValetComponent extends Component<IProps> {
  startBooking = async () => {
    if (this.props.valet) {
      await BookingStore.createNewBooking(this.props.valet.valet.id);
      await TimeStore.getAvailableTime(moment(), this.props.valet.valet.id);
      ValetsStore.isUserHasOnlyValet = true;
      this.props.history.push(
        `/booking_address?valetID=${this.props.valet.valet.id}`
      );
    }
  };
  render() {
    if (!this.props.valet || (this.props.valet && !this.props.valet.valet))
      return null;
    const {
      first_name,
      avatar_url,
      rating,
      id,
      address,
    } = this.props.valet.valet;
    const { favorite, only } = this.props.valet;
    const technical =
      address.length && address[0]
        ? address[0].city === 'Los Angeles'
          ? 'Los Angeles Spray Tan Technician '
          : 'Las Vegas Spray Tan Technician '
        : '';
    return (
      <div
        className={
          'valet_component_container d-flex flex-column align-items-center'
        }
      >
        <div className="d-flex justify-content-between w-100 avatar_container">
          <div className="score">
            <img
              src={require(`../../../assets/icons/${
                rating === 5 ? '5' : '4'
              }stars.svg`)}
              alt="star"
            />
            <span>{rating}</span>
          </div>
          <div className="avatar">
            <img src={avatar_url || defaultAvatar} alt="avatar" />
          </div>
          <div className="add_menu" onClick={() => ValetsStore.removeValet(id)}>
            <i className="far fa-trash-alt" />
            <span>REMOVE</span>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div className="up_conner" />
              <span
                className="dropdown-item"
                onClick={() => ValetsStore.removeValet(id)}
              >
                Remove
              </span>
            </div>
          </div>
        </div>
        <div className="name">{first_name}</div>
        <div className="technical">{technical}</div>
        <div className={'d-flex justify-content-around status w-100'}>
          <ValetStatusComponent
            sign={'heart'}
            text={'favorite'}
            valetId={id}
            isActive={favorite}
            property={'favorite'}
          />
          <ValetStatusComponent
            sign={'exclamation'}
            text={'only'}
            valetId={id}
            isActive={only}
            property={'only'}
          />
        </div>
        <div className="button_book" onClick={this.startBooking}>
          Book
        </div>
      </div>
    );
  }
}

export default withRouter(ValetComponent);
