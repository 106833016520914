import React, { Component } from 'react';
import AddressesStore from '../../../Stores/AddressesStore';
import AddressSettingsItem from '../../ProfilePages/Addresses/AddressSettingsItem';
import OutlineButton from '../../../Shared/Buttons/OutlineButton';
import YellowButton from '../../../Shared/Buttons/YellowButton';
import { RouterProps } from 'react-router';
import BookingStore from '../../../Stores/BookingStore';
import StepsComponent from '../../../Shared/StepsComponent';
import { observer } from 'mobx-react';
import {
  IAddress,
  defaultLocationInfo,
} from '../../../Interfaces/AddressesInterface';
import TimeStore from '../../../Stores/TimeStore';
import moment from 'moment';
import queryString from 'query-string';
import ReactGA from 'react-ga4';
import config from '../../../config';
// import { parse } from 'path';

interface IState {
  serviceAreaModal: boolean;
  extraFeeModal: boolean;
  e: string;
}

@observer
export default class LocationInformationForRegistered extends Component<
  RouterProps,
  IState
> {
  state = { serviceAreaModal: false, extraFeeModal: false, e: '' };
  componentDidMount(): void {
    const parsed: any = queryString.parse(window.location.search);

    if (parsed.city != null){
      AddressesStore.getAddresses(parsed.city)
    }else{
      // if (!AddressesStore.addressesSettings.length) {
        AddressesStore.getAddresses();
      // }
    }
    if (config.isProd) {
      ReactGA.event({
        category: 'form-submission',
        action: 'book-appoitment-form',
        label: 'location',
      });
    }
  }

  continue = async () => {
    this.setState({ e: '' });
    try {
      const parsed: any = queryString.parse(window.location.search);

      const address =
        AddressesStore.addressesSettings.find(
          (e) => e.id === AddressesStore.defaultAddressId
        ) || null;

      if (parsed.rescheduleItem) {
        const time = BookingStore.bookingData.time.padStart(8, '0');
        TimeStore.timeStructure[time] = {
          ...TimeStore.timeStructure[time],
          reserved: true,
          available: true,
        };
        return parsed.valetID
        ? this.props.history.push(`/booking_valet?rescheduleItem=${parsed.rescheduleItem}&valetID=${parsed.valetID}`)
        : this.props.history.push(
          `/booking_valet?rescheduleItem=${parsed.rescheduleItem}`
        );
      } else {
        await TimeStore.getAvailableTime(
          moment(),
          null,
          address && address.city
        );
      }
      parsed.valetID
        ? this.props.history.push(`/booking_valet?valetID=${parsed.valetID}`)
        : this.props.history.push(`/booking_valet`);
    } catch (e) {
      console.log(e);
      this.setState({ e: e.message });
    }
  };

  editItem = async (id: number) => {
    await AddressesStore.editAddress(id);
    this.props.history.push('/add_location');
  };

  deleteItem = (id: number) => AddressesStore.deleteAddress(id);

  getRescheduleAddress = () => {
    const address = AddressesStore.addressesSettings.find(
      (e) => e.id === BookingStore.bookingData.address.id
    );
    return address ? [address] : [];
  };

  render() {
    const { addressesSettings, defaultAddressId } = AddressesStore;
    const parsed: any = queryString.parse(window.location.search);
    const addresses: IAddress[] = parsed.rescheduleItem
      ? this.getRescheduleAddress()
      : addressesSettings;
    return (
      <div className="gray_background location_information">
        <StepsComponent steps={'bookingSteps'} active={0} />
        <div className={'location_form'}>
          <h1>Location</h1>
          <h4>Address</h4>
          {addresses.map((address: IAddress, i: number) => (
            <AddressSettingsItem
              img={'fas fa-map-marker-alt'}
              value={address}
              defaultAddressId={defaultAddressId}
              editItem={this.editItem}
              deleteItem={this.deleteItem}
              hideControl={parsed.rescheduleItem}
              key={`${i}-${address.address}`}
            />
          ))}
          {!parsed.rescheduleItem && (
            <OutlineButton
              value={'+ add new address'}
              onClick={() => {
                AddressesStore.addressObject = defaultLocationInfo;
                this.props.history.push('/add_location');
              }}
            />
          )}
          <span className="text-danger">{this.state.e}</span>
          <YellowButton
            onClick={this.continue}
            value={'continue'}
            additionalClass={'w-100'}
            disabled={!AddressesStore.defaultAddressId}
            spinner={BookingStore.isLoading}
          />
        </div>
      </div>
    );
  }
}
