import React, {Component} from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import {RouteComponentProps, withRouter} from "react-router";
import {pagesWithoutNavbar} from "../../Constants/PagesWithoutNavbar";
import config from '../../config';

interface IProps extends RouteComponentProps{

}

class UnregisteredNavbar extends Component <IProps>{

    onLoad = (event:React.UIEvent<HTMLElement>) => {
        if (pagesWithoutNavbar.includes(this.props.location.pathname)){
            event.currentTarget.classList.add('hide');
        }
    };
    render(){
        return (
          <Navbar expand="lg" id={"unregister_navbar"} onLoad={this.onLoad}>
            <Navbar.Brand href="https://sprayvalet.com" className={"d-flex justify-content-center"}>
              <img src={require("../../assets/Logo.svg")} alt={"logo"} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="w-100 justify-content-around">
                <Nav.Link href={`${config.baseUrlForNavLinks}/`}>
                  Our Services
                </Nav.Link>
                <Nav.Link href={`${config.baseUrlForNavLinks}/pricing/`}>
                  Pricing
                </Nav.Link>
                <Nav.Link
                  href={`${config.baseUrlForNavLinks}/gift-cards-catalogue/`}
                >
                  Gift Cards
                </Nav.Link>
                <Nav.Link href={`${config.baseUrlForNavLinks}/about-us/`}>
                  About Us
                </Nav.Link>
                {/* <Nav.Link href={`${config.baseUrlForNavLinks}/blog/`}>Blog</Nav.Link> */}
                <Nav.Link href={`${config.baseUrlForNavLinks}/contact/`}>
                  Contact
                </Nav.Link>
                <Nav.Link href={`${config.baseUrlForNavLinks}/qa/`}>
                  FAQ
                </Nav.Link>
              </Nav>
              <div className={"navbar_button_container"}>
                <Button
                  type={"button"}
                  variant={"outline-secondary"}
                  onClick={() => this.props.history.push("/logIn")}
                >
                  Log In
                </Button>
                <Button
                  type={"button"}
                  variant={"outline-secondary"}
                  onClick={() => this.props.history.push("/signIn")}
                >
                  Sign Up
                </Button>
              </div>
            </Navbar.Collapse>
          </Navbar>
        );}
}

export default withRouter(UnregisteredNavbar);