import ProfileStore from "../Stores/ProfileStore";
import MessagesStore from "../Stores/MessagesStore";
import config from "../config";

class ChatService {
  constructor(id) {
    this.socket = new WebSocket(
      `${config.chat_url}${id}/`
    );
    this.hearBeatIntervalId = null;
    this.socket.onopen = this.onOpen;
    this.socket.onmessage = this.onMessage;
    this.socket.onclose = this.onClose;
  }
  onOpen = async () => {
    this.socket.send(JSON.stringify(this.getAuthMessage("login")));
    this.hearBeat()
  };

  onMessage = event => {
    const message = JSON.parse(event.data);
    message.text
      ? MessagesStore.receiveNewMessage(message)
      : MessagesStore.setOnline(message);
  };

  onClose = e => {
    console.warn("Chat closed", e.reason);
  };

  sendMessage = message => {
    this.socket.send(JSON.stringify(message));
  };

  hearBeat = () => {
    this.hearBeatIntervalId = setInterval(() => {
      this.socket.send(JSON.stringify(this.getAuthMessage("login")));
    }, 6000);
  };

  //action: "logout", "login"
  getAuthMessage = action => ({ user: ProfileStore.profile.id, action });

  close = () => {
    clearInterval(this.hearBeatIntervalId);
    this.socket.send(JSON.stringify(this.getAuthMessage("logout")));
    this.hearBeatIntervalId = null;
    this.socket.close();
  };
}
export default ChatService;
