import React, { ChangeEvent, Component } from "react";
import Logo from "../../../assets/Logo.svg";
import LoginInput from "../../../Shared/LoginInput";
import YellowButton from "../../../Shared/Buttons/YellowButton";
import { observer } from "mobx-react";
import { RouterProps } from "react-router";
import AvatarIconWithPicker from "../../../Shared/AvatarIconWithPicker";
import StepsComponent from "../../../Shared/StepsComponent";
import AuthStore from "../../../Stores/AuthStore";
import { Link } from "react-router-dom";
import ProfileStore from "../../../Stores/ProfileStore";

interface IState {
  avatar_url: string;
}

@observer
export default class SignInClientInfo extends Component<RouterProps, IState> {
  private readonly fileInput: React.RefObject<any>;
  private readonly plusButton: React.RefObject<any>;
  constructor(props: RouterProps) {
    super(props);
    this.state = {
      avatar_url: require("../../../assets/human.svg")
    };
    this.fileInput = React.createRef();
    this.plusButton = React.createRef();
  }
  componentDidMount(): void {
    const navbar = document.querySelector("nav");
    if (navbar) navbar.classList.add("hide");
    if (!AuthStore.isLogged) {
      this.props.history.replace("/signIn");
    }
    const button = this.plusButton.current;
    const input = this.fileInput.current;
    if (button) {
      button.addEventListener(
        "click",
        (e: React.MouseEvent<HTMLElement>) => {
          if (input) {
            input.click();
          }
        },
        false
      );
    }
  }

  componentWillUnmount(): void {
    const navbar = document.querySelector("nav");
    if (navbar) navbar.classList.remove("hide");
  }

  nextStep = async () => {
    await ProfileStore.saveProfileData();
    this.props.history.push("/sign_location_info");
  };
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    ProfileStore.changeProfileData(event.target.name, event.target.value);
  };
  getFile = () => {
    const fileReader = new FileReader();
    ProfileStore.avatarFile = this.fileInput.current.files[0];
    fileReader.readAsDataURL(this.fileInput.current.files[0]);
    fileReader.onloadend = (event: any) => {
      this.setState({ avatar_url: event.target.result });
    };
  };
  render() {
    const { first_name, last_name } = ProfileStore.profile;
    const disable = () => {
      return !(first_name && last_name);
    };
    return (
      <div className={"d-flex min-vh-100"}>
        <div className={"col-md-7 col-12"}>
          <div id={"sign_in_client_info"}>
            <div className={"logo_container mb-5"}>
              <Link
                to={"/"}
                onClick={(e: any) => {
                  e.preventDefault();
                  this.props.history.push("/");
                }}
              >
                <img src={Logo} alt={"Logo"} />
              </Link>
            </div>
            <div className={"content_container"}>
              <h1 className={"text-capitalize text-center font-weight-bold"}>
                A few more steps left
              </h1>
              <StepsComponent steps={"regSteps"} active={0} />
              <AvatarIconWithPicker
                inputRef={this.fileInput}
                buttonRef={this.plusButton}
                onChange={this.getFile}
                src={ProfileStore.profile.avatar_url || this.state.avatar_url}
              />
              <div className={"form-group variable-width"}>
                <LoginInput
                  type={"text"}
                  placeholder={"First Name"}
                  name={"first_name"}
                  onChange={this.handleChange}
                  iconName={"fa-user"}
                  value={first_name}
                />
                <LoginInput
                  type={"text"}
                  placeholder={"Last Name"}
                  name={"last_name"}
                  iconName={"fa-user"}
                  onChange={this.handleChange}
                  value={last_name}
                />
              </div>
              <YellowButton
                onClick={this.nextStep}
                value={"next"}
                disabled={disable()}
                spinner={ProfileStore.isLoading}
              />
            </div>
          </div>
        </div>
        <div className={"col-md-5 col-0 p-0"}>
          <div id={"signIn_steps"}></div>
        </div>
      </div>
    );
  }
}
