import React, { Component } from "react";
import Compose from "../Compose";
import Toolbar from "../Toolbar";
import Message from "../Message";
import moment from "moment";
import MessagesStore from "../../../Stores/MessagesStore";
import { observer } from "mobx-react";
import ProfileStore from "../../../Stores/ProfileStore";
import SpinnerComponent from "../../../Shared/Spinner";

@observer
export default class MessageList extends Component {
  static renderMessages() {
    let i = 0;
    const messageList = MessagesStore.messageList;
    const activeConversation = MessagesStore.activeConversation;
    const messageCount = messageList.length;
    const messages = [];
    const userId = ProfileStore.profile.id;

    while (i < messageCount) {
      const previous = messageList[i - 1];
      const current = messageList[i];
      const next = messageList.length > i + 1 ? messageList[i + 1] : null;
      const isMine = current.from_user === userId;
      const currentMoment = moment(current.created_at);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
      let showDate = false;
      let photo = ProfileStore.profile.avatar_url;

      if (previous) {
        const previousMoment = moment(previous.created_at);
        const previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.from_user === current.from_user;

        if (prevBySameAuthor && previousDuration.as("minutes") < 1) {
          startsSequence = false;
        }

        if (nextBySameAuthor && previousDuration.as("minutes") < 1) {
          showTimestamp = false;
        }
        if (previousDuration.as("days") >= 1) {
          showDate = true;
        }
      }

      if (next) {
        const nextMoment = moment(next.created_at);
        const nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.from_user === current.from_user;

        if (nextBySameAuthor && nextDuration.as("minutes") < 1) {
          endsSequence = false;
        }
        if (nextBySameAuthor && nextDuration.as("minutes") < 1) {
          showTimestamp = false;
        }
        if (!previous && nextDuration.as("days") >= 1) {
          showDate = true;
        }
      }
      if (!isMine) {
        photo =
          activeConversation.chatting_with.avatar_url ||
          activeConversation.appointment.valet.avatar_url;
      }
      messages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          showDate={showDate}
          data={current}
          photo={photo}
        />
      );

      // Proceed to the next message.
      i += 1;
    }
    return messages;
  }

  render() {
    return (
      <div className="message-list">
        <Toolbar />

        <div className="message-list-container">
          {MessagesStore.isLoading && MessagesStore.showSpinner ? (
            <SpinnerComponent variant={"warning"} size={"lg"} />
          ) : MessagesStore.messageList.length ? (
            MessageList.renderMessages()
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <span>
                Seems you don’t have any messages with this valet yet - don't
                hesitate to contact valet if you have any questions.
              </span>
            </div>
          )}
        </div>

        <Compose />
      </div>
    );
  }
}
