import React, {Component, RefObject} from 'react';
import changeActiveItem from "../../../Utils/changeActiveItem";
import AddressesStore from "../../../Stores/AddressesStore";
import {IAddress} from "../../../Interfaces/AddressesInterface";

interface IProps {
    img: string;
    value: IAddress;
    editItem: (id:number) => void;
    deleteItem: (id:number) => void;
    defaultAddressId: number;
    hideControl?:boolean
}

class AddressSettingsItem extends Component<IProps> {
    itemRef: RefObject<HTMLDivElement> = React.createRef();

    render() {
        const {img, value, editItem, deleteItem, defaultAddressId, hideControl} = this.props;
        const makeActive = async () => {
            await AddressesStore.changeDefaultAddress(value.id);
            if (this.itemRef.current) {
                changeActiveItem(this.itemRef.current, 'active')
            }
        };
        const isActive = value.id === defaultAddressId;
        return (
            <div
                className={`profile_item_container d-flex flex-column ${isActive ? 'active' : ''} address_item`}
                onClick={makeActive}
                ref={this.itemRef}
            >
                <div className={'d-inline-flex align-items-center flex-grow-1'}>
                    <div className={`round`}/>
                    <i className={img}/>
                    <div className="profile_item">{value.address}</div>
                </div>
                {!hideControl && <div className={'inline_buttons_container d-inline-flex justify-content-end'}>
                    <div className={'inline_button'} onClick={() => editItem(value.id)}>Edit</div>
                    <div className={'inline_button'} onClick={() => deleteItem(value.id)}>Delete</div>
                </div>}
            </div>
        );
    }
}

export default AddressSettingsItem;