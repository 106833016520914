import React, { Component } from "react";
import { RouterProps } from "react-router";
import PaymentStore from "../../../Stores/PaymentStore";
import ProfileStore from "../../../Stores/ProfileStore";
import BookingStore from "../../../Stores/BookingStore";
import { observer } from "mobx-react";
import CustomStepsComponent from '../../../Shared/CustomStepsComponent';
import ContactInformation from './ContactInformation';
import BookingSummary from './BookingSummary';
import CustomNavBar from "../../../Shared/Navbars/CustomNavBar";
import StorageService from '../../../Services/StorageService';
import { IUserSubscription } from '../../../Interfaces/ProfileInterfaces';
import ModalStore from '../../../Stores/ModalStore';
import CustomPopup from '../../Popups/CustomPopup';
import { Elements } from 'react-stripe-elements';
import { StripeProvider } from 'react-stripe-elements';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import FacebookConversionApiService from '../../../Services/FacebookConversionApiService';
import config from '../../../config';
import SpinnerComponent from '../../../Shared/Spinner';
@observer
class PaymentDetailsBookingPage extends Component<RouterProps> {
  timeout: any;
  state = { e: '', errorMessage: '', showModal: false, isLoading: false };
  cardForm: React.RefObject<any> = React.createRef();
  constructor(props: RouterProps) {
    super(props);
    this.timeout = null;
    StorageService.getItem('zip_code')
      .then((response: string) => {
        if (!response) {
          this.props.history.push('/');
        }
      }); 
  }

  componentDidMount(): void {
    if (config.isProd) {
      ReactGA.event({
        category: 'form-submission',
        action: 'WebApp_booking_details_completed',
        label: 'Booking details completed',
      });
    }

    if (config.REACT_APP_FACEBOOK_PIXEL_ID) {
      ReactPixel.trackCustom('Add to cart', {event_time: Math.floor(Date.now() / 1000)});

      const event_data = {
        data: {
          user_data: {
            client_user_agent: window.navigator.userAgent,
            fbp: ''
          }
        }
      };

      let fbpRes = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);

      if ((fbpRes && fbpRes[1])) {
        event_data.data.user_data.fbp = fbpRes[1];
        FacebookConversionApiService.trackAddToCart(event_data);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  nextStep = () => this.props.history.push('/thank_you');
  
  onConfirmBooking = async () => {
    const { first_name, last_name, email, phone } = PaymentStore.paymentContacts;
    if (!first_name || !last_name || !email || !phone)  {
      this.setState({ e: "Invalid or Missing contact information"});
      return;
    }
    if (phone.includes('123456789')) {
      this.setState({ showModal: true, errorMessage: 'Phone number is invalid'});
      return;
    }
    const card_holder = PaymentStore.creditCard['card_holder'];
    if (!card_holder) {
      this.setState({
        showModal: true,
        errorMessage: "Card holder name is required"
      });
      return;
    }

    const card_number = PaymentStore.cardEmptyInfo['card_number'];
    if (card_number) {
      this.setState({
        showModal: true,
        errorMessage: "Card number is required"
      });
      return;
    }

    const card_exp_date = PaymentStore.cardEmptyInfo['exp_date'];
    if (card_exp_date) {
      this.setState({
        showModal: true,
        errorMessage: "Expiration date is required"
      });
      return;
    }

    const card_cvc = PaymentStore.cardEmptyInfo['cvc'];
    if (card_cvc) {
      this.setState({
        showModal: true,
        errorMessage: "Security code is required"
      });
      return;
    }

    this.timeout = setTimeout(() => {
      this.setState({ isLoading: true });
    }, 1500);

    try {
      await ProfileStore.updateProfileData({
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone
      });
      const isCardOk = await this.saveCard();
      if(!isCardOk){
        this.setState({ isLoading: false });
        clearTimeout(this.timeout);
        return;
      }
      const error = await this.subscribe();
      if (!error) {
        await this.bookingSuccess();
      }else{
        this.setState({ isLoading: false });
        clearTimeout(this.timeout);
      }
    } catch (e) {
      console.log(e);      
      clearTimeout(this.timeout);
      this.setState({ isLoading: false })
      const error = ProfileStore.processError.toLocaleUpperCase();
      if(error.indexOf('EXISTS') > 0){
        const errMsg = `USER WITH THIS EMAIL ADDRESS ALREADY EXISTS. Go to <a href="/login" style="color: #767676"><u>Login</u></a>`;
        this.setState({ showModal: true, errorMessage: errMsg });
      } else {
        this.setState({ showModal: true, errorMessage: ProfileStore.processError ? ProfileStore.processError.toLocaleUpperCase() : "Invalid or Missing contact information" });
      }
    }
  };
  
  saveCard = async () => {
    try {
      if (BookingStore.bookingData_new.payment_info.id === 0) {
        const response = await this.cardForm.current.wrappedInstance.saveCard();

        if (response && response.error) {
          PaymentStore.errorOnComponent.modalError =
            'Your card was declined. Please try another one.';
          ModalStore.toggleModal('paymentError', true);
          this.setState({
            showModal: true,
            errorMessage: 'Your card was declined. Please try another one.'
          });
          return false;
        }

        const payment_info = {
          first_name: ProfileStore.profile.first_name,
          last_name: ProfileStore.profile.last_name,
          email: ProfileStore.profile.email,
          phone: ProfileStore.profile.phone
        }

        await PaymentStore.savePaymentInfo(payment_info);
        return true;
      }
    } catch (error) {
      console.log("Error:", error);
      if(PaymentStore.errorOnComponent.modalError){
        BookingStore.setError('secondStep', PaymentStore.errorOnComponent.modalError);
        this.setState({ showModal: true, errorMessage: PaymentStore.errorOnComponent.modalError });
      }
      return false;
    }
  };

  subscribe = async () => {
    if (
      BookingStore.bookingData_new.purchase_method === 'Subscription' &&
      !ProfileStore.profile.client_subscriptions  && !this.hasRemainingAppointments(ProfileStore.activeUsersSubscriptions)
    ) {
      if (
        ProfileStore.selectedSubscription &&
        !ProfileStore.compareSubscriptions()
      ) {
        const error = await ProfileStore.changeSubscription();
        if (error) {
          BookingStore.setError('secondStep', error.message.detail);
          this.setState({ showModal: true, errorMessage: error.message.detail });
          return true;
        }
      } else {
        BookingStore.inlineErrors.subscription = true;
        return true;
      }
    }
    return false;
  };

  hasRemainingAppointments = (subscriptions: IUserSubscription[]) => {
    return subscriptions[0] && subscriptions[0].cancellation_date && subscriptions[0].appointments_left + subscriptions[0].unused_appointments > 0
  };

  bookingSuccess = async () => {
    try{
      ProfileStore.selectedSubscription = null;
      await BookingStore.createBookingStep2_new(PaymentStore.paymentContacts, PaymentStore.creditCard);
      
    } catch (error) {
      if(error.message){
        this.setState({ showModal: true, errorMessage: error.message });
      }
    }finally{
      if (BookingStore.sendSuccess) {
        // ModalStore.toggleModal('ShowTOS', true);
        this.nextStep();
      }else{
        this.setState({ isLoading: false });
      }
    }
    
  };

  render() {
    const { showModal, errorMessage, isLoading  } = this.state;
    return (
          <div className="payment-detail-container">
            <div className={"payment_detail_booking_page"}>
              <CustomNavBar/>
              <CustomStepsComponent
                steps={'bookingSteps'}
                active={2}
                onBack={this.props.history.goBack}
                done={[0,1]}
              />
              <div className={'custom_small_container'}>
                <Elements
                  fonts={[
                    {
                      family: 'Montserrat',
                      src: `url('https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2') format('woff2')`,
                      weight: '400'
                    }]
                  }
                >
                  <ContactInformation 
                    nextStep={this.nextStep}
                    confirmBooking={this.onConfirmBooking}
                    value={PaymentStore.paymentContacts}
                    credit_value={PaymentStore.creditCard}
                    onChangeContact={PaymentStore.changePaymentInfo}
                    onChangeCredit={PaymentStore.changeCreditInfo}
                    error={this.state.e}
                    ref={this.cardForm}
                    showError={(error?: string) => this.setState({
                      showModal: true,
                      errorMessage: error
                    })}
                  />
                </Elements>
                <BookingSummary
                  booking_value={BookingStore.bookingData_new}
                  credit_value={PaymentStore.creditCard}
                  onChangeCredit={PaymentStore.changeCreditInfo}
                  error={this.state.e}
                />
                <CustomPopup
                  id={'email_exists'}
                  content={errorMessage}
                  onHide={() => this.setState({showModal: false})}
                  show={showModal}
                />
              </div>
              {isLoading && (
              <div className="spinner-overlay">
                <SpinnerComponent variant={'warning'} size={'lg'} />
              </div>
            )}
            </div>
          </div>
    )
  }
}
export default PaymentDetailsBookingPage;
