import React, {Component} from 'react';

interface IProps{
    inputRef:React.RefObject<any>;
    buttonRef:React.RefObject<any>;
    onChange:()=>void;
    src:string;
}

export default class AvatarIconWithPicker extends Component<IProps>{
    render(){
        const {inputRef, buttonRef, onChange, src} = this.props;
        return (<div className={'user_container'}>
            <div className={'user_icon'}>
                <img src={src} alt="human" className={'avatar_image'}/>
            </div>
            <input type={'file'} accept={"image/x-png,image/gif,image/jpeg"} ref={inputRef} className={'d-none'} onChange={onChange}/>
            <div className={'image_picker_plus'} ref={buttonRef}>
                <i className={'fas fa-plus'}/>
            </div>
        </div>);
    }
}