import React, {Component, RefObject} from 'react';
import Button from "react-bootstrap/Button";
import SpinnerComponent from "../Spinner";


export interface IButtonProps {
    onClick: () => void;
    value: string;
    disabled?: boolean;
    additionalClass?: string;
    refLink?: RefObject<any>;
    spinner?: boolean;
}

export default class CustomLocationButton extends Component<IButtonProps> {
    render() {
        const {onClick, value, disabled, refLink, spinner = false} = this.props;
        return (
            <div className={'custom_button_container'} style={{padding:'0px', width: '335px'}}>
                <Button
                    onClick={onClick}
                    className={`custom_yellow_button`}
                    disabled={disabled || spinner}
                    ref={refLink}
                    style={{height:'40px', fontSize: '16px', fontWeight: 700, position: 'relative'}}
                >
                    {spinner ?
                        <SpinnerComponent />
                        :
                        value
                    }
                    <i className='fas fa-arrow-right' style={{position: 'absolute', top: '10px', right: '20px'}}></i>
                </Button>
            </div>
        )
    }
}