import React, { ChangeEvent, Component } from "react";
import WhiteButton from "../../Shared/Buttons/WhiteButton";
import Logo from "../../assets/Logo.svg";
import Google from "../../assets/Google.png";
import LoginInput from "../../Shared/LoginInput";
import YellowButton from "../../Shared/Buttons/YellowButton";
import Button from "react-bootstrap/Button";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { RouterProps } from "react-router";
import AuthStore from "../../Stores/AuthStore";
import Validation from "../../Utils/Validation";
import SocialLoginsStore from "../../Stores/SocialLoginStore";
import mixpanel from "mixpanel-browser";
interface IState {
  showPassword: boolean;
  inlineError: boolean;
  loginError: boolean;
  email: string;
  password: string;

  [key: string]: string | boolean;
}

@observer
export default class LogIn extends Component<RouterProps, IState> {
  state = {
    showPassword: false,
    inlineError: false,
    loginError: false,
    email: "",
    password: ""
  };
  gooClick = () => {
    SocialLoginsStore.googleSocialLoginUrl &&
      window.location.replace(SocialLoginsStore.googleSocialLoginUrl);
  };
  logIn = async () => {
    this.setState({ loginError: false });
    await AuthStore.login(this.state.email, this.state.password);
    mixpanel.track("Login", { email: this.state.email });
    mixpanel.identify(this.state.email);
    if (AuthStore.isLogged) {
      return this.props.history.push("/");
    }
    this.setState({ loginError: true });
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount(): void {
    const navbar = document.querySelector("nav");
    if (navbar) navbar.classList.add("hide");
  }

  componentWillUnmount(): void {
    const navbar = document.querySelector("nav");
    if (navbar) navbar.classList.remove("hide");
  }

  emailValidation = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name && value) {
      this.setState({ inlineError: !Validation[name](value) });
    }
  };

  render() {
    const { email, password } = this.state;
    const passwordType = this.state.showPassword ? "text" : "password";
    return (
      <div className={"d-flex min-vh-100"}>
        <div className={"col-md-7 col-12 "}>
          <div id={"log_in"}>
            <div className={"logo_container mb-5"}>
              <Link
                to={"/"}
                onClick={(e: any) => {
                  e.preventDefault();
                  this.props.history.push("/");
                }}
              >
                <img src={Logo} alt={"Logo"} />
              </Link>
            </div>
            <div className={"content_container"}>
              <h1 className={"text-capitalize text-center font-weight-bold"}>
                Log in
              </h1>
              <div
                className={
                  "d-flex flex-direction-row m-5 justify-content-center social_buttons_container"
                }
              >
                {/* }
                  /> */}

                {/* /> */}

                {SocialLoginsStore.googleSocialLoginUrl && (
                  <Button
                    type={"button"}
                    className={"sign_social_button"}
                    onClick={this.gooClick}
                  >
                    <img src={Google} alt={"Google"} />
                  </Button>
                )}
              </div>
              <div className={"form-group variable-width"}>
                <div className={"some_notes"}>or use your email account:</div>
                <LoginInput
                  type={"text"}
                  placeholder={"email"}
                  name={"email"}
                  onChange={this.handleChange}
                  onBlur={event => this.emailValidation(event)}
                  value={email}
                />
                <span
                  className={`inline_error ${
                    this.state.inlineError ? "visible" : "invisible"
                  }`}
                >
                  Please enter valid email
                </span>
                <LoginInput
                  type={passwordType}
                  placeholder={`Password`}
                  name={"password"}
                  onChange={this.handleChange}
                  iconName={"fa-key"}
                  secondIcon={"fa-eye"}
                  showPassword={() =>
                    this.setState({ showPassword: !this.state.showPassword })
                  }
                  value={password}
                />
                <span
                  className={`inline_error ${
                    this.state.loginError ? "visible" : "invisible"
                  }`}
                >
                  Invalid email or password
                </span>
                <div className={"link_container"}>
                  <Link to={"/password_recovery"} className={"agreements_link"}>
                    forgot your password?
                  </Link>
                </div>

                <YellowButton
                  onClick={this.logIn}
                  value={"login"}
                  disabled={this.state.inlineError}
                  additionalClass={"w-100"}
                  spinner={AuthStore.isLoading}
                />
                <div
                  className={"inline_button"}
                  onClick={() => this.props.history.push("/signIn")}
                >
                  Sign Up
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-md-5 col-0 p-0"}>
          <div id={"yellow_background_with_picture"}>
            <div className={"label"}>Don't have an account?</div>
            <WhiteButton
              onClick={() => this.props.history.push("/signIn")}
              value={"sign up"}
            />
          </div>
        </div>
      </div>
    );
  }
}
