import React, {Component} from 'react';
import SubNavbar from "../../../Shared/Navbars/SubNavbar";
import {Redirect, RouterProps} from "react-router";
import HotelForm from "../../../Components/HotelForm";
import {observer} from "mobx-react";
import AddressesStore from "../../../Stores/AddressesStore";
import AuthStore from "../../../Stores/AuthStore";


@observer
export default class HotelInformation extends Component<RouterProps>{
    saveCard = async () => {
        if (AddressesStore.addressObject.address){
            await AddressesStore.saveAddressObjectData();
            if (Object.values(AddressesStore.modals).includes(true)) {
              return;
            }
            this.props.history.push('/addresses_settings');
            return;
        }
        this.props.history.push('/add_new_address')
    };
    render(){
        if (!AuthStore.isLogged) return <Redirect to={'/'}/>;
        return (
            <div className={'settings_container'}>
                <SubNavbar active={3}/>
                <div className={'hotel_info_container'}>
                <HotelForm onBack={()=>this.props.history.goBack()} saveCard={this.saveCard} onChange={AddressesStore.changeAddressObject}/>
                </div>
            </div>
        );
    }
}