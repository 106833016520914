import React, { Component } from 'react';
import StepsComponent from '../../Shared/StepsComponent';
import BookingStore from '../../Stores/BookingStore';
import YellowButton from '../../Shared/Buttons/YellowButton';
import Form from 'react-bootstrap/Form';
import { observer } from 'mobx-react';
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import { FormControlProps } from 'react-bootstrap';
import { RouterProps } from 'react-router';
import AuthStore from '../../Stores/AuthStore';
import ProfileStore from '../../Stores/ProfileStore';
import ReactGA from 'react-ga4';
import config from '../../config';
import ReactPixel from 'react-facebook-pixel';
import FacebookConversionApiService from '../../Services/FacebookConversionApiService';


interface IState {
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  [key: string]: string | null;
}

@observer
export default class PersonalInformation extends Component<
  RouterProps,
  IState
> {
  state = {
    phone: '',
    email: '',
    first_name: '',
    last_name: '',
    error: null,
  };

  componentDidMount() {
    if (config.isProd) {
      ReactGA.event({
        category: 'form-submission',
        action: 'book-appoitment-form',
        label: 'personal information',
      });
    }
  }

  continueBooking = async () => {
    try {
      this.setState({ error: null });
      await AuthStore.addClientInDataBase(ProfileStore.newProfile);
      await BookingStore.setBookingToStorage();
      this.props.history.push('/booking_address');
    } catch (e) {
      console.log('ERROR Personal Information');
      console.dir(e);
      this.setState({ error: e.message });
    }
  };

  onLinkClick = (e:React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      const href = e.currentTarget.getAttribute('href');
      ReactPixel.trackCustom('AppDownload', {link: href});
      
      const event_data = {
        data: {
          user_data: {
            client_user_agent: window.navigator.userAgent,
            fbp: ''
          },
          custom_data: {
            link: href
          }
        }
      };

      let fbpRes = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
      if ((fbpRes && fbpRes[1])) {
        event_data.data.user_data.fbp = fbpRes[1];
      }
      FacebookConversionApiService.trackAppDownload(event_data).then(() => {
        window.location.href = String(href);
      });
  };

  changeHandle = (
    event: React.FormEvent<
      ReplaceProps<'input', BsPrefixProps<'input'> & FormControlProps>
    >
  ) => {
    let { id, value = '' } = event.currentTarget;
    if (id) {
      if (id === 'phone') {
        value = value.toString().replace(/[^0-9.]/g, '');
      }
      ProfileStore.newProfile[id] = value;
      this.setState({ error: null });
    }
  };

  render() {
    const { phone, email, first_name, last_name } = ProfileStore.newProfile;
    const isAllFieldValid = !(first_name && last_name && phone && email);
    const isFormHasErrors =
      Object.values(BookingStore.inlineErrors).includes(true) ||
      !!this.state.error;
    const disabled = isAllFieldValid || isFormHasErrors;
    return (
      <div className="gray_background personal_information_container">
        <StepsComponent steps={'bookingStepsNotReg'} active={0} />
        <div className="container small_container personal_information">
          <h1>personal information</h1>
          <Form>
            <Form.Group controlId={'first_name'}>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type={'text'}
                onChange={this.changeHandle}
                value={first_name}
              />
            </Form.Group>
            <Form.Group controlId={'last_name'}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type={'text'}
                onChange={this.changeHandle}
                value={last_name}
              />
            </Form.Group>
            <Form.Group controlId={'phone'}>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type={'tel'}
                onChange={this.changeHandle}
                value={phone}
                onBlur={BookingStore.inputFieldsValidation}
              />
              <span
                className={`inline_error ${
                  BookingStore.inlineErrors.phone ? 'visible' : 'invisible'
                }`}
              >
                Please enter only numbers
              </span>
            </Form.Group>

            <Form.Group controlId={'email'}>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type={'email'}
                onChange={this.changeHandle}
                value={email}
                onBlur={BookingStore.inputFieldsValidation}
              />
              <span
                className={`inline_error ${
                  BookingStore.inlineErrors.email ? 'visible' : 'invisible'
                }`}
              >
                Please enter valid email
              </span>
            </Form.Group>
            <span
              className={`inline_error ${
                this.state.error ? 'visible' : 'invisible'
              }`}
            >
              {this.state.error}
            </span>
          </Form>

          <YellowButton
            onClick={this.continueBooking}
            value={'continue'}
            disabled={disabled}
          />
        </div>
        {!AuthStore.isLogged ? (
          <div className="dashboard_container">
            <div className="dashboard">
              <div className="dashboard_list small_container">
                <div className="app_container">
                  <div className={'text'}>
                    <b>Download the app for extra features!</b>
                    <br />
                    <small>
                      Download our app from App Store to
                      book appointments to enjoy all the benefits of our
                      service.
                    </small>
                  </div>
                  <div style={{ width: '50%' }}>
                    <a
                      href={
                        'https://apps.apple.com/us/app/sprayvalet/id1485585423'
                      }
                      onClick={this.onLinkClick}
                    >
                      <img
                        style={{ width: '100%', marginBottom: '5px' }}
                        src={require('../../assets/App Store.svg')}
                        alt={'app store'}
                        width={500}
                      />
                    </a>
                    {/* <a
                      href={
                        'https://play.google.com/store/apps/details?id=com.sprayvalets'
                      }
                      onClick={this.onLinkClick}
                    >
                      <img
                        style={{ width: '100%' }}
                        src={require('../../assets/gp.png')}
                        alt={'gp'}
                        width={500}
                      />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}