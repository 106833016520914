import React, { Component } from 'react';
import DateFormatting from "../../../Utils/DateFormatting";
import BookingStore from "../../../Stores/BookingStore";
import LoginInput from "../../../Shared/LoginInput";
import { observer } from "mobx-react";
import PromoComponent from "./PromoComponent";
import PromoStore from '../../../Stores/PromoStore';
import TimeStore from '../../../Stores/TimeStore';
import queryString from 'query-string';

@observer
export default class OrderSummary extends Component {
    render() {
        const { date, valet } = BookingStore.bookingData;

        let newDate = DateFormatting.dateAndTime(
            date,
            TimeStore.appointmentStartTime
          )

        let ValetValue = valet && valet.first_name ? `${valet.first_name}` : "Valet to be defined";

        const parsed: any = queryString.parse(window.location.search);

        return (
            <div className="order_summary">
                <h2>Order summary</h2>
                <LoginInput
                    readOnly={true}
                    value={DateFormatting.time(newDate)}
                    type={'text'}
                    onChange={() => null}
                    iconName={'fa-clock'}
                    placeholder={'time'}
                    name={'time'} />
                <LoginInput
                    readOnly={true}
                    value={DateFormatting.usFormat(newDate)}
                    type={'text'}
                    onChange={() => null}
                    iconName={'fa-calendar-check'}
                    placeholder={'date'}
                    name={'date'} />
                <LoginInput
                    readOnly={true}
                    value={ValetValue}
                    type={'text'}
                    onChange={() => null}
                    iconName={'fa-user'}
                    placeholder={'Valet'}
                    name={'valet'} />
                <div className="total d-flex justify-content-between">
                    <span>Total:</span>
                    <span>{`$ ${BookingStore.totalPrice}`}</span>
                </div>
                {!parsed.rescheduleItem && <PromoComponent />}
            </div>
        );
    }
}