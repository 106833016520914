import { action, observable, computed, reaction } from 'mobx';
import AuthStore from './AuthStore';
import { IErrorsObject } from '../Interfaces/OtherInterfaces';
import React from 'react';
import Validation from '../Utils/Validation';
import {
  defaultChangePasswordObject,
  defaultProfile,
  IAvatar,
  IChangePasswordObject,
  IProfile,
  ISubscription,
  IUserSubscription,
} from '../Interfaces/ProfileInterfaces';
import ProfileService from '../Services/ProfileService';
import AddressesStore from './AddressesStore';
import PaymentStore from './PaymentStore';
import ValetsStore from './ValetsStore';
import NotificationsStore from './NotificationsStore';
import ModalStore from './ModalStore';
import StorageService from '../Services/StorageService';

class ProfileStore {
  @observable profile: IProfile = defaultProfile;

  @observable subscriptions: ISubscription[] = [];

  @observable selectedSubscription: number | null | undefined = null;

  @observable selectedSubscriptionItem: ISubscription | null | undefined = null;

  @observable inlineErrors: IErrorsObject = {};

  @observable
  passwordObject: IChangePasswordObject = defaultChangePasswordObject;
  @observable passwordChangeError: string = '';

  @observable uploadError: string = '';

  @observable processError: string = '';

  @observable isLoading: boolean = false;

  @observable success: boolean = false;

  @observable activeUsersSubscriptions: IUserSubscription[] = [];
  avatarFile: File | null = null;

  @observable newProfile: any = {
    phone: '',
    email: '',
    password: '',
  };

  @observable subscriptionError: string = '';

  @observable hasCancelledSubscriptions: null | ISubscription = null;

  constructor() {
    this.getSubscriptions();
    reaction(
      () => AuthStore && AuthStore.isLoading,
      () => this.initApp()
    );
    reaction(
      () => this.selectedSubscription!,
      (subId?: number) => {
        if (!subId) {
          this.selectedSubscriptionItem = null;
        } else {
          this.selectedSubscriptionItem = this.subscriptions.find(
            (subscription) => this.selectedSubscription === subscription.id
          );
        }
      }
    );
  }
  @computed get showNotificationError() {
    return (
      !this.profile.notification_email &&
      !this.profile.notification_push &&
      !this.profile.notification_sms
    );
  }

  @action initApp = async () => {
    if (AuthStore.isLogged) {
      await Promise.all([
        NotificationsStore.getNotifications(),
        AddressesStore.getAddresses(),
        ValetsStore.getListOfUserValets(),
      ]);
      await this.getProfileData();
      await PaymentStore.getCreditCards();
    }
  };

  @action getProfileData = async () => {
    try {
      this.isLoading = true;
      let response: any = await ProfileService.getUserData();
      const cancelledSubscription = response.client_subscriptions.find(
        (sub: ISubscription) => sub.cancellation_date
      );
      this.hasCancelledSubscriptions = cancelledSubscription;
      const client_subscriptions =
        response.client_subscriptions && response.client_subscriptions.length
          ? response.client_subscriptions.find(
              (sub: ISubscription) => !sub.cancellation_date
            )
          : null;

          // console.log(response);
      // AddressesStore.addressesSettings = response.address;

      PaymentStore.paymentSettings = response.payments;
      // console.log('RESP', response);
      await StorageService.getItem('jwt');
      response.stripe_id = "1";
      this.profile = {
        ...response,
        client_subscriptions,
      };
      // console.log("Profile:", this.profile);
      this.selectedSubscription =
        this.profile.client_subscriptions &&
        this.profile.client_subscriptions.subscription
          ? this.profile.client_subscriptions.subscription.id
          : null;
      this.activeUsersSubscriptions = response.client_subscriptions;
    } catch (e) {
      console.log('getProfileData ERROR');
      console.dir(e);
      throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
    return this.profile;
  };

  @action changeProfileData = (
    name: string,
    value: string | boolean | IAvatar
  ) => {
    if (name === 'phone') {
      value = value.toString().replace(/[^0-9.]/g, '');
    }
    this.profile[name] = value;
  };

  @action changePasswordObject = (name: string, value: string) =>
    (this.passwordObject[name] = value);

  @action passwordValidation = () => {
    this.passwordChangeError = '';
    this.success = false;
    const { newPassword, confirmNewPassword } = this.passwordObject;

    if (!newPassword) {
      this.passwordChangeError = 'Please enter new password.';
      return false;
    }

    if (newPassword !== confirmNewPassword) {
      this.passwordChangeError =
        'Password and confirm password does not match.';
      return false;
    }
    return true;
  };

  @action changePassword = async () => {
    if (!this.passwordValidation()) return;
    try {
      this.isLoading = true;
      this.success = false;
      const { newPassword, currentPassword } = this.passwordObject;
      await ProfileService.changePassword(
        this.profile.id,
        currentPassword,
        newPassword
      );
      this.success = true;
      this.passwordChangeError = '';
      this.passwordObject = defaultChangePasswordObject;
    } catch (e) {
      console.log(e);
      this.passwordChangeError = e.message.detail;
      this.success = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action inputFieldsValidation = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { id, value } = event.target;
    this.inlineErrors[id] = !Validation[id](value);
  };

  @action phoneValidation = (name: string, value: string) =>
    (this.inlineErrors[name] = !Validation[name](value));

  @action saveProfileData = async (data: any = {}) => {
    try {
      this.isLoading = true;
      this.success = false;
      const {
        id,
        first_name,
        last_name,
        email,
        phone,
        notification_sms,
        notification_email,
        notification_push,
        notification_text_magic,
      } = this.profile;
      const profile = {
        first_name,
        last_name,
        email,
        phone,
        notification_sms,
        notification_email,
        notification_push,
        notification_text_magic,
        ...data,
      };
      if (this.avatarFile) {
        this.uploadAvatar();
      }
      console.log('USER ID', id);
      const response: any = await ProfileService.editProfileData(id, profile);
      const cancelledSubscription = response.client_subscriptions.find(
        (sub: ISubscription) => sub.cancellation_date
      );
      this.hasCancelledSubscriptions = cancelledSubscription;
      const client_subscriptions =
        response.client_subscriptions && response.client_subscriptions.length
          ? response.client_subscriptions.find((sub: ISubscription) => !sub.cancellation_date)
          : null;
      this.profile = response;
      this.profile = {
        ...response,
        client_subscriptions,
      };
      this.selectedSubscription =
        this.profile.client_subscriptions &&
        this.profile.client_subscriptions.subscription
          ? this.profile.client_subscriptions.subscription.id
          : null;
      this.success = true;
    } catch (e) {
      console.log('saveProfileData ERROR', e);
      this.success = false;
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action updateProfileData = async (data: any = {}) => {
    try {
      this.isLoading = true;
      this.success = false;
      const {
        id,
        first_name,
        last_name,
        email,
        phone,
        notification_sms,
        notification_email,
        notification_push,
        notification_text_magic,
      } = this.profile;
      console.log("asdfa", this.profile);
      const profile = {
        first_name,
        last_name,
        email,
        phone,
        notification_sms,
        notification_email,
        notification_push,
        notification_text_magic,
        ...data,
      };
      console.log('USER ID', id);
      const response: any = await ProfileService.editProfileData(id, profile);
      const cancelledSubscription = response.client_subscriptions.find(
        (sub: ISubscription) => sub.cancellation_date
      );
      this.hasCancelledSubscriptions = cancelledSubscription;
      const client_subscriptions =
        response.client_subscriptions && response.client_subscriptions.length
          ? response.find((sub: ISubscription) => !sub.cancellation_date)
          : null;
      this.profile = {
        ...response,
        client_subscriptions,
      };
      this.success = true;
    } catch (e) {
      console.log('updateProfileData ERROR', e);
      this.success = false;
      if(e.code === 400) 
        this.processError = e.message.email[0];
      else 
        this.processError = e.message.detail;
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action uploadAvatar = async () => {
    try {
      this.isLoading = true;
      if (this.avatarFile) {
        const formData = new FormData();
        formData.append('file', this.avatarFile, this.avatarFile.name);
        const uploadAvatarResponse: any = await ProfileService.uploadAvatar(
          formData
        );
        if (uploadAvatarResponse.avatar_url) {
          this.profile.avatar_url = uploadAvatarResponse.avatar_url;
        }
        if (uploadAvatarResponse.status) {
          this.uploadError = uploadAvatarResponse.status;
        }
        this.avatarFile = null;
      }
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action getSubscriptions = async () => {
    try {
      this.isLoading = true;
      const response: any = await ProfileService.getSubscriptions();
      response.sort((a: any, b: any) => {
        return a.price - b.price;
      });
      this.subscriptions = response;
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action changeSubscription = async () => {
    console.log('changeSubscription');
    console.log('this.selectedSubscription', this.selectedSubscription);
    if (this.selectedSubscription) {
      try {
        this.isLoading = true;
        let response: any;
        const data = {
          client: this.profile.id,
          subscription: this.selectedSubscription,
          payment: PaymentStore.defaultPaymentMethod.id,
        };
        if (
          this.profile.client_subscriptions ||
          this.hasCancelledSubscriptions
        ) {
          response = await ProfileService.updateSubscription(
            (this.profile.client_subscriptions &&
              this.profile.client_subscriptions.id) ||
              (this.hasCancelledSubscriptions &&
                this.hasCancelledSubscriptions.id),
            data
          );
        } else {
          response = await ProfileService.addSubscription(data);
        }

        if (!response) return null;
        this.profile = { ...this.profile, client_subscriptions: response };
        this.selectedSubscription =
          this.profile.client_subscriptions &&
          this.profile.client_subscriptions.subscription
            ? this.profile.client_subscriptions.subscription.id
            : null;
        await this.getProfileData();
      } catch (e) {
        console.log(e);
        return e;
      } finally {
        this.isLoading = false;
      }
    }
  };

  @action cancelSubscription = async () => {
    try {
      if (!this.profile.client_subscriptions) return;
      this.isLoading = true;
      await ProfileService.cancelSubscription(
        this.profile.client_subscriptions.id
      );

      await this.getProfileData();
      ModalStore.toggleModal('subscriptionCancel', true);
    } catch (e) {
      this.subscriptionError = e.message.detail;
      ModalStore.toggleModal('errorOnSubscriptions', true);
    } finally {
      this.isLoading = false;
    }
  };
  @action compareSubscriptions = () => {
    const { client_subscriptions } = this.profile;
    const selectedSubscription:
      | ISubscription
      | undefined = this.subscriptions.find(
      (subscription: ISubscription) =>
        subscription.id === this.selectedSubscription
    );
    if (
      client_subscriptions &&
      selectedSubscription &&
      client_subscriptions.subscription.city !== selectedSubscription.city
    ) {
      ModalStore.toggleModal('subscriptionChangeCity', true);
      return true;
    }
    return false;
  };
}

export default new ProfileStore();
