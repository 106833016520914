import React, {Component} from 'react';
import { Button } from 'react-bootstrap'
import {RouteComponentProps, withRouter} from "react-router";
import {pagesWithoutNavbar} from "../../Constants/PagesWithoutNavbar";
import backgroundImg from "./../../assets/background images/picture_bg1.png";
interface IProps extends RouteComponentProps{

}

class Leave extends Component <IProps>{

  onLeave = () => {
    window.location.href = "https://sprayvalet.com/";
  };

  onBack = () => {
    this.props.history.goBack();
  };

  render(){
    return (
      <div className={"background"}>
          <img src={backgroundImg}/>
          <div className="leave-modal">
            <div className="leave-modal-header">DO YOU REALLY WANT TO LEAVE THE BOOKING PROCESS?</div>
            <p style={{color: '#1A1A1A', marginBottom: 40}}>All data will be lost.</p>
            <button className="leave-btn" onClick={this.onLeave}>LEAVE BOOKING</button>
            <button className="back-btn" onClick={this.onBack}>BACK TO BOOKING</button>
          </div>
        </div>
    );
  }
}

export default withRouter(Leave);