import React, { Component } from "react";
import changeActiveItem from "../../../Utils/changeActiveItem";
import { IConversation } from "../../../Interfaces/MessagesInterfaces";
import MessagesStore from "../../../Stores/MessagesStore";
import DateFormatting from "../../../Utils/DateFormatting";
import { observer } from "mobx-react";
import { defaultLocationInfo } from "../../../Interfaces/AddressesInterface";
import { defaultAvatar } from "../../../Interfaces/OtherInterfaces";


interface IProps {
  data: IConversation;
}

@observer
export default class ConversationListItem extends Component<IProps> {
  makeActive = async (event: React.MouseEvent<HTMLDivElement>) => {
    changeActiveItem(event.currentTarget, "active");
    await MessagesStore.changeActiveConversation(this.props.data.id);
  };
  render() {
    const {
      id,
      appointment,
      draft,
      last_message_time,
      unread_count,
      chatting_with,
      is_active
    } = this.props.data;
    const { valet } = appointment;
    const isActive = MessagesStore.activeConversation.id === id;
    const valetAvatarUrl = chatting_with && chatting_with.avatar_url;
    return (
      chatting_with && <div
        className={`conversation-list-item d-flex flex-md-row flex-column justify-content-md-between align-items-start ${
          isActive ? "active" : ""
          } ${is_active ? '' : 'not_active_chat'}`}
        onClick={this.makeActive}
      >
        <div className={"d-flex"}>
          <img
            className="conversation-photo"
            src={valetAvatarUrl || (valet && valet.avatar_url) || defaultAvatar}
            alt="conversation"
          />
          <div className="conversation-info d-flex flex-column align-items-start">
            <h1 className="conversation-title">{chatting_with.first_name}</h1>
            <div className="conversation-snippet">
              {appointment.address ? appointment.address.address : defaultLocationInfo.address}
            </div>
            <div
              className={`conversation-draft-indicator ${
                draft && !isActive ? "visible" : "invisible"
                }`}
            >
              draft:{draft}
            </div>
          </div>
        </div>
        <div
          className={
            "d-flex flex-md-column flex-row align-items-end justify-content-between conversation-add-data"
          }
        >
          <div className={"conversation-time"}>
            {DateFormatting.calendarDate(last_message_time)}
          </div>
          {unread_count ? (
            <div className={"conversation-new_messages"}>{unread_count}</div>
          ) : (
              ""
            )}
          <div className={'not_active'}>{!is_active && 'not active'}</div>
        </div>
      </div>
    );
  }
}
