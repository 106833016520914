import React, { Component } from "react";
import arrow_left from "../assets/icons/arrow_left.svg";
import { RouteComponentProps, withRouter } from "react-router";

interface IProps extends RouteComponentProps {
  steps: string;
  active: number;
  done?: number[];
  onBack?: () => void;
}

interface IStepsTypes {
  [key: string]: string[];
}

class StepsComponent extends Component<IProps> {
  steps: IStepsTypes = {
    bookingStepsNotReg: [
      // "personal information",
      "location",
      "booking details",
      "payment details"
    ],
    bookingSteps: ["location", "booking details", "payment details"],
    regSteps: ["information", "location"]
  };

  render() {
    const { steps, active, done = [], onBack } = this.props;
    return (
      <div className="steps_component d-flex justify-content-center">
        <div
          className="steps_info_container d-flex justify-content-around align-items-center"
        >
          {onBack && (
            <div className="back_button d-flex flex-nowrap" onClick={onBack}>
              <img src={arrow_left} alt={"arrow_left"} />
              Back
            </div>
          )}
          {this.steps[steps].map((step: string, index: number) => {
            return (
              <div
                className={
                  "d-flex justify-content-center step align-items-center"
                }
                key={step}
              >
                {done.includes(index) ? (
                  <div className={"step_number step_done align-middle"}>
                    <span className="">
                      <i className="fas fa-check" />
                    </span>
                  </div>
                ) : (
                  <div
                    className={`step_number ${
                      active === index ? "color_yellow" : ""
                    }`}
                  >
                    <span className="align-middle m-0">{index + 1}</span>
                  </div>
                )}
                <div className={"step_info"}>
                  <span>{step}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(StepsComponent);
