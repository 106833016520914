import Location from '../assets/icons/location.png';
import React, { Component, ElementType, FormEvent} from 'react';
import Form from 'react-bootstrap/Form';
import YellowButton from '../Shared/Buttons/YellowButton';
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import { FormControlProps } from 'react-bootstrap';
import { observer } from 'mobx-react';
import AddressAutocomplete from '../Utils/AutoCompleteAddress';
import { PopupContent } from '../Constants/PopupContent';
import AddressesStore from '../Stores/AddressesStore';
import Popup from '../Pages/Popups/Popup';
import { IAddress } from '../Interfaces/AddressesInterface';
import parkingOptions from '../Constants/ParkingOptions';
import HotelInformationPopup from '../Pages/Booking/Location/HotelInformationPopup';

interface IProps {
  nextStep: () => void;
  hotelClick: () => void;
  buttonValue: string;
  onChange: (name: string, value: string | boolean) => void;
  value: IAddress;
  goNext?: () => void;
  error?: string;
}

@observer
export default class LocationForm extends Component<IProps> {
  state = { showHotelInformation: false };
  componentDidMount(): void {
    const input = document.querySelector('#address') as HTMLInputElement;
    if (input) {
      AddressAutocomplete(input, this.props.onChange);
    }
  }

  changeData = (
    event: FormEvent<
      ReplaceProps<
        ElementType<any>,
        BsPrefixProps<ElementType<any>> & FormControlProps
      >
    >
  ) => {
    const { id, value = '' } = event.currentTarget;
    if (id) {
      this.props.onChange(id, value);
    }
  };

  changeParkingSettings = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id } = event.currentTarget;
    this.props.onChange('selected_parking_option', parkingOptions[id]);
  };

  agreeOnFee = async () => {
    AddressesStore.showModal('extraFee');
    if (this.props.goNext) this.props.goNext();
  };
  disagreeFee = async () => {
    const idOfLocation =
      AddressesStore.addressesSettings[
        AddressesStore.addressesSettings.length - 1
      ].id;
    await AddressesStore.deleteAddress(idOfLocation);
    AddressesStore.showModal('extraFee');
  };

  render() {
    const { hotelClick, buttonValue, value, nextStep, error } = this.props;
    const {
      selected_parking_option,
      address,
      note_parking,
      zip_code,
      room_number,
      apartment,
      gate_code,
      code,
      is_hotel,
    } = value;

    return (
      <div className={'location_form'}>
        <h1>Location</h1>
        <Form>
          <Form.Row>
            <Form.Group
              controlId={'address'}
              className={'w-100 position-relative'}
            >
              <Form.Label>Address</Form.Label>
              {error ? (
                <p className="text-danger" id="text-danger">
                  {error}
                </p>
              ) : (
                <p>
                  <b>Start typing and select drop down address from the list</b>
                </p>
              )}

              <Form.Control
                type={'text'}
                placeholder={'address'}
                style={error ? { border: '1px solid red' } : undefined}
                value={address}
                onChange={this.changeData}
                isValid={error ? !error : undefined}
              />
              <img
                src={Location}
                alt={'location'}
                className={'location_icon'}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              controlId={'zip_code'}
              className={'w-100 position-relative'}
            >
              <Form.Label>ZIP</Form.Label>
              <Form.Control
                type={'text'}
                placeholder={'ZIP'}
                value={zip_code}
                onChange={this.changeData}
              />
              <img
                src={Location}
                alt={'location'}
                className={'location_icon_zip'}
              />
            </Form.Group>
          </Form.Row>
          <h2>where should we park?</h2>
          <div className={'parking_container d-flex flex-wrap'}>
            <label className="parking">
              Free Street Parking
              <input
                type="radio"
                name="parking"
                id={'FREE_STREET'}
                onChange={this.changeParkingSettings}
                checked={selected_parking_option === parkingOptions.FREE_STREET}
              />
              <span className="checkmark" />
            </label>
            <label className="parking">
              Parking Lot or Garage
              <input
                type="radio"
                name="parking"
                id={'GARAGE_LOG'}
                onChange={this.changeParkingSettings}
                checked={selected_parking_option === parkingOptions.GARAGE_LOG}
              />
              <span className="checkmark" />
            </label>
            <label className="parking">
              Meter parking
              <input
                type="radio"
                name="parking"
                id={'METERET'}
                onChange={this.changeParkingSettings}
                checked={selected_parking_option === parkingOptions.METERET}
              />
              <span className="checkmark" />
            </label>
            <label className="parking">
              Valet Service
              <input
                type="radio"
                name="parking"
                id={'VALET_SERVICE'}
                onChange={this.changeParkingSettings}
                checked={
                  selected_parking_option === parkingOptions.VALET_SERVICE
                }
              />
              <span className="checkmark" />
            </label>
          </div>
          <Form.Row>
            <Form.Group
              controlId={'apartment'}
              className={'w-100 position-relative'}
            >
              <Form.Label>Apartment</Form.Label>
              <Form.Control
                type={'text'}
                placeholder={'Apartment'}
                value={is_hotel?"":apartment}
                disabled={is_hotel}
                onChange={this.changeData}
              />
              {AddressesStore.errorsOnForm.apartment && (
                <span className={'inline_error'}>
                  {AddressesStore.errorsOnForm.apartment}
                </span>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              controlId={'gate_code'}
              className={'w-100 position-relative'}
            >
              <Form.Label>Door/Gate Code</Form.Label>
              <Form.Control
                type={'text'}
                placeholder={'Door/Gate Code'}
                value={is_hotel?"":gate_code}
                disabled={is_hotel}
                onChange={this.changeData}
              />
              {AddressesStore.errorsOnForm.gate_code && (
                <span className={'inline_error'}>
                  {AddressesStore.errorsOnForm.gate_code}
                </span>
              )}
            </Form.Group>
          </Form.Row>
          <input
            type="checkbox"
            className="form-check-input styled-checkbox"
            id="this_a_hotel"
            checked={is_hotel}
            onChange={() => {
              console.log(`is Hotel = ${is_hotel} showHotelInformation = ${this.state.showHotelInformation}`);
                if (!is_hotel) this.setState({showHotelInformation: true});
                this.props.onChange('is_hotel', !is_hotel)
              }
            }
          />
          <label className="form-check-label" htmlFor="this_a_hotel">
            This is a hotel
          </label>
          <Form.Group controlId={'note_parking'}>
            <Form.Label>Note</Form.Label>
            <Form.Control
              as={'textarea'}
              placeholder={'Notes...'}
              rows={4}
              onChange={this.changeData}
              value={note_parking}
            />
            {AddressesStore.errorsOnForm.note_parking && (
              <span className={'inline_error'}>
                {AddressesStore.errorsOnForm.note_parking}
              </span>
            )}
          </Form.Group>
        </Form>

        <YellowButton
          onClick={nextStep}
          value={buttonValue}
          additionalClass={'w-100'}
          disabled={
            !address || !zip_code || !!AddressesStore.errorsOnForm.note_parking
          }
          spinner={AddressesStore.isLoading}
        />
        {is_hotel && <div className={'inline_button'} onClick={hotelClick}>
          Hotel Information
        </div>
        }
        
        <Popup
          title={"sorry we don't service this area"}
          id={'dontServiceArea'}
          content={PopupContent.dontServiceArea}
          onHide={() => AddressesStore.showModal('outOfService')}
          show={AddressesStore.modals.outOfService}
        />
        <Popup
          title={'extra fee'}
          id={'extraFee'}
          content={PopupContent.extraFee}
          onHide={this.disagreeFee}
          show={AddressesStore.modals.extraFee}
          showConfirmation
          onClick={this.agreeOnFee}
        />

        <HotelInformationPopup
          id={'hotelInformation'}
          onHide={()=>this.setState({ showHotelInformation: false })}
          show={this.state.showHotelInformation}
        />

      </div>
    );
  }
}
