import React, { Component } from "react";
import "./CustomCalendar.css";
import ArrowButton from "./Buttons/ArrowButton";

interface IProps {
	id: string;
	additionClassName?: string;
	year?: number;
	month?: number;
	day?: number;
	handleChange: (year: number, month: number, day: number, month_name: string) => void;
}

export default class CustomCalendar extends Component<IProps> {
	currentDate: Date;
	currentYear: number;
	currentMonth: number;
	currentDay: number;
	setYear: number;
	setMonth: number;
	setDay: number;
	monthName: string;
	months = [
		["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
	];
	constructor(props: IProps) {
        super(props);
		this.currentDate = new Date();
		this.currentYear = this.currentDate.getFullYear();
		this.currentMonth = this.currentDate.getMonth();
		this.currentDay = this.currentDate.getDate();
		this.setYear = (props.year !== undefined) ? props.year : this.currentYear;
		this.setMonth = (props.month !== undefined) ? props.month : this.currentMonth;
		this.setDay = (props.day != undefined) ? props.day : this.currentDay;
		this.monthName = this.months[1][this.setMonth];
    }
	
	clickPrevMonth = async () => {
		if ( (this.setMonth > this.currentMonth && this.setYear == this.currentYear) ||
			(this.setYear > this.currentYear) ) {
			if (this.setMonth === 0) {
				this.props.handleChange(this.setYear - 1, 11, 1, this.months[1][11]);
			} else {
				this.props.handleChange(this.setYear, this.setMonth - 1, 1, this.months[1][this.setMonth - 1]);
			}
		}
	}

	clickNextMonth = async () => {
		if (this.setMonth === 11) {
			this.props.handleChange(this.setYear + 1, 0, 1, this.months[1][0]);
		} else {
			this.props.handleChange(this.setYear, this.setMonth + 1, 1, this.months[1][this.setMonth + 1]);
		}
	}

	handleSelectDay = async (col: any) => {
		if (!col.disabled && col.day !== 0) {
			await this.setState({
				day: col.day
			});
			this.props.handleChange(this.setYear, this.setMonth, col.day, this.monthName);
		}
	}

    render() {
        const { id, additionClassName = ''} = this.props;
		this.setYear = (this.props.year !== undefined) ? this.props.year : this.currentYear;
		this.setMonth = (this.props.month !== undefined) ? this.props.month : this.currentMonth;
		this.setDay = (this.props.day != undefined) ? this.props.day : this.currentDay;
		this.monthName = this.months[1][this.setMonth];
		
		const createDays = (): any [][] => {
			const array = [];
			for (let i = 0; i < 5; i++) {
			  const row = new Array(7).fill({
				day: 0,
				disabled: false
			  });
			  array.push(row);
			}
			return array;
		}

		const getWeekOfMonth = (date: Date): number => {
			const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
			const firstDayOfWeek = firstDayOfMonth.getDay();
			const dayInMonth = date.getDate();
			return Math.ceil((firstDayOfWeek + dayInMonth) / 7) - 1;
		}

		const getLastDayOfMonth = (date: Date): number => {
			return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
		}

		const days = createDays();
		const date = new Date(this.setYear, this.setMonth, 1);
		const lastDay = getLastDayOfMonth(date);

		for (let i = 1; i <= lastDay; i++) {
			let temp_date = new Date(this.setYear, this.setMonth, i);
			const weekNumber = getWeekOfMonth(temp_date);
			const dayOfWeek = temp_date.getDay();
			days[weekNumber % 5][dayOfWeek] = {
				day: i,
				disabled: ( i < this.currentDay && this.setMonth === this.currentMonth && this.setYear === this.currentYear ) || ( this.setMonth < this.currentMonth && this.setYear === this.currentYear ) || this.setYear < this.currentYear
			};
		}

        return (
			<div id={id} className={`custom-calendar ${additionClassName}`} >
				<ArrowButton direct="left" disabled={this.setYear < this.currentYear || (this.setMonth <= this.currentMonth && this.setYear == this.currentYear)} onClick={this.clickPrevMonth} />
				<span className="month">{this.monthName}</span>
				<ArrowButton direct="right" onClick={this.clickNextMonth} />
				<table className="days">
					<tbody>
						<tr>
							<td className={"day-of-week"}>S</td>
							<td className={"day-of-week"}>M</td>
							<td className={"day-of-week"}>T</td>
							<td className={"day-of-week"}>W</td>
							<td className={"day-of-week"}>T</td>
							<td className={"day-of-week"}>F</td>
							<td className={"day-of-week"}>S</td>
						</tr>
						{
							days.map((row, rowIndex) => (
								<tr key={`row-${rowIndex}`}>
									{
										row.map((col, colIndex) => (
											<td
												key={`col-${colIndex}`}
												className={`day ${this.setDay === col.day ? 'active' : ''} ${(col.day === this.currentDay && this.setMonth == this.currentMonth && this.setYear == this.currentYear) ? 'today' : ''} ${col.day === 0 ? 'empty' : ''} ${col.disabled ? 'disabled' : ''}`}
												onClick={() => this.handleSelectDay(col)}
											>
												{col.day !== 0 ? col.day : ''}
											</td>
										))
									}
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
        );
    }
}