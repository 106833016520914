import React, { Component, RefObject } from 'react';
import { observer } from 'mobx-react';
import BookingStore from '../../../Stores/BookingStore';
import TooltipsContent from '../../../Constants/TooltipsContent';

interface IProps {
  makeActive: (event: React.MouseEvent<HTMLDivElement>, value: string) => void;
  value: string;
  activeClass?: boolean;
  purchase_method: string;
  isSale: boolean;
  sum: number;
  showPopup: () => void;
  tooltipContent: string | JSX.Element;
  priceDetails?: any;
  title: string;
}

interface IState {
  showTooltip: boolean;
}
@observer
class PaymentTypesItem extends Component<IProps, IState> {
  state = {
    showTooltip: false,
  };
  target: RefObject<any> = React.createRef();
  showTooltip = () => {
    this.setState({ showTooltip: !this.state.showTooltip });
  };
  render() {
    const { makeActive, value, title, tooltipContent } = this.props;
    const isPayAsYouGo = value === 'Pay As You Go'
    return (
      <div
        className={`pd-top flex-column  align-items-center w-100`}
        onClick={event => makeActive(event, value)}
        key={"item"}
      >
        <div className="flex-row d-flex justify-content-between align-items-center">
          <div className={"d-flex align-items-center w-100"}>
            <div
              className={`round align-self-start ${
                BookingStore.bookingData.purchase_method === value
                  ? "active"
                  : ""
                }`}
            />
            <div className="profile_item w-100">
              <div>{title}</div>
              {TooltipsContent(isPayAsYouGo, tooltipContent, value === "Subscription")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PaymentTypesItem;
