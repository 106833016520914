import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

interface IProps {
  editBooking: () => void;
  disabled: boolean;
  showCancelConfirmation: () => void;
  title: string;
  dropdownButtons: string[];
}

const DropdownActionButton = (props: IProps) => {
  const {
    editBooking,
    disabled,
    showCancelConfirmation,
    title,
    dropdownButtons
  } = props;
  const isEditBtn = dropdownButtons[0] !== "Edit Booking" 
  return (
    <DropdownButton
      as={"td"}
      variant="outline-secondary"
      title={title}
      id="action-dropdown"
      alignRight
    >
      {isEditBtn && (
        <Dropdown.Item disabled={disabled} onClick={editBooking}>
          {dropdownButtons[0]}
        </Dropdown.Item>
      )}
      <Dropdown.Item onClick={showCancelConfirmation} disabled={disabled}>
        {dropdownButtons[1]}
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default DropdownActionButton;
