import React, { Component } from "react";
import { observer } from "mobx-react";
import YellowButton from "../../Shared/Buttons/YellowButton";
import Form from "react-bootstrap/Form";
import AuthStore from "../../Stores/AuthStore";
import ProfileStore from "../../Stores/ProfileStore";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/helpers";
import { FormControlProps } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import moment from "moment";
import AuthService from "../../Services/AuthService";
import mixpanel from "mixpanel-browser";

interface IState{
    password: string;
    showPassword: boolean;
    errorOnForm: string;
    [key:string]:string|null|boolean;
}

@observer
class SignInAfterBooking extends Component<RouteComponentProps, IState> {
  state = {
    password: "",
    error: false,
    errorOnForm: "",
    showPassword: false,
    isLoading: false
  };

  changeHandle = (
    event: React.FormEvent<
      ReplaceProps<"input", BsPrefixProps<"input"> & FormControlProps>
    >
  ) => {
    let { id, value = "" } = event.currentTarget;
    if (id) {
      ProfileStore.newProfile[id] = value;
      this.setState({ error: ProfileStore.newProfile.password.length < 6 });
    }
  };

  submitForm = async () => {
    try {
      this.setState({ isLoading: true });
      const signInData = {
        email: ProfileStore.newProfile.email,
        phone: ProfileStore.newProfile.phone,
        password1: ProfileStore.newProfile.password,
        first_name: ProfileStore.newProfile.first_name,
        last_name: ProfileStore.newProfile.last_name,
        user_type: 2
      };
      const response: any = await AuthService.signIn(signInData);
      mixpanel.track("Register", {
        email: signInData.email,
        phone: signInData.phone
      });
      mixpanel.people.set({
        email: signInData.email,
        "Sign up date": moment().format("YYYY-MM-DD"),
        USER_ID: signInData.email
      });
      await AuthStore.setJWT(response.key);
      AuthStore.isLogged = true;
      this.props.history.push("/booking_done_step1");
    } catch (e) {
      this.setState({
        error: !!e.message.password1
      });
      AuthStore.isLogged = false;
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { phone, email, password } = ProfileStore.newProfile;
    return (
      <div className="gray_background personal_information_container">
        <div className="container small_container personal_information">
          <h1>Sign Up</h1>
          <Form>
            <Form.Group controlId={"phone"}>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type={"tel"} value={phone} disabled={true} />
            </Form.Group>

            <Form.Group controlId={"email"}>
              <Form.Label>Email Address</Form.Label>
              <Form.Control type={"email"} value={email} disabled={true} />
            </Form.Group>
            <span
              className={`inline_error ${
                this.state.error ? "visible" : "invisible"
              }`}
            >
              {this.state.error}
            </span>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="password"
                value={password}
                onChange={this.changeHandle}
              />
            </Form.Group>
            <span
              className={`inline_error ${
                this.state.error ? "visible" : "invisible"
              }`}
            >
              Password must be a minimum of 6 characters
            </span>
          </Form>

          <YellowButton
            onClick={this.submitForm}
            value={"Sign Up"}
            disabled={this.state.error}
            spinner={this.state.isLoading}
          />
          <div className={"link_container"}>
            <a
              href={`https://sprayvalet.com/terms-of-service/`}
              target="_blank"
              rel="noopener noreferrer"
              className={"agreements_link"}
            >
              terms & service
            </a>{" "}
            |{" "}
            <a
              href={`https://sprayvalet.com/privacy-policy/`}
              target="_blank"
              rel="noopener noreferrer"
              className={"agreements_link"}
            >
              privacy policy
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignInAfterBooking);
