import { action, observable, reaction } from "mobx";
import {
  defaultConversation,
  IConversation,
  IMessageObject,
  INewMessage
} from "../Interfaces/MessagesInterfaces";
import MessagesService from "../Services/MessagesService";
import Axios from "axios";
import ChatService from "../Services/ChatService";

interface socketProps {
  sendMessage: (message: INewMessage) => void;
  close: () => void;
}

class MessagesStore {
  @observable conversationsList: IConversation[] | null = null;
  @observable activeConversation: IConversation = defaultConversation;
  @observable messageList: IMessageObject[] = [];

  @observable socketInst: socketProps | null = null;

  @observable isLoading: boolean = false;
  @observable showSpinner: boolean = true;
  @observable searchString: string = "";
  source: any = null;
  checkNewMessages: any = null;

  constructor() {
    reaction(
      () => this.activeConversation.id,
      (id: number) => {
        const input = document.querySelector(
          ".compose-input"
        ) as HTMLInputElement;
        if (input) {
          input.value = "";
        }
        clearInterval(this.checkNewMessages);
        if (this.activeConversation.unread_count) {
          this.readNewMessages(id);
        }
        this.getMessagesList(id, true);
        this.setSocketInst(id);
      }
    );
    reaction(
      () => this.searchString,
      (name: string) => this.getConversationList(name),
      { delay: 500 }
    );
  }

  @action setSocketInst = (id: number) => {
    if (this.socketInst) {
      this.socketInst.close();
      this.socketInst = null;
    }
    this.socketInst = new ChatService(id);
  };

  @action changeActiveConversation = (id: number) => {
    if (!this.conversationsList) return;
    const indexOfActiveConversation: number = this.conversationsList.findIndex(
      (conversation: IConversation) => conversation.id === id
    );
    if (indexOfActiveConversation >= 0 && this.conversationsList.length) {
      this.activeConversation = this.conversationsList[
        indexOfActiveConversation
      ];
    }
  };

  @action getConversationList = async (
    name: string = "",
    id: number | null = null
  ) => {
    if (this.source) {
      this.source.cancel("operation canceled");
    }
    try {
      const cancelToken = Axios.CancelToken;
      const source = cancelToken.source();
      this.source = source;
      this.isLoading = true;
      const params: { [key: string]: string | number } = { name };
      if (id) {
        params.page = 1;
        params.limit = 200;
      }
      const response: any = await MessagesService.getConversations(
        params,
        source
      );
      if (!id) {
        this.activeConversation = response.results.length
          ? response.results[0]
          : defaultConversation;
      } else {
        this.activeConversation = response.results.find(
          (conversation: any) => conversation.id === id
        );
        this.getMessagesList(id, true);
      }
      this.conversationsList = response.results;
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action createDraft = (message: string) =>
    (this.activeConversation.draft = message);

  @action searchConversation = (name: string) => (this.searchString = name);

  @action
  getMessagesList = async (id: number, showSpinner = true) => {
    try {
      this.isLoading = true;
      this.showSpinner = showSpinner;
      const response: any = await MessagesService.getMessagesList(id);
      this.messageList = response.results.reverse();
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action
  addNewMessage = async () => {
    const conversation = this.activeConversation;
    try {
      this.isLoading = true;
      const chattingWith = conversation.appointment.valet
        ? conversation.appointment.valet.id
        : conversation.chatting_with
        ? conversation.chatting_with.id
        : null;
      if (!chattingWith) return;
      const newMessage: INewMessage = {
        text: conversation.draft,
        appointment_chat: conversation.id,
        from_user: conversation.appointment.client.id,
        to_user: chattingWith
      };
      this.socketInst
        ? this.socketInst.sendMessage(newMessage)
        : console.log("SOCKET INSTANCE is't available");
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.isLoading = false;
      conversation.draft = "";
    }
  };

  @action receiveNewMessage = (message: IMessageObject) => {
    this.messageList.push(message);
  };

  @action setOnline = (event: {
    chatting_with_online: boolean;
    time_online: string | null;
  }) => {
    this.activeConversation = { ...this.activeConversation, ...event };
  };

  @action readNewMessages = async (id: number) => {
    try {
      this.isLoading = true;
      await MessagesService.readNewMessages(id);
      this.getConversationList();
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
  };
}

export default new MessagesStore();
