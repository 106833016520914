import React from 'react';
import DateFormatting from "../../Utils/DateFormatting";
import NotificationsStore, {IUserNotification} from "../../Stores/NotificationsStore";
import {RouteComponentProps, withRouter} from "react-router";
import {hideNavbar} from "../../Utils/HideNavbar";
import NotificationsTemplates from "./NotificationsTemplates";
import MyBookingsStore from "../../Stores/MyBookingsStore";


interface IProps extends RouteComponentProps {
    notification: IUserNotification;
}

const NotificationItem = (props: IProps) => {
    const markAsRead = async (event: React.MouseEvent<HTMLLIElement>) => {
        const {notification} = props;
        const navbar = document.querySelector('#main_navbar') as HTMLElement;

        event.stopPropagation();

        await NotificationsStore.markAsRead(notification.id);

        switch (notification.type) {
            case 'feedback':
                props.history.push('/leaving_a_feedback', {valet_id: notification.valet_id, appointment_id:notification.appointment_id});
                break;
            case 'new_message':
                props.history.push('/messages');
                break;
            case 'cc_expiring':
                props.history.push('/payment_settings');
                break;
            case 'appointment':
                MyBookingsStore.activeStatusOfBooking='upcoming';
                props.history.push('/my_booking');
                break;
            case 'pick_appointment':
                props.history.push('/my_booking');
                break;
            case 'reschedule':
                MyBookingsStore.activeStatusOfBooking='pending';
                props.history.push('/my_booking');
                break;
            case"add_people":
                MyBookingsStore.activeStatusOfBooking='upcoming';
                props.history.push('/my_booking');
                break;
            case 'cancel_appointment':
                MyBookingsStore.activeStatusOfBooking='cancelled';
                props.history.push('/my_booking');
                break;
            default: return;
        }
        hideNavbar(navbar);

    };
    const {notification} = props;
    return (
        <li onClick={markAsRead} className={`${notification.read ? '' : 'active'} notification-item`} >
            <div className="d-flex">
                <i className={'fas fa-circle'}/>
                {NotificationsTemplates[notification.type] ? NotificationsTemplates[notification.type](notification): null}
            </div>
            <small>{DateFormatting.calendarDate(notification.date_created)}</small>
        </li>
    );
};

export default withRouter(NotificationItem);