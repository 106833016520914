import http from "./http";
import {IFeedback} from "../Interfaces/OtherInterfaces";
import {CancelTokenSource} from "axios";

interface IParams{
    [key:string]:string|number|boolean;
}

class ValetService{
    public static getListOfUserValets = (id:number, status:string) => {
        let params:IParams = {};
        if(status) {
            params = { [status.toLocaleLowerCase()]:true };
        }
        return http.get(`/favorite-valets/`, { params })
    };

    public static getValetsGroupedByCity = (isFavoriteOnly: boolean) => {
        let params: IParams = {};
        params = {"is_active":true, "favorite_only": isFavoriteOnly}
        return http.get(`valets/grouped-by-city/`, { params })
    }

    public static getUserValet(id:number, source:CancelTokenSource){
        return http.get(`/favorite-valets/${id}/`, {cancelToken:source.token})
    }

    public static getValet(id:number, source:CancelTokenSource){
        return http.get(`/valets/${id}/`, {cancelToken:source.token})
    }

    public static changeValetStatus = (body:IParams) => {
        return http.put(`/favorite-valets/${body.valet}/`, body)
    };

    public static deleteValet = (id:number, valetId: number) => {
        return http.delete(`/favorite-valets/${valetId}/`)
    };

    public static addFeedback = (feedback:IFeedback) => {
        return http.post(`/feedback/`, feedback)
    }
}

export default ValetService;