declare var google: any;

const checkAddress = (address: string) =>
  new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address,
      },
      function (results: any, status: any) {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          if (results[0] && results[0].address_components) {
            const types = results[0].address_components.reduce(
              (prev: any, item: any) => {
                item.types.forEach((el: string) => prev.push(el));
                return prev;
              },
              []
            );
            const checkAdresses =
              types.indexOf('street_number') !== -1 &&
              types.indexOf('postal_code') !== -1;
            if (checkAdresses) {
              resolve('valid');
            } else {
              reject({
                message:
                  'Address must contain at least the street, house number and zip code.',
              });
            }
          } else {
            reject({
              message:
                'Address must contain at least the street, house number and zip code.',
            });
          }
        } else {
          if (address !== '') {
            reject({
              message: 'Invalid address',
            });
          } else {
            reject({
              message: 'Fill in the field',
            });
          }
        }
      }
    );
  });

export default checkAddress;
