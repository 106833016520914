import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import AuthStore from "../Stores/AuthStore";
import { observer } from "mobx-react";
import GoogleCallbackPage from "../Pages/SocialLogin/GoogleCallbackPage";
import FacebookCallBackPage from "../Pages/SocialLogin/FacebookCallbackPage";
import PrivateRouter from "./PrivateRouter";
import AuthRouter from "./AuthRouter";

@observer
export default class MainRouter extends Component {

  render() {
    return (
      <Switch>
        <Route path="/google-auth-callback" component={GoogleCallbackPage} />
        <Route path="/facebook-callback" component={FacebookCallBackPage} />

        {AuthStore.isLogged ? <PrivateRouter /> : <AuthRouter />}

        <Route component={() => <div className="404">Page 404</div>} />
      </Switch>
    );
  }
}
