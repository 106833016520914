import http from "./http";


class NotificationServices{
    public static getNotifications(limit:number, page:number){
        return http.get('/notifications/', {params:{limit, page}})
    };

    public static readNotification(id:number, params:{[key:string]:boolean}){
        return http.put(`/notifications/${id}/`, params)
    };
}

export default NotificationServices;