import { action, observable, reaction } from 'mobx';
import ProfileService from '../Services/ProfileService';
import {
  defaultLocationInfo,
  IAddress,
  IAddressModals,
  defaultErrorsOnForm,
  defaultAddressModals,
} from '../Interfaces/AddressesInterface';
import StorageService from '../Services/StorageService';
import BookingStore from './BookingStore';
import AuthStore from './AuthStore';
import mixpanel from 'mixpanel-browser';
import errorsMessage from '../Constants/errorsMessage';
import checkAddress from '../Utils/checkAddress';

class AddressesStore {
  @observable addressesSettings: IAddress[] = [];
  @observable addressObject: IAddress = defaultLocationInfo;
  @observable addressBillingObject: IAddress = defaultLocationInfo;
  @observable defaultAddressId: number = 0;

  @observable isLoading: boolean = false;
  @observable modals: IAddressModals = { ...defaultAddressModals };

  @observable errorsOnForm = { ...defaultErrorsOnForm };

  constructor() {
    StorageService.getItem('defaultAddress')
      .then((response: number) => (this.defaultAddressId = response))
      .catch(() => (this.defaultAddressId = 0));
    reaction(
      () => this.defaultAddressId,
      (id: number) => {
        const addressObject = this.addressesSettings.find(
          (address: IAddress) => address.id === id
        );
        if (addressObject) {
          BookingStore.bookingData.address = addressObject;
        }
        StorageService.setItem('defaultAddress', id);
      }
    );
    reaction(
      () => this.addressesSettings.length,
      (length: number) => {
        if (!this.defaultAddressId) {
          this.defaultAddressId = this.addressesSettings[length - 1].id || 0;
        }
      }
    );
  }

  @action changeAddressObject = (name: string, value: string | boolean) => {
    this.errorsOnForm.note_parking =
      name === 'note_parking' && value.toString().length >= 200
        ? errorsMessage.address.note_parking
        : '';
    this.addressObject[name] = value;
  };

  @action changeBillingAddressObject = (name: string, value: string | boolean) => {
    this.errorsOnForm.note_parking =
      name === 'note_parking' && value.toString().length >= 200
        ? errorsMessage.address.note_parking
        : '';
    this.addressBillingObject[name] = value;
  };

  @action changeDefaultAddress = async (id: number) => {
    this.defaultAddressId = id;
    // StorageService.setItem('defaultAddress', id);
  };

  @action showModal = (modal: string) =>
    (this.modals[modal] = !this.modals[modal]);

  @action editAddress = (id: number) => {
    const indexOfEditedAddress = this.addressesSettings.findIndex(
      (address: IAddress) => address.id === id
    );
    this.addressObject = this.addressesSettings[indexOfEditedAddress];
  };

  @action getAddresses = async (city: string = "") => {
    try {
      this.resetErrors();
      this.isLoading = true;
      const response: any = await ProfileService.getLocations(city);
      this.addressesSettings = response;
      const addressObject = this.addressesSettings.find(
        (address: IAddress) => address.id === this.defaultAddressId
      );
      if (addressObject) {
        BookingStore.bookingData.address = addressObject;
      }

    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action saveAddressObjectData = async () => {
    const isNewAddressObject = this.addressesSettings.findIndex(

      (address: IAddress) => address.id === this.addressObject.id
    );

    const testResponse = await checkAddress(this.addressObject.address); // adress pre check

    try {
      this.isLoading = true;
      this.resetErrors();
      let response: any = '';
      if (isNewAddressObject === -1) {
        delete this.addressObject.id;
        response = await ProfileService.saveAddressData(this.addressObject);
      } else {
        response = await ProfileService.editAddressData(this.addressObject);
      }
      if (!response) return null;
      this.addressesSettings = [...this.addressesSettings, response];
      this.defaultAddressId = response.id;
      this.modals.extraFee = response.extra_fee;
      await this.getAddresses();
      mixpanel.track('Address added Successful');
      if (AuthStore.isLogged) this.addressObject = defaultLocationInfo;
    } catch (e) {
      this.modals.isError = true;
      if (e.checker) {
        console.log('ERROR');
      } else if (e.message.detail === 'Address not allowed') {
        this.modals.outOfService = true;
      } else {
        this.errorsOnForm = { ...this.errorsOnForm, ...e.message };
      }
      mixpanel.track('Address added Error', {
        errorMessage: e.message.detail,
        error: e,
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action saveNewAddressObjectData = async () => {
    try {
      this.isLoading = true;
      this.resetErrors();
      let response: any = '';
      response = await ProfileService.saveAddressData(this.addressObject);
      if (!response) return null;
      this.defaultAddressId = response.id;
      this.addressObject.id = response.id;
      mixpanel.track('Address added Successful');      
    } catch (e) {
      this.modals.isError = true;
      if (e.checker) {
        console.log('ERROR');
      } else if (e.message.detail === 'Address not allowed') {
        this.modals.outOfService = true;
      } else {
        this.errorsOnForm = { ...this.errorsOnForm, ...e.message };
      }
      mixpanel.track('Address added Error', {
        errorMessage: e.message.detail,
        error: e,
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action checkAddressObjectData = async () => {
    await checkAddress(this.addressObject.address); // adress pre check

    try {
      this.isLoading = true;
      this.resetErrors();
      let response: any = '';
      response = await ProfileService.checkAddressData(this.addressObject);
      if (!response) return null;
    } catch (e) {
      this.modals.isError = true;
      if (e.checker) {
        console.log('ERROR');
      } else if (e.message.detail === 'Address not allowed') {
        this.modals.outOfService = true;
      } else {
        this.errorsOnForm = { ...this.errorsOnForm, ...e.message };
      }
      mixpanel.track('Address added Error', {
        errorMessage: e.message.detail,
        error: e,
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action deleteAddress = async (id: number) => {
    try {
      this.isLoading = true;
      await ProfileService.deleteAddressData(id);
      this.addressesSettings = this.addressesSettings.filter(
        (address: IAddress) => address.id !== id
      );
      if (id === this.defaultAddressId) {
        this.defaultAddressId = this.addressesSettings.length
          ? this.addressesSettings[0].id
          : 0;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action resetErrors = () => {
    this.errorsOnForm = { ...defaultErrorsOnForm };
    this.modals = { ...defaultAddressModals };
  };
  @action clear = () => {
    this.addressesSettings = [];
    this.addressObject = defaultLocationInfo;
    this.defaultAddressId = 0;
  };
}

export default new AddressesStore();
