import React from "react";
import Spinner from "react-bootstrap/Spinner";

const SpinnerComponent = (props: {
  variant?: "warning" | "light";
  size?: "sm" | "lg";
}) => {
  const { variant = "light", size = "sm" } = props;
  return (
    <div
      className={`d-flex justify-content-center align-items-center spinner_container ${size}`}
    >
      <Spinner
        animation={"border"}
        role={"status"}
        variant={variant}
        style={variant === "warning" ? { color: "#D7BD35" } : {}}
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

export default SpinnerComponent;
