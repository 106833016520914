import React, { Component, ElementType, FormEvent, RefObject} from 'react';
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import { FormControlProps } from 'react-bootstrap';
import { RouterProps } from "react-router";
import { observer } from "mobx-react";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CustomNavBar from "../../../Shared/Navbars/CustomNavBar";
import BookingStore from "../../../Stores/BookingStore";
import StorageService from '../../../Services/StorageService';
import default_avatar from './../../../assets/icons/default_avatar.png';
import AddressesStore from "../../../Stores/AddressesStore";
import SpinnerComponent from '../../../Shared/Spinner';
import config from '../../../config';
import ReactGA from 'react-ga4';

@observer
class ThankYouPage extends Component<RouterProps> {
  state = { e: '', notes: '', error: false };
  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  week_of_days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
  handleBackButtonEvent = (e: PopStateEvent) => {
    console.log('Back button was clicked');
    e.preventDefault();
    window.location.href = "/";
    AddressesStore.clear();
    BookingStore.bookingData_new = null;
  };

  componentWillUnmount(): void {
    setTimeout(() => {
      window.removeEventListener('popstate', this.handleBackButtonEvent);
    }, 300);    
  }
  
  componentDidMount() {
    if (config.isProd) {
      ReactGA.event({
        category: 'form-submission',
        action: 'WebApp_booking_completed',
        label: 'Web Booking Completed',
      });
    }
    window.addEventListener('popstate', this.handleBackButtonEvent);
    const image: any = document.getElementById('valet_avatar');
    if (image) {
      image.addEventListener('load', function() {
        console.log('Image loaded successfully.');
      });

      image.addEventListener('error', function() {
          console.error('Error loading the image.');
          image.src = default_avatar;
      });
    }
	}
  constructor(props: RouterProps) {
    super(props);
    StorageService.getItem('zip_code')
      .then((response: string) => {
        if (!response) {
          this.props.history.push('/');
        }
      });
  }
  onSendIT = async () => {
    const result = await BookingStore.setBookingNote(this.state.notes);
    if(result) {
      this.props.history.push('/see_you_soon');    
    } else {
      this.setState({ error: true });
    }
  };

  handleChangeNotes = (
    event: FormEvent<
      ReplaceProps<
        ElementType<any>,
        BsPrefixProps<ElementType<any>> & FormControlProps
      >
    >
  ) => {
    const { id, value = '' } = event.currentTarget;
    this.setState({ notes: value })
  };

  render() {
    const { number_of_people, address, booking_date, booking_time, valet_avatar, valet_first_name, location_type, location_unit, direct_comment, hotel_name } = BookingStore.bookingData_new;
    const date = new Date(booking_date);
    const template_date = (typeof(booking_date) !== 'undefined') ? `${this.week_of_days[date.getDay()]}, ${this.months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}` : '';
    return (
      <div>
              <CustomNavBar redirction={false} />
              <div className={'thank_you_page'}>
                <div className={'thank_you_form center-text'}>
                  <div className="d-flex justify-content-center logo-container">
                    <img
                      src={require("../../../assets/icons/thankyou_logo.svg")}
                      alt={'thankyou_logo'}            
                    />
                  </div>
                  
                  <div>
                    <p style={{fontSize: 21, marginBottom: 40}}><strong>YOUR APPOINTMENT IS <span style={{color: '#219653'}}>CONFIRMED.</span></strong></p>
                    { number_of_people === 1 ? <p>Individual</p> : <p>Group</p>}
                    <p>{template_date}</p>
                    <p>{booking_time}</p>
                    <p style={{textAlign: 'center', margin: 'auto', width: '335px', marginBottom: 10}}>{address}</p>
                    <p>{(location_type && location_type !== '' && location_type !== 'Hotel') ? location_type : ''}{location_type === 'Hotel' ? hotel_name : ''}{(location_type && location_type !== '' && location_unit && location_unit !== '') ? ', ' : ''}{(location_unit && location_unit !== '') ? location_unit : ''}</p>
                    <p style={{ textAlign: 'center', margin: 'auto', width: '335px', marginBottom: 10}}>{direct_comment}</p>
                    <Dropdown.Divider style={{ marginTop: 20, marginBottom: 30}} />
                    <p style={{color: '#828282'}}>Your valet</p>
                    <img
                      id="valet_avatar"
                      src={valet_avatar ? valet_avatar : default_avatar}
                      alt="Your_Valet_Avatar"
                      style={{width: 80, height: 80, borderRadius: '50%', marginBottom: 10, objectFit: 'cover'}} 
                    />
                    <p style={{color: '#1A1A1A'}}>{valet_first_name}</p>
                  </div>
                </div>
                <div className={'more_form center-text'} style={{marginBottom: '100px'}}>
                  <p style={{fontSize: 26, marginTop: 60}}><strong>ONE MORE THING</strong></p>
                  <p style={{fontSize: 16, color: '#CFB12B'}}><strong>OPTIONAL</strong></p>
                  <p style={{fontSize: 16 }}><strong>Anything else we should know?</strong></p>
                  <Form className="feedback_form">
                    <Form.Group controlId="whatKnow">
                      <Form.Control 
                        as="textarea"
                        rows={3} 
                        style={{height: 80}}
                        placeholder={'No'}
                        onChange={this.handleChangeNotes} />
                      {this.state.error && (
                        <p className="text-danger" id="text-danger" style={{fontSize: '12px', marginLeft: '20px', marginTop: '5px', fontWeight: 'normal'}}>
                          Something wrong happened...
                        </p>
                      )}
                    </Form.Group>       
                  </Form>
                  <div className={'custom_button_container'}>
                    <Button
                      className={`custom_yellow_button`}
                      style={{height:'40px', marginBottom: '60px'}}
                      onClick={this.onSendIT}
                    >
                      <strong>SEND IT</strong>
                    </Button>
                  </div>
                </div>
              </div>
      </div>
    );
  }
}
export default ThankYouPage;
