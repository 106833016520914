import React, { Component, RefObject } from "react";
import SubNavbar from "../../Shared/Navbars/SubNavbar";
import referral from "../../assets/referral.svg";
import ReferralStore from "../../Stores/ReferralStore";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react";
import YellowButton from "../../Shared/Buttons/YellowButton";
import ProfileStore from "../../Stores/ProfileStore";
import ModalStore from "../../Stores/ModalStore";
import Popup from "../Popups/Popup";

@observer
class Referral extends Component {
  telInput: RefObject<any> = React.createRef();
  render() {
    const {
      form,
      onChange,
      submit,
    } = ReferralStore;
    return (
      <>
        <div className={"settings_container"}>
          <SubNavbar active={5} />
          <div className="container">
            <div className="referral-content">
              <h3 className="title">share your promo code and get 20% off!</h3>
              <img src={referral} alt="referral" />
              <h5 className="referral-subtitle">
                Share the code with your friend: once they signup and place the
                first order – you both will get 20%* discount!
              </h5>
              <h5 className="referral-subtitle">
                You a member? Get $20 off every 5 people you refer!
              </h5>
              <h3 className="referral-code">
                {ProfileStore.profile.referal_code}
              </h3>
            </div>
            <div className="referral-form">
              <Form.Group controlId="phone">
                <Form.Control
                  type="tel"
                  placeholder="enter Your friend phone number"
                  value={form.phone.label}
                  onChange={onChange}
                />
              </Form.Group>
              <YellowButton
                onClick={submit}
                value={"invite friend"}
                spinner={form.loading}
                disabled={
                  form.phone.label.length < 13 ||
                  form.loading
                }
              />
            </div>
            <p className="referral-description">
              *20% discount is one-time discount for the base fee for the
              appointment. Additional fees can be applied.
            </p>
          </div>
        </div>
        <Popup
          title={"Error!"}
          content={""}
          dynamicContent={() => <p>{form.errors.errorFromBe}</p>}
          id={"subscriptions_info"}
          onHide={() => ModalStore.toggleModal("errorOnReferral", false)}
          show={ModalStore.errorOnReferral}
          size={"sm"}
        />
        <Popup
          title={"Success!"}
          content={"Invitation was sent!"}
          id={"subscriptions_info"}
          onHide={() => ModalStore.toggleModal("referralSuccess", false)}
          show={ModalStore.referralSuccess}
          size={"sm"}
        />
      </>
    );
  }
}

export default Referral;
