import React, { Component } from "react";
import moment from "moment";
import { IMessageObject } from "../../../Interfaces/MessagesInterfaces";
import { defaultAvatar } from "../../../Interfaces/OtherInterfaces";

interface IProps {
  data: IMessageObject;
  isMine: boolean;
  startsSequence: boolean;
  endsSequence: boolean;
  showTimestamp: boolean;
  photo: string;
  showDate: boolean;
}

export default class Message extends Component<IProps> {
  render() {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp,
      photo,
      showDate
    } = this.props;

    const friendlyTimestamp = moment(data.created_at).format("LT");
    const date = moment(data.created_at).format("LL");
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`
        ].join(" ")}
      >
        {showDate && <div className={"date"}>{date}</div>}
        <div className="bubble-container">
          <div className={"message-photo"}>
            {startsSequence && (
              <img
                className={"message-photo-img"}
                src={photo || defaultAvatar}
                alt={"avatar"}
              />
            )}
          </div>
          <div className="bubble" title={friendlyTimestamp}>
            {data.text}
            {showTimestamp && (
              <div className="timestamp">{friendlyTimestamp}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
