import React, { Component } from 'react';
import MessagesStore from "../../../Stores/MessagesStore";
import {observer} from "mobx-react";

@observer
export default class ConversationSearch extends Component {
    handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      MessagesStore.searchConversation(value);
    };
  render() {
    return (
      <div className="conversation-search">
        <input
          type="search"
          className="conversation-search-input"
          placeholder="Search"
          onChange={this.handleChange}
          value={MessagesStore.searchString}
        />
        <i className={'fas fa-search search_icon'} />
      </div>
    );
  }
}