import {action, observable, reaction} from "mobx";
import {
    defaultBookingData,
    IBookingData
} from "../Interfaces/BookingInterfaces";
import BookingService from "../Services/BookingService";
import BookingStore from "./BookingStore";
import makeCapitalizeWord from "../Utils/MakeCapitalizeWord";
import TimeStore from "./TimeStore";
import moment from "moment";
import ProfileStore from "./ProfileStore";
import AddressesStore from "./AddressesStore";
import PaymentStore from "./PaymentStore";
import ModalStore from "./ModalStore";

class MyBookingsStore {
  @observable myBookings: IBookingData[] = [];
  @observable activeStatusOfBooking: string = "all";
  @observable isLoading: boolean = false;
  @observable errorsOnComponent = {
    modalError: ""
  }

  constructor() {
    reaction(
      () => this.activeStatusOfBooking,
      (status: string) => this.getBookingsList(status)
    );
  }

  @action cancelBooking = async (id: number | null) => {
    if (id) {
      try {
        this.isLoading = true;
        const response: any = await BookingService.cancelBooking(id);
        const bookingIndex = this.myBookings.findIndex(e => (e.id = id));
        this.myBookings[bookingIndex] = response;
        await this.getBookingsList(this.activeStatusOfBooking);
      } catch (e) {
        throw new Error(e.message);
      } finally {
        this.isLoading = false;
      }
    }
  };

  @action getBookingsList = async (status: string = "") => {
    try {
      this.isLoading = true;
      const search = makeCapitalizeWord(status);
      let params = { search: "" };
      if (search !== "All") {
        params = { search };
      }
      //TO-DO booking list API returns only payment id as number without info object
      const response: any = await BookingService.getBookingsList(params);
      this.myBookings = response;
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.isLoading = false;
    }
  };

  @action editBooking = (id: number) => {
    const editedBooking: any = this.myBookings.find(
      (booking: any) => booking.id === id
    );
    if (editedBooking) {
      BookingStore.bookingData = {
        ...defaultBookingData,
        id: editedBooking.id,
        date: moment(editedBooking.date),
        time: moment(editedBooking.date).format("LT"),
        quantity: editedBooking.number_of_people
          ? editedBooking.number_of_people
          : 1,
        notes: editedBooking.notes,
        tell_me_on_way: editedBooking.tell_me_on_way,
        personal_info: editedBooking.personal_info,
        location_info: editedBooking.location_info,
        payment_info: editedBooking.payments,
        address: editedBooking.address,
        client: ProfileStore.profile.id
      };
    }
    TimeStore.resetReserveTime();
    TimeStore.timeStructure[
      BookingStore.bookingData.time.padStart(8, "0")
    ].reserved = true;
  };
  @action startReschedule = (id: number) => {
    const editedBooking: any = this.myBookings.find(
      (booking: any) => booking.id === id
    );
    console.log("StartReschedule editedBooking = ", editedBooking);
    if (editedBooking) {
      BookingStore.bookingData = {
        ...defaultBookingData,
        id: editedBooking.id,
        date: moment(editedBooking.date),
        time: moment(editedBooking.date).format("LT"),
        notes: editedBooking.notes,
        quantity: editedBooking.number_of_people
          ? editedBooking.number_of_people
          : 1,
        tell_me_on_way: editedBooking.tell_me_on_way,
        personal_info: editedBooking.personal_info,
        location_info: editedBooking.location_info,
        payments: editedBooking.payments,
        // payment_info: editedBooking.payments,
        address: editedBooking.address,
        client: ProfileStore.profile.id,
        valet: editedBooking.valet,
      };

      AddressesStore.defaultAddressId = editedBooking.address.id;
      PaymentStore.defaultPaymentMethod = editedBooking.payments;
    }
  };

  @action setError = (message: string) => {
    this.errorsOnComponent.modalError = message;
    ModalStore.toggleModal("myBookingsError", true);
  };

  @action approveBooking = async (
    id: number | null,
    approveStatus: boolean
  ) => {
    if (id) {
      try {
        this.isLoading = true;
        const params = {
          is_confirmed: approveStatus
        };
        const response: any = await BookingService.approveBooking(id, params);
        if (!response) return null;
        await this.getBookingsList(this.activeStatusOfBooking);

        console.log("status of approving", response);
      } catch (e) {
        this.setError(e.message.detail)
        return e.message.detail;
      } finally {
        this.isLoading = false;
      }
    }
  };
}

export default new MyBookingsStore();
