import {IApiAppointment, defaultApiAppointment} from "./BookingInterfaces";
import moment from "moment";
import {defaultApiProfile, IApiProfile} from "./ProfileInterfaces";


export interface IConversation {
    id: number;
    appointment: IApiAppointment;
    draft: string;
    is_active: boolean;
    last_message_time: string;
    unread_count: number|null;
    chatting_with:IApiProfile;
    chatting_with_online: boolean;
    time_online: string|null
}

export interface IMessageObject {
    id: number;
    from_user: number;
    to_user: string;
    text: string;
    created_at: string;
    appointment_chat: number;
}

export const defaultConversation: IConversation = {
    id: 1,
    appointment: defaultApiAppointment,
    draft: '',
    is_active: true,
    last_message_time: moment().format(),
    unread_count: 0,
    chatting_with: defaultApiProfile,
    chatting_with_online: false,
    time_online: null,
}

export interface INewMessage {
    text: string;
    appointment_chat: number;
    from_user: number | null;
    to_user: number;
}