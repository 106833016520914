import React, { Component } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

interface IProps {
  target: Component;
  show: boolean;
  content: string | JSX.Element;
  placement?:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start"
    | undefined;
}

const TooltipComponent = (props: IProps) => {
  const { target, show, content, placement } = props;
  return (
    <Overlay target={target} show={show} placement={placement || "right"}>
      <Tooltip id="tooltip">{content}</Tooltip>
    </Overlay>
  );
};

export default TooltipComponent;
