import React, { Component } from 'react'
import queryString from 'query-string';
import SpinnerComponent from '../../Shared/Spinner';
import AuthStore from  '../../Stores/AuthStore'
import { observer } from 'mobx-react';
import Popup from '../Popups/Popup';

interface iState{
    error:string;
}
@observer
export default class FacebookCallBackPage extends Component<any, iState> {
  state: iState = { error: "" };
  async componentDidMount() {
    const parsed = queryString.parse(window.location.search);
    const parsedHash = queryString.parse(window.location.hash);
    const token = parsed.access_token || parsedHash.access_token;
    if (token) {
      try {
        await AuthStore.faceBookLogin(token);
      } catch (error) {
        this.setState({
          error: "Please set up email in your facebook account"
        });
      }
    }
  }
  render() {
    return (
      <div>
        {this.state.error ? (
          <p className="text-danger">{this.state.error}</p>
        ) : (
          <SpinnerComponent variant={"warning"} size={"lg"} />
        )}
        <Popup
          title="ERROR!"
          content={AuthStore.errorsAfterRequest}
          show={!!AuthStore.errorsAfterRequest}
          id="error_after_google_login"
          onHide={() => (window.location.href = "/login")}
        />
      </div>
    );
  }
}
