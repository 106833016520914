import React, {Component} from 'react';
import MessageList from "./MessageList";
import ConversationList from "./ConversationList";
import MessagesStore from "../../Stores/MessagesStore";
import {observer} from 'mobx-react';
import SpinnerComponent from '../../Shared/Spinner';
import { withRouter, RouteComponentProps } from 'react-router-dom';

@observer
class Messages extends Component<RouteComponentProps> {
  componentWillUnmount(): void {
    if (MessagesStore.socketInst) {
      MessagesStore.socketInst.close();
      MessagesStore.socketInst = null;
    }
  }

  async componentDidMount() {
      const conversationId: number | null = this.props.location.search
        ? +this.props.location.search.split("=")[1]
        : null;
    await MessagesStore.getConversationList(undefined, conversationId);
    if (!MessagesStore.socketInst)
      MessagesStore.setSocketInst(MessagesStore.activeConversation.id);
  }

  render() {
    
    return !MessagesStore.conversationsList ? (
      <SpinnerComponent />
    ) : !MessagesStore.conversationsList.length &&
      !MessagesStore.searchString ? (
      <div className="d-flex justify-content-center mt-5">
        <span>
          Seems you don’t have any conversations yet - they will appear here
          with new appointments!
        </span>
      </div>
    ) : (
      <div className="messenger">
        <div className="scrollable sidebar">
          <ConversationList />
        </div>

        <div className="scrollable content">
          {MessagesStore.conversationsList.length ? <MessageList /> : null}
        </div>
      </div>
    );
  }
}

export default withRouter(Messages);