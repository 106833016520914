import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import changeActiveItem from "../../Utils/changeActiveItem";
import NotificationsList from "../../Pages/Notifications/NotificationsList";
import AuthStore from "../../Stores/AuthStore";
import { observer } from "mobx-react";
import ProfileStore from "../../Stores/ProfileStore";
import { pagesWithoutNavbar } from "../../Constants/PagesWithoutNavbar";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import NotificationsStore from "../../Stores/NotificationsStore";
import config from "../../config";
import BookingStore from "../../Stores/BookingStore";
import CustomPopup from "../../Pages/Popups/CustomPopup";
import { PopupContent } from "../../Constants/PopupContent";

interface IState {
  showNotifications: boolean;
  showAddPhoneWarning: boolean;
}

@observer
class MainNavbar extends Component<RouteComponentProps, IState> {
  state = {
    showNotifications: false,
    showAddPhoneWarning: false
  };

  componentDidMount(): void {
    document.body.addEventListener("click", e => {
      const target = e.target as HTMLElement;
      const parent = target.parentElement as HTMLElement;
      const notificContainer = document.querySelector(".notifications-menu");
      if (notificContainer) {
        if (
          !target.classList.contains("notification-item") &&
          !(parent && parent.classList.contains("notification-item")) &&
          !(
            parent.parentElement &&
            parent.parentElement.classList.contains("notification-item")
          ) &&
          notificContainer && notificContainer.classList.contains("open")
        ) {
          notificContainer.classList.remove("open");
        }
      }
    });
  }

  makeActive = (event: React.MouseEvent<HTMLLIElement>) => {
    changeActiveItem(event.currentTarget, "active");
  };
  onLoad = (event: React.UIEvent<HTMLElement>) => {
    if (pagesWithoutNavbar.includes(this.props.location.pathname)) {
      event.currentTarget.classList.add("hide");
    }
  };
  notificationIcon = (
    <div className="notifications_icon d-flex">
      <img src={require("../../assets/icons/Bell.svg")} alt="" />
      <div
        className={`active_indicator ${
          !!NotificationsStore.newNotification ? "newNotifications" : ""
        }`}
      >
        <div className={"indicator"} />
      </div>
    </div>
  );
  profileMenuButton = () => {
    const { first_name, last_name, avatar_url } = ProfileStore.profile;
    return (
      <>
        <div className={"small_avatar_container d-lg-flex d-none"}>
          <img
            className={"small_avatar"}
            alt={"avatar"}
            src={avatar_url ? avatar_url : require("../../assets/human.svg")}
          />
        </div>
        <span className={"user_name"}>
          {first_name ? `${first_name} ${last_name}` : `Profile`}
        </span>
      </>
    );
  };

  handleBookNowClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const { phone } = ProfileStore.profile;
    if (phone) {
      BookingStore.createNewBooking()
      this.props.history.push("/booking_address");
    } else {
      this.setState({showAddPhoneWarning: true})
    }
  };

  render() {
    const active_tab = this.props.location.pathname;
    const newNotifications = !!NotificationsStore.newNotification;
    return (
      <div>
      <Navbar collapseOnSelect expand="lg" id={"main_navbar"}>
        <Navbar.Brand href="/" className={"ml-3"}>
          <img src={require("../../assets/Logo.svg")} alt={"logo"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className=" flex-lg-grow-1 justify-content-around align-items-center">
            <Nav.Link
              as={Link}
              to="/"
              eventKey={1}
              className={active_tab === "/" ? "active" : ""}
            >
              Dashboard
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/my_booking"
              eventKey={2}
              className={active_tab === "/my_booking" ? "active" : ""}
            >
              My Bookings
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/messages"
              eventKey={3}
              className={active_tab === "/messages" ? "active" : ""}
            >
              Messages
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/booking_address"
              onClick={this.handleBookNowClick}
            >
              <div id="book-now">Book Now</div>
            </Nav.Link>
            <NavDropdown
              title={this.notificationIcon}
              id="collasible-nav-dropdown"
              className={`flex-lg-grow-0 justify-content-center notifications ${
                newNotifications ? "active" : ""
              }`}
            >
              <NotificationsList />
            </NavDropdown>
          </Nav>
          <Nav className={"Profile_Menu mr-lg-5"}>
            <NavDropdown title={this.profileMenuButton()} id="profile_link">
              <NavDropdown.Item
                active={window.location.pathname === "/profile_settings"}
                as={Link}
                to="/profile_settings"
                eventKey={"4"}
              >
                Profile Settings
              </NavDropdown.Item>
              <NavDropdown.Item
                href={`${config.baseUrlForNavLinks}/gift-cards`}
                eventKey={"5"}
              >
                Order Gift Card
              </NavDropdown.Item>
              <NavDropdown.Item onClick={AuthStore.clean}>
                Log Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <CustomPopup
          id={'addPhoneWarning'}
          content={PopupContent.add_phone_number}
          onClick={() => {
            this.setState({showAddPhoneWarning:false})
            this.props.history.push("/profile_settings")
          }}
          onHide={() => this.setState({showAddPhoneWarning: false})}
          show={this.state.showAddPhoneWarning}
          showConfirmation={true}
          leftButtonText={'Edit profile'}
          rightButtonText={'Cancel'}
        />
      </div>
    );
  }
}

export default withRouter(MainNavbar);
