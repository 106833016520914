import React, { Component, ElementType, FormEvent, KeyboardEvent } from 'react';
import Form from 'react-bootstrap/Form';
import CustomLocationButton from '../Shared/Buttons/CustomLocationButton';
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import { FormControlProps } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { IAddress } from '../Interfaces/AddressesInterface';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import StorageService from '../Services/StorageService';
import AddressAutocomplete from '../Utils/AutoCompleteAddress';
import BookingService from '../Services/BookingService';
import AddressesStore from '../Stores/AddressesStore';
import { PopupContent } from '../Constants/PopupContent';
import CustomPopup from '../Pages/Popups/CustomPopup';
import checkAddress from '../Utils/checkAddress';

interface IProps extends RouteComponentProps {
  nextStep: (query?: string) => void;
  buttonValue: string;
  error?: string;
  value: IAddress,
  onChange: (name: string, value: string | boolean) => void;
  location: any;
}

type TQueryData = {
  pre_filled_zip_code?: string | number;
  query?: string;
}

@observer
class CustomLocationForm extends Component<IProps> {
// export default class CustomLocationForm extends Component<IProps> {
  state = { showHotelInformation: false, showModal: false, zip_code: '', err: '', errorPopup: '', query_zip_code: '' };
  componentDidMount(): void {
    const { location, onChange } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const zipCode = searchParams.get('zip_code') || '';
    const time = searchParams.get('time') || '';
    const date = searchParams.get('date') || '';

    if (zipCode) {
      this.setState({ query_zip_code: zipCode }, () => {
        this.continue(zipCode, `?zip_code=${zipCode}&time=${time}&date=${date}`);
      });
    }

    const input = document.querySelector('#address') as HTMLInputElement;
    if (input) {
      AddressAutocomplete(input, this.props.onChange);
    }
  }

  changeData = (
    event: FormEvent<
      ReplaceProps<
        ElementType<any>,
        BsPrefixProps<ElementType<any>> & FormControlProps
      >
    >
  ) => {
    const { id, value = '' } = event.currentTarget;
    if (value !== '') {
      this.setState({ err: "" });
    }
    if (id) {
      AddressesStore.addressObject.zip_code = '';
      this.props.onChange(id, value);
    }
  };

  handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
      this.continue();
    }
  };

  continue = async (pre_filled_zip_code?: string, query?: string) => {
    const { value, nextStep, error } = this.props;   
    const { err, showModal } = this.state;
    const { address, zip_code } = value;

    if ( (address === '' || !address) && !pre_filled_zip_code) {
      this.setState({err: "Fill in this field"});
      return;
    }

    let temp_zip_code = address.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    if (address.length !== 5 || temp_zip_code.length !== 5) {
      temp_zip_code = zip_code || pre_filled_zip_code || '';
      try {
        await checkAddress(address);
      } catch (error) {
        console.log(error);
        if (error.message.includes('Invalid')) {
          this.setState({ err: PopupContent.choose_address_suggestions });
          return;
        }
      }
    }
    
    await BookingService.checkZipCode({zip_code: temp_zip_code})
    .then((response: any) => {
      StorageService.setItem('zip_code', temp_zip_code);
      nextStep(query);
    })
    .catch((error: any) => {
      if (error.code === 400 || error.code === 404) {
        this.setState({
          showModal: true,
          errorPopup: PopupContent.no_service_area
        });
      }
    });
  }

  render() {
    const { value, nextStep, error } = this.props;   
    const { err, showModal, query_zip_code } = this.state;
    const { address, zip_code } = value;

    return (
      <div className={'custom_location_form'}>
        <div className='d-flex justify-content-center' style={{marginTop:'30px'}}>
          <img src={require("../assets/icons/location1.svg")} alt={"location"} ></img>
        </div>
        <div style={{textAlign:'center'}}>
          <h1 className='location_heading'>WHERE WOULD YOU LIKE US TO MEET YOU?</h1>
        </div>
        <p style={{fontSize:'16px', fontWeight:'bold', textAlign:'center'}}>
          Type in your address or zip code below
        </p>
        <Form>
          <Form.Row style={{width: '335px', margin: 'auto'}}>
            <Form.Group
              controlId={'address'}
              className={'position-relative location_main'}
            >
              <Form.Control
                type={'text'}
                placeholder={'Input Address / Zip Code'}
                className={(err || error) ? 'input-valid' : ''}
                style={(err || error) ? { border: '1px solid red', padding: '0.1rem 2rem', height:'40px' } : {padding: '0.1rem 2rem', height:'40px'}}
                value={address || query_zip_code}
                onKeyDown={this.handleKeyDown}
                onChange={this.changeData}
                isValid={error ? !error : undefined}
              />
              <img
                src={require("../assets/icons/location2.svg")}
                alt={'location'}
                className={'custom_location_icon'}
              />
              {error && (
                <p className="text-danger" id="text-danger" style={{fontSize: '12px', marginLeft: '20px', marginTop: '5px', fontWeight: 'normal'}}>
                  {error}
                </p>
              )}
              {err && (
                <p className="text-danger" id="text-danger" style={{fontSize: '12px', marginLeft: '20px', marginTop: '5px'}}>
                  {err}
                </p>
              )}
              <CustomLocationButton
                onClick={this.continue}
                value={'SELECT DATE & TIME'}
                additionalClass={'w-100'}
              />
            </Form.Group>
          </Form.Row>
        </Form>

        <div className='d-flex justify-content-center flex-column' style={{marginBottom:'50px', marginTop:'40px'}}>
          <div className='d-flex justify-content-center' style={{textAlign:'center', marginBottom:'10px'}}>
            <span style={{fontSize: '13px', fontWeight: 400}}>Booked with Spray Valet before?</span>
          </div>
          <Link to={'/logIn'} className='d-flex justify-content-center' style={{color:'#CFB12B', fontSize: '13px', fontWeight: 700}}>
            SIGN IN!
          </Link>
        </div>
        <CustomPopup
          id={'chooseAddress'}
          content={this.state.errorPopup}
          show={showModal}
          onHide={() => this.setState({showModal: false})}
        />
      </div>
    );
  }
}
export default withRouter(CustomLocationForm);
