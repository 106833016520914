import moment from 'moment';
import React from 'react';
import { IUserSubscription } from '../../../Interfaces/ProfileInterfaces';
import checkmark_round from '../../../assets/icons/2.svg';

interface IProps {
  subscriptions: IUserSubscription[];
}

function CurrentPlan({ subscriptions }: IProps) {
  return (
    <>
      {subscriptions.map((e: IUserSubscription) => {
        const isCanceled = e.cancellation_date;
        return (
          <div className={'current-plan-container'} key={e.id}>
            <p className={'current-plan'}>
              <img src={checkmark_round} alt='Your membership plan' /> Your membership plan{' '}
              {!isCanceled && 'is'} &nbsp;
              <b>
                {e.subscription.city} - {e.subscription.subscription_type}
              </b>
              &nbsp; {isCanceled && 'is cancelled'}
            </p>
            {isCanceled ? (
              <small>
                Cancellation date{' '}
                {moment(e.cancellation_date).format('MMMM D, YYYY')}
              </small>
            ) : (
              <small>
                will renew on{' '}
                {moment(e.date_created).add(30, 'days').format('MMMM D, YYYY')}
              </small>
            )}
            {isCanceled ? (
              <p className={'current-plan-appointments-left'}>
                Remaining appointments:{' '}
                {e.appointments_left + e.unused_appointments}
              </p>
            ) : (
              <>
                <p className={'current-plan-appointments-left'}>
                  Appointments left: {e.appointments_left}
                </p>
                <p className={'current-plan-all-appointments-left'}>
                  All banked appointments:{' '}
                  {e.appointments_left + e.unused_appointments}
                </p>
              </>
            )}
          </div>
        );
      })}
    </>
  );
}

export default CurrentPlan;
