export const pagesWithoutNavbar: string[] =
    [
        '/booking_done_step1',
        '/booking_done_step2',
        '/signIn',
        '/logIn',
        '/password_recovery',
        '/sign_client_info',
        '/sign_location_info',
        '/password_recovery_success'
    ];