import React, { useState } from "react";
import NotificationsStore, {
  IUserNotification
} from "../../Stores/NotificationsStore";
import Button from "react-bootstrap/Button";
import Stars from "../../Shared/Stars";
import MyBookingsStore from "../../Stores/MyBookingsStore";

interface INotificationTemplate {
  [key: string]: (notification: IUserNotification) => React.ReactElement;
}

interface IButtonProps {
  notification: IUserNotification;
  text: string;
}

const ConfirmButton = (props: IButtonProps) => {
  const onClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      setLoading(true);
      event.stopPropagation();
      const error = await MyBookingsStore.approveBooking(
        props.notification.appointment_id,
        true
      );
      if (error) {
        NotificationsStore.setError(error);
      }
      NotificationsStore.markAsRead(props.notification.id);
      setLoading(false);
  };
  const [loading, setLoading] = useState(false);
  return (
    <Button
      variant={"warning"}
      className={"flex-grow-1"}
      size={"sm"}
      disabled={!props.notification.is_active || loading}
      onClick={onClick}
    >
      {props.text}
    </Button>
  );
};

const CancelButton = (props: IButtonProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      variant={"outline-warning"}
      className={"flex-shrink-1"}
      size={"sm"}
      disabled={!props.notification.is_active || loading}
      onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
        setLoading(true);
        event.stopPropagation();
        await MyBookingsStore.approveBooking(
          props.notification.appointment_id,
          false
        );
        
        NotificationsStore.markAsRead(props.notification.id);
        setLoading(false);
      }}
    >
      {props.text}
    </Button>
  );
};

const NotificationsTemplates: INotificationTemplate = {
  info: (notification: IUserNotification) => <div>{notification.text}</div>,

  inform: (notification: IUserNotification) => <div>{notification.text}</div>,

  feedback: (notification: IUserNotification) => (
    <div className="d-flex flex-column">
      <div>{notification.text}</div>
      <Stars />
    </div>
  ),

  reschedule: (notification: IUserNotification) => {
    if (
      notification.meta &&
      notification.meta.new_price &&
      notification.meta.new_price > 0
    ) {
      return (
        <div className={"d-flex flex-column"}>
          <div>
            {notification.text} <b>${notification.meta.new_price}</b>
            {"  "}
            <span className={"old_price"}>${notification.meta.old_price}</span>
          </div>
          <div
            className={"d-flex justify-content-between flex-column flex-sm-row"}
          >
            <ConfirmButton
              notification={notification}
              text={"Approve and pay"}
            />
            <CancelButton notification={notification} text={"Decline"} />
          </div>
        </div>
      );
    }
    if (
      notification.meta &&
      notification.meta.new_price &&
      notification.meta.new_price < 0
    ) {
      return (
        <div className={"d-flex flex-column"}>
          <div>
            {notification.text} <b>${Math.abs(notification.meta.new_price)}</b>
            <span className={"old_price"}>${notification.meta.old_price}</span>
          </div>
          <div className={"d-flex justify-content-between"}>
            <ConfirmButton notification={notification} text={"Confirm"} />
            <CancelButton notification={notification} text={"Decline"} />
          </div>
        </div>
      );
    }
    return (
      <div className={"d-flex flex-column"}>
        <div>{notification.text}</div>
        <div className={"d-flex justify-content-between"}>
          <ConfirmButton notification={notification} text={"Confirm"} />
          <CancelButton notification={notification} text={"Decline"} />
        </div>
      </div>
    );
  },

  pick_appointment: (notification: IUserNotification) => (
    <div>{notification.text}</div>
  ),

  appointment: (notification: IUserNotification) => (
    <div>{notification.text}</div>
  ),

  cc_expiring: (notification: IUserNotification) => (
    <div>{notification.text}</div>
  ),

  new_message: (notification: IUserNotification) => (
    <div>{notification.text}</div>
  ),

  add_people: (notification: IUserNotification) => {
    const text = notification.text.split("&&");
    return (
    <div className={"d-flex flex-column"}>
      <div> {text[0]} <b>${notification.meta.new_price}</b>
      {"  "}
          <span className={"old_price"}>${notification.meta.old_price}</span>
          {/* {". "} */}
          {text[1]}</div>
      <ConfirmButton notification={notification} text={"Approve and pay"} />
      <CancelButton notification={notification} text={"Decline"} />
    </div>
    )
    },

  cancel_appointment: (notification: IUserNotification) => (
    <div className={"d-flex flex-column"}>
      <div>{notification.text}</div>
      <CancelButton notification={notification} text={"Cancel"} />
    </div>
  ),
  reschedule_up: (notification: IUserNotification) => {
    const text = notification.text.split("&&");
    return (
      <div className={"d-flex flex-column"}>
        <div>
          {text[0]} <b>${notification.meta.new_price}</b>
          {"  "}
          <span className={"old_price"}>${notification.meta.old_price}</span>
          {". "}
          {text[1]}
        </div>
        <div className={"d-flex justify-content-between"}>
          <ConfirmButton notification={notification} text={"Approve"} />
          <CancelButton notification={notification} text={"Decline"} />
        </div>
      </div>
    );
  }
};

export default NotificationsTemplates;
