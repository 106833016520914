import React, { Component, FormEvent } from "react";
import { observer } from "mobx-react";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/helpers";
import { FormControlProps } from "react-bootstrap";
import BookingStore from "../../../Stores/BookingStore";
import Form from "react-bootstrap/Form";
import PromoStore from "../../../Stores/PromoStore";

interface IState {
  percent: null | number;
  showInputPromo: boolean;
  promoSuccess: boolean;
  promo: string;
  promoFailed: boolean;
  type: string;
  discount: null | number;
}

@observer
export default class PromoComponent extends Component<any, IState> {
  state = {
    percent: null,
    showInputPromo: false,
    promoSuccess: false,
    promo: "",
    promoFailed: false,
    type: "promocode",
    discount: null
  };
  async componentDidMount() {
    let state: any = { type: "", discount: null };
    if (BookingStore.bookingData.promocode) {
      state = {
        type: "promocode",
        promo: BookingStore.bookingData.promocode
      };
    }
    if (BookingStore.bookingData.gift_card) {
      state = {
        type: "gift_card",
        promo: BookingStore.bookingData.gift_card
      };
    }
    if (state.discount) {
      PromoStore.type = state.type;
      PromoStore.promo = state.promo;
      await this.checkPromo();
    }
  }

  checkPromo = async () => {
    try {
      const promo = await BookingStore.checkPromo(
        PromoStore.promo,
        PromoStore.type
      );
      if (promo.promocode_full || promo.gift_card) {
        PromoStore.promoSuccess = true;
        PromoStore.discount = BookingStore.discount.value;

        PromoStore.showInputPromo = false;

        PromoStore.percent = null;
        PromoStore.promoFailed = false;
      } else {
        throw new Error(`Invalid ${PromoStore.type}`);
      }
    } catch (e) {
      PromoStore.promoFailed = true;
      console.log(e);
    }
  };

  promoCode = () => {
    if (BookingStore.bookingData.purchase_method !== "Subscription") {
      PromoStore.promo = ''
      PromoStore.showInputPromo = !PromoStore.showInputPromo;
    } else {
      PromoStore.promo = ' ' // for error You can't use promo code or gift card if you use your ...
    }
  };

  changeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.currentTarget;
    PromoStore.type = id;
  };

  getCodeValue() {
    let { discount } = PromoStore;
    if (BookingStore.bookingData.purchase_method === "Subscription") {
      discount = 0;
    }
    return BookingStore.discount.type === "percent"
      ? `${discount} %`
      : `$${discount} `;
  }

  render() {
    const {
      showInputPromo,
      promo,
      promoFailed,
      promoSuccess,
      type
    } = PromoStore;
    const { discountValue } = BookingStore;
    if (showInputPromo) {
      return (
        <div className="promo_input">
          <h4>have a promo code/gift card/REFERRAL CODE</h4>
          <Form>
            <div className="promo_container ">
              <label className="promo">
                Promo code/Referral code
                <input
                  type="radio"
                  name="promo"
                  id={"promocode"}
                  onChange={this.changeType}
                  checked={type === "promocode"}
                />
                <span className="checkmark" />
              </label>
              <label className="promo">
                Gift Card
                <input
                  type="radio"
                  name="promo"
                  id={"gift_card"}
                  onChange={this.changeType}
                  checked={type === "gift_card"}
                />
                <span className="checkmark" />
              </label>
            </div>
            <Form.Group>
              <Form.Control
                type={"text"}
                placeholder={"Enter your code"}
                value={promo}
                className={promoFailed ? "error" : ""}
                onChange={(
                  event: FormEvent<
                    ReplaceProps<
                      "input",
                      BsPrefixProps<"input"> & FormControlProps
                    >
                  >
                ) => (PromoStore.promo = event.currentTarget.value || "")}
              />
            </Form.Group>
            <div className="inline_button" onClick={this.checkPromo}>
              apply
            </div>
          </Form>
        </div>
      );
    } else {
      if (promoSuccess) {
        return (
          <div className="promo_success">
            <h6>
              <i className={"fas fa-check-circle"} /> you got a {discountValue}{" "}
              discount
            </h6>
            <div
              className="inline_button"
              onClick={async () => {
                BookingStore.destroyPromocode();
                BookingStore.bookingData.gift_card = "";
                BookingStore.bookingData.promocode = "";
                BookingStore.discount.value = 0;
                PromoStore.promoSuccess = false;
                PromoStore.showInputPromo = true;
              }}
            >
              edit
            </div>
          </div>
        );
      } else {
        return (
          <div className="inline_button" onClick={this.promoCode}>
            <p>+ have a promo code/</p>
            <p>gift card/</p>
            <p>REFERRAL CODE</p>
          </div>
        );
      }
    }
  }
}
