import React, {Component} from 'react';
import Form from "react-bootstrap/Form";
import YellowButton from "../../Shared/Buttons/YellowButton";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import ValetsStore from "../../Stores/ValetsStore";
import Stars from "../../Shared/Stars";
import ValetStatusComponent from "../Booking/RebookValet/ValetStatusComponent";
import {defaultValet, IValet} from "../../Interfaces/ValetInterfaces";
import SpinnerComponent from "../../Shared/Spinner";
import { defaultAvatar } from '../../Interfaces/OtherInterfaces';
import YelpInfoStore from '../../Stores/YelpInfoStore';


interface IState{
    showEnvelope: boolean;
    valet:IValet;
}

const openInNewTab = (url: string) => {
    console.log("OpenInNewTab URL = ", url);
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

@observer
export default class LeavingAFeedback extends Component<RouteComponentProps, IState> {
    state = {
        showEnvelope: false,
        valet: defaultValet,
    };
    componentDidMount = () => {
        this.checkValetData();
        // YelpInfoStore.getYelpInfos();
    };

    componentWillUnmount() {
        ValetsStore.cancelRequest();
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
        ValetsStore.changeFeedBack(event.target.id, event.target.value);
    sendFeedBack = async () => {
        if (this.props.location.state.valet_id && 
            this.props.location.state.appointment_id
            ) {
            await ValetsStore.sendFeedback(this.state.valet.valet.id, this.props.location.state.appointment_id);
            this.setState({showEnvelope: true})
        }
    };

    writeYelpReview = async () => {
        if (this.props.location.state.appointment_id){
            await YelpInfoStore.getYelpReviewUrl(this.props.location.state.appointment_id);
        }
        this.props.history.push('/');
    }

    checkValetData = async () => {
        const valet = await ValetsStore.getUserValet(this.props.location.state.valet_id);
        this.setState({ valet })
    };
    
    render() {
        const {valet, favorite} = this.state.valet;
        const {yelpInfoList} = YelpInfoStore;

        console.log("yelpInfoList", yelpInfoList.length);
        if (!this.props.location.state.valet_id){
            return (
                <div className="gray_background leaving_feedback">
                    <div className="feedback_small_container">
                        <h1>Ops, something goes wrong. Please, come back later!</h1>
                        <YellowButton onClick={() => this.props.history.push('/')} value={'back to home'}/>
                    </div>
                </div>
            )
        }
        if (ValetsStore.isLoading){
            return (
                <SpinnerComponent size={'lg'} variant={'warning'}/>
            )
        }
        if (this.state.showEnvelope) {
            return (
                <div className="gray_background leaving_feedback">
                    <div className="feedback_small_container">
                        <div className="icon_container">
                            <img src={require('../../assets/icons/envelope.svg')} alt={'envelope'}/>
                        </div>
                        <h1>Thanks for your feedback!</h1>
                        <YellowButton onClick={() => this.props.history.push('/')} value={'back to home'}/>
                    </div>
                </div>
            );
        }
        return (
            <div className="gray_background leaving_feedback">
                <div className="small_container container d-flex flex-column align-items-center">
                    <ValetStatusComponent sign={'heart'} text={'save as favorite'} valetId={valet.id} isActive={favorite} property={'favorite'} onClick={this.checkValetData}/>
                    <h1>How was {valet.first_name}?</h1>
                    <div className="avatar_container">
                        <img src={valet.avatar_url || defaultAvatar} alt={'avatar'}/>
                    </div>
                    <h4>{valet.first_name}</h4>
                    <div className="stars_container">
                        <h6>How happy are you?</h6>
                        <Stars/>
                    </div>
                    <Form>
                        <Form.Group controlId={'text'}>
                            <Form.Label>Comment (Optional)</Form.Label>
                            <Form.Control as={'textarea'} value={ValetsStore.feedbackObject.text}
                                          onChange={this.handleChange}
                                          rows={4}/>
                        </Form.Group>
                        <YellowButton onClick={this.sendFeedBack} value={'send'} additionalClass={'w-100'}/>
                        <YellowButton onClick={this.writeYelpReview} value={'write review on yelp'} additionalClass={'w-100'} spinner={YelpInfoStore.isLoading}/>
                        <div className={'inline_button'} onClick={() => this.props.history.push('/')}>Remind me later
                        </div>
                    </Form>

                </div>
            </div>
        )
    }
}