import React, { ChangeEvent, Component } from "react";
import Location from "../assets/icons/location.png";

export interface IInputProps {
  type: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  iconName?: string;
  secondIcon?: string;
  showPassword?: () => void;
  locationVisible?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
  ref?: any;
  autofocus?: boolean;
}

export default class LoginInput extends Component<IInputProps> {
  render() {
    const {
      type,
      onChange,
      placeholder,
      value,
      name,
      iconName = null,
      secondIcon = null,
      showPassword,
      locationVisible = false,
      onBlur,
      autoComplete,
      id,
      disabled,
      readOnly = false,
      ref,
      autofocus = false
    } = this.props;

    return (
      <div className={"login-input-container"}>
        {id ? (
          <input
            type={type}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            name={name}
            readOnly={readOnly}
            className={"login_input"}
            onBlur={onBlur}
            autoComplete={autoComplete}
            id={id}
          />
        ) : (
          <input
            autoFocus={autofocus}
            ref={ref || null}
            type={type}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            name={name}
            className={"login_input"}
            disabled={disabled}
            onBlur={onBlur}
            autoComplete={autoComplete}
            readOnly={readOnly}
          />
        )}

        <i className={"fas " + iconName} />
        {secondIcon && (
          <i
            onClick={!disabled ? showPassword : () => {}}
            className={`fas ${secondIcon} second_icon`}
          />
        )}
        <img
          className={"location_icon"}
          src={Location}
          alt={"location"}
          style={{ display: locationVisible ? "inline-block" : "none" }}
        />
      </div>
    );
  }
}
