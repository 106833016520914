import React, { ChangeEvent, Component, RefObject } from "react";
import Logo from "../../../assets/Logo.svg";
import Google from "../../../assets/Google.png";
import Button from "react-bootstrap/Button";
import LoginInput from "../../../Shared/LoginInput";
import YellowButton from "../../../Shared/Buttons/YellowButton";
import WhiteButton from "../../../Shared/Buttons/WhiteButton";
import { observer } from "mobx-react";
import { RouterProps } from "react-router";
import AuthStore from "../../../Stores/AuthStore";
import { Link } from "react-router-dom";
import queryString from "query-string";
import ProfileService from "../../../Services/ProfileService";
import StorageService from "../../../Services/StorageService";
import SocialLoginsStore from "../../../Stores/SocialLoginStore";
import ProfileStore from "../../../Stores/ProfileStore";
import AuthService from "../../../Services/AuthService";
import RecaptchaScriptLoader from "./RecaptchaScriptLoader";
import config from "../../../config";

interface IState {
  showPassword?: boolean;
  signInError: boolean;
  afterSocialLogin: boolean;
}

@observer
export default class SignIn extends Component<RouterProps, IState> {
  state = {
    showPassword: false,
    signInError: false,
    afterSocialLogin: false
  };

  gooClick = () => {
    SocialLoginsStore.googleSocialLoginUrl &&
      window.location.replace(SocialLoginsStore.googleSocialLoginUrl);
  };

  signIn = async () => {
    try {
      const token = await window.grecaptcha.execute(config.RECAPTCHA_SITE_KEY); // Replace with your actual API key
      if (token){
        const response: any = await AuthService.verifyRecaptcha(token);
        if (response && (!response.success || response.score < 0.7)){
          AuthStore.errorsAfterRequest = "We detected something suspicious in your behavior. Please try again."
          this.setState({ signInError: true });
          return;
        }
      }

      const id = await StorageService.getItem("id");
      const phone = AuthStore.signInData.phone
      this.state.afterSocialLogin
        ? await ProfileService.editProfileData(id, { phone })
        : await AuthStore.signIn();
      ProfileStore.profile.phone = phone;

      if (AuthStore.isLogged) {
        this.props.history.push("/sign_client_info");
        return;
      }
    } catch (error) {
      this.setState({ signInError: true });
    }
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    AuthStore.changeSignInData(name, value);
    AuthStore.phoneValidation(name, value);
  };

  handleClick = () => this.props.history.push("/Login");

  handleRecaptchaLoad = () => {
    // This callback will be called when the reCAPTCHA script is loaded
    // Perform any initialization or actions related to reCAPTCHA here
    // For example, you can execute reCAPTCHA verification after the script is loaded
  };

  componentDidMount(): void {
    const parsed = queryString.parse(window.location.search);
    if (parsed.afterSocial) {
      AuthStore.signInData.email = parsed.afterSocial as string;
      AuthStore.signInData.password = "********************";
      this.setState({ afterSocialLogin: true });
    }
    const navbar = document.querySelector("nav");
    if (navbar) navbar.classList.add("hide");
  }

  componentWillUnmount(): void {
    const navbar = document.querySelector("nav");
    if (navbar) navbar.classList.remove("hide");
  }
  telInput: RefObject<any> = React.createRef();
  render() {
    const { email, phone, password } = AuthStore.signInData;
    const isUserHasError = AuthStore.inlineErrors.email || AuthStore.inlineErrors.phone
    const disable = () => !(email && phone && password) || isUserHasError;
    return (
      <div className={"d-flex min-vh-100"}>
        <RecaptchaScriptLoader onLoad={this.handleRecaptchaLoad} />
        <div className={"col-md-7 col-12"}>
          <div id={"sign_up"}>
            <div className={"logo_container mb-5"}>
              <Link
                to={"/"}
                onClick={(e: any) => {
                  e.preventDefault();
                  this.props.history.push("/");
                }}
              >
                <img src={Logo} alt={"Logo"} />
              </Link>
            </div>
            <div className={"content_container"}>
              <h1 className={"text-capitalize text-center font-weight-bold"}>
                Sign up
              </h1>
              <div
                className={
                  "d-flex flex-direction-row m-5 justify-content-center"
                }
              >

                <Button
                  type={"button"}
                  className={"sign_social_button"}
                  onClick={this.gooClick}
                >
                  <img src={Google} alt={"Google"} />
                </Button>
              </div>
              <div className={"form-group variable-width"}>
                <LoginInput
                  type={"email"}
                  placeholder={"email"}
                  name={"email"}
                  onChange={this.handleChange}
                  value={email}
                  disabled={this.state.afterSocialLogin}
                  autoComplete="email"
                  onBlur={AuthStore.inputFieldsValidation}
                />
                <span
                  className={`inline_error ${
                    AuthStore.inlineErrors.email ? "visible" : "invisible"
                  }`}
                >
                  Please enter valid email
                </span>
                <LoginInput
                  type={"tel"}
                  placeholder={"Phone"}
                  name={"phone"}
                  ref={this.telInput}
                  iconName={"fa-mobile"}
                  autoComplete={"tel"}
                  onChange={this.handleChange}
                  value={phone}
                  autofocus={this.state.afterSocialLogin}
                  onBlur={AuthStore.inputFieldsValidation}
                />
                <span
                  className={`inline_error ${
                    AuthStore.inlineErrors.phone ? "visible" : "invisible"
                  }`}
                >
                  Please enter only numbers
                </span>
                <LoginInput
                  type={this.state.showPassword ? "text" : "password"}
                  placeholder={`Password`}
                  iconName={"fa-key"}
                  disabled={this.state.afterSocialLogin}
                  secondIcon={"fa-eye"}
                  name={"password"}
                  onChange={this.handleChange}
                  showPassword={() =>
                    this.setState({ showPassword: !this.state.showPassword })
                  }
                  onBlur={AuthStore.inputFieldsValidation}
                  value={password}
                />
                <span
                  className={`inline_error ${
                    AuthStore.inlineErrors.password ? "visible" : "invisible"
                  }`}
                >
                  Password must be a minimum of 6 characters
                </span>
                <span
                  className={`inline_error ${
                    AuthStore.errorsAfterRequest || this.state.signInError
                      ? "visible"
                      : "invisible"
                  }`}
                >
                  {AuthStore.errorsAfterRequest}
                </span>
                <YellowButton
                  onClick={this.signIn}
                  value={"sign up"}
                  disabled={disable()}
                  additionalClass={"w-100"}
                  spinner={AuthStore.isLoading}
                />
                <div className="inline_button" onClick={this.handleClick}>
                  Log In
                </div>
              </div>

              <div className={"link_container"}>
                <a
                  href={`https://sprayvalet.com/terms-of-service/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"agreements_link"}
                >
                  terms & service
                </a>{" "}
                |{" "}
                <a
                  href={`https://sprayvalet.com/privacy-policy/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"agreements_link"}
                >
                  privacy policy
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-md-5 col-0 p-0"}>
          <div id={"yellow_background_with_picture"}>
            <div className={"label"}>Already Have An Account?</div>
            <WhiteButton onClick={this.handleClick} value={"log in"} />
          </div>
        </div>
      </div>
    );
  }
}
