import { action, observable } from "mobx";
import YelpService from "../Services/YelpService";

export interface YelpInfo {
    id: number;
    city: string;
    main_url: string;
    review_url: string;
}

const openInNewTab = (url: string) => {
    console.log("OpenInNewTab URL = ", url);
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}


class YelpInfoStore {
    @observable yelpInfoList: YelpInfo[] = [];
    @observable isLoading: boolean = false;

    @action getYelpReviewUrl = async (id: number) => {
        try {
            this.isLoading = true;
            const response: any = await YelpService.getAppointment(id);
            console.log(response)
            
            const city = response.address.city;

            const yelpResponse: unknown = await YelpService.getYelpInfo();
            let yelpInfoList = yelpResponse as [YelpInfo]
            console.log("YelpInfoList = ", yelpInfoList)

            const yelpInfo = yelpInfoList.find(element => element.city === city );
            if (yelpInfo){
                openInNewTab(yelpInfo.review_url);
            }
            
        } catch (e) {
            throw new Error(e.message);
        } finally {
            this.isLoading = false;
        }
    }
}


export default new YelpInfoStore();