import React, {Component} from 'react';
import {observer} from "mobx-react";
import MyBookingsStore from "../../Stores/MyBookingsStore";


@observer
export default class MyBookingNavbar extends Component{
    state = {
        tabs: ['all', 'pending', 'upcoming', 'complete', 'cancelled']
    }
    handleClick = (event:React.MouseEvent<HTMLElement>) => {
        MyBookingsStore.activeStatusOfBooking = event.currentTarget.innerText.toLowerCase();
    };

    render() {
        const {activeStatusOfBooking} = MyBookingsStore;
        const {tabs} = this.state;
        return (<div id={'sub_navbar'}>
            {this.state.tabs.map((tab:string, i:number) =>
                <span
                    className={`sub_navbar_link ${activeStatusOfBooking===tabs[i]?'active':''}`}
                    onClick={this.handleClick}
                    key={tab}
                >
                    {tab}
                </span>
            )}
        </div>);
    }
}