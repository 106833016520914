export interface ICreditCardBeforeSave {
    card_number: string;
    date: IExpDate;
    cvc: string
    [key: string]: string | number | boolean | IExpDate;
}

export interface ICreditCardAfterSave {
    id:number;
    card_number:string;
    card_type:string;
    date: IExpDate;
    cvc:string;
    first_name:string;
    last_name:string;
    email:string;
    phone:string;
    [key: string]: number|string|boolean|IExpDate;
}

export interface IExpDate {
    exp_month: string;
    exp_year: string;
}

export const defaultCreditCardBeforeSave = {
    id: 0,
    card_number: '',
    date: {exp_month: '', exp_year: ''},
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    cvc: '',
    card_type: ','
};
