import Location from '../assets/icons/location.png';
import React, { Component, ElementType, FormEvent, RefObject} from 'react';
import { OverlayTrigger, Form, Button, Row, Col, Popover, Image } from 'react-bootstrap';
import { FormControlProps } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { IPaymentContact, ICreditCard } from '../../../Interfaces/PaymentContactInterface';
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import CheckBoxButton from '../../../Shared/Buttons/CheckBoxButton';
import AddressAutocomplete from '../../../Utils/AutoCompleteAddress';
import InfoIconHover from '../../../assets/icons/info_over.svg';
import InfoIcon from '../../../assets/icons/info.svg';
import { CheckBoxItem } from '../../../Shared/Buttons/CheckBoxButton.type';
import AddressesStore from '../../../Stores/AddressesStore';
import { IAddress } from '../../../Interfaces/AddressesInterface';
import { ReactStripeElements, injectStripe, CardNumberElement, CardCVCElement, CardExpiryElement } from 'react-stripe-elements';
import PaymentStore from '../../../Stores/PaymentStore';
interface IProps extends ReactStripeElements.InjectedStripeProps {
  ref: RefObject<any>;
  nextStep: () => void;
  confirmBooking: () => void;
  onChangeContact: (name: string, value: string | boolean) => void;
  onChangeCredit: (name: string, value: string | number) => void;
  value: IPaymentContact;
  credit_value: ICreditCard;
  // buttonValue: string;
  // goNext?: () => void;
  error?: string;
  showError: (error?: string) => void;
}

@observer
class ContactInformation extends Component<IProps> {
  address: IAddress = AddressesStore.addressBillingObject; 
  state = {
    differentAddress: false,
    location: {},    
    card_type: '',
    isEmptyFirstName: false,
    isEmptyLastName: false,
    isEmptyEmail: false,
    isEmptyPhone: false
  };

  changeContactData = (
    event: FormEvent<
      ReplaceProps<
        ElementType<any>,
        BsPrefixProps<ElementType<any>> & FormControlProps
      >
    >
  ) => {
    const { id, value = '' } = event.currentTarget;
    if (id) {
      this.props.onChangeContact(id, value);
    if (id === "first_name" && value != ""){
        this.setState({isEmptyFirstName: false})
    }
    if (id === "last_name" && value != ""){
        this.setState({isEmptyLastName: false})
    }
    if (id === "email" && value != ""){
      this.setState({isEmptyEmail: false})
    }
    if (id === "phone" && value != ""){
      this.setState({isEmptyPhone: false})
  }
    }
  };

  continue = async () => {
    const {first_name, last_name, email, phone} = this.props.value

    var isValid = true;
    if (!first_name){
      this.setState({isEmptyFirstName : true})
      isValid = false
    }
    if (!last_name){
      this.setState({isEmptyLastName : true})
      isValid = false
    }
    if (!email){
      this.setState({isEmptyEmail : true})
      isValid = false
    }
    if (!phone){
      this.setState({isEmptyPhone : true})
      isValid = false
    }

    if (isValid){
      this.props.confirmBooking()
    }
  }

  handleChangeLocation = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value = '' } = event.target;
    if (id) {
      await AddressesStore.changeBillingAddressObject(id, value);
      await this.props.onChangeContact('address', this.address.address);
    }

  }

  handleChangeCard = (event: React.ChangeEvent<HTMLInputElement>) =>{
    // this.props.onChangeCredit('card_number', event.target.value);
    const rawValue = event.target.value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    let formattedValue = '';

    for (let i = 0; i < rawValue.length; i++) {
        if (i > 0 && i % 4 === 0) {
            formattedValue += ' ';
        }
        formattedValue += rawValue[i];
    }

    this.props.onChangeCredit('card_number', formattedValue);
  }

  changeCreditData = (event: FormEvent<
    ReplaceProps<
      ElementType<any>,
      BsPrefixProps<ElementType<any>> & FormControlProps
    >
  >) => {
    const { id = '', value = '' } = event.currentTarget;
    switch(id) {
      case 'exp_date':
        const rawValue = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        let formattedValue = '';
        let flag = false;

        for (let i = 0; i < rawValue.length; i++) {
          switch (i) {
            case 0:
              if (parseInt(rawValue[0]) === 0) {
                break;
              }
              formattedValue += rawValue[i];
              if (parseInt(rawValue[0]) !== 1) {
                flag = true;
              }
              break;
            case 1:
              if (parseInt(rawValue[0]) === 1) {
                if (parseInt(rawValue[i]) <= 2) {
                  
                }
              } else {
                flag = true;
              }
              if (flag) {
                formattedValue += '/';
                formattedValue += rawValue[i];
                flag = false;
              } else {
                if (parseInt(rawValue[i]) <= 2) {
                  formattedValue += rawValue[i];
                  flag = true;
                } else {
                  formattedValue += '/';
                  formattedValue += rawValue[i];
                }
              }
              break;
            case 2:
              if (flag) {
                formattedValue += '/';
              }
              formattedValue += rawValue[i];
              break;
            case 3:
              if (flag) {
                formattedValue += rawValue[i];
              }
              break;
            default:
              break;
          }
        }
        this.props.onChangeCredit('exp_date', formattedValue);
        break;
      default:
        this.props.onChangeCredit(id, value);
        break;

    }
    // if (id) {
    //   this.props.onChangeCredit(id, value);
    // }
  };

  

  changeCheckBox = (checkboxs: CheckBoxItem[]) => {
    if(checkboxs[0] === undefined) {
      this.setState({ differentAddress: false });
    } else {
      this.setState({ differentAddress: true });
      const input = document.querySelector('#address') as HTMLInputElement;
      if (input) {
        AddressAutocomplete(input, AddressesStore.changeBillingAddressObject);
      }
    }
  }

  saveCard = async () => {
    const { first_name, last_name } = this.props.value;
    const billing_name = `${first_name ? first_name : ''} ${last_name ? last_name : ''}`;
    try {
      const token = this.props.stripe && await this.props.stripe.createToken({
        type: 'card',
        name: billing_name
      });
      if (token && token.token) {
        PaymentStore.changeToken(token);
      }
      if (token && token.error) {
        this.props.showError(token.error.code)
        return {
          error: token.error.code
        }
      }

    } catch (e) {
      return { error: e.message }
    }
  };
  // color: '#495057',
  // '::placeholder': {
  //   color: '#495057',
  // },
  createOptions = () => {
    return {
      style: {
        base: {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSmoothing: 'antialiased',
          '::placeholder': {
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            color: '#495057', // Just as an example; set to your desired color
          },
        },
      },
    };
  };

  render() {
    const { value, credit_value, error } = this.props;
    const { first_name, last_name, email, phone } = value;
    const { card_holder } = credit_value;
    const { address } = this.address;
    const checkboxButtons: CheckBoxItem[] = [
      {
        id: "different_billing_address",
        label: "Different Billing Address?",
        name: "diffent_billing_address",
        value: 1
      }
    ]

    const popoverTop = (
      <Popover id="popover-positioned-top" title="Popover top" style={{padding: '20px 30px'}}>
        <span style={{fontSize: 13, fontWeight: 500}}>We only use your number to send important updates about your booking, to make sure your experience is the best it can be!</span>
      </Popover>
    );

    return (
      <div className={'contact_information_form'} ref={this.props.ref}>
        <div className='title_container'>
          <p><strong>CONTACT INFORMATION</strong></p>
        </div>
        <div className='personal_information_form' >
          <Form>
            <Form.Control
              id='first_name'
              type={'text'}
              placeholder={'First name'}
              style={this.state.isEmptyFirstName ? { border: '1px solid red', padding: '0.1rem 2rem', height:'40px', marginTop:'40px' } : {padding: '0.1rem 2rem', height:'40px', marginTop:'40px'}}
              onChange={this.changeContactData}
              isValid={error ? !error : undefined}
              value={first_name}
              autoFocus
            />
            {this.state.isEmptyFirstName && (
                <p className="text-danger" id="text-danger" style={{fontSize: '12px', marginLeft: '20px', marginTop: '5px', fontWeight: 'normal', textAlign: 'left'}}>
                  {"Missing contact information"}
                </p>
              )}
            <Form.Control
              id='last_name'
              type={'text'}
              placeholder={'Last Name'}
              style={this.state.isEmptyLastName ? { border: '1px solid red', padding: '0.1rem 2rem', height:'40px', marginTop:'20px' } : {padding: '0.1rem 2rem', height:'40px', marginTop:'20px'}}
              onChange={this.changeContactData}
              isValid={error ? !error : undefined}
              value={last_name}
            />
            {this.state.isEmptyLastName && (
                <p className="text-danger" id="text-danger" style={{fontSize: '12px', marginLeft: '20px', marginTop: '5px', fontWeight: 'normal', textAlign: 'left'}}>
                  {"Missing contact information"}
                </p>
              )}
            <Form.Control
              id='email'
              type={'email'}
              placeholder={'Email'}
              style={this.state.isEmptyEmail ? { border: '1px solid red', padding: '0.1rem 2rem', height:'40px', marginTop:'20px' } : {padding: '0.1rem 2rem', height:'40px', marginTop:'20px'}}
              onChange={this.changeContactData}
              isValid={error ? !error : undefined}
              value={email}
            />
            {this.state.isEmptyEmail && (
                <p className="text-danger" id="text-danger" style={{fontSize: '12px', marginLeft: '20px', marginTop: '5px', fontWeight: 'normal', textAlign: 'left'}}>
                  {"Missing contact information"}
                </p>
              )}
            <Form.Control
              id='phone'
              type={'tel'}
              placeholder={'Phone number'}
              style={this.state.isEmptyPhone ? { border: '1px solid red', padding: '0.1rem 2rem', height:'40px', marginTop:'20px' } : {padding: '0.1rem 2rem', height:'40px', marginTop:'20px'}}
              onChange={this.changeContactData}
              isValid={error ? !error : undefined}
              value={phone}
            />
            {this.state.isEmptyPhone && (
                <p className="text-danger" id="text-danger" style={{fontSize: '12px', marginLeft: '20px', marginTop: '5px', fontWeight: 'normal', textAlign: 'left'}}>
                  {"Missing contact information"}
                </p>
              )}
            <div className="d-flex" style={{height: 15}}>
              <p style={{ marginTop: '8px', marginLeft: 33, fontSize: 12}}>Why do we need this?</p>
              <OverlayTrigger trigger="click" placement="top" overlay={popoverTop}>
                <Image
                  style={{width: 20, height: 20, marginTop: 8, marginLeft: 5 }}
                  src = {InfoIcon}
                  onMouseOver={e => e.currentTarget.src = InfoIconHover }
                  onMouseOut={e => e.currentTarget.src = InfoIcon} />
              </OverlayTrigger>

            </div>
            <div style={{ marginTop: 32}}>
              <CheckBoxButton
                checkboxs={checkboxButtons}
                handleChange={this.changeCheckBox}
              />
            </div>
            { this.state.differentAddress && 
              <div>
                <div style={{display:'flex', padding:'5px', marginTop: 20, height:40, border:'1px solid #E0E0E0'}}>
                  <img
                      src={require("../../../assets/icons/location2.svg")}
                      alt={'location'}
                      style={{width: 40}}
                  />
                  <input 
                    id='address'
                    className='custom_location_input'
                    style={{ fontSize: 16}}
                    placeholder='Address'
                    value={address}
                    onChange={this.handleChangeLocation}></input>                    
                </div>
                
                <Form.Group style={{ marginTop: 20}}                  
                >
                  <Form.Control
                    id='apartment'
                    type={'text'}
                    placeholder={'Apt, Unit, Room'}
                    style={error ? { border: '1px solid red', padding: '0.1rem 2rem', height:'40px' } : {padding: '0.1rem 2rem', height:'40px'}}
                    onChange={this.changeContactData}
                    isValid={error ? !error : undefined}
                  />
                </Form.Group>
              </div>
            }

          </Form>
        </div>
        <div className={'credit_card_form'}>
          <Form>
            <div style={{marginTop: '30px'}}>
              <p style={{textAlign: 'center', fontSize: 26}}><strong>PAYMENT</strong></p>
            </div>
            <div style={{ paddingLeft: 30, paddingRight: 30}}>
              <Form.Group controlId="card_number" style={{paddingBottom: '8px'}}>
                <Form.Group as={Row}  controlId="card_type" style={{paddingBottom: '8px'}}>
                  <Col xs={10}>
                    <Form.Label style={{fontSize: 12, marginLeft: 10, marginBottom: 0, paddingTop: 6}}>
                      CARD NUMBER
                    </Form.Label>
                  </Col>
                  {this.state.card_type === 'visa' && (
                    <Col xs={1}>
                    <img
                      src={require("../../../assets/icons/visa.svg")}
                      alt={'location'}
                    />
                    </Col>
                  )}
                  {this.state.card_type === 'mastercard' && (
                    <Col xs={1}>
                      <img
                        src={require("../../../assets/icons/mastercard.svg")}
                        alt={'location'}
                      />
                    </Col>
                  )}
                  {this.state.card_type === 'amex' && (
                    <Col xs={1}>
                      <img
                        src={require("../../../assets/icons/amex.svg")}
                        alt={'location'}
                      />
                    </Col>
                  )}
                </Form.Group>                
                <div style={{display:'flex', height:40, border:'1px solid #E0E0E0'}}>
                  <CardNumberElement 
                    onChange={(e: ReactStripeElements.ElementChangeResponse) => {
                      console.log(e);
                      if (e.brand) {
                        if (e.brand !== this.state.card_type) {
                          this.setState({
                            card_type: e.brand
                          });
                        }
                      }

                      PaymentStore.cardEmptyInfo['card_number'] = e.empty
                    }}
                    className='form-control'
                    classes={{ base: "login_input_new", empty: "error" }}                    
                    {...this.createOptions()}
                  />
                  <img
                    src={require("../../../assets/icons/lock.svg")}
                    alt={'location'}
                    style={{width: 40}}
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label style={{fontSize: 12, marginLeft: 10}}>
                  NAME ON CARD
                </Form.Label>
                <Form.Control
                  id='card_holder'
                  value={card_holder}
                  type="text"
                  onChange={this.changeCreditData}  
                  placeholder="Name on Card" />                
              </Form.Group>
              <Form.Group as={Row}  style={{fontSize: 16, marginBottom: 20}}>
                <Col sm="6">
                  <Form.Group  style={{fontSize: 16}}>
                    <Form.Label style={{fontSize: 12, marginLeft: 10}}>
                      EXPIRATION DATE
                    </Form.Label>
                    <CardExpiryElement 
                      onChange={(e: ReactStripeElements.ElementChangeResponse) =>{
                        console.log(e)   
                        PaymentStore.cardEmptyInfo['exp_date'] = e.empty
                      }}                      
                      classes={{ base: "login_input_new", empty: "error" }}
                      placeholder='12 / 12'
                      {...this.createOptions()}
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group  style={{fontSize: 16}}>
                    <Form.Label style={{fontSize: 12, marginLeft: 10}}>
                      SECURITY CODE
                    </Form.Label>
                    <CardCVCElement 
                      placeholder='121'
                      onChange={(e: ReactStripeElements.ElementChangeResponse) => {
                          console.log(e)                    
                          PaymentStore.cardEmptyInfo['cvc'] = e.empty
                        }    
                      }
                      classes={{ base: "login_input_new", empty: "error" }}                      
                      {...this.createOptions()}
                    />
                  </Form.Group>
                </Col>
              </Form.Group>
            </div>
          </Form>

        </div>
        <div className={'custom_button_container'} style={{paddingTop: '0px'}}>
          <Button
            className={`custom_yellow_button`}
            style={{height:'40px', fontSize: '16px', fontWeight: 700}}
            onClick={this.continue}
          >
            CONFIRM BOOKING
          </Button>
        </div>
      </div>
    );
  }
}

export default injectStripe(ContactInformation, { withRef: true });