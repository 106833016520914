import React, {Component} from 'react';
import {Link} from "react-router-dom";

interface IProps {
    active: number;
}

class SubNavbar extends Component <IProps> {
    parent: React.RefObject<any> = React.createRef();

    componentDidMount(): void {
        this.parent.current.children[this.props.active].classList.add('active');
    }

    render() {
        return (
          <div id={"sub_navbar"} ref={this.parent}>
            <Link to={"/profile_settings"} className={"sub_navbar_link"}>
              Profile settings
            </Link>
            <Link to={"/password_settings"} className={"sub_navbar_link"}>
              password
            </Link>
            <Link to={"/payment_settings"} className={"sub_navbar_link"}>
              payments
            </Link>
            <Link to={"/addresses_settings"} className={"sub_navbar_link"}>
              addresses
            </Link>
            <Link to={"/subscription_settings"} className={"sub_navbar_link"}>
              membership
            </Link>
            <Link to={"/referral"} className={"sub_navbar_link"}>
              referral
            </Link>
            <a
              href={"https://sprayvalet.com/qa/"}
              className={"sub_navbar_link"}
            >
              {`Q&A`}
            </a>
            <Link
              to={"/booking_done_step1?redirect_url=profile_settings"}
              className={"sub_navbar_link"}
            >
              {`Pre & Post Care`}
            </Link>
          </div>
        );
    }
}

export default SubNavbar;