import React, { Component } from "react";
import arrow_left from "../assets/icons/arrow_left.svg";
import { RouteComponentProps, withRouter } from "react-router";
import StepBar from "./StepBar";
import BookingReserved from '../Pages/Booking/PaymentDetails/BookingReserved';
import "./CustomSteps.css";
import step_check_icon from "./../assets/icons/step_check.svg"
interface IProps extends RouteComponentProps {
  steps: string;
  active: number;
  done?: number[];
  onBack?: () => void;
}

interface IStepsTypes {
  [key: string]: string[];
}

class CustomStepsComponent extends Component<IProps> {
  steps: IStepsTypes = {
    bookingSteps: [
      "location",
      "booking",
      "checkout"
    ]
  };

  handleClick = (step: string) => {
    switch(step) {
      case 'location':
        this.props.history.push('/');
        break;
      case 'booking':
        this.props.history.push('/booking');
        break;
      default:
    }
  }

  render() {
    const { steps, active, done = [], onBack } = this.props;
    return (
      <div className="guest_booking_steps_component justify-content-center">
        <StepBar totalStep={3} currentStep={active + 1}/>
        { active == 2 ? (
          <div className='d-flex justify-content-center' style={{marginTop: 34}}>
            <BookingReserved />
          </div>) : <></>}
        <div
          className="guest_booking_steps_info_container d-flex justify-content-center align-items-center" style={{marginTop:'20px'}}
        >
          {/* {onBack && (
            <div className="back_button d-flex flex-nowrap" onClick={onBack}>
              <img src={arrow_left} alt={"arrow_left"} />
              Back
            </div>
          )} */}
          {this.steps[steps].map((step: string, index: number) => {
            return (
              <div
                className={
                  "step_container"
                }
                key={step}
              >
                <div className="step_button" onClick={e => done.includes(index) ? this.handleClick(step) : null}>
                  {done.includes(index) ? (
                    <img src={step_check_icon}/>
                  ) : (
                    <div className="step_info_header">
                      {`STEP ${index + 1}`}
                    </div>
                  )}

                  {active === index ? (
                    <div className="step_info_active">
                      {step}
                    </div>
                  ) : (
                    <div className="step_info">
                      {step}
                    </div>
                  )}

                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(CustomStepsComponent);
