import { action, observable } from 'mobx';
import StorageService from '../Services/StorageService';
import { IClientData, ISignData } from '../Interfaces/RegistrationInterface';
import React from 'react';
import Validation from '../Utils/Validation';
import { defaultJwt, IErrorsObject, IJwt } from '../Interfaces/OtherInterfaces';
import { IAvatar, defaultProfile } from '../Interfaces/ProfileInterfaces';
import AuthService from '../Services/AuthService';
import ProfileStore from './ProfileStore';
import AddressesStore from './AddressesStore';
import PaymentStore from './PaymentStore';
import mixpanel from 'mixpanel-browser';
import moment, { Moment } from 'moment';

const defaultSignIn: ISignData = {
  email: '',
  phone: '',
  password: '',
};

class AuthStore {
  @observable isLogged: boolean = false;

  @observable errorsAfterRequest: string = '';

  @observable ready: boolean = false;

  @observable jwt: IJwt = defaultJwt;

  @observable isLoading: boolean = false;

  @observable signInData: ISignData = {
    ...defaultSignIn,
  };

  @observable inlineErrors: IErrorsObject = {};

  @observable isSignInSuccess: boolean = false;
  @observable guestLoginTime: Moment = moment();

  constructor() {
    StorageService.getItem('jwt')
      .then((jwt) => {
        this.isLogged = jwt && jwt.token && jwt.isAuth;
        this.ready = true;
        if (jwt && jwt.isAuth) {
          ProfileStore.initApp();
          this.setJWT(jwt.token, jwt.isAuth);
        }
      })
      .catch(async (e: any) => {
        this.isLogged = false;
        await this.setJWT(defaultJwt.token);
        this.ready = true;
      });
  }

  @action changeSignInData = (key: string, value: string | IAvatar) => {
    if (key === 'phone') {
      value = value.toString().replace(/[^0-9.]/g, '');
    }
    return (this.signInData[key] = value);
  };

  @action
  login = async (email: string, password: string) => {
    try {
      this.isLoading = true;
      this.isLogged = false;
      const resp: any = await AuthService.postLogin({ email, password });
      await this.setJWT(resp.key);
      this.isLogged = true;
      ProfileStore.initApp();
    } catch (e) {
      console.log(e);
      this.inlineErrors.formError = e.message.detail;
      this.isLogged = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action signIn = async () => {
    try {
      this.isLoading = true;
      this.isSignInSuccess = false;
      const signInData = {
        email: this.signInData.email,
        phone: this.signInData.phone,
        password1: this.signInData.password,
        user_type: 2,
      };
      const response: any = await AuthService.signIn(signInData);
      mixpanel.track('Register', {
        email: this.signInData.email,
        phone: this.signInData.phone,
      });
      mixpanel.people.set({
        email: this.signInData.email,
        'Sign up date': moment().format('YYYY-MM-DD'),
        USER_ID: this.signInData.email,
      });
      if (!response) return null;
      await this.setJWT(response.key);
      await ProfileStore.getProfileData();
      this.isLogged = true;
    } catch (e) {
      console.log(e);
      this.errorsAfterRequest = e.message
        ? e.message.join()
        : 'Internal Server Error';
      this.isLogged = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action addClientInDataBase = async (data: IClientData) => {
    try {
      this.isLoading = true;
      const authData = {
        email: data.email,
        phone: data.phone,
        user_type: 2,
      };
      StorageService.clear();
      AddressesStore.clear();
      PaymentStore.clear();
      const response: any = await AuthService.signIn(authData);
      await this.setJWT(response.key, false);
      await ProfileStore.getProfileData();
      await ProfileStore.saveProfileData({
        first_name: data.first_name,
        last_name: data.last_name,
      });
    } catch (e) {
      console.dir(e);
      throw new Error(e.message.join());
    } finally {
      this.isLoading = false;
    }
  };

  @action guestLogin = async () => {
    try {
      this.isLoading = true;
      const response: any = await AuthService.guestLogin();
      this.guestLoginTime = moment();
      await this.setJWT(response.token, false);
      await ProfileStore.getProfileData();
    } catch (e) {
      console.dir(e);
      throw new Error(e.message.join());
    } finally {
      this.isLoading = false;
    }
  }

  @action
  setJWT = async (data: string, isAuth: boolean = true) => {
    this.jwt = { token: data, isAuth: isAuth };
    await StorageService.setItem('jwt', this.jwt);
  };

  @action
  async googleLogin(code: any) {
    try {
      const resp: any = await AuthService.googleLogin(code);
      await this.setJWT(resp.token);
      const profile: any = await ProfileStore.getProfileData();
      await StorageService.setItem('id', profile.id);
      if (resp.is_new) {
        this.signInData = {
          email: profile.email,
          phone: this.signInData.phone,
          password: '1111111111111111',
          user_type: 's2',
        };
        window.location.href = `/signIn?afterSocial=${profile.email}`;
      } else {
        window.location.href = `/`;
      }
    } catch (error) {
      this.errorsAfterRequest = error.message.detail
        ? error.message.detail
        : 'Internal Server Error';
    }
  }
  async faceBookLogin(code: any) {
    try {
      const resp: any = await AuthService.facebookLogin({
        provider: 'facebook',
        access_token: code,
        user_type: 2,
      });
      await this.setJWT(resp.token);
      const profile: any = await ProfileStore.getProfileData();
      await StorageService.setItem('id', profile.id);
      if (resp.is_new) {
        this.signInData = {
          email: profile.email,
          phone: this.signInData.phone,
          password: '1111111111111111',
          user_type: 's2',
        };
        window.location.href = `/signIn?afterSocial=${profile.email}`;
      } else {
        window.location.href = `/`;
      }
    } catch (error) {
      this.errorsAfterRequest = error.message.detail
        ? error.message.detail
        : 'Internal Server Error';
    }
  }
  @action inputFieldsValidation = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (name && value) {
      return (this.inlineErrors[name] = !Validation[name](value));
    }
  };

  @action phoneValidation = (name: string, value: string) =>
    (this.inlineErrors[name] = !Validation[name](value));

  @action
  clean = async () => {
    try {
      this.signInData = defaultSignIn;
      await this.setJWT(defaultJwt.token);
      ProfileStore.profile = defaultProfile;
      await AuthService.logOut();
      this.isLogged = false;
      localStorage.clear();
      StorageService.clear();
      AddressesStore.clear();
      PaymentStore.clear();
      window.location.href = '/';
      return;
    } catch (error) {
      throw new Error(error.message.join());
    }
  };
}

export default new AuthStore();
