import moment, { Moment } from "moment-timezone";

class Helper {
  static FromLANowDate = (date: Moment | Date | string| null) => {
    const nowInLa = moment.tz("America/Los_Angeles").format();
    const pastDate = moment.tz(date, "America/Los_Angeles");
    return pastDate.isValid() ? pastDate.from(nowInLa) : "";
  };
}
export default Helper;
