import React from 'react';

interface Iprops {
    day: string;
    weekDay: string
}

const DayCard = ({day, weekDay}: Iprops) => (
  <div>
    <div className={"day"}>{day}</div>
    <div className={"weekDay"}>{weekDay}</div>
  </div>
);

export default DayCard;