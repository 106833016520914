import http from './http';
import { IAddress } from '../Interfaces/AddressesInterface';
import { ICreditCardAfterSave } from '../Interfaces/CreditCardsInterfaces';

interface IParams{
  [key:string]:string|number|boolean;
}

class ProfileService {
  public static getLocations = (city: string = "") => {
    if (city !== ""){
      let params: IParams = {};
      params = {"city": city}
      return http.get(`/address/`, { params })
    }
    return http.get(`/address/`);
  };

  public static saveAddressData = (addressObject: IAddress) => {
    return http.post(`/address/`, { ...addressObject });
  };

  public static checkAddressData = (addressObject: IAddress) => {
    return http.post(`/check-address/`, { ...addressObject });
  };

  public static editAddressData = (addressObject: IAddress) => {
    return http.put(`/address/${addressObject.id}/`, { ...addressObject });
  };

  public static deleteAddressData = (addressObjectId: number) => {
    return http.delete(`/address/${addressObjectId}/`);
  };

  public static getCreditCards = () => {
    return http.get(`/payments/`);
  };

  public static saveCreditCard = (payment_info: any) => {
    return http.post(`/payments/`, { ...payment_info });
  };

  public static editCreditCard = (payment_info: any) => {
    return http.put(`/payments/${payment_info.id}/`, { ...payment_info });
  };

  // public static editCreditCard = (payment_info: any) => {
  //   return http.put(`/payments/`, { ...payment_info });
  // };

  public static deleteCreditCard = (cc_id: number) => {
    return http.delete(`/payments/${cc_id}/`);
  };

  public static getUserData = () => {
    return http.get('/me/');
  };

  public static changePassword = (
    id: number,
    old_password: string,
    new_password: string
  ) => {
    return http.post(`/clients/${id}/change-password/`, {
      old_password,
      new_password,
    });
  };

  public static editProfileData = (id: number, profile: any) => {
    return http.patch(`/clients/${id}/`, profile);
  };

  public static getSubscriptions = (id?: number) => {
    return http.get(`/info/subscriptions/${id ? id + '/' : ''}`);
  };

  public static changeSubscription = (id: number, subscriptionId: number[]) => {
    return http.patch(`/clients/${id}/`, {
      client_subscriptions: subscriptionId,
    });
  };

  public static cancelSubscription = (id: number) => {
    return http.delete(`/subscriptions/${id}/`);
  };
  public static addSubscription = (data: {
    client: number;
    subscription: number | null | undefined;
    payment: number;
  }) => {
    return http.post(`/subscriptions/`, data);
  };
  public static updateSubscription = (
    subID: number | null,
    data: {
      client: number | undefined | null;
      subscription: number | null | undefined;
      payment: number;
    }
  ) => {
    return http.put(`/subscriptions/${subID}/`, data);
  };

  public static uploadAvatar(file: FormData) {
    return http.post('/upload-avatar/', file);
  }

  public static sendReferral(data: { phone: string }) {
    return http.post('/send-referral/', data);
  }
}

export default ProfileService;
