import React, { Component } from "react";
import "./DropDown.css";
import { OptionItem } from "./DropDown.type";

interface IProps {
	id: string;
	additionClassName?: string;
	label?: string;
	placeholder?: string;
	value?: string | number;
	options: OptionItem[];
	imgSrc?: any;
	handleChange: (option: OptionItem) => void;
	prefilledValue?: string;
	isNight?: boolean;
}

interface IState {
	hasClass: boolean;
}

export default class DropDown extends Component<IProps, IState> {
	node: HTMLElement | null = null;
	constructor(props: IProps) {
        super(props);
		let temp_value: string | number = props.value ? props.value : -1;
		let temp_label: string | number = props.placeholder || '';
		let temp_isIcon: boolean = false;
		const temp_options: OptionItem[] = props.options || [];
		if (temp_value !== -1) {
			for (const row of temp_options) {
				if (row.value === temp_value) {
					temp_label = row.label;
					temp_isIcon = row.isIcon || false;
				}
			}
		}

        this.state = {
			hasClass: false
        };
    }

	componentDidMount() {
		document.addEventListener('mousedown', this.handleDocumentClick);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleDocumentClick);
	}
	
	handleClick = () => {
		this.setState(prevState => ({ hasClass: !prevState.hasClass }));
	}

	setNodeRef = (ref: HTMLElement | null) => {
		this.node = ref;
	};
	

	handleDocumentClick = (event: MouseEvent) => {
		if (this.node && !this.node.contains(event.target as Node)) {
			if (this.state.hasClass) {
				this.setState({ hasClass: false });
			}
		}
	}

	handleSelect = (option: OptionItem) => {
		this.setState({
			hasClass: false
		});
		if (this.props.value !== option.value) {
			this.props.handleChange(option);
		}
	}

	render() {
		const { id, additionClassName = '', label, options, isNight, imgSrc = null, value, placeholder = '', prefilledValue } = this.props;
		let temp_label: string | number = placeholder;
		let temp_isIcon;
		for (const option of options) {
			if (option.value === value) {
				temp_label = option.label;
				temp_isIcon = option.isIcon || false;
			}
		}

		return (
			<div className={`drop-down ${additionClassName} ${this.state.hasClass ? 'active' : ''}`}  ref={this.setNodeRef} >
				{label && (<label htmlFor={id}>{label}</label>)}
				<div id={id} className="selected-item" onClick={this.handleClick}>
					{/* {this.state.label} */}
					{prefilledValue ? prefilledValue : temp_label}
					{imgSrc && (temp_isIcon || isNight) && (
						<img src={imgSrc} />
					)}
					<i className="fas fa-angle-down" />
					<i className="fas fa-angle-up" />
				</div>
				<div className="drop-down-menu">
					{options.map((option: OptionItem, index) => {
						return (
							<div
								key={index}
								className={`drop-down-menu-item ${value === option.value ? 'selected' : ''}`}
								onClick={() => this.handleSelect(option)}
							>
								{option.label}
								{imgSrc && option.isIcon && (
									<img src={imgSrc} alt="" />
								)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}