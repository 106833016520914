import React, { Component } from 'react';
import SubNavbar from '../../../Shared/Navbars/SubNavbar';
import arrow_left from '../../../assets/icons/arrow_left.svg';
import { Redirect, RouterProps } from 'react-router';
import LocationForm from '../../../Components/LocationForm';
import AddressesStore from '../../../Stores/AddressesStore';
import { observer } from 'mobx-react';
import AuthStore from '../../../Stores/AuthStore';

@observer
export default class AddNewAddress extends Component<RouterProps> {
  state = { e: '' };
  saveAddress = async () => {
    this.setState({ e: '' });
    try {
      await AddressesStore.saveAddressObjectData();
      if (Object.values(AddressesStore.modals).includes(true)) {
        return;
      }
      this.goBack();
    } catch (e) {
      this.setState({ e: e.message });
      const errorCont = document.getElementById('text-danger');
      if (errorCont) {
        errorCont.scrollIntoView();
      }
    }
  };
  goBack = () => this.props.history.goBack();

  componentDidMount(): any {
    if (!AuthStore.isLogged) return <Redirect to={'/'} />;
  }

  render() {
    return (
      <div className={'settings_container'}>
        <SubNavbar active={3} />
        <div className={'add_address_container'}>
          <div
            className="back_button"
            onClick={() => this.props.history.goBack()}
          >
            <img src={arrow_left} alt={'arrow_left'} /> Back
          </div>
          <LocationForm
            nextStep={this.saveAddress}
            hotelClick={() =>
              this.props.history.push('/settings_hotel_information')
            }
            buttonValue={'save'}
            value={AddressesStore.addressObject}
            onChange={AddressesStore.changeAddressObject}
            goNext={this.goBack}
            error={this.state.e}
          />
        </div>
      </div>
    );
  }
}
