import React, { Component } from 'react';
import DateFormatting from '../../../Utils/DateFormatting';
import ToolbarButton from '../ToolbarButton';
import MessagesStore from '../../../Stores/MessagesStore';
import { defaultLocationInfo } from '../../../Interfaces/AddressesInterface';
import { IApiProfile } from '../../../Interfaces/ProfileInterfaces';
import Helper from '../../../Utils/Helper';
import Popup from '../../Popups/Popup';

export default class Toolbar extends Component {
  state = {
    showModal: false,
  };
  render() {
    const valet: IApiProfile =
      MessagesStore.activeConversation.chatting_with ||
      MessagesStore.activeConversation.appointment.valet;
    const { address, date, status } = MessagesStore.activeConversation.appointment;
    const { chatting_with_online, time_online, is_active } = MessagesStore.activeConversation;
    const last_activity = Helper.FromLANowDate(time_online);
    const isUpcomingAndNotActive = (status === 'Upcoming' || status === 'Pending') && !is_active;
    return (
      <div className="toolbar">
        <Popup
          title={''}
          content={'You can contact the valet 2 hours from your appointment'}
          id={`modal2Hours`}
          onClick={() =>
            this.setState({
              showModal: false,
            })
          }
          onHide={() =>
            this.setState({
              showModal: false,
            })
          }
          show={this.state.showModal}
        />
        <div className="left-items">
          <div key={'ffff'}>{valet.first_name}</div>
          {chatting_with_online ? (
            <div className="d-flex flex-row">
              <span className="activity-dot"></span>
              <p>Online</p>
            </div>
          ) : (
            <p className="last-activity">
              {time_online && last_activity ? `last seen ${last_activity}` : null}
            </p>
          )}
        </div>
        <div className="right-items">
          <ToolbarButton
            key="phone"
            icon="fas fa-phone"
            onClick={() => {
              if (isUpcomingAndNotActive) {
                this.setState({
                  showModal: true,
                });
              }
            }}
            phone={isUpcomingAndNotActive ? undefined : valet.phone}
          />
        </div>
        <div className={'order-info'}>
          <div className="order-item">
            <img src={require('../../../assets/icons/clock.svg')} width={35} alt={'clock'} />
            <div className={'order-info-text'}>{DateFormatting.time(date) || null}</div>
          </div>
          <div className="order-item">
            <img src={require('../../../assets/icons/calendar.svg')} width={35} alt={'calendar'} />
            <div className={'order-info-text'}>{DateFormatting.usFormat(date) || null}</div>
          </div>
          <div className="order-item">
            <img src={require('../../../assets/icons/map_mark.svg')} width={35} alt={'map mark'} />
            <div className={'order-info-text address'}>
              {address ? address.address : defaultLocationInfo.address}
            </div>
          </div>
        </div>
        {isUpcomingAndNotActive && (
          <div className="d-flex justify-content-center w-100 border-bottom p-2 text-muted">
            <span>You can message 2 hours prior to the appointment.</span>
          </div>
        )}
      </div>
    );
  }
}
