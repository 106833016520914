import React from "react";
import { Route } from "react-router-dom";
import BookingDetails from "../Pages/Booking/BookingDetails/BookingDetails";
import BookingDone1 from "../Pages/Booking/BookingDone/BookingDone1";
import BookingDone2 from "../Pages/Booking/BookingDone/BookingDone2";
import LeavingAFeedback from "../Pages/FeedBack/LeavingAFeedback";
import PaymentDetails from "../Pages/Booking/PaymentDetails/PaymentDetails";
import PersonalInformation from "../Pages/Booking/PersonalInformation";
import LogIn from "../Pages/Registration/LogIn";
import SignInLocationInfo from "../Pages/Registration/signIn/SignInLocationInfo";
import SignInClientInfo from "../Pages/Registration/signIn/SignInClientInfo";
import SignIn from "../Pages/Registration/signIn/SignIn";
import PasswordRecovery from "../Pages/Registration/PasswordRecovery";
import ResetPassword from "../Pages/Registration/ResetPassword";
import HotelInformationForBooking from "../Pages/Booking/Location/HotelInformation";
import LocationInformation from "../Pages/Booking/Location/LocationInformation";
import SignInAfterBooking from '../Pages/Booking/SignInAfterBooking';
import MyBooking from "../Pages/MyBooking/MyBooking";

import CustomBookingDetails from "../Pages/Booking/BookingDetails/CustomBookingDetails";
import PaymentDetailsBookingPage from "../Pages/Booking/PaymentDetails/PaymentDetailsBookingPage";
import ThankYouPage from "../Pages/Booking/BookingDone/ThankYou";
import SeeYouPage from "../Pages/Booking/BookingDone/SeeYouSoon";
import Leave from "../Pages/Booking/Leave";
import Location from "../Pages/Booking/Location/Location";

const AuthRouter = () => {
    return (
      <>
        <Route path={"/my_booking"} component={MyBooking} />
        <Route path={"/booking_address"} component={LocationInformation} />
        <Route path={"/add_location"} component={LocationInformation} />
        <Route path={"/booking_done_step1"} component={BookingDone1} />
        <Route path={"/booking_done_step2"} component={BookingDone2} />
        <Route path={"/booking_valet"} component={BookingDetails} />
        <Route
          path={"/hotel_information"}
          component={HotelInformationForBooking}
        />
        <Route path={"/payment_details"} component={PaymentDetails} />
        <Route path={"/leaving_a_feedback"} component={LeavingAFeedback} />
        <Route path={"/personal_information"} component={PersonalInformation} />
        <Route path={"/logIn"} component={LogIn} />
        <Route path={"/sign_location_info"} component={SignInLocationInfo} />

        <Route path={"/sign_client_info"} component={SignInClientInfo} />
        <Route path={"/signIn"} component={SignIn} />
        <Route path={"/password_recovery_success"} component={ResetPassword} />
        <Route path={"/password_recovery"} component={PasswordRecovery} />

        <Route path={"/location_information"} component={LocationInformation} exact />
        <Route
          path="/additional_booking_signin"
          component={SignInAfterBooking}
        />
        {/* new route for payment details by james */}
        <Route path={"/payment_details_booking"} component={PaymentDetailsBookingPage} />
        <Route path={"/thank_you"} component={ThankYouPage} />
        <Route path={"/see_you_soon"} component={SeeYouPage} />
        <Route path={"/"} component={Location} exact />
        <Route path={"/booking"} component={CustomBookingDetails} exact />
        <Route path={"/leave"} component={Leave}/>

      </>
    );
}

export default AuthRouter;