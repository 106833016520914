import React, { Component } from 'react';
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import MessagesStore from "../../../Stores/MessagesStore";
import {IConversation} from "../../../Interfaces/MessagesInterfaces";
import {observer} from "mobx-react";

@observer
export default class ConversationList extends Component {
  render() {
    return (
      <div className="conversation-list">
        <ConversationSearch />
           
        { MessagesStore.conversationsList!.length ?
        MessagesStore.conversationsList!.map((conversation:IConversation) =>
          <ConversationListItem data={conversation} key={conversation.id}/>):
          <div className="d-flex justify-content-center">You don`t have any chats</div>
        }
      </div>
    );
  }
}