import React from 'react';
import { ISubscription } from '../../../Interfaces/ProfileInterfaces';
import { CheckMarkIcon } from '../../../Utils/CheckMarkIcon';

interface IProps {
  subscription: ISubscription;
  onClick?: (id: number) => void;
  active?: boolean | null | undefined;
  advantagesOnly?: boolean;
}

const DetailedSubscription: React.FC<IProps> = ({ onClick, active, subscription, advantagesOnly = false }) => {
  const advantages = [
    <span>{subscription.appointments_left} spray tans a month.</span>,
    <span>
      ${subscription.total_price}/month will be charged monthly, bring your spray tans to &nbsp;
      <b>${parseFloat(subscription.total_price) / subscription.appointments_left}</b> a tan!
    </span>,
    <span>
      Cancel <b>anytime</b> after 2 months.
    </span>,
    <span>
      All unused sprays are valid for <b>60 days</b>.
    </span>,
    <span>
      Tans can be used during <b>late-night</b> hours but you will be charged the difference.
    </span>,
    <span>
      <b>20% tip</b> will be charged when each tan is booked.
    </span>,
    <span>If you are out of our area a fee could apply.</span>,
    <span>Membership tans cannot be used for hotel locations.</span>,
  ];

  if (advantagesOnly) {
    advantages.shift();
  }

  return (
    <div
      className={`detailed-subscription-container ${active && 'active'}`}
      onClick={() => {
        if (onClick) onClick(subscription.id);
      }}
    >
      {!advantagesOnly && (
        <>
          <h6 className={'detailed-subscription-title'}>{subscription.subscription_type}</h6>
          <p className={'subscription-price-container'}>
            <b>
              $<span className={'subscription-price'}>{subscription.total_price}</span>
            </b>
            / month
          </p>
        </>
      )}
      <ul className={'detailed-subscription-advantages'}>
        {advantages.map((adv, index) => (
          <li key={index}>
            <CheckMarkIcon fill={active ? '#FFF' : '#D7BD35'}></CheckMarkIcon>
            {adv}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DetailedSubscription;