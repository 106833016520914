import React, { Component } from "react";
import MyBookingNavbar from "./MyBookingNavbar";
import Table from "react-bootstrap/Table";
import MyBookingItem from "./MyBookongItem";
import { observer } from "mobx-react";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import AuthStore from "../../Stores/AuthStore";
import MyBookingsStore from "../../Stores/MyBookingsStore";
import Popup from "../Popups/Popup";
import ModalStore from "../../Stores/ModalStore";

@observer
class MyBooking extends Component<RouteComponentProps> {
  goToBooking = (id:number) => {
    MyBookingsStore.editBooking(id);
    this.props.history.push("/booking_address");
  };

  rescheduleItem = async (id:number, valetId: number | null) => {
    if (valetId){
      this.props.history.push(`/booking_address?rescheduleItem=${id}&valetID=${valetId}`);
    }else{
      this.props.history.push(`/booking_address?rescheduleItem=${id}`);
    }
    
    await MyBookingsStore.startReschedule(id);
  };
  componentDidMount = () => {
    if (!AuthStore.isLogged){
      this.props.history.push("/login");
      return;
    }
    MyBookingsStore.getBookingsList(MyBookingsStore.activeStatusOfBooking);
  };

  render() {
    const {myBookings} = MyBookingsStore;
    return (
      <div className={"gray_background my_booking_container"}>
        <MyBookingNavbar />
        <div className={"container my_booking"}>
          <Table responsive={"md"} size={"sm"}>
            <thead>
              <tr>
                <th className={"valet_td"}>Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>Address</th>
                <th>billing type</th>
                <th>amount</th>
                <th>status</th>
                <th>contact</th>
                <th></th>
              </tr>
            </thead>
            {myBookings.length > 0 ? (
              <tbody>
                {MyBookingsStore.myBookings.map((data: any, i: number) => (
                  <MyBookingItem
                    data={data}
                    rescheduleItem={this.rescheduleItem}
                    disabled={MyBookingsStore.isLoading}
                    editBooking={this.goToBooking}
                    key={i}
                    history={this.props.history}
                  />
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={9} className={"text-center text-uppercase"}>
                    {" "}
                    You don’t have any appointments to show - book the first
                    one!
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
        <Popup
          title={"Error"}
          id={"myBookingsError"}
          content={MyBookingsStore.errorsOnComponent.modalError}
          show={ModalStore.myBookingsError}
          onHide={() => ModalStore.toggleModal("myBookingsError", false)}
        />
      </div>
    );
  }
}

export default withRouter(MyBooking);
