import React, { Component } from "react";
import SubNavbar from "../../../Shared/Navbars/SubNavbar";
import OutlineButton from "../../../Shared/Buttons/OutlineButton";
import { Redirect, RouterProps } from "react-router";
import AddressSettingsItem from "./AddressSettingsItem";
import AddressesStore from "../../../Stores/AddressesStore";
import { observer } from "mobx-react";
import AuthStore from "../../../Stores/AuthStore";
import {
  IAddress,
  defaultLocationInfo
} from "../../../Interfaces/AddressesInterface";

@observer
export default class AddressesSettings extends Component<RouterProps> {
  editItem = async (id: number) => {
    await AddressesStore.editAddress(id);
    this.props.history.push("/add_new_address");
  };

  deleteItem = (id: number) => AddressesStore.deleteAddress(id);

  componentDidMount(): any {
    if (!AuthStore.isLogged) return <Redirect to={"/"} />;
    // if (!AddressesStore.addressesSettings.length) {
      AddressesStore.getAddresses();
    // }
  }

  render() {
    const { addressesSettings, defaultAddressId } = AddressesStore;
    console.log(addressesSettings)
    
    return (
      <div className="settings_container">
        <SubNavbar active={3} />
        <div className={"container addresses_settings"}>
          <h1>Addresses</h1>
          {!addressesSettings.length ? (
            <div className="mb-5 mt-5 text-center">
              Add an address to start booking appointments
            </div>
          ) : (
            <>
              <h4>Address</h4>
              {addressesSettings.map((address: IAddress, i: number) => (
                <AddressSettingsItem
                  img={"fas fa-map-marker-alt"}
                  value={address}
                  defaultAddressId={defaultAddressId}
                  editItem={this.editItem}
                  deleteItem={this.deleteItem}
                  key={`${i}-${address.address}`}
                />
              ))}
            </>
          )}
          <OutlineButton
            value={"+ add new address"}
            onClick={() => {
              AddressesStore.addressObject = defaultLocationInfo;
              this.props.history.push("/add_new_address");
            }}
          />
        </div>
      </div>
    );
  }
}
