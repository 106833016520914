import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { observer } from 'mobx-react';
import TimeStore from '../../../Stores/TimeStore';
import moment from 'moment';

interface IProps {
  period: string;
  showModal: () => void;
}

interface IDayTimes {
  [key: string]: string[];
}

@observer
export default class TimeButtonsComponent extends Component<IProps> {
  bookingTime = (event: React.MouseEvent<HTMLButtonElement>, showPopup: boolean) =>
    TimeStore.reserveTime(event.currentTarget.innerText, showPopup);

  onSelect = (event: React.MouseEvent<HTMLButtonElement>, time: string) => {
    this.bookingTime(
      event,
      moment(time, 'hh:mm A').isBefore(moment('8:59 AM', 'hh:mm A')) ||
        moment(time, 'hh:mm A').isAfter(moment('8:59 PM', 'hh:mm A'))
    );
  };

  render() {
    const { period } = this.props;
    const { timeStructure } = TimeStore;
    const times = Object.keys(timeStructure).filter(
      (hour: string) => timeStructure[hour].period === period
    );

    var hasAvailableTime = false;

    times.map((time: string, i: number) => {
      if (timeStructure[time].available) hasAvailableTime = true;
    })

    if (!hasAvailableTime) {
      return (<></>)
    }else{
      return (
        <div className="time_buttons_container">
          <div className="time_button_title">
            {period}{' '}
            {/* {period.toLowerCase() === 'evening' && <i className={'fas fa-info-circle info'} />} */}
          </div>
          <div className="d-flex flex-wrap buttons-time">
            {times.map((time: string, i: number) => {
              const disabled = !timeStructure[time].available;
              const selected = timeStructure[time].reserved;
              const btnClass = selected ? 'time_button selected' : 'time_button';
              return (
                <Button
                  className={btnClass}
                  key={`${i}-${time}`}
                  type={'button'}
                  variant={'warning'}
                  style={{
                    position: 'relative',
                  }}
                  disabled={disabled}
                  onClick={(evt: React.MouseEvent<HTMLButtonElement>) => this.onSelect(evt, time)}
                >
                  {`${time}`}
                  {disabled && (
                    <div className="info-badge">
                      <span className="info-round">
                        <i className="fas fa-info"></i>
                      </span>
                      <span className="info-text">BOOKED</span>
                    </div>
                  )}
                  {!disabled && selected && (
                    <div className="info-badge">
                      <span className="info-round selected">
                        <i className="fas fa-info"></i>
                      </span>
                      <span className="info-text selected-text">SELECTED</span>
                    </div>
                  )}
                </Button>
              );
            })}
          </div>
        </div>
      );
    }
    
    
  }
}
