import React, { Component } from "react";
import { RouterProps } from "react-router";
import BookingStore from "../../Stores/BookingStore";
import YellowButton from "../../Shared/Buttons/YellowButton";
import ReactPixel from 'react-facebook-pixel';
import FacebookConversionApiService from '../../Services/FacebookConversionApiService';
import ProfileStore from "../../Stores/ProfileStore";
import CustomPopup from "../Popups/CustomPopup";
import { PopupContent } from "../../Constants/PopupContent";

interface IState {
  showAddPhoneWarning: boolean;
}

export default class DashBoard extends Component<RouterProps, IState> {
  state = {
    showAddPhoneWarning: false
  };

  bookTone = async (people: number) => {
    const { phone } = ProfileStore.profile;
    if (phone) {
      await BookingStore.createNewBooking(undefined, people);
      this.props.history.push("/booking_address");
    } else {
      this.setState({showAddPhoneWarning: true})
    }
  }

  handleSelectAValet = async() => {
    const { phone } = ProfileStore.profile;
    if (phone) {
      this.props.history.push("/select_valet");
    } else {
      this.setState({showAddPhoneWarning: true})
    }
  }

  onLinkClick = (e:React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    
    const href = e.currentTarget.getAttribute('href');
    ReactPixel.trackCustom('AppDownload', {link: href});

    const event_data = {
      data: {
        user_data: {
          client_user_agent: window.navigator.userAgent,
          fbp: ''
        },
        custom_data: {
          link: href
        }
      }
    };

    let fbpRes = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
    if ((fbpRes && fbpRes[1])) {
      event_data.data.user_data.fbp = fbpRes[1];
    }
    FacebookConversionApiService.trackAppDownload(event_data).then(() => {
      window.location.href = String(href);
    });
};

  render() {
    return (
      <div className="dashboard_container">
        <CustomPopup
          id={'addPhoneWarning'}
          content={PopupContent.add_phone_number}
          onClick={() => {
            this.setState({showAddPhoneWarning:false})
            this.props.history.push("/profile_settings")
          }}
          onHide={() => this.setState({showAddPhoneWarning: false})}
          show={this.state.showAddPhoneWarning}
          showConfirmation={true}
          leftButtonText={'Edit profile'}
          rightButtonText={'Cancel'}
        />
        <div className={"dashboard"}>
          <div className={"content_container"}>
            <div className="booking_components_container d-flex justify-content-center">
              <div className="booking_groups_container d-flex justify-content-center flex-sm-wrap">
                <div className="group_box">
                  <YellowButton
                    value="book spray tan"
                    onClick={async () => this.bookTone(1)}
                  />
                </div>
                {/* <div className="group_box">
                  <YellowButton
                    value="rebook valet"
                    onClick={async () =>
                      this.props.history.push("/rebook_valet")
                    }
                  />
                </div> */}

                <div className="group_box">
                  <YellowButton
                    value="select a valet"
                    onClick={async () => this.handleSelectAValet()}
                  />
                </div>

                {/* <div className="group_box">
                  <YellowButton
                    value="book group spray tan"
                    onClick={async () => this.bookTone(2)}
                  />
                </div> */}
              </div>
            </div>
            <div className="dashboard_list">
              <ul>
                <li>
                  <span>
                    <b>Book with a few simple taps in the SprayValet app.</b>{" "}
                    Just choose the time and location (24/7/365, anywhere you
                    want us to be). You can even select your favorite spray tan
                    technician right from the app. It's that easy!
                  </span>
                </li>
                <li>
                  <span>
                    <b>Next-level convenience.</b>Our highly trained,
                    professional spray tan stylists put you first. We'll meet
                    you where you are—your home, hotel, office—and have the
                    whole process over within about 20 minutes.
                  </span>
                </li>
                <li>
                  <span>
                    <b>We keep it natural and non-toxic.</b> Our signature spray
                    tan solution is 100% natural, organic, and paraben-free. Not
                    only that, but our experts are pros at finding the perfect
                    shade to suit your skin.
                  </span>
                </li>
                <li>
                  <span>
                    <b>We'll leave you glowing.</b> Our quick drying, non-sticky
                    tan will have you looking your best for 7 to 10 days. And if
                    that wasn't enough, our tan solution will leave you with a
                    soft floral scent and not like a toxic waste site.
                  </span>
                </li>
                <li>
                  <span>
                    <b>No need to fumble for your wallet.</b> Book, pay, tip,
                    and leave reviews all in the app. So there's no need for an
                    awkward payment conversation with your valet.
                  </span>
                </li>
              </ul>
              <div className="app_container">
                <div className={"text"}>
                  <b>Download the app for extra features!</b>
                  <br />
                  <small>
                    Download our app from App Store to
                    book appointments to enjoy all the benefits of our service.
                  </small>
                </div>
                <div style={{ width: "50%" }}>
                  <a
                    href={
                      "https://apps.apple.com/us/app/sprayvalet/id1485585423"
                    }
                    onClick={this.onLinkClick}
                  >
                    <img
                      style={{ width: "100%"}}
                      src={require("../../assets/App Store.svg")}
                      alt={"app store"}
                      width={500}
                    />
                  </a>
                  {/* <a
                    href={
                      "https://play.google.com/store/apps/details?id=com.sprayvalets"
                    }
                    onClick={this.onLinkClick}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={require("../../assets/gp.png")}
                      alt={"gp"}
                      width={500}
                    />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}