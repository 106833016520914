import React, { useEffect } from 'react';
import config from '../../../config';

interface Props {
  onLoad: () => void;
}

const RecaptchaScriptLoader: React.FC<Props> = ({ onLoad }) => {
  useEffect(() => {
    const badge = document.querySelector('.grecaptcha-badge') as HTMLElement;
    if (badge) {
        badge.style.display = 'block';
    }

    // Check if the reCAPTCHA script has already been loaded
    if (window.grecaptcha) {
      onLoad(); // Call the onLoad callback immediately since the script is already loaded
    } else {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${config.RECAPTCHA_SITE_KEY}`; // Replace with your actual API key
      script.async = true;

      script.onload = () => {
        // The reCAPTCHA script has been loaded
        onLoad();
      };

      document.body.appendChild(script);
    }

    return () => {
        const badge = document.querySelector('.grecaptcha-badge') as HTMLElement;
        if (badge) {
            badge.style.display = 'none';
        }
        const script = document.querySelector('[src^="https://www.google.com/recaptcha/api.js"]');
        if (script) {
            document.body.removeChild(script);
        }
    };
  }, [onLoad]);

  return null;
};

export default RecaptchaScriptLoader;