import React, { Component } from "react";
import "./CheckBoxButton.css";

interface IProps {
    id: string,
    name: string,
    text: string,
    value: string,
    checked?: boolean,
    onCheckedChange?: (id: string) => void,
    icon: any,
    iconActive: any,
}
export default class CheckBoxImgButton extends Component<IProps> {
    isChecked = false;

    componentDidUpdate(): void {
        const { id, checked } = this.props;
        const input = document.getElementById(`${ id }`) as HTMLInputElement;
        if (input && checked) {
            input.setAttribute("checked", "checked");
            this.isChecked = true;
        } else {
            input.checked = false;
            this.isChecked = false;
        }
    }
    componentDidMount(): void {
        const { id, checked } = this.props;
        const input = document.getElementById(`${ id }`) as HTMLInputElement;

        if (input && checked) {
            input.setAttribute("checked", "checked");
            this.isChecked = true;
        } else {
            input.checked = false;
            this.isChecked = false;
        }
    }

    handleChange = (e: any) => {
        const { id, checked } = this.props;
        const input = document.getElementById(`${ id }`) as HTMLInputElement;
        if (input) {
            input.checked = true;
        }
        this.isChecked = true;
        if (e.target.checked) {
            if (this.props.onCheckedChange) {
                this.props.onCheckedChange(this.props.id);
            }
        }
    }

    render() {
        const { id, name, text, value, icon, iconActive, checked } = this.props;
        return (
            <label htmlFor={ id } className="checkbox-img-btn" onClick={this.handleChange}>
                <input type="checkbox" id={ id } name={ name } value={ value } onChange={this.handleChange} style={{display:'none'}}/>
                {checked ? (
                    <img
                        src={iconActive}
                        alt={'location'}
                        style={{margin:'0px 5px',width:'60px',borderRadius:'4px'}}
                    />
                ) : (
                    <img
                        src={icon}
                        alt={'location'}
                        style={{margin:'0px 5px',width:'60px',borderRadius:'4px'}}
                    />
                )}
                { text }
            </label>
        );
    }
}