import { Moment } from 'moment';
import moment from 'moment';
import { defaultLocationInfo, IAddress } from './AddressesInterface';
import { defaultCreditCardBeforeSave, ICreditCardAfterSave } from './CreditCardsInterfaces';
import { defaultApiProfile, IApiProfile } from './ProfileInterfaces';

export interface ITimeRequestParams {
  date: string;
  any?: boolean;
  city?: string | null;
}

export interface IBookingData {
  id: number;
  appointment_id: number;
  quantity: number;
  pay_as_you_go_price: number;
  subscription_price: number;
  date: Moment;
  notes: string;
  tell_me_on_way: boolean;
  status?: string | null;
  purchase_method: string;
  valet: IApiProfile;
  address: IAddress;
  // payment_info: number;
  payment_info: ICreditCardAfterSave;
  payments: number;
  micro_status: null | string;
  phone: string;

  [name: string]: string | boolean | number | IApiProfile | IAddress | Moment | null | any;
}

export interface IApiAppointment {
  id: number;
  appointment_id: number;
  client: IApiProfile;
  valet: IApiProfile;
  date: string;
  price: string;
  address: IAddress;
  payments: ICreditCardAfterSave;
  payment_status: string;
  status?: string;
  confirmed_by_client: boolean;
  confirmed_by_valet: boolean;
  refund: string;
  duration: number;
  micro_status: string;
}

export const defaultBookingData = {
  id: 0,
  appointment_id: 0,
  quantity: 1,
  date: moment(),
  notes: '',
  tell_me_on_way: true,
  pay_as_you_go_price: 0,
  subscription_price: 0,
  status: null,
  purchase_method: 'Pay As You Go',
  valet: defaultApiProfile,
  address: defaultLocationInfo,
  payments: 0,
  payment_info: defaultCreditCardBeforeSave,
  confirmed_by_client: false,
  confirmed_by_valet: false,
  micro_status: null,
  phone: ''
};

// export const defaultBookingData_new = {
//   id: 0,
//   quantity: 1,
//   date: moment(),
//   notes: '',
//   address: defaultLocationInfo,  
// };

export const defaultApiAppointment = {
  id: 0,
  appointment_id: 0,
  address: defaultLocationInfo,
  client: defaultApiProfile,
  confirmed_by_client: true,
  confirmed_by_valet: true,
  date: moment().format('YYYY-MM-DD'),
  duration: 1,
  micro_status: '',
  payment_status: '',
  payments: defaultCreditCardBeforeSave,
  price: '100',
  refund: '0',
  status: '',
  valet: defaultApiProfile,
};
