import Location from '../assets/icons/location.png';
import React, { Component, ElementType, FormEvent} from 'react';
import { Form, Image, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';

import { FormControlProps } from 'react-bootstrap';
import { observer } from 'mobx-react';
import PromoComponent from '../PaymentDetails/PromoComponent';
import FormInput from '../../../Shared/FormInput';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import ArrowUpIcon from '../../../assets/icons/arrow_up.svg';
import ArrowDownIcon from '../../../assets/icons/arrow_down.svg';
import CheckSuccessIcon from './../../../assets/icons/check_success.svg';
import { ICreditCard } from '../../../Interfaces/PaymentContactInterface';
import BookingStore from '../../../Stores/BookingStore';
import InfoIconHover from '../../../assets/icons/info_over.svg';
import InfoIcon from '../../../assets/icons/info.svg';

interface IProps {
  onChangeCredit: (name: string, value: string | number) => void;
  booking_value: any;
  credit_value: ICreditCard;
  error?: string;
}
@observer
export default class BookingSummary extends Component<IProps> {

  private arrowRef: HTMLImageElement | null = null;

  constructor(props : IProps) {
    super(props);    
  }

  state = {
    currentImageIndex: 0,
    showRightIcon: false,
    rightButtonText: 'APPLY'
  };

  toggleArrowIcons = [
    ArrowDownIcon,
    ArrowUpIcon
  ];
  
  componentDidMount() {
    this.checkArrowVisibility();
    window.addEventListener('resize', this.checkArrowVisibility);
    
  }

  componentWillUnmount() {
      window.removeEventListener('resize', this.checkArrowVisibility);
  }

  checkArrowVisibility = () => {
    if (this.arrowRef) {
      const isArrowVisible = window.getComputedStyle(this.arrowRef).display !== 'none';
      if (isArrowVisible) {
        this.setState({ currentImageIndex: 0 });
      } else {
        this.setState({ currentImageIndex: 1 });
      }
    } 
  };

  toggleArrowButton = () => {
    if (this.arrowRef) {
      if (window.getComputedStyle(this.arrowRef).display === 'none') {
        this.setState({ currentImageIndex: 1 });
        return;
      }
      const nextIndex = (this.state.currentImageIndex + 1) % this.toggleArrowIcons.length;
      this.setState({ currentImageIndex: nextIndex });
    }
  };
  

  // onChangePromoCode = (
  //   event: FormEvent<
  //     ReplaceProps<
  //       ElementType<any>,
  //       BsPrefixProps<ElementType<any>> & FormControlProps
  //     >
  //   >
  // ) => {
  //   const { id, value = '' } = event.currentTarget;
  //   if (id) {
  //     this.props.onChangeCredit('promo_code', value);
  //     if(value === '' || value === undefined) {
  //       this.setState({ showRightIcon: false, rightButtonText: 'APPLY'});
  //     } else {
  //       this.setState({ showRightIcon: true, rightButtonText: ''});
  //     }
  //   }
  // };

  render() {
    const { promo_code } = this.props.credit_value;
    const { price_details, pay_as_you_go_price = null, subscription_price = null, location_type } = this.props.booking_value;
    let price_label = 'Base Price';
    let base_price = 0;
    let additional_price = 0;
    let service_area_fee = 0;
    let pay_as_you_go_tips = 0;
    let sub_total = 0;    
    let promo_value = "$ 0";
    
    if (BookingStore.discount.value) {
      if (BookingStore.discount.type === 'percent') {
        promo_value = `${BookingStore.discount.value}%`
      }
      if (BookingStore.discount.type === 'discount') {
        promo_value = `$${BookingStore.discount.value}`
      }
    }
    let total_price = pay_as_you_go_price
    if (price_details) {
      if (price_details.base_fee) {
        base_price = price_details.base_fee;
      }
      if (price_details.night_base_fee) {
        price_label = 'Night Base Price';
        base_price = price_details.night_base_fee;
      }
      if (price_details.hotel_base_fee) {
        price_label = 'Hotel Base Price';
        base_price = price_details.hotel_base_fee;
      }
      if (price_details.hotel_night_base_fee) {
        price_label = 'Hotel Night Base Price';
        base_price = price_details.hotel_night_base_fee;
      }
      if (price_details.pay_as_you_go_tips) {
        pay_as_you_go_tips = price_details.pay_as_you_go_tips;
      }
      if (price_details.service_area_fee) {
        service_area_fee = price_details.service_area_fee;                
      }
      if (price_details.multiple_people_appointment) {
        additional_price = price_details.multiple_people_appointment;              
      }
      sub_total = base_price + pay_as_you_go_tips + service_area_fee + additional_price;
    }
    const popoverTop = (
      <Popover id="popover-positioned-top" title="Popover top" style={{padding: '20px 30px'}}>
        <span style={{fontSize: 13, fontWeight: 500}}>A 20% gratuity is charged with every booking. This amount will be received entirely by your valet.</span>
      </Popover>
    );
    
    return (
      <div className={'booking_summary_form center_text'} style={{height: (additional_price > 0 ? 578 : 540)}}>
        <div style={{marginTop:'60px'}}>
          <p style={{fontSize: 26}}><strong>BOOKING SUMMARY</strong></p>
        </div>       
        <Form>
          { this.state.currentImageIndex === 1 && 
            <div>
              <Form.Group as={Row}  controlId="product_name" className={'label_container'} >
                <Form.Label column xs="8" style={{textAlign: 'start'}}>
                  {`${price_label}:`}
                </Form.Label>
                <Form.Label column xs="4" style={{textAlign: 'end', fontWeight: 500}}>
                  $ {base_price}
                </Form.Label>
                { additional_price > 0 && (
                  <>
                    <Form.Label column xs="6" style={{textAlign: 'start'}}>
                      {`Additional people:`}
                    </Form.Label>
                    <Form.Label column xs="6" style={{textAlign: 'end', fontWeight: 500}}>
                      $ {additional_price}
                    </Form.Label>
                  </>
                )}
                { service_area_fee > 0 && (
                  <>
                    <Form.Label column xs="6" style={{textAlign: 'start'}}>
                      {'Area fee:'}
                    </Form.Label>
                    <Form.Label column xs="6" style={{textAlign: 'end', fontWeight: 500}}>
                      $ {service_area_fee}
                    </Form.Label>
                  </>
                )}
                <Form.Label column xs="6" style={{textAlign: 'start'}}>
                  {'Tip:'}
                  <OverlayTrigger trigger="click" placement="top" overlay={popoverTop} >
                    <Image
                      style={{width: 20, height: 20, marginLeft: 5 }}
                      src = {InfoIcon}
                      // onMouseOver={e => e.currentTarget.src = InfoIconHover }
                      // onMouseOut={e => e.currentTarget.src = InfoIcon} 
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Label column xs="6" style={{textAlign: 'end', fontWeight: 500}}>
                  $ {pay_as_you_go_tips}
                </Form.Label>
              </Form.Group>
              <Dropdown.Divider style={{ marginTop: 67 + (service_area_fee > 0 ? 38 : 0) + (additional_price > 0 ? 38 : 0), marginBottom: 30}} />
              <Form.Row className={'promo_container'}>              
                <FormInput
                  controlId={'promo_code'}
                  placeholder={'Promo code?'}
                  rightIconSrc={CheckSuccessIcon}
                  value={promo_code}
                  isValid={false}
                />
              </Form.Row>
              <Dropdown.Divider style={{ marginTop: 30, marginBottom: 18}}/>
              <Form.Group as={Row}  controlId="subtotal" className={'label_container'}>
                <Form.Label column xs="6" style={{textAlign: 'start'}}>
                  Subtotal:
                </Form.Label>
                <Form.Label column xs="6" style={{textAlign: 'end', fontWeight: 500}}>
                  $ { typeof(sub_total) !== 'undefined' ? sub_total : 0.00}
                </Form.Label>              
              </Form.Group>
              <Form.Group as={Row}  controlId="discount" className={'label_container'} style={{ marginTop: 20}}>
                <Form.Label column xs="6" style={{textAlign: 'start'}}>
                  Discount:
                </Form.Label>
                <Form.Label column xs="6" style={{textAlign: 'end', fontWeight: 500}}>
                  {promo_value}
                </Form.Label>              
              </Form.Group>
            </div>
          }
          <Form.Group as={Row}  controlId="total" className={'total_container'}>
            <Col xs="6" style={{textAlign: 'start', paddingTop: 7 }}>
              <div className='d-flex'>
                <Form.Label style={{textAlign: 'start'}}>
                  <strong>Total:</strong>
                </Form.Label>
                <Image
                  ref={(el: any) => (this.arrowRef = el)}
                  id='toggleArrow'
                  className='arrow_container'
                  style={{width: 20, height: 20, marginTop: 5, marginLeft: 5}}
                  src={this.toggleArrowIcons[this.state.currentImageIndex]}
                  onClick={this.toggleArrowButton}
                />
              </div>
            </Col>            
            <Form.Label column xs="6" style={{textAlign: 'end'}}>
              <strong>$ { total_price ? total_price.toFixed(2) : 0}</strong>
            </Form.Label>              
          </Form.Group>            
        </Form>              
      </div>
    );
  }
}
