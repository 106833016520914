import React, {Component} from 'react';
import NotificationsStore, {IUserNotification} from "../../Stores/NotificationsStore";
import NotificationItem from "./NotificationItem";
import {observer} from "mobx-react";
import Popup from '../Popups/Popup';
import ModalStore from '../../Stores/ModalStore';

interface IProps{
}

@observer
export default class NotificationsList extends Component <IProps>{
    render() {
        const {notificationList} = NotificationsStore;
        return (
          <div className={`notifications_container `}>
            <div className={"up_conner"} />
            {notificationList.length ? (
              <>
                <ul>
                  {notificationList.map(
                    (item: IUserNotification, index: number) => (
                      <NotificationItem notification={item} key={index} />
                    )
                  )}
                </ul>
                {NotificationsStore.page < NotificationsStore.totalPages ? (
                  <div
                    className={"view_more"}
                    onClick={() =>
                      NotificationsStore.getNotifications(
                        5,
                        NotificationsStore.page++
                      )
                    }
                  >
                    view more
                  </div>
                ) : null}
              </>
            ) : (
              <span className="d-flex text-center">
                Book appointment to start receiving new notifications
              </span>
            )}
            <Popup
              title={"Error"}
              id={"notificationError"}
              content={NotificationsStore.errorsOnComponent.modalError}
              show={ModalStore.notificationError}
              onHide={() => ModalStore.toggleModal("notificationError", false)}
            />
          </div>
        );
    }
}