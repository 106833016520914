import React from 'react'

interface StepBarProps {
    totalStep: number;
    currentStep: number;
}

const StepBar: React.FC<StepBarProps> = ({ totalStep, currentStep }) => {
  return (
    <div id="step_bar">
      <div className={`step ${currentStep >= 1 ? 'active' : ''}`}/>
      <div className={`step ${currentStep >= 2 ? 'active' : ''}`}/>
      <div className={`step ${currentStep >= 3 ? 'active' : ''}`}/>
    </div>
  );
};

export default StepBar;
