declare var google: any;

const AddressAutocomplete = (
  input: HTMLInputElement,
  onChange: (name: string, value: string) => void
) => {
  const options = {
    language: "en",
    componentRestrictions: { country: "us" }
  };
  const autocomplete = new google.maps.places.Autocomplete(input, options);
  var enterKeyPressed:Boolean = false;

  autocomplete.setFields([
    "address_components",
    "geometry",
    "formatted_address"
  ]);

  input.addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
      enterKeyPressed = true;
    }
  });

  autocomplete.addListener("place_changed", () => {
    if (!enterKeyPressed) {
      enterKeyPressed = false;
      const location = autocomplete.getPlace();
      const address: any = {};
      address.address = location.formatted_address;
      address.latitude = location.geometry.location.lat();
      address.longitude = location.geometry.location.lng();
      if (location.address_components) {
        location.address_components.forEach((component: any) => {
          if (component.types.includes("locality")) {
            address.city = component.short_name;
          }
          if (component.types.includes("postal_code")) {
            address.zip_code = component.short_name;
          }
        });
      }
      Object.keys(address).forEach((key: string) => onChange(key, address[key]));
    }
  });
};

export default AddressAutocomplete;
