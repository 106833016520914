import {action, observable} from "mobx";
import AuthService from "../Services/AuthService";

class SocialLoginsStore {
    @observable googleSocialLoginUrl: string | null = null;
   
    constructor(){
        Promise.all([this.getGoogleLoginUrl()]).then();
    }

    @action
    async getGoogleLoginUrl(){
        try {
            const resp:any = await  AuthService.googleLoginUrl();
            this.googleSocialLoginUrl = resp.url;
        }catch(e){
          throw new Error(e);
        }
    }
}

export default new SocialLoginsStore();