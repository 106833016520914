import React, {Component} from 'react'
import Modal from "react-bootstrap/Modal";
import HotelForm from '../../../Components/HotelForm';
import { defaultHotelInfo, IHotelInfo } from '../../../Interfaces/AddressesInterface';
import AddressesStore from '../../../Stores/AddressesStore';

interface IProps {
    id:string;
    onHide: () => void;
    show: boolean;
    size?:'sm'|'lg'|'xl'|undefined;
}

 class HotelInformationPopup extends Component<IProps> {

    save = (data: {[key: string]: string} ) => {
        AddressesStore.addressObject["hotel_name"] = data["hotel_name"]
        AddressesStore.addressObject["room_number"] = data["room_number"]
        AddressesStore.addressObject["code"] = data["code"]
        this.props.onHide();
      };

    render(){
        const { show, onHide, size='lg'} = this.props;
        return (
            <Modal
                show={show}
                size={size}
                keyboard
                onHide={onHide}
            >
                <Modal.Body>
                    <HotelForm saveCard={this.save} onChange={()=>{}}/>
                </Modal.Body>
            </Modal>

        );
    }
}

export default HotelInformationPopup;