import { action, observable } from "mobx";
import { FormEvent } from "react";
import { ReplaceProps, BsPrefixProps } from "react-bootstrap/helpers";
import { FormControlProps } from "react-bootstrap";
import ProfileService from "../Services/ProfileService";
import ModalStore from "./ModalStore";

const defaultForm = {
  phone: {
    label: "",
    value: "",
  },
  errors: {
    errorFromBe: "",
  },
  loading: false,
};

interface FormInterface {
  phone: {
    [key: string]: string;
    label: string;
    value: string;
  };
  errors: {
    errorFromBe: string;
  };
  loading: boolean;
}
class ReferralStore {
  @observable form: FormInterface = { ...defaultForm };
  @observable referralCode: string | null = "";

  @action onChange = (
    event: FormEvent<
      ReplaceProps<"input", BsPrefixProps<"input"> & FormControlProps>
    >
  ) => {
    const { value = "" } = event.currentTarget;
    if (value.length <= 13) {
      const phone = value.replace(/[^0-9]/g, "");
      this.form.phone.value = phone;
      if (value.length < this.form.phone.label.length) {
        this.form.phone.label = value;
      } else {
        this.formatPhone(phone);
      }
    }
  };

  @action formatPhone = (phone: String) => {
    const label = phone.replace(/(\d{3})(\d{3})/, "($1)$2-");
    this.form.phone.label = label;
  };

  @action submit = async () => {
    try {
      this.form.loading = true;
      await ProfileService.sendReferral({ phone: this.form.phone.value });
      ModalStore.toggleModal("referralSuccess", true);
      this.form = { ...defaultForm };
    } catch (error) {
      this.form.errors.errorFromBe = error.message.detail;
      ModalStore.toggleModal("errorOnReferral", true);
    } finally {
      this.form.loading = false;
    }
  };
}

export default new ReferralStore();
