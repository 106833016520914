import React, { Component } from 'react';
import SubNavbar from '../../../Shared/Navbars/SubNavbar';
import YellowButton from '../../../Shared/Buttons/YellowButton';
import Popup from '../../Popups/Popup';
import { PopupContent } from '../../../Constants/PopupContent';
import ProfileStore from '../../../Stores/ProfileStore';
import { observer } from 'mobx-react';
import AuthStore from '../../../Stores/AuthStore';
import { Redirect } from 'react-router';
import { ISubscription } from '../../../Interfaces/ProfileInterfaces';
import AddressesStore from '../../../Stores/AddressesStore';
import ModalStore from '../../../Stores/ModalStore';
import CurrentPlan from './CurrentPlan';
import DetailedSubscription from './DetailedSubscription';
import PaymentStore from '../../../Stores/PaymentStore';

interface IState {
  active: { name: string; id: number };
  inlineError: string;
  initial: boolean;
}

@observer
export default class SubscriptionSettings extends Component<any, IState> {
  state = {
    active: { name: '', id: 0 },
    inlineError: '',
    initial: true,
  };
  activeSubscription = (id: number) => {
    ProfileStore.selectedSubscription = id;
    this.setState({ initial: false });
  };
  subscribe = async () => {
    const error = await ProfileStore.changeSubscription();
    const history = this.props.history;
    if (
      error &&
      error.message &&
      error.message.payment &&
      error.message.payment[0] === 'Invalid pk "0" - object does not exist.'
    ) {
      history.push('/payment_settings');
      PaymentStore.setRedirect(true);
      return;
    }
    if (error) {
      this.setState({ inlineError: error.message.detail });
      return;
    }
    ModalStore.toggleModal('subscriptionSuccess', true);
  };
  checkNewSubscription = () => {
    this.setState({ inlineError: '' });
    if (!ProfileStore.selectedSubscription) {
      this.setState({ inlineError: 'Choose one of the subscriptions.' });
      return;
    }
    if (ProfileStore.compareSubscriptions()) {
      return;
    } else {
      this.subscribe();
    }
  };

  cancelSubscription = async () => {
    await ProfileStore.cancelSubscription();
  };

  componentDidMount(): any {
    if (!AuthStore.isLogged) return <Redirect to={'/'} />;
    PaymentStore.setRedirect(false);
  }

  render() {
    const { hasCancelledSubscriptions } = ProfileStore;
    const { client_subscriptions } = ProfileStore.profile;
    const defaultCities = ['Las Vegas', 'Los Angeles', 'Miami'];
    let cities = Array.from(
      new Set([
        ...AddressesStore.addressesSettings.map(e => e.city),
        client_subscriptions && client_subscriptions.subscription.city,
      ])
    ).filter(e => e && defaultCities.includes(e));
    cities = cities.length ? cities : defaultCities;
    const { subscriptions, activeUsersSubscriptions, subscriptionError } = ProfileStore;
    const showCancel = activeUsersSubscriptions[0] && activeUsersSubscriptions[0].cancellation_date;
    const { initial } = this.state;

    return (
      <div className={'settings_container'}>
        <SubNavbar active={4} />
        <div className="container subscription_settings">
          <h1>membership</h1>
          <CurrentPlan subscriptions={activeUsersSubscriptions}></CurrentPlan>

          {cities.map(city => (
            <div key={city} className={'subscription_city_container'}>
              <h4 className={'subscription_city'}>{city}</h4>
              <div className="d-flex justify-content-between align-items-center">
                {subscriptions
                  .filter((subscription: ISubscription) => subscription.city === city)
                  .map((item: ISubscription, i: number) => {
                    return (
                      <DetailedSubscription
                        active={
                          initial && item.cancellation_date
                            ? null
                            : ProfileStore.selectedSubscription === item.id
                        }
                        key={`${i}-${item.subscription_type}`}
                        onClick={this.activeSubscription}
                        subscription={item}
                      />
                    );
                  })}
              </div>
            </div>
          ))}
          <span className={`inline_error ${this.state.inlineError ? 'visible' : 'invisible'}`}>
            {this.state.inlineError}
          </span>
          <YellowButton
            onClick={this.checkNewSubscription}
            disabled={!!this.state.active.id}
            value={
              hasCancelledSubscriptions || (client_subscriptions && !showCancel)
                ? 'update'
                : 'subscribe'
            }
            additionalClass={'w-100'}
          />
          {hasCancelledSubscriptions && !client_subscriptions ? null : (
            <div className={`inline_button`} onClick={this.cancelSubscription}>
              Cancel membership
            </div>
          )}
        </div>
        <Popup
          title={'thank you!'}
          content={PopupContent.subscriptionSuccess}
          id={'subscriptionSuccess'}
          onHide={() => ModalStore.toggleModal('subscriptionSuccess', false)}
          show={ModalStore.subscriptionSuccess}
        />
        <Popup
          title={'Done!'}
          content={PopupContent.subscriptionCanceled}
          id={'subscriptionCanceled'}
          onHide={() => ModalStore.toggleModal('subscriptionCancel', false)}
          show={ModalStore.subscriptionCancel}
        />
        <Popup
          title={'Change membership plan'}
          content={''}
          dynamicContent={() => PopupContent.changeCityOfSubscription('Los Angeles', 'Las Vegas')}
          id={'subscriptions_info'}
          onHide={() => ModalStore.toggleModal('subscriptionChangeCity', false)}
          show={ModalStore.subscriptionChangeCity}
          size={'sm'}
        />
        <Popup
          title={'Error!'}
          content={''}
          dynamicContent={() => <p>{subscriptionError}</p>}
          id={'subscriptions_info'}
          onHide={() => ModalStore.toggleModal('errorOnSubscriptions', false)}
          show={ModalStore.errorOnSubscriptions}
          size={'sm'}
        />
      </div>
    );
  }
}
