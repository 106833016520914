import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "../Pages/Dashboard/DashBoard";
import ProfileSettings from "../Pages/ProfilePages/ProfileSettings";
import PasswordSettings from "../Pages/ProfilePages/PasswordSettings";
import PaymentSettings from "../Pages/ProfilePages/Payment/PaymentSettings";
import AddressesSettings from "../Pages/ProfilePages/Addresses/AddressesSettings";
import SubscriptionSettings from "../Pages/ProfilePages/Subscriptions/SubscriptionSettings";
import AddCreditCard from "../Pages/ProfilePages/Payment/AddCreditCard";
import AddNewAddress from "../Pages/ProfilePages/Addresses/AddNewAddress";
import HotelInformation from "../Pages/ProfilePages/Addresses/HotelInformation";
import MyBooking from "../Pages/MyBooking/MyBooking";
import Messages from "../Pages/Messages/Messages";
import BookingDetails from "../Pages/Booking/BookingDetails/BookingDetails";
import BookingDone1 from "../Pages/Booking/BookingDone/BookingDone1";
import BookingDone2 from "../Pages/Booking/BookingDone/BookingDone2";
import LeavingAFeedback from "../Pages/FeedBack/LeavingAFeedback";
import RebookValet from "../Pages/Booking/RebookValet/RebookValet";
import LocationInformation from "../Pages/Booking/Location/LocationInformation";
import PaymentDetails from "../Pages/Booking/PaymentDetails/PaymentDetails";
import PersonalInformation from "../Pages/Booking/PersonalInformation";
import LogIn from "../Pages/Registration/LogIn";
import SignInLocationInfo from "../Pages/Registration/signIn/SignInLocationInfo";
import SignInClientInfo from "../Pages/Registration/signIn/SignInClientInfo";
import SignIn from "../Pages/Registration/signIn/SignIn";
import PasswordRecovery from "../Pages/Registration/PasswordRecovery";
import ResetPassword from "../Pages/Registration/ResetPassword";
import HotelInformationForBooking from "../Pages/Booking/Location/HotelInformation";
import LocationInformationForRegistered from "../Pages/Booking/Location/LocationInformationForRegistered";
import PaymentChoosePage from "../Pages/Booking/PaymentDetails/PaymentChoosePage";
import Referral from "../Pages/ProfilePages/Referral";
import SelectValet from "../Pages/Booking/SelectValet/SelectValet";
import CustomBookingDetails from "../Pages/Booking/BookingDetails/CustomBookingDetails";

import PaymentDetailsBookingPage from "../Pages/Booking/PaymentDetails/PaymentDetailsBookingPage";
import ThankYouPage from "../Pages/Booking/BookingDone/ThankYou";
import SeeYouPage from "../Pages/Booking/BookingDone/SeeYouSoon";
import Leave from "../Pages/Booking/Leave";
import Location from "../Pages/Booking/Location/Location";

const PrivateRouter = () => {
    return (
      <>
        <Route path={"/my_booking"} component={MyBooking} />
        <Route path={"/profile_settings"} component={ProfileSettings} />
        <Route path={"/password_settings"} component={PasswordSettings} />
        <Route path={"/add_new_card"} component={AddCreditCard} />
        <Route path={"/payment_settings"} component={PaymentSettings} />
        <Route
          path={"/settings_hotel_information"}
          component={HotelInformation}
        />
        <Route path={"/add_new_address"} component={AddNewAddress} />
        <Route path={"/addresses_settings"} component={AddressesSettings} />
        <Route
          path={"/subscription_settings"}
          component={SubscriptionSettings}
        />
        <Route path={"/referral"} component={Referral} />
        <Route path={"/messages"} component={Messages} />
        <Route path={"/rebook_valet"} component={RebookValet} />
        <Route path={"/select_valet"} component={SelectValet} />
        <Route
          path={"/booking_address"}
          component={LocationInformationForRegistered}
        />
        <Route path={"/add_location"} component={LocationInformation} />
        <Route path={"/booking_done_step1"} component={BookingDone1} />
        <Route path={"/booking_done_step2"} component={BookingDone2} />
        <Route path={"/booking_valet"} component={BookingDetails} />
        <Route
          path={"/hotel_information"}
          component={HotelInformationForBooking}
        />
        <Route path={"/payment_choose"} component={PaymentChoosePage} />
        <Route path={"/payment_details"} component={PaymentDetails} />
        <Route path={"/leaving_a_feedback"} component={LeavingAFeedback} />
        <Route path={"/personal_information"} component={PersonalInformation} />
        <Route path={"/logIn"} component={LogIn} />
        <Route path={"/sign_location_info"} component={SignInLocationInfo} />
        <Route path={"/sign_client_info"} component={SignInClientInfo} />
        <Route path={"/signIn"} component={SignIn} />
        <Route path={"/password_recovery_success"} component={ResetPassword} />
        <Route path={"/password_recovery"} component={PasswordRecovery} />
        <Route path={"/"} component={Dashboard} exact />

        {/* <Route path={"/payment_details_booking"} component={PaymentDetailsBookingPage} />
        <Route path={"/thank_you"} component={ThankYouPage} />
        <Route path={"/see_you_soon"} component={SeeYouPage} />
        <Route path={"/location"} component={Location} exact />
        <Route path={"/booking"} component={CustomBookingDetails} exact />
        <Route path={"/leave"} component={Leave}/> */}
      </>
    );
}

export default PrivateRouter;