import React, { Component, RefObject } from 'react';
import ValetsStore from '../../../Stores/ValetsStore';
import changeActiveItem from '../../../Utils/changeActiveItem';

interface IProps {
  active: number;
}

export default class RebookNavbar extends Component<IProps> {
  parent: RefObject<any> = React.createRef();

  filterValets = async (event: React.MouseEvent<HTMLDivElement>) => {
    changeActiveItem(event.currentTarget as HTMLElement, 'active');
    await ValetsStore.getListOfUserValets(event.currentTarget.innerText);
  };

  render() {
    return (
      <div id={'sub_navbar'} ref={this.parent}>
        <div className={'sub_navbar_link active'} onClick={this.filterValets}>
          all
        </div>
        <div className={'sub_navbar_link'} onClick={this.filterValets}>
          favorite
        </div>
        {/* <div className={'sub_navbar_link'} onClick={this.filterValets}>preferred</div> */}
        <div className={'sub_navbar_link'} onClick={this.filterValets}>
          only
        </div>
      </div>
    );
  }
}
