import http from "./http";
import {INewMessage} from "../Interfaces/MessagesInterfaces";


class MessagesService {
  public static getConversations = (
    params: { [key: string]: string | number },
    source: any
  ) => {
    return http.get("/appointment-chats/", {
      params,
      cancelToken: source.token
    });
  };

  public static getMessagesList = (id: number) => {
    return http.get(`/appointment-chats/${id}/messages/`);
  };

  public static postNewMessage = (id: number, message: INewMessage) => {
    return http.post(`/appointment-chats/${id}/messages/`, message);
  };

  public static readNewMessages = (id: number) => {
    return http.post(`/appointment-chats/${id}/read/`);
  };
}

export default MessagesService;