import React, { Component } from 'react';

interface IProps {
  phone?: string;
  icon: string;
  onClick: () => void;
}

export default class ToolbarButton extends Component<IProps> {
  render() {
    const { icon, onClick, phone } = this.props;
    return (
      <a href={phone ? `tel:${phone}` : undefined} onClick={onClick}>
        <i className={`toolbar-button ${icon}`} />
      </a>
    );
  }
}
