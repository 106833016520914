import React, { Component } from 'react';
import MessagesStore from "../../../Stores/MessagesStore";
import { observer } from "mobx-react";
import moment from 'moment'

interface IState {
    message: string;
}

@observer
export default class Compose extends Component<any, IState> {
    state = {
        message: '',
    };

    inputMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
        MessagesStore.createDraft(event.currentTarget.value);
    keyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.charCode === 13 && !event.shiftKey) {
            event.preventDefault();
            if (event.currentTarget.value) {
                MessagesStore.addNewMessage();
                event.currentTarget.value = '';
            }
        }
    };
    sendMessage = (event: React.MouseEvent<HTMLElement>) => {
        const input = document.querySelector('.compose-input') as HTMLTextAreaElement;
        if (input.value) {
            MessagesStore.addNewMessage();
            input.value = '';
        }
    };

    render() {
        const { is_active, appointment } = MessagesStore.activeConversation
        let status = false;
        
        if (appointment) {
            status = (appointment.status == 'Pending' || appointment.status == 'Upcoming')
        }

        return (
            <>
                {is_active && status && (
                    <div className={`compose ${MessagesStore.activeConversation.is_active ? '' : 'd-none'}`}>
                        <textarea
                            className="compose-input"
                            placeholder="Message..."
                            onChange={this.inputMessage}
                            onKeyPress={this.keyPress}
                            value={MessagesStore.activeConversation.draft}
                            wrap={'hard'}
                            rows={3}
                        />
                        <i className={'fas fa-paper-plane'} onClick={this.sendMessage} />
                    </div>
                )}
            </>
        );
    }
}