import React, { Component, FormEvent } from 'react';
import StepsComponent from '../../../Shared/StepsComponent';
import BookingStore from '../../../Stores/BookingStore';
import YellowButton from '../../../Shared/Buttons/YellowButton';
import PaymentTypesItem from './PaymentTypesItem';
import changeActiveItem from '../../../Utils/changeActiveItem';
import AuthStore from '../../../Stores/AuthStore';
import { RouterProps } from 'react-router';
import { observer } from 'mobx-react';
import ProfileStore from '../../../Stores/ProfileStore';
import OrderSummary from './OrderSummary';
import Popup from '../../Popups/Popup';
import { PopupContent } from '../../../Constants/PopupContent';
import { Elements } from 'react-stripe-elements';
import CardForm from '../../../Components/CardForm';
import { ISubscription, IUserSubscription } from '../../../Interfaces/ProfileInterfaces';
import SubscriptionItem from '../../ProfilePages/Subscriptions/SubscriptionItem';
import PaymentStore from '../../../Stores/PaymentStore';
import ModalStore from '../../../Stores/ModalStore';
import PromoStore from '../../../Stores/PromoStore';
import queryString from 'query-string';
import SubscriptionDetail from './SubscriptionDetail';
import DetailedSubscription from '../../ProfilePages/Subscriptions/DetailedSubscription';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import FacebookConversionApiService from '../../../Services/FacebookConversionApiService';
import config from '../../../config';
import { Form, FormControlProps} from 'react-bootstrap';
import Validation from "../../../Utils/Validation";
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import AlreadyRegisteredPopup from '../../../Pages/Popups/AlreadyRegisteredPopup'

interface IState {
  error?: string;
  showPopup: boolean;
  active: number;
  inputComplete: boolean;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  isInvalidFirstName: boolean;
  isInvalidLastName: boolean;
  isInvalidEmail: boolean;
  isInvalidPhone: boolean;
  showAlreadyRegisteredPopup: boolean;
}

@observer
export default class PaymentDetails extends Component<RouterProps, IState> {
  state = {
    error: '',
    showPopup: false,
    active: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    isInvalidFirstName: false,
    isInvalidLastName: false,
    isInvalidEmail: false,
    isInvalidPhone: false,
    inputComplete: false,
    showAlreadyRegisteredPopup: false,
  };
  cardForm: React.RefObject<any> = React.createRef();

  rescheduleAppointment = async (rescheduleItem: number) => {
    try {
      await BookingStore.rescheduleItem(rescheduleItem);
      this.props.history.push('/booking_done_step1');
    } catch (error) {
      BookingStore.setError('secondStep', error.message.detail);
    }
  };

  saveCard = async () => {
    try {
      if (BookingStore.bookingData.payment_info.id === 0) {
        await this.cardForm.current.wrappedInstance.saveCard();

        const payment_info = {
          first_name: ProfileStore.profile.first_name,
          last_name: ProfileStore.profile.last_name,
          email: ProfileStore.profile.email,
          phone: ProfileStore.profile.phone
        }

        await PaymentStore.savePaymentInfo(payment_info);
      }
    } catch (error) {
      BookingStore.setError('secondStep', error.message.detail);
    }
  };

  subscribe = async () => {
    if (
      BookingStore.bookingData.purchase_method === 'Subscription' &&
      !ProfileStore.profile.client_subscriptions  && !this.hasRemainingAppointments(ProfileStore.activeUsersSubscriptions)
    ) {
      if (
        ProfileStore.selectedSubscription &&
        !ProfileStore.compareSubscriptions()
      ) {
        const error = await ProfileStore.changeSubscription();
        if (error) {
          BookingStore.setError('secondStep', error.message.detail);
          return true;
        }
      } else {
        BookingStore.inlineErrors.subscription = true;
        return true;
      }
    }
    return false;
  };

  bookingSuccess = async () => {
    if (!this.state.error) {
      ProfileStore.selectedSubscription = null;
      await BookingStore.createBookingStep2();
      if (BookingStore.sendSuccess) {
        ModalStore.toggleModal('ShowTOS', true);
      }
    }
  };

  bookHandle = async () => {
    const parsed: any = queryString.parse(window.location.search);

    if (parsed.rescheduleItem) {
      this.rescheduleAppointment(parsed.rescheduleItem);
      return;
    }
    //ADD CARD IF USER DOES NOT HAVE
    if (BookingStore.bookingData.payment_info.id === 0 && !this.state.inputComplete)
      return;

    if (BookingStore.bookingData.payment_info.id === 0){

      if (
        this.state.first_name === '' ||
        this.state.last_name === '' ||
        !Validation["email"](this.state.email) ||
        (!Validation["phone"](this.state.phone) || this.state.phone === '')
      ) {
        if (this.state.first_name === '') {
          this.setState({ isInvalidFirstName: true });
        }
        if (this.state.last_name === '') {
          this.setState({ isInvalidLastName: true });
        }
        if (!Validation["email"](this.state.email)) {
          this.setState({ isInvalidEmail: true });
        }
        if (!Validation["phone"](this.state.phone) || this.state.phone === '') {
          this.setState({ isInvalidPhone: true });
        }
        return;
      }

      try{
        await ProfileStore.updateProfileData({
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          phone: this.state.phone,
        });
      }catch(e){
        this.setState({showAlreadyRegisteredPopup: true})
        return
      }
      
    }

    await this.saveCard();
    const error = await this.subscribe();
    if (!error) {
      await this.bookingSuccess();
    }
  };
  cancelAndSubscribe = () => {};

  makeActive = (event: React.MouseEvent<HTMLDivElement>, value: string) => {
    BookingStore.changeBookingData('purchase_method', value);
    changeActiveItem(event.currentTarget, 'active');
  };

  showPopup = () => this.setState({ showPopup: !this.state.showPopup });

  activeSubscription = (id: number) => {
    ProfileStore.selectedSubscription = id;
    const sub = ProfileStore.subscriptions.find((e) => e.id === id);
    if (sub) {
      BookingStore.bookingData.price_details.members_base_fee = sub.price;
      BookingStore.bookingData.price_details.subscription_tips = Math.round(
        (BookingStore.bookingData.price_details.members_base_fee +
          BookingStore.bookingData.price_details.service_area_fee) *
          0.2
      );
      BookingStore.bookingData.subscription_price =
        BookingStore.bookingData.price_details.members_base_fee +
        BookingStore.bookingData.price_details.subscription_tips +
        BookingStore.bookingData.price_details.service_area_fee;
    }
    BookingStore.inlineErrors.subscription = false;
  };

  hasRemainingAppointments = (subscriptions: IUserSubscription[]) => {
    return subscriptions[0] && subscriptions[0].cancellation_date && subscriptions[0].appointments_left + subscriptions[0].unused_appointments > 0
  };

  renderSubscriptionItem() {
    // if user have subscription
    if (BookingStore.bookingData.subscription_price !== null) {
      if (ProfileStore.profile.client_subscriptions) {
        if (
          ProfileStore.profile.client_subscriptions.is_active &&
          ProfileStore.profile.client_subscriptions.subscription.city ===
            BookingStore.bookingData.address.city
        ) {
          return true;
        } else {
          BookingStore.changeBookingData('purchase_method', 'Pay As You Go');
          return false;
        }
      } else {
        return true;
      }
    } else {
      BookingStore.changeBookingData('purchase_method', 'Pay As You Go');
      return false;
    }
  }

  emailValidation = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name && value) {
      this.setState({ isInvalidEmail: !Validation[name](value) });
    }
  };

  changeData = async (
    event: FormEvent<
      ReplaceProps<'input', BsPrefixProps<'input'> & FormControlProps>
    >
  ) => {
    const { id, value = '' } = event.currentTarget;
    if (id){
      ProfileStore.newProfile[id] = value;
    }

    if (id === 'first_name') {
      this.setState({ isInvalidFirstName: false });
      this.setState({
        first_name: value },
      );
    }
    if (id === 'last_name') {
      this.setState({ isInvalidLastName: false });
      this.setState({
        last_name: value 
      });
    }
    if (id === 'email') {
      this.setState({
        email: value,
        isInvalidEmail: !Validation["email"](value)
      });
    }
    if (id === 'phone') {
      this.setState({
        phone: value,
        isInvalidPhone: !Validation["phone"](value)
      });
    }
  };

  componentDidMount() {
    if (config.isProd) {
      ReactGA.event({
        category: 'form-submission',
        action: 'book-appoitment-form',
        label: 'payment details',
      });
    }

    if (config.REACT_APP_FACEBOOK_PIXEL_ID) {
      ReactPixel.trackCustom('Add to cart', {event_time: Math.floor(Date.now() / 1000)});

      const event_data = {
        data: {
          user_data: {
            client_user_agent: window.navigator.userAgent,
            fbp: ''
          }
        }
      };

      let fbpRes = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);

      if ((fbpRes && fbpRes[1])) {
        event_data.data.user_data.fbp = fbpRes[1];
        FacebookConversionApiService.trackAddToCart(event_data);
      }
    }

    BookingStore.error = '';
    if (!BookingStore.bookingData.id || !AuthStore.jwt.token)
      this.props.history.push('/');
  }

  render() {
    const { bookingData } = BookingStore;
    const { profile, subscriptions, activeUsersSubscriptions } = ProfileStore;
    const filteredSubscriptions = subscriptions.filter(
      (subscription: ISubscription) =>
        subscription.city === bookingData.address.city
    );
    const disabled =
      !!this.state.error ||
      (bookingData.payment_info.id === 0 && !this.state.inputComplete) ||
      !!(
        BookingStore.bookingData.purchase_method === 'Subscription' &&
        PromoStore.promo
      ) ||
      (bookingData.purchase_method === 'Subscription' &&
      !profile.client_subscriptions && !this.hasRemainingAppointments(activeUsersSubscriptions)
        ? !filteredSubscriptions.some(
            (item: ISubscription, i: number) =>
              ProfileStore.selectedSubscription === item.id
          )
        : false);

    return (
      <div className="payment_details_container">
        <Popup
          title={'Subscribe and save!'}
          content={PopupContent.subscriptions}
          id={'subscriptions_info'}
          onHide={this.showPopup}
          show={this.state.showPopup}
          size={'lg'}
        />
        <Popup
          title={'Change subscription plan'}
          content={''}
          dynamicContent={() =>
            PopupContent.changeCityOfSubscription('Los Angeles', 'Las Vegas')
          }
          id={'subscriptions_info'}
          onHide={() => ModalStore.toggleModal('subscriptionChangeCity', false)}
          show={ModalStore.subscriptionChangeCity}
          showConfirmation={true}
          onClick={this.cancelAndSubscribe}
          size={'sm'}
        />
        <Popup
          title={'Cancellation and Reschedule Policy'}
          content={''}
          dynamicContent={PopupContent.ToSAndPrivacy}
          id={'TOS'}
          size={'lg'}
          onHide={() => {
            ModalStore.toggleModal('ShowTOS', false);
            if (!AuthStore.isLogged) {
              this.props.history.push('/additional_booking_signin');
            } else {
              this.props.history.push('/booking_done_step1');
            }
          }}
          show={ModalStore.ShowTOS}
        />
        {AuthStore.isLogged ? (
          <StepsComponent
            steps={'bookingSteps'}
            active={2}
            done={[0, 1]}
            onBack={this.props.history.goBack}
          />
        ) : (
          <StepsComponent
            steps={'bookingStepsNotReg'}
            active={2}
            done={[0, 1]}
            onBack={this.props.history.goBack}
          />
        )}
        <div className="d-flex justify-content-center flex-md-row flex-column-reverse">
          {/* {bookingData.pay_as_you_go_price >= 0 ? ( */}
          <div className="payment_details">
            <h1>Payment Details</h1>
            {
              <div>
                <Form>
            <h4>Contact Info</h4>
            <Form.Row className='mx-0 my-3'>
              <Form.Group className='m-0' controlId="first_name">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="first name"
                  value={bookingData.payment_info.id !== 0 ? bookingData.payment_info.first_name : this.state.first_name}
                  onChange={this.changeData}
                  disabled={bookingData.payment_info.id !== 0}
                />
                <span
                  className={`inline_error ${
                    this.state.isInvalidFirstName ? 'visible' : 'invisible'
                  }`}
                >
                  Please enter a first name.
                </span>
              </Form.Group>

              <Form.Group className='m-0' controlId="last_name">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="last name"
                  value={bookingData.payment_info.id !== 0 ? bookingData.payment_info.last_name : this.state.last_name}
                  onChange={this.changeData}
                  disabled={bookingData.payment_info.id !== 0}
                />
                <span
                  className={`inline_error ${
                    this.state.isInvalidLastName ? 'visible' : 'invisible'
                  }`}
                >
                  Please enter a last name.
                </span>
              </Form.Group>
            </Form.Row>

            <Form.Row className='mx-0 my-3'>
              <Form.Group className="m-0" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="email"
                  value={bookingData.payment_info.id !== 0 ? bookingData.payment_info.email : this.state.email}
                  onChange={this.changeData}
                  disabled={bookingData.payment_info.id !== 0}
                />
                <span
                  className={`inline_error ${
                    this.state.isInvalidEmail ? 'visible' : 'invisible'
                  }`}
                >
                  Please enter valid email
                </span>
              </Form.Group>
              <Form.Group className="m-0" controlId="phone">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="phone"
                  value={bookingData.payment_info.id !== 0 ? bookingData.payment_info.phone : this.state.phone}
                  onChange={this.changeData}
                  disabled={bookingData.payment_info.id !== 0}
                />
                <span
                  className={`inline_error ${
                    this.state.isInvalidPhone ? "visible" : "invisible"
                  }`}
                >
                  Please enter valid phone number
                </span>
              </Form.Group>
            </Form.Row>
          </Form>
          <div className="payment_info">
            <h4>Payment Info</h4>
            { bookingData.payment_info.id !== 0 && 
              <div className={"card_info d-inline-flex flex-grow-1 align-items-center"}>
                <i className={"fas fa-credit-card"} />
                <div className="profile_item">{`${bookingData.payment_info.card_type} **${bookingData.payment_info.last_4}`}</div>
              </div>
            }
            
          
        </div>
              </div>
            }
            {
              <div style={{ display: bookingData.payment_info.id === 0 ? '' : 'none' }}>
                <Elements>
                  <CardForm
                    inputComplete={(e: boolean) =>
                      this.setState({ inputComplete: e })
                    }
                    showError={(error?: string) => this.setState({ error })}
                    ref={this.cardForm}
                  />
                </Elements>
              </div>
            }
            <div className="payments_container">
              <PaymentTypesItem
                makeActive={this.makeActive}
                value={'Pay As You Go'}
                title={
                  bookingData.pay_as_you_go_price >= 0
                    ? 'Pay As You Go'
                    : 'You will be refunded for the price change'
                }
                isSale={false}
                tooltipContent={bookingData.price_details}
                sum={bookingData.pay_as_you_go_price}
                purchase_method={BookingStore.bookingData.purchase_method}
                showPopup={() => null}
                key={'Pay As You Go'}
              />
              {this.renderSubscriptionItem() && (
                <PaymentTypesItem
                  makeActive={this.makeActive}
                  value={'Subscription'}
                  title={'Membership'}
                  isSale={true}
                  sum={bookingData.subscription_price}
                  tooltipContent={bookingData.price_details}
                  purchase_method={BookingStore.bookingData.purchase_method}
                  showPopup={this.showPopup}
                  key={'Subscription'}
                />
              )}
            </div>
            {bookingData.purchase_method === 'Subscription' && !this.hasRemainingAppointments(activeUsersSubscriptions) &&
              !profile.client_subscriptions &&  (
                <>
                  <h4 className={'subscription_city'}>
                    {bookingData.address.city}:
                  </h4>
                  <div className="d-flex justify-content-between align-items-center">
                    {filteredSubscriptions.map(
                      (item: ISubscription, i: number) => (
                        <SubscriptionItem
                          active={ProfileStore.selectedSubscription === item.id}
                          key={`${i}-${item.subscription_type}`}
                          onClick={this.activeSubscription}
                          subscription={item}
                        />
                      )
                    )}
                  </div>
                  {ProfileStore.selectedSubscriptionItem && (
                    <DetailedSubscription
                      advantagesOnly={true}
                      subscription={ProfileStore.selectedSubscriptionItem}
                    />
                  )}
                  <SubscriptionDetail />
                </>
              )}
            <span>
              {BookingStore.inlineErrors.subscription &&
                'Please select subscription!'}
            </span>
            {BookingStore.bookingData.purchase_method === 'Subscription' &&
            PromoStore.promo ? (
              <span className={'inline_error'}>
                You can't use promo code or gift card if you use your membership
                as a payment method.
              </span>
            ) : null}
            {BookingStore.errorsOnForm.secondStep && (
              <span className={'inline_error'}>
                {BookingStore.errorsOnForm.secondStep}
              </span>
            )}
            <YellowButton
              onClick={this.bookHandle}
              value={'book'}
              additionalClass={'w-100'}
              disabled={disabled}
              spinner={BookingStore.isLoading}
            />
          </div>
          {/* ) : (
              <div className="payment_details">
                <div className="text-center">
                  You will be refunded for the price change
              </div>
                <YellowButton
                  onClick={this.bookHandle}
                  value={"book"}
                  additionalClass={"w-100"}
                  disabled={disabled}
                  spinner={BookingStore.isLoading}
                />
              </div>
            ) */}
          {/* } */}
          <OrderSummary />
        </div>
        <Popup
          title={'Error!'}
          content={PaymentStore.errorOnComponent.modalError}
          id={'paymentError'}
          onHide={() => ModalStore.toggleModal('paymentError', false)}
          show={ModalStore.paymentError}
        />
        <AlreadyRegisteredPopup
          onGoTo={() => this.props.history.push("/login")}
          onHide={() => this.setState({showAlreadyRegisteredPopup: false})}
          show={this.state.showAlreadyRegisteredPopup}
        />
      </div>
    );
  }
}
