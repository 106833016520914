import Location from '../assets/icons/location.png';
import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { observer } from 'mobx-react';
import CustomPopup from '../../Popups/CustomPopup';
import { PopupContent } from '../../../Constants/PopupContent';
import moment from 'moment';
import AuthStore from '../../../Stores/AuthStore';
interface IProps {}

interface IState {
  minutes: number;
  seconds: number;
  showModal: boolean;
}

@observer
export default class BookingReserved extends Component<IProps, IState> {
  countdownInterval: NodeJS.Timeout | null = null;

  constructor(props: IProps) {
    super(props);

    const [minutes, seconds] = this.getMinutesAndSeconds()
    
    this.state = {
      minutes: minutes,
      seconds: seconds,
      showModal: false
    };
  }

  componentDidMount() {
    this.startCountdown();
  }

  componentWillUnmount() {
    this.stopCountdown();
  }

  getMinutesAndSeconds = (): [number, number] => {
    const remainSeconds = 900 - moment().diff(AuthStore.guestLoginTime, 'seconds');
    if (remainSeconds <= 0) {
      return [0, 0];
    }

    const minutes = Math.floor(remainSeconds / 60);
    const seconds = remainSeconds % 60;
  
    return [minutes, seconds];
  };

  startCountdown = () => {
    this.countdownInterval = setInterval(() => {
      const { minutes, seconds } = this.state;

      if (minutes === 0 && seconds === 0) {
        this.stopCountdown();
        return;
      }

      if (seconds === 0) {
        this.setState(prevState => ({
          minutes: prevState.minutes - 1,
          seconds: 59,
        }));
      } else {
        this.setState(prevState => ({
          seconds: prevState.seconds - 1,
        }));
      }
    }, 1000);
  }

  stopCountdown = () => {
    if (this.countdownInterval !== null) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
      this.setState({showModal: true});      
    }
  }

  onHide = () => {
    // const [minutes, seconds] = this.getMinutesAndSeconds()
    // this.setState({ showModal: false, minutes: minutes, seconds: seconds });
    // this.startCountdown();    
    window.location.href = '/';
  }

  render() {
    const { minutes, seconds, showModal } = this.state;

    return (
      <div className={'booking_reserved_time'}>
        <div style={{marginTop: 30, textAlign: 'center'}}>
          <p style={{fontSize: 16, fontWeight: 400}}>YOUR BOOKING IS RESERVED FOR</p>          
        </div>       
        <div className='d-flex justify-content-center'>
          <div style={{marginTop: 10, textAlign: 'center', paddingLeft: 10, paddingRight: 10}}>
            <p style={{fontSize: 55, color: '#CFB12B'}}>{minutes.toString().padStart(2, '0')}</p>
            <p style={{fontSize: 12, color: '#1A1A1A'}}>MINUTES</p>
          </div>          
          <div style={{marginTop: 10, textAlign: 'center', paddingLeft: 10, paddingRight: 10}}>
            <p style={{fontSize: 55, color: '#CFB12B'}}>:</p>            
          </div>          
          <div style={{marginTop: 10, textAlign: 'center'}}>
            <p style={{fontSize: 55, color: '#CFB12B', paddingLeft: 10, paddingRight: 10}}>{seconds.toString().padStart(2, '0')}</p>
            <p style={{fontSize: 12, color: '#1A1A1A'}}>SECONDS</p>
          </div>          
        </div> 
        <CustomPopup
            id={'email_exists'}
            content={PopupContent.booking_time_out}
            onClick={() => window.location.href = '/'}
            onHide={this.onHide}
            show={showModal}
            showConfirmation={false}
            // leftButtonText={'Cancel Booking'}
            // rightButtonText={'Keep Booking'}
          />       
      </div>
    );
  }
}