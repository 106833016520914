import React from 'react';
import Button from "react-bootstrap/Button";

interface IProps{
    value: string;
    onClick: ()=>void;
}

const OutlineButton = (props:IProps) => {
    const {value, onClick} = props;
    return (
        <div className={'button_container'}>
            <Button variant={'outline-warning'} type={'button'} onClick={onClick} className={'outline_button'}>{value}</Button>
        </div>
    );
}

export default OutlineButton;