import React, { Component } from 'react';
import WhiteButton from '../../../Shared/Buttons/WhiteButton';
import { RouterProps } from 'react-router';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import config from '../../../config';

export default class BookingDone1 extends Component<RouterProps> {
  componentDidMount(): void {
    if (config.isProd) {
      ReactGA.event({
        category: 'form-submission',
        action: 'book-appoitment-form',
        label: 'Booking Completed',
      });
    }
    const navbars = document.querySelectorAll('nav');
    if (navbars)
      [].map.call(navbars, (nav: HTMLElement) => nav.classList.add('hide'));
  }

  componentWillUnmount(): void {
    const navbars = document.querySelector('nav');
    if (navbars)
      [].map.call(navbars, (nav: HTMLElement) => nav.classList.remove('hide'));
  }

  nextPage = () => {
    const search = this.props.history.location.search;
    const url = search ? `/booking_done_step2${search}` : `/booking_done_step2`;
    this.props.history.push(url);
  };

  render() {
    return (
      <div className={'booking_done'}>
        <Helmet>
          {
            "<script> gtag('event', 'conversion', {'send_to': 'AW-981739349/kH6gCNiI5esBENXOkNQD'}); </script>"
          }
        </Helmet>
        <div className={'logo_container'}>
          <img
            src={require('../../../assets/Logo.svg')}
            alt={'logo'}
            width={300}
          />
        </div>
        <div
          className={
            'background_container d-flex align-items-center justify-content-center'
          }
        >
          <div className="content_container">
            <h1>Prepare For My Spray Tan</h1>
            <ul>
              <li className={'d-flex'}>
                <div className={'icon'}>
                  <img
                    src={require('../../../assets/icons/Shower.svg')}
                    alt={'shower'}
                  />
                </div>
                <div className={'instruction'}>
                  Please be showered and exfoliated with nothing on your skin
                  before your valet arrives.{' '}
                </div>
              </li>
              <li className={'d-flex'}>
                <div className={'icon'}>
                  <img
                    src={require('../../../assets/icons/Group 25.svg')}
                    alt={'house'}
                  />
                </div>
                <div className={'instruction'}>
                  Valet will bring towels and a pop up tent. No mess will be
                  left behind.
                </div>
              </li>
              <li className={'d-flex'}>
                <div className={'icon'}>
                  <img
                    src={require('../../../assets/icons/Group 26.svg')}
                    alt={'outlet'}
                  />
                </div>
                <div className={'instruction'}>
                  Valet will need an outlet and at least 5ft x 5ft to set up.
                </div>
              </li>
              <li className={'d-flex'}>
                <div className={'icon'}>
                  <img
                    src={require('../../../assets/icons/Group 27.svg')}
                    alt={'manicure'}
                  />
                </div>
                <div className={'instruction'}>
                  Wax and have your pedicure and manicure before done before
                  your spray tan.
                </div>
              </li>
              <WhiteButton
                onClick={this.nextPage}
                value={'ok'}
                addClass={'w-70'}
              />
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
