import React, { Component } from "react";
import "./CheckBoxButton.css";

interface IProps {
    id: string,
    name: string,
    text: string,
    value: string,
    checked?: boolean,
    onCheckedChange?: (id: string) => void,
}
export default class CheckBoxButtonInBooking extends Component<IProps> {

    componentDidUpdate(): void {
        const { id, checked } = this.props;
        const input = document.getElementById(`${ id }`) as HTMLInputElement;
        if (input && checked) {
            input.setAttribute("checked", "checked");
        } else {
            input.checked = false;
        }
    }
    componentDidMount(): void {
        const { id, checked } = this.props;
        const input = document.getElementById(`${ id }`) as HTMLInputElement;
        if (input && checked) {
            input.setAttribute("checked", "checked");
        }
    }

    handleChange = (e: any) => {
        const { id } = this.props;
        const input = document.getElementById(`${ id }`) as HTMLInputElement;
        if (input) {
            input.checked = true;
        }
        if (e.target.checked) {
            if (this.props.onCheckedChange) {
                this.props.onCheckedChange(this.props.id);
            }
        }
    }

    render() {
        const { id, name, text, value } = this.props;
        return (
            <label htmlFor={ id } className="checkbox-btn" >
                { text }
                <input type="checkbox" id={ id } name={ name } value={ value } onChange={this.handleChange} />
                <span className="check-mark" />
            </label>
        );
    }
}