export interface IPersonalInfo {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    [key: string]:string;
}

export interface IErrorsObject {
    [key: string]: boolean|undefined;
}

export interface IJwt{
    token: string;
    isAuth: boolean;
}

export const defaultJwt = {
    token: '',
    isAuth: false
};

export interface IFeedback{
    id?: number;
    text?: string;
    rate?: number;
    user?: number;
    author?: number;
    appointment?: number;

    [key:string]:string|number|undefined;
}

export const defaultAvatar = require('../assets/human.svg');



