import React, {Component} from 'react';

interface IProps {
    checked: boolean;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

class HeartToggle extends Component <IProps> {
    render() {
        const {checked, onChange, name} = this.props;
        return (
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={onChange} name={name}/>
                <div className="heart-slider" />
            </label>
        );
    }
}

export default HeartToggle;