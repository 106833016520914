import { observable } from "mobx";

class PromoStore {
    @observable percent: null | number;
    @observable showInputPromo: boolean;
    @observable promoSuccess: boolean;
    @observable promo: string;
    @observable promoFailed: boolean;
    @observable type: string;
    @observable discount: null | number;
    [key: string]: string | boolean | null | number | Function;

    constructor() {

        this.percent = null;
        this.showInputPromo = false;
        this.promoSuccess = false;
        this.promo = '';
        this.promoFailed = false;
        this.type = 'promocode';
        this.discount = null;

    }
    clear() {
        this.percent = null;
        this.showInputPromo = false;
        this.promoSuccess = false;
        this.promo = '';
        this.promoFailed = false;
        this.type = 'promocode';
        this.discount = null;

    }

}


export default new PromoStore();