import React, { Component, RefObject, useState } from 'react';
import ValetsStore from '../../../Stores/ValetsStore';
import changeActiveItem from '../../../Utils/changeActiveItem';
import HeartToggle from '../../../Shared/HeartCheckbox';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

interface IProps {
  active: number;
}

@observer
export default class SelectValetNavbar extends Component<IProps> {
  parent: RefObject<any> = React.createRef();

  selectedCity: string = "LOS ANGELES"
  filterValets = async (event: React.MouseEvent<HTMLDivElement>) => {
    changeActiveItem(event.currentTarget as HTMLElement, 'active');
    this.selectedCity = event.currentTarget.innerText
    await ValetsStore.getValetsGroupedByCity(this.selectedCity, ValetsStore.isFavoriteOnly);
  };

  favoritChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked = false } = event.currentTarget;
    ValetsStore.isFavoriteOnly = checked
    await ValetsStore.getValetsGroupedByCity(this.selectedCity, ValetsStore.isFavoriteOnly);
  };

  render() {
    return (
      <div id={'sub_navbar'} ref={this.parent}>
        <div className={'sub_navbar_link active'} onClick={this.filterValets}>
          los angeles
        </div>
        <div className={'sub_navbar_link'} onClick={this.filterValets}>
          las vegas
        </div>
        <div className={'sub_navbar_link'} onClick={this.filterValets}>
          miami
        </div>
        <div className={'sub_navbar_link'}>
          <HeartToggle
            checked={ValetsStore.isFavoriteOnly}
            onChange={this.favoritChange}
            name=""
          />
        </div>
      </div>
    );
  }
}