import React, { Component } from "react";
import "./CheckBoxButton.css";
import { CheckBoxItem } from "./CheckBoxButton.type";
import { escape } from "querystring";
import { values } from "mobx";

interface IProps {
    checkboxs: CheckBoxItem[];
    value?: string | number;
    isSingle?: boolean;
    handleChange: (radio: any) => void;
}

interface IState {
	value: string | number;
    values: (string | number)[];
}

export default class CheckBoxButton extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const { value = -1, isSingle = false } = props;
        this.state = {
            value: value,
            values: [value]
        };
    }

    handleChange = async (event: React.ChangeEvent<HTMLInputElement>, checkbox: CheckBoxItem) => {
        const { checkboxs, isSingle = false } = this.props;
        let checked = event.target.checked;
        if (isSingle) {
            if (checkbox.value !== this.state.value) {
                await this.setState({
                    value: checkbox.value
                });
                this.props.handleChange([checkbox]);
            }
        } else {
            if (checked) {
                await this.setState(previousState => {
                    return {
                        values: [ ...previousState.values, checkbox.value]
                    };
                });
            } else {
                await this.setState(previousState => {
                    const temp_values = previousState.values.filter(
                        value => value !== checkbox.value
                    );
                    return {
                        values: temp_values
                    };
                });
            }
            let temp_checkboxs = checkboxs.map(checkbox => {
                if (this.state.values.includes(checkbox.value)) {
                    return checkbox;
                }
            });
            this.props.handleChange(temp_checkboxs);
        }
    }
    
    render() {
        const { checkboxs, isSingle = false } = this.props;
        const { value, values } = this.state;
        return (
            <div>
                { isSingle ?
                    checkboxs.map((checkbox: CheckBoxItem, index) => {
                        return (
                            <label key={index} htmlFor={ checkbox.id } className="checkbox-btn" >
                                { checkbox.label }
                                <input
                                    type="checkbox"
                                    id={ checkbox.id }
                                    name={ checkbox.name }
                                    value={ checkbox.value }
                                    checked={ value === checkbox.value }
                                    onChange={ (event) => this.handleChange(event, checkbox) }
                                />
                                <span className="check-mark" />
                            </label>
                        );
                    })
                    :
                    checkboxs.map((checkbox: CheckBoxItem, index) => {
                        return (
                            <label key={index} htmlFor={ checkbox.id } className="checkbox-btn" >
                                { checkbox.label }
                                <input
                                    type="checkbox"
                                    id={ checkbox.id }
                                    name={ checkbox.name }
                                    value={ checkbox.value }
                                    checked={ values.includes(checkbox.value) }
                                    onChange={ (event) => this.handleChange(event, checkbox) }
                                />
                                <span className="check-mark" />
                            </label>
                        );
                    })
                }
            </div>
        );
    }
}