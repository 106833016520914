import React, { Component } from "react";
import "./RadioButton.css";
import { RadioItem } from "./RadioButton.type";

interface IProps {
    radios: RadioItem[];
    value?: string | number;
    handleChange: (radio: any) => void;
}

export default class RadioButton extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        const { value = -1 } = props;
        this.state = {
            value: value
        };
    }

    handleChange = (radio: RadioItem) => {
        this.props.handleChange(radio);
    }

    render() {
        const { radios, value } = this.props;
        return (
            <div>
                {
                    radios.map((radio: RadioItem, index) => {
                        return (
                            <label key={index} htmlFor={ radio.id } className="radio-btn" >
                                { radio.label }
                                <input
                                    type="radio"
                                    id={ radio.id }
                                    name={ radio.name }
                                    value={ radio.value }
                                    checked={ value === radio.value }
                                    onChange={ () => this.handleChange(radio) }
                                />
                                <span className="check-mark" />
                            </label>
                        );
                    })
                }
            </div>
        );
    }
}