import {action, computed, observable, reaction} from "mobx";
import {ITime, ITimeStructure} from "../Interfaces/TimeInterfaces";
import BookingStore from "./BookingStore";
import {Moment} from "moment";
import {ITimeRequestParams} from "../Interfaces/BookingInterfaces";
import DateFormatting from "../Utils/DateFormatting";
import BookingService from "../Services/BookingService";
import moment from "moment";
import queryString from 'query-string';
import AddressesStore from "./AddressesStore";
import mixpanel from "mixpanel-browser";
import AuthStore from "./AuthStore";
class TimeStore {
    @observable timeStructure: ITimeStructure = DateFormatting.generateTimeTable()
    @observable showLateNightModal:boolean = false;
    @observable isLoading:boolean = false;
    @observable showAllValet:boolean = true;

    constructor(){
        reaction(()=>this.showAllValet, () => this.getAvailableTime(BookingStore.bookingData.date))
    }

    @action reserveTime = (time: string, showPopup?:boolean) => {
        this.resetReserveTime();
        const hoursQuantity = Math.round(BookingStore.bookingData.quantity / 2);
        const indexOfReservedTime = Object.keys(this.timeStructure).findIndex((timeName: string) => timeName === time);
        for (let i = indexOfReservedTime; i < indexOfReservedTime + hoursQuantity; i++) {
            const times = Object.keys(this.timeStructure);
            const timeName = times[i];
            if (this.timeStructure[timeName] && this.timeStructure[timeName].available) {
                this.timeStructure[timeName].reserved = !this.timeStructure[timeName].reserved;
            } else {
                this.resetReserveTime();
                return;
            }
        }
        this.showLateNightModal = !!showPopup;


    };

    @action resetReserveTime = () =>
        Object.keys(this.timeStructure).forEach((hour: string) => this.timeStructure[hour].reserved = false);

    @computed get reservedHoursQuantity(){
        return Object.values(this.timeStructure)
            .filter((timeData:ITime) => timeData.reserved)
            .length;
    }

    @computed get appointmentStartTime(){
        const times = Object.keys(this.timeStructure);
       const index = times.findIndex((time:string) =>
            this.timeStructure[time].reserved);
       return times[index];
    }

    @action getAvailableTime = async (date: Moment = moment(), valetID?:number|null, city?:string|null) => {
        try {  
            const parsed: any = queryString.parse(window.location.search);
            this.isLoading = true;
            const any = this.showAllValet;
            const params: ITimeRequestParams = {
                date: DateFormatting.apiFormat(date),
                city: BookingStore.bookingData.address.city
            };

            const address = AddressesStore.addressesSettings.find(e  => e.id === AddressesStore.defaultAddressId) || null;
            
            const addressCity = address && address.city;
            city = city || addressCity;
            let response:any;

            console.log("GetAvailableTime Parsed ValetID = ", parsed.valetID, " ValetID = ", valetID);
            if(parsed.valetID || valetID){ // GET AVAILABLE TIME FOR VALET
                response = await BookingService.getValetAvailableTime(parsed.valetID || valetID, {...params, any});
            }
            else /* GET AVAILABLE TIME FOR ALL VALEST*/ response = await BookingService.getAvailableTimes(null, {...params, city});
            if (!response) return null;

            for (const key in  this.timeStructure) {
                if ( this.timeStructure.hasOwnProperty(key)) {
                    const element =  this.timeStructure[key];
                    element.available = false;
                }
            }

            response.available_times.map((time:string) => this.timeStructure[time].available = true);
            this.timeStructure =  {...this.timeStructure };
            mixpanel.track("Available time", {
              available_times_counter: response.available_times.length,
              params,
              city,
              valet: {
                id: parsed.valetID || valetID
              }
            });
        } catch (e) {
            mixpanel.track("Available time ERROR", {
              date,
              valetID,
              city,
              error: e
            });
            throw new Error(e.message);
        } finally {
            this.isLoading = false;
        }
    };

    @action getAvailableTimeByZipCode = async (date: Moment = moment(), zipCode:string) => {
        try {  
            this.isLoading = true;
            const params = {
                date: DateFormatting.apiFormat(date),
                zip_code: zipCode
            };
;
            let response: any = await BookingService.getAvailableTimesByZipCode(params);
            if (!response) return null;

            for (const key in  this.timeStructure) {
                if ( this.timeStructure.hasOwnProperty(key)) {
                    const element =  this.timeStructure[key];
                    element.available = false;
                }
            }

            response.available_times.map((time:string) => this.timeStructure[time].available = true);
            this.timeStructure =  {...this.timeStructure };
            mixpanel.track("Available time", {
              available_times_counter: response.available_times.length,
              params,
            });
        } catch (e) {
            mixpanel.track("Available time ERROR", {
              date,
              zipCode,
              error: e
            });
            throw new Error(e.message);
        } finally {
            this.isLoading = false;
        }
    };


    @action getValetAvailableTime = async (valetId: number,date:Moment = moment()) => {
        try {
            this.isLoading = true;
            const params: ITimeRequestParams = {
                date: DateFormatting.apiFormat(date)
            }
            const response: any = await BookingService.getValetAvailableTime(valetId, params);
            if (!response) return null;
            response.available_times.forEach((time:string) => this.timeStructure[time].available = true);
            this.timeStructure =  {...this.timeStructure };
            mixpanel.track("Available time for Valet", {
              available_times_counter: response.available_times.length,
              params,
              valet: valetId
            });
        } catch (e) {
            mixpanel.track("Available time ERROR", {
              date,
              valetId,
              error: e
            });
            throw new Error(e.message);
        } finally {
            this.isLoading = false;
        }
    };
}

export default new TimeStore();
