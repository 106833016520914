import React from 'react';
import Button from 'react-bootstrap/Button';
import { ISubscription } from '../../../Interfaces/ProfileInterfaces';

interface IProps {
  subscription: ISubscription;
  onClick: (id: number) => void;
  active?: boolean | null | undefined;
}

const SubscriptionItem = (props: IProps) => {
  const { total_price, subscription_type, id, appointments_left } = props.subscription;
  const handleClick = () => props.onClick(id);
  return (
    <Button
      className={`d-flex flex-column subscription_item justify-content-center align-items-center ${
        props.active && 'active'
        }`}
      onClick={handleClick}
    >
      <h6 className={`subscription_title ${props.active ? 'active' : ''}`}>{subscription_type}</h6>
      <p className={`subscription-price-container ${props.active ? 'active' : ''}`}>
        <b>
          $<span className={`subscription-price ${props.active ? 'active' : ''}`}>{total_price}</span>
        </b>{' '}
        / month
      </p>
      <p className={`subscription-sprays-per-month ${props.active ? 'active' : ''}`}>{appointments_left} spray tans a month</p>
    </Button>
  );
};

export default SubscriptionItem;
