import React, {Component, FormEvent} from "react";
import arrow_left from "../assets/icons/arrow_left.svg";
import Form from "react-bootstrap/Form";
import YellowButton from "../Shared/Buttons/YellowButton";
import {BsPrefixProps, ReplaceProps} from "react-bootstrap/helpers";
import {FormControlProps} from "react-bootstrap";

import {observer} from "mobx-react";
import AddressesStore from "../Stores/AddressesStore";


interface IProps {
    onBack?: () => void;
    saveCard: ( data: {[key: string]: string}  ) => void;
    onChange: (name: string, value: string) => void;
}

@observer
export default class HotelForm extends Component<IProps> {
    state = {
      hotel_name: AddressesStore.addressObject.hotel_name,
      room_number: AddressesStore.addressObject.room_number,
      code: AddressesStore.addressObject.code
    }

    changeData = (event: FormEvent<ReplaceProps<"input", BsPrefixProps<"input"> & FormControlProps>>) => {
        const {id, value=''} = event.currentTarget;
        if (id) {
            this.setState({[id]: value});
            this.props.onChange(id, value);
        }
    };
    
    save = () => {
        this.props.saveCard(this.state);
    };

    render() {
        const {onBack} = this.props;
        // const {hotel_name, room_number, code} = AddressesStore.addressObject;
        return (
          <div className={"hotel_form"}>
            <div className={"container hotel_information small_container"}>
              {onBack && (
                <div className="back_button" onClick={onBack}>
                  <img src={arrow_left} alt={"arrow_left"} />
                  Back
                </div>
              )}
              <h1>Hotel information</h1>
              <Form>
                <Form.Row>
                  <Form.Group controlId={"hotel_name"} className={"w-100"}>
                    <Form.Label>Hotel Name</Form.Label>
                    <Form.Control
                      type={"text"}
                      placeholder={""}
                      onChange={this.changeData}
                      value={this.state.hotel_name}
                    />
                    {AddressesStore.errorsOnForm.hotel_name && (
                      <span className={"inline_error"}>
                        {AddressesStore.errorsOnForm.hotel_name}
                      </span>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId={"room_number"} className={"w-100"}>
                    <Form.Label>Room number</Form.Label>
                    <Form.Control
                      type={"text"}
                      placeholder={""}
                      onChange={this.changeData}
                      value={this.state.room_number}
                    />
                    {AddressesStore.errorsOnForm.room_number && (
                      <span className={"inline_error"}>
                        {AddressesStore.errorsOnForm.room_number}
                      </span>
                    )}
                  </Form.Group>
                </Form.Row>
                {/* <Form.Row>
                  <h4 className={"text-uppercase mb-1"}>
                    is there an entrance code?
                  </h4>
                  <Form.Group controlId={"code"} className={"w-100"}>
                    <Form.Label>Door/Gate Code</Form.Label>
                    <Form.Control
                      type={"text"}
                      placeholder={""}
                      onChange={this.changeData}
                      value={this.state.code}
                    />
                    {AddressesStore.errorsOnForm.code && (
                      <span className={"inline_error"}>
                        {AddressesStore.errorsOnForm.code}
                      </span>
                    )}
                  </Form.Group>
                </Form.Row> */}
                <YellowButton
                  onClick={this.save}
                  value={"save"}
                  additionalClass={"w-100"}
                  spinner={AddressesStore.isLoading}
                />
              </Form>
            </div>
          </div>
        );
    }
}