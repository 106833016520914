import {action, computed, observable, reaction} from "mobx";
import NotificationServices from "../Services/NotificationServices";
import AuthStore from "./AuthStore";
import ModalStore from "./ModalStore";

interface IMetaOfNotification {
    date: string;
    new_price: number;
    old_price: number;
}

export type NotificationTypes =
    'feedback'
    | 'info'
    | 'reschedule'
    | 'appointment'
    | 'cc_expiring'
    | 'new_message'
    | 'pick_appointment'
    | "add_people"
    | 'cancel_appointment';

export interface IUserNotification {
    id: number;
    read: boolean;
    text: string;
    type: NotificationTypes;
    date_created: number;
    meta: IMetaOfNotification
    valet_id: number | null;
    appointment_id: number | null;
    feed_id: number | null;
    user: number;
    is_active:boolean;

    [key: string]: string | boolean | number | NotificationTypes | IMetaOfNotification | null;
}

class NotificationsStore {
    @observable notificationList: IUserNotification[] = [];
    @observable isLoading: boolean = true;
    @observable errorsOnComponent = {
        modalError: ''
    };
    page: number = 1;
    totalPages: number = 0;

    constructor() {
        reaction(() => AuthStore && AuthStore.isLogged, (isLogged: boolean) => {
            if (isLogged) {
                // check new notifications every 5 minutes
                setInterval(
                    () => this.getNotifications(), 30000
                )
            }
        })
    }

    @action setError = (message: string) => {
        this.errorsOnComponent.modalError = message;
        ModalStore.toggleModal("notificationError", true);
    }

    @action getNotifications = async (limit: number = 5, page: number = 0) => {
        try {
            this.isLoading = true;

            const response: any = await NotificationServices.getNotifications(limit, this.page);
            this.totalPages = response.total_pages;
            if (page) {
                this.notificationList.push(...response.results);
                return;
            }
            this.notificationList = [...response.results, ...this.notificationList.slice(limit),];
        } catch (e) {
           throw new Error(e.message);
        } finally {
            this.isLoading = false;
        }
    };

    @action markAsRead = async (id: number) => {
        try {
            this.isLoading = true;
            const response: any = await NotificationServices.readNotification(id, {read: true});
            const indexOfNotification = this.notificationList.findIndex((notification:IUserNotification) =>
                notification.id === id);
            this.notificationList[indexOfNotification] = response;
        } catch (e) {
            throw new Error(e.message);
        } finally {
            this.isLoading = false;
        }
    };

    @action makeInactive = async (id:number) => {
        try{
            this.isLoading = true;
            const response:any = await NotificationServices.readNotification(id, {is_active:false});
            const indexOfNotification = this.notificationList.findIndex((notification:IUserNotification) =>
                notification.id === id);
            this.notificationList[indexOfNotification] = response;
        }catch (e) {
            console.log(e);
        }finally {
            this.isLoading = false;
        }
    }

    @computed get newNotification() {
        return this.notificationList.filter((notification: IUserNotification) =>
            notification.read === false).length
    }
}

export default new NotificationsStore();