import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ValetComponent from './ValetComponent';
import { Redirect, RouterProps } from 'react-router';
import ValetsStore from '../../../Stores/ValetsStore';
import AuthStore from '../../../Stores/AuthStore';
import { IValet } from '../../../Interfaces/ValetInterfaces';
import RebookNavbar from './RebookNavbar';

@observer
export default class RebookValet extends Component<RouterProps> {
  componentDidMount(): any {
    if (!AuthStore.isLogged) return <Redirect to={'/'} />;
    ValetsStore.getListOfUserValets();
  }

  render() {
    return (
      <div className="gray_background rebook">
        <RebookNavbar active={0} />
        <div
          className={'back_button_container'}
          onClick={this.props.history.goBack}
        >
          <img
            src={require('../../../assets/icons/arrow_left.svg')}
            alt={'arrow left'}
          />
          Back
        </div>
        <div className="d-flex flex-wrap justify-content-start valet_container">
          {ValetsStore.listOfValets.map((valet: IValet, index: number) => (
            <ValetComponent valet={valet} key={index} />
          ))}
        </div>
      </div>
    );
  }
}
