import React, { Component, useEffect, useState} from 'react';
import AuthStore from './Stores/AuthStore';
import './App.css';
import MainRouter from './Routers/MainRouter';
import { observer} from 'mobx-react';
import { action, autorun } from "mobx"
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import MainNavbar from './Shared/Navbars/MainNavbar';
import UnregisteredNavbar from './Shared/Navbars/UnregisteredNavbar';
import SpinnerComponent from './Shared/Spinner';
import ReactPixel from 'react-facebook-pixel';
import FacebookConversionApiService from './Services/FacebookConversionApiService';
import mixpanel from 'mixpanel-browser';
import { StripeProvider } from 'react-stripe-elements';
import config from './config';
import ReactGA from 'react-ga4';
import Popup from './Pages/Popups/Popup';
import { PopupContent } from './Constants/PopupContent';
import PromoPopup from './Pages/Popups/PromoPopup';
import CustomNavBar from './Shared/Navbars/CustomNavBar';

if (config.isProd) {
  ReactGA.initialize('G-B4PSRZ7KNW', {
    // debug: true,
  });
}

export const history = createBrowserHistory();

@observer
class App extends Component<React.FC> {
  state = { showPromoPopup: false,
    showPromoSuccess: false, 
    showPromoError: false
  };

  componentDidMount  () {
    if (config.REACT_APP_FACEBOOK_PIXEL_ID) {
      ReactPixel.init(config.REACT_APP_FACEBOOK_PIXEL_ID);
      ReactPixel.trackCustom('StartBooking', {event_time: Math.floor(Date.now() / 1000)});

      const event_data_start_booking = {
        data: {
          user_data: {
            client_user_agent: window.navigator.userAgent,
            fbp: ''
          }
        }
      };

      let fbpRes = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);

      if ((fbpRes && fbpRes[1])) {
        event_data_start_booking.data.user_data.fbp = fbpRes[1];
        FacebookConversionApiService.trackStartBooking(event_data_start_booking);
      }
    } else {
      console.log('Disabled FB');
    }

    // autorun(() => {
    //   if (AuthStore.ready){
    //   console.log("AuthStore is ready");
    //   if (!AuthStore.isLogged){
    //     console.log("Need to show popup")
    //     setTimeout(() => {
    //       if (!AuthStore.isLogged && history.location.pathname.toLowerCase() !== "/login" && history.location.pathname.toLowerCase() !== "/signin"){
    //         this.setState({showPromoPopup: true})
    //       }
    //     }
    //     , 30000);
    //   }
    // }
    // })
  }

  render() {
    history.listen((location, action) => {
      // location is an object like window.location
      mixpanel.track(`#${location.pathname}`, { url: location.pathname });

      ReactPixel.pageView();

      const pageview_event_data = {
        data: {
            user_data: {
              client_user_agent: window.navigator.userAgent,
              fbp: ''
            },
            custom_data: {
              page_path: location.pathname
            }
        }
      };

      let fbpRes = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
      if ((fbpRes && fbpRes[1])) {
        pageview_event_data.data.user_data.fbp = fbpRes[1];
        FacebookConversionApiService.trackPageView(pageview_event_data);
      }
    });

    return (
      <Router history={history}>
        {AuthStore.ready ? (
          <>
            {AuthStore.isLogged ? <MainNavbar /> : <UnregisteredNavbar />}
            <StripeProvider apiKey={config.STRIPE_TOKEN}>
              <MainRouter />
            </StripeProvider>
          </>
        ) : (
          <SpinnerComponent variant={'warning'} size={'lg'} />
        )}

        <PromoPopup
          onDismiss={
            (success) => {
              this.setState({showPromoPopup: false}) 
              success? this.setState({showPromoSuccess: true}) : this.setState({showPromoError: true})
            }
          }
          onHide={() => this.setState({showPromoPopup: false})}
          show={this.state.showPromoPopup}
        />

        <Popup
          title={"congratulations!"}
          id={'firstTimePromoSuccess'}
          content={PopupContent.firstTimePromoSuccess}
          onHide={() => this.setState({showPromoSuccess: false})}
          show={this.state.showPromoSuccess}
        />

        <Popup
          title={"Sorry, we can not provide Promo Code!"}
          id={'firstTimePromoFailed'}
          content={PopupContent.firstTimePromoFailed}
          onHide={() => this.setState({showPromoError: false})}
          show={this.state.showPromoError}
        />

      </Router>
    );
  }
}

export default App;
