import {defaultApiProfile, IApiProfile} from "./ProfileInterfaces";

export interface IValet {
    valet:IApiProfile;
    favorite: boolean;
    only?: boolean;
    preferred?: boolean;
    [key:string]:IApiProfile|boolean|undefined;
};

export const defaultValet: IValet = {
    valet: defaultApiProfile,
    favorite: false,
    only: false,
    preferred: false
};
