import moment, { Moment } from "moment";
import { ITimeStructure } from "../Interfaces/TimeInterfaces";

const DateFormatting = {
  bookingTitleDate: (date: Date | Moment | string) => {
    return moment(date).isValid() ? moment(date).format("dddd, Do MMMM") : "";
  },
  week: (date: string | Date | Moment) => {
    const week = [];
    for (let i = 0; i < 7; ++i) {
      if (i === 0) week.push(moment(date));
      else week.push(moment(date).add(i, "days"));
    }
    return week;
  },
  subtractWeek: (date: string | Date | Moment) => {
    const week = [];
    const firstDay = moment(date).subtract(1, "day");
    for (let i = 0; i < 7; i++) {
      if (i === 0) week.push(firstDay);
      else week.push(moment(firstDay).add(i, "days"));
    }
    return week;
  },
  fromNow: (date: string | undefined) =>
    moment(date).isValid() ? moment(date).fromNow() : "",
  calendarDate: (date: Date | Moment | number | string) =>
    moment(date).isValid() ? moment(date).calendar() : "",
  usFormat: (date: Date | string | Moment | string) =>
    moment(date).isValid() ? moment(date).format("MMM DD, YYYY") : "",
  apiFormat: (date: Date | Moment | string) =>
    moment(date).format("YYYY-MM-DD"),
  dateDiffWithToday: (date: Moment | string | Date, time: string) => {
    const dateMoment = moment(date, moment.HTML5_FMT.DATETIME_LOCAL_MS);
    const momentTime = moment(time, "LT");
    const dateObject = moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      day: dateMoment.date(),
      hour: momentTime.hour(),
      minute: momentTime.minute()
    });
    return dateObject.diff(moment(), "hours");
  },
  dateAndTime: (date: Moment | string, time?: string) => {
    if (!moment(date).isValid()) {
      return "";
    }
    const dateMoment = moment(date);
    const momentTime = moment(time, "LT");
    return moment({
      year: dateMoment.year(),
      month: dateMoment.month(),
      day: dateMoment.date(),
      hour: momentTime.hour(),
      minute: momentTime.minute()
    }).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
  },
  time: (date: Moment | string | Date) =>
    moment(date).isValid() ? moment(date).format("hh:mm A") : "",
  fromDateAndTime: (data: Moment) => {
    return [DateFormatting.apiFormat(data), DateFormatting.time(data)];
  },
  generateTimeTable: function(): ITimeStructure {
    const startHour = 0;
    const endHour = 23;
    const hourMap: ITimeStructure = {};
    const startDate = moment()
      .hours(0)
      .minutes(0);

    for (let i = startHour; i <= endHour; i++) {
      const hourTime = moment(startDate).add(i, "hour");
      const halfHourTime = moment(hourTime).add(30, "minutes");

      const TimeArr = [hourTime, halfHourTime];
      TimeArr.forEach((time: Moment) => {
        let timeStr = DateFormatting.time(time);
        if (timeStr === "12:00 AM") timeStr = "00:00 AM";
        if (timeStr === "12:30 AM") timeStr = "00:30 AM";
        hourMap[timeStr] = {
          available: false,
          reserved: false,
          period: DateFormatting.getPeriodOfDay(time)
        };
      });
    }

    return hourMap;
  },

  getPeriodOfDay(time: Moment) {
    const FORMAT = "hh:mm A";
    const afterMorning = moment("12:00 AM", FORMAT);
    const beforeMorning = moment("11:59 AM", FORMAT);

    const afterAfternoon = moment("12:00 PM", FORMAT);
    const beforeAfternoon = moment("04:59 PM", FORMAT);

    const afterEvening = moment("05:00 PM", FORMAT);
    const beforeEvening = moment("11:59 PM", FORMAT);

    const MomentTIme = moment(DateFormatting.time(time), "hh:mm A");

    if (MomentTIme.isBetween(afterMorning, beforeMorning, "hours", "[]"))
      return "morning";
    if (MomentTIme.isBetween(afterAfternoon, beforeAfternoon, "hours", "[]"))
      return "afternoon";
    if (MomentTIme.isBetween(afterEvening, beforeEvening, "hours", "[]"))
      return "evening";
    return "not-set";
  }
};
export default DateFormatting;
