import React, {Component} from 'react';
import Button from "react-bootstrap/Button";

export interface IWhiteButton{
    onClick: (event:React.MouseEvent<HTMLButtonElement>)=>void;
    value: string;
    addClass?: string;
}

export default class WhiteButton extends Component<IWhiteButton>{
    render(){
        const {onClick, value, addClass} = this.props;
        return (
            <Button className={`login_button ${addClass||""}`} onClick={onClick} >{value}</Button>
        );
    }
}