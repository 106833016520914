import React, { Component } from "react";
import BookingStore from "../Stores/BookingStore";
import { observer } from "mobx-react";
import TimeStore from "../Stores/TimeStore";

interface IState {
  value: number;
  min: number;
  max: number;
}

interface IProps {
  value: number;
  disabled?: boolean;
}

@observer
class CustomInputNumber extends Component<IProps, IState> {
  state = {
    value: this.props.value,
    min: 1,
    max: 100
  };
  plus = async () => {
    if (this.props.disabled) return;
    if (this.state.value < this.state.max) {
      await this.setState({ value: this.state.value + 1 });
      BookingStore.changeBookingData("quantity", this.state.value);
    }
  };
  minus = async () => {
    if (this.props.disabled) return;
    if (this.state.value > this.state.min) {
      await this.setState({ value: this.state.value - 1 });
      BookingStore.changeBookingData("quantity", this.state.value);
    }
  };
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    if (value > this.state.min && value < this.state.max) {
      this.setState({ value: value });
      BookingStore.changeBookingData("quantity", value);
    }
  };
  render() {
    const numberOfNeededHours =
      Math.round(BookingStore.bookingData.quantity / 2) -
      TimeStore.reservedHoursQuantity;
    return (
      <div className={"custom_input_container d-flex align-items-center"}>
        <label>How many persons? </label>
        <input
          type={"number"}
          min={this.state.min}
          max={this.state.max}
          step={1}
          id={"custom_input"}
          value={this.state.value}
          onChange={this.onChange}
        />
        <div className="quantity-nav">
          <div className="quantity-button quantity-up" onClick={this.plus}>
            <i className={"fas fa-angle-up"} />{" "}
          </div>
          <div className="quantity-button quantity-down" onClick={this.minus}>
            <i className={"fas fa-angle-down"} />{" "}
          </div>
        </div>
        <span
          className={`small_text ${
            this.state.value >= 3 ? "visible" : "invisible"
          }`}
        >
          {`To serve ${this.state.value} person(s) you need to book ${numberOfNeededHours} more hour(s).`}
        </span>
      </div>
    );
  }
}

export default CustomInputNumber;
