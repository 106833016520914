import React, {Component} from 'react';
import Navbar from "react-bootstrap/Navbar";
import ReactGA from 'react-ga4';
import {RouteComponentProps, withRouter} from "react-router";
import {pagesWithoutNavbar} from "../../Constants/PagesWithoutNavbar";
import "./CustomNavBar.css";
import logo_icon from "./../../assets/Logo1.svg";
import config from '../../config';

interface IProps extends RouteComponentProps{
  redirction?: boolean;
}

class CustomNavbar extends Component <IProps>{
  componentDidMount() {
    if (config.isProd) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }

  onLoad = (event:React.UIEvent<HTMLElement>) => {
      if (pagesWithoutNavbar.includes(this.props.location.pathname)){
          event.currentTarget.classList.add('hide');
      }
      const navElement = document.getElementById('main_navbar');
      if (navElement) {
        navElement.classList.add('hide');
      }
      const navElement1 = document.getElementById('unregister_navbar');
      if (navElement1) {
        navElement1.classList.add('hide');
      }
  };

  onCloseBtn = () => {
    const { redirction = true } = this.props;
    if (redirction) {  
      this.props.history.push('/leave'); 
    } else {
      window.location.href = config.baseUrlForNavLinks;
    }
  };

  render(){
    return (
      <Navbar id={"custom_navbar"} onLoad={this.onLoad}>
        <Navbar.Brand href={config.baseUrlForNavLinks}>
          <img src={logo_icon} alt={"logo"}/>
        </Navbar.Brand>
        <img 
          onClick={this.onCloseBtn}
          src={require("../../assets/icons/closeIcon-yellow.svg")} alt={"close"} className='navbar_closeBtn'></img>
      </Navbar>
    );
  }
}

export default withRouter(CustomNavbar);