export interface IHotelInfo {
    hotel_name: string;
    room_number: string;
    door_code: string;

    [key: string]: string;
}

export const defaultHotelInfo: IHotelInfo = {
    hotel_name: '',
    room_number: '',
    door_code: '',
};

export interface IAddress {
    id:number;
    address: string;
    selected_parking_option: string;
    note_parking: string;
    hotel_name: string;
    room_number: string;
    code: string;
    city: string;
    state?: string;
    zip_code: string;
    extra_fee: boolean;
    latitude: number,
    longitude: number,
    is_hotel: boolean,
    apartment: string;
    gate_code: string;
    [key: string]: string | boolean | undefined | number;
}


export const defaultLocationInfo = {
    id: 0,
    address: '',
    city: '',
    selected_parking_option: 'valet_service',
    note_parking: '',
    hotel_name: '',
    room_number: '',
    code: '',
    extra_fee: false,
    zip_code: '',
    longitude: 0,
    latitude: 0,
    is_hotel: false,
    apartment: '',
    gate_code: '',
};

export const defaultErrorsOnForm = {
    note_parking: "",
    hotel_name: "",
    room_number: "",
    code: "",
    city: "",
    zip_code: "",
    extra_fee: "",
    latitude: '',
    longitude: '',
    apartment: '',
    gate_code: '',
};

 export const defaultAddressModals = {
    extraFee: false,
    outOfService: false,
    isError: false,
  };



export interface IAddressModals {
    extraFee: boolean;
    outOfService: boolean;
    isError: boolean;
    [key: string]: boolean;
}

export interface IParkingOptions {
    [key: string]: string;
}
