import React from 'react';
import RoundButton from '../../Shared/Buttons/RoundButton';
import Popup from '../Popups/Popup';
import { PopupContent } from '../../Constants/PopupContent';
import DateFormatting from '../../Utils/DateFormatting';
import MyBookingsStore from '../../Stores/MyBookingsStore';
import { IBookingData } from '../../Interfaces/BookingInterfaces';
import DropdownActionButton from '../../Shared/Buttons/DropdownActionButton';
import { Button } from 'react-bootstrap';
import { defaultLocationInfo } from '../../Interfaces/AddressesInterface';
import { defaultAvatar } from '../../Interfaces/OtherInterfaces';
import { defaultApiProfile } from '../../Interfaces/ProfileInterfaces';
import moment from 'moment';
import BookingStore from '../../Stores/BookingStore';
import TimeStore from '../../Stores/TimeStore';
import ValetsStore from '../../Stores/ValetsStore';
import { RouterProps } from 'react-router';
import BookingService from '../../Services/BookingService';
import errorsMessage from '../../Constants/errorsMessage';

interface IProps extends RouterProps {
  data: IBookingData;
  editBooking: (id: number) => void;
  rescheduleItem: (id: number, valetId: number | null) => void;
  disabled: boolean;
}

const MyBookingItem = (props: IProps) => {
  const editBooking = async () => {
    await BookingStore.createNewBooking(props.data.valet.id, undefined);
    await TimeStore.getAvailableTime(moment(), props.data.valet.id);
    ValetsStore.isUserHasOnlyValet = true;
    props.history.push(`/booking_address?valetID=${props.data.valet.id}`);
  };
  const rescheduleBooking = async () => {
    try {
      await BookingService.rescheduleItem(id);
    } catch (error) {
      if (error.message.detail === errorsMessage.myBookings.rescheduling) {
        await setModalShow4(true);
        return;
      }
    }
    props.rescheduleItem(id, is_selected_valet? valet.id : null);
  };

  const openConversation = async () => {
    try {
      props.history.push(`/messages?conversationId=${props.data.chat[0]}`);
    } catch (error) {
      console.log(error);
    }
  };

  const approveChanges = async (isApprove: boolean) => {
    await MyBookingsStore.approveBooking(id, isApprove);
  };
  const cancelBooking = async () => {
    await MyBookingsStore.cancelBooking(id);
    setModalShow1(false);
    setModalShow6(false);
  };

  const showCancelConfirmation = () => {
    let { status } = props.data;
    if (status && status.toLowerCase() === 'pending'){
      cancelBooking();
      return;
    }
    const datesDiff = DateFormatting.dateDiffWithToday(date, time);
    if (micro_status === 'Valet on my way') {
      setModalShow6(true);
      return;
    }
    setDiffTime(datesDiff);
    datesDiff < 24 ? setModalShow1(true) : cancelBooking();
  };

  const {
    date = new Date(),
    price = '',
    id,
    purchase_method,
    confirmed_by_client,
    confirmed_by_valet,
    micro_status,
    available_to_call,
    phone,
  } = props.data;
  let { address, valet, status, is_selected_valet} = props.data;
  const time = moment(date).format('LT');
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [modalShow6, setModalShow6] = React.useState(false);
  const [modalShow7, setModalShow7] = React.useState(false);
  const [diffTime, setDiffTime] = React.useState(24);

  // const disabled = status === ("pending" || "upcoming");
  if (address === null) {
    address = defaultLocationInfo;
  }
  if (valet === null) valet = defaultApiProfile;
  if (status === null) status = '';

  return (
    <tr>
      <td className={'valet_td'}>
        <Popup
          title={'Cancel booking?'}
          showConfirmation
          content={PopupContent.cancelBooking(diffTime)}
          disabled={props.disabled}
          id={`cancelBooking-${id}`}
          onClick={cancelBooking}
          onHide={() => setModalShow1(false)}
          show={modalShow1}
        />
        <Popup
          title={'Edit booking?'}
          showConfirmation
          disabled={props.disabled}
          content={PopupContent.editBooking(!confirmed_by_valet)}
          id={`editBooking-${id}`}
          onClick={() => props.editBooking(id)}
          onHide={() => setModalShow3(false)}
          show={modalShow3}
        />
        <Popup
          title={'Re-schedule booking?'}
          disabled={props.disabled}
          content={PopupContent.clientReschedule}
          id={`Re-schedule-${id}`}
          onClick={() => props.editBooking(id)}
          onHide={() => setModalShow4(false)}
          show={modalShow4}
        />
        <Popup
          title={'Cancel booking?'}
          showConfirmation
          content={PopupContent.valetOnMyWay()}
          disabled={props.disabled}
          id={`cancelBooking-${id}`}
          onClick={cancelBooking}
          onHide={() => setModalShow6(false)}
          show={modalShow6}
        />
        <Popup
          title={''}
          content={'You can contact the valet 2 hours from your appointment'}
          id={`modal2Hours-${id}`}
          onClick={() => setModalShow7(false)}
          onHide={() => setModalShow7(false)}
          show={modalShow7}
        />

        <div className={' d-flex'}>
          <div className={'myBooking_avatar'}>
            <img src={valet.avatar_url || defaultAvatar} alt={'avatar'} />
          </div>
          <div className={'align-self-center full_name'}>
            {valet.first_name ? <div>{`${valet.first_name}`}</div> : <div>To be defined</div>}
            <div>
              <img
                src={require(`../../assets/icons/${valet.rating === 5 ? '5' : '4'}stars.svg`)}
                alt={'star'}
              />
              {valet.rating}
            </div>
          </div>
        </div>
      </td>
      <td className={'date_td'}>{DateFormatting.usFormat(date)}</td>
      <td className={'time_td'}>{time}</td>
      <td className={'address_td'}>{address.address}</td>
      <td className={'billing_type_td text-center'}>{purchase_method || '-'}</td>
      <td className={'price text-center'}>{price ? `$${price}` : '-'}</td>
      <td className={'status_td'}>
        <div className="d-flex status">
          <div className={`status_indicator ${status && status.toLowerCase()}`} />
          {status}
        </div>
      </td>
      <td className={'myBooking_buttons'}>
        <div className="d-flex justify-content-center align-items-center flex-md-row flex-column">
          <RoundButton
            _onClick={() => {
              console.log(`MY BOOKING ${phone}`);
              if (!available_to_call) {
                setModalShow7(true);
              }
            }}
            value={
              <a href={available_to_call ? `tel:${phone}` : undefined} target="_top">
                <i className="fas fa-phone" />
              </a>
            }
          />
          <RoundButton value={<i className="fas fa-envelope-open" />} _onClick={openConversation} />
        </div>
      </td>
      {!confirmed_by_client && status && status.toLowerCase() !== 'cancelled' ? (
        <DropdownActionButton
          disabled={props.disabled}
          title={'Approve'}
          editBooking={() => approveChanges(true)}
          showCancelConfirmation={() => approveChanges(false)}
          dropdownButtons={['confirm', 'decline']}
        />
      ) : status && (status.toLowerCase() === 'upcoming' || status.toLowerCase() === 'pending') ? (
        <DropdownActionButton
          disabled={props.disabled}
          title={'Action'}
          editBooking={rescheduleBooking}
          showCancelConfirmation={showCancelConfirmation}
          dropdownButtons={['Re-schedule', 'Cancel Booking']}
        />
      ) : (
        <td className={'dropdown'}>
          <Button onClick={editBooking} className={'btn btn-outline-secondary'}>
            Book Again
          </Button>
        </td>
      )}
    </tr>
  );
};

export default MyBookingItem;
