import React, {Component} from 'react';
import ValetsStore from "../Stores/ValetsStore";
import {observer} from "mobx-react";

interface IState{
    mark: number;
}

@observer
export default class Stars extends Component<any, IState>{
    state = {
        mark: 5,
    };
    stars:JSX.Element[]=[];
    componentDidMount() {
        ValetsStore.changeFeedBack('rate', this.state.mark);
    }
    
    hoverStar = (event: React.MouseEvent<HTMLElement>, i: number) => {
        const relatives = event.currentTarget.parentNode && event.currentTarget.parentNode.children;
        if (relatives) {
            [].forEach.call(relatives, (el: HTMLElement, index: number) => {
                if (el.tagName === 'I') {
                    el.classList.remove('active');
                    if (index <= i) {
                        el.classList.add('active');
                    }
                }
            })
        }
    };
    clickStar = async (event: React.MouseEvent<HTMLElement>, i: number) => {
        const relatives = event.currentTarget.parentNode && event.currentTarget.parentNode.children;
        await this.setState({mark: i + 1});
        ValetsStore.changeFeedBack('rate', this.state.mark);
        if (relatives) {
            [].forEach.call(relatives, (el: HTMLElement, index: number) => {
                if (el.tagName === 'I') {
                    el.classList.remove('active');
                    if (index <= i) {
                        el.classList.add('active');
                    }
                }
            })
        }
    };

    render(){
        for (let i = 0; i < 5; i++) {
            this.stars.push(
                <i
                    className={'fas fa-star active'}
                    onClick={(event) => this.clickStar(event, i)}
                    key={i}
                    onMouseEnter={(event) => this.hoverStar(event, i)}
                    onMouseOut={(event) => this.hoverStar(event, this.state.mark - 1)}

                />)
        }
        return (
            <div className="stars d-flex">
                {this.stars} <span className={'mark-class'}>{this.state.mark}</span>
            </div>
        );
    }
}