export const hideNavbar = (navbar:HTMLElement) => {
        [].forEach.call(navbar.children, (item: HTMLElement) => {
            if (window.innerWidth < 993){
                if (item.classList.contains('navbar-toggler')) {
                    item.click();
                }
            } else{
                hideNotifications(navbar);
            }
        })
};
export const hideNotifications = (navbar:HTMLElement) => {
    const button = navbar.querySelector('.notifications>.dropdown-toggle') as HTMLAnchorElement;
    if (button) button.click();
}
