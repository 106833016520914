import React, { ChangeEvent, Component } from 'react';
import Logo from '../../../assets/Logo.svg';
import LoginInput from '../../../Shared/LoginInput';
import YellowButton from '../../../Shared/Buttons/YellowButton';
import { observer } from 'mobx-react';
import { RouterProps } from 'react-router';
import StepsComponent from '../../../Shared/StepsComponent';
import AuthStore from '../../../Stores/AuthStore';
import { Link } from 'react-router-dom';
import AddressAutocomplete from '../../../Utils/AutoCompleteAddress';
import ProfileStore from '../../../Stores/ProfileStore';
import AddressesStore from '../../../Stores/AddressesStore';
import Popup from '../../Popups/Popup';
import { PopupContent } from '../../../Constants/PopupContent';
import parkingOptions from '../../../Constants/ParkingOptions';
import HotelForm from '../../../Components/HotelForm';
import HotelInformationPopup from '../../Booking/Location/HotelInformationPopup';

@observer
export default class SignInLocationInfo extends Component<RouterProps> {
  state = { is_hotel: false, showHotel: false, e: '', showHotelInformationPopup: false};
  registrationDone = async () => {
    this.setState({ e: '' });
    try {
      await AddressesStore.saveAddressObjectData();
      if (
        AuthStore.isLogged &&
        !Object.values(AddressesStore.modals).includes(true)
      ) {
        this.goNext();
      }
    } catch (e) {
      this.setState({ e: e.message });
      const errorCont = document.getElementById('text-danger');
      if (errorCont) {
        errorCont.scrollIntoView();
      }
    }
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    AddressesStore.changeAddressObject(event.target.name, event.target.value);
  };

  goNext = () => this.props.history.push('/');

  componentDidMount(): void {
    const navbar = document.querySelector('nav');
    if (navbar) navbar.classList.add('hide');
    const { first_name, last_name } = ProfileStore.profile;
    if (!AuthStore.isLogged) {
      this.props.history.replace('/signIn');
    }
    if (!first_name || !last_name) {
      this.props.history.replace('/sign_client_info');
    }
    const input = document.querySelector(
      '#signIn_location'
    ) as HTMLInputElement;
    if (input) {
      AddressAutocomplete(input, AddressesStore.changeAddressObject);
    }
  }

  backToLocation = async () => {
    await this.setState({ showHotel: false });
    const input = document.querySelector(
      '#signIn_location'
    ) as HTMLInputElement;
    if (input) {
      AddressAutocomplete(input, AddressesStore.changeAddressObject);
    }
  };

  componentWillUnmount(): void {
    const navbar = document.querySelector('nav');
    if (navbar) navbar.classList.remove('hide');
  }

  agreeOnFee = async () => {
    await AddressesStore.showModal('extraFee');
    this.goNext();
  };

  disagreeFee = async () => {
    const idOfLocation =
      AddressesStore.addressesSettings[
        AddressesStore.addressesSettings.length - 1
      ].id;
    await AddressesStore.deleteAddress(idOfLocation);
    AddressesStore.showModal('extraFee');
  };
  changeParkingSettings = (event: ChangeEvent<HTMLInputElement>) => {
    const { id } = event.currentTarget;
    AddressesStore.changeAddressObject(
      'selected_parking_option',
      parkingOptions[id]
    );
  };

  render() {
    const {
      address,
      zip_code,
      selected_parking_option,
      // room_number,
      // code,
      // hotel_name,
      apartment,
      gate_code,
    } = AddressesStore.addressObject;
    return (
      <div className={'d-flex min-vh-100'}>
        <div className={'col-12 col-md-7'}>
          <div id={'sign_in_location_info'}>
            <div className={'logo_container mb-5'}>
              <Link
                to={'/'}
                onClick={(e: any) => {
                  e.preventDefault();
                  this.props.history.push('/');
                }}
              >
                <img src={Logo} alt={'Logo'} />
              </Link>
            </div>
            <div className={'content_container'}>
              <h1 className={'text-capitalize text-center font-weight-bold'}>
                A few more steps left
              </h1>
              <StepsComponent steps={'regSteps'} active={1} done={[0]} />
              {!this.state.showHotel && (
                <div className={'form-group location_form variable-width'}>
                  <span className="text-danger" id="text-danger">
                    {this.state.e}
                  </span>
                  <LoginInput
                    type={'text'}
                    placeholder={'Address'}
                    name={'address'}
                    id={'signIn_location'}
                    onChange={this.handleChange}
                    iconName={'fa-map-marker-alt'}
                    value={address}
                    locationVisible={true}
                    autoComplete={'street-address'}
                  />
                  <LoginInput
                    type={'text'}
                    placeholder={'ZIP'}
                    name={'zip_code'}
                    id={'signIn_location'}
                    onChange={this.handleChange}
                    iconName={'fa-map-marker-alt'}
                    value={zip_code}
                    locationVisible={true}
                  />
                  <h2>where should we park?</h2>
                  <div className={'parking_container d-flex flex-wrap'}>
                    <label className="parking">
                      Free Street Parking
                      <input
                        type="radio"
                        name="parking"
                        id={'FREE_STREET'}
                        onChange={this.changeParkingSettings}
                        checked={
                          selected_parking_option === parkingOptions.FREE_STREET
                        }
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="parking">
                      Parking Lot or Garage
                      <input
                        type="radio"
                        name="parking"
                        id={'GARAGE_LOG'}
                        onChange={this.changeParkingSettings}
                        checked={
                          selected_parking_option === parkingOptions.GARAGE_LOG
                        }
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="parking">
                      Meter parking
                      <input
                        type="radio"
                        name="parking"
                        id={'METERET'}
                        onChange={this.changeParkingSettings}
                        checked={
                          selected_parking_option === parkingOptions.METERET
                        }
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="parking">
                      Valet Service
                      <input
                        type="radio"
                        name="parking"
                        id={'VALET_SERVICE'}
                        onChange={this.changeParkingSettings}
                        checked={
                          selected_parking_option ===
                          parkingOptions.VALET_SERVICE
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <LoginInput
                    type={'text'}
                    placeholder={'Apartment'}
                    name={'apartment'}
                    id={'signIn_location'}
                    disabled={this.state.is_hotel}
                    onChange={this.handleChange}
                    value={this.state.is_hotel?"":apartment}
                  />
                  {AddressesStore.errorsOnForm.room_number && (
                    <span className={'inline_error'}>
                      {AddressesStore.errorsOnForm.room_number}
                    </span>
                  )}
                  <LoginInput
                    type={'text'}
                    placeholder={'Door/Gate Code'}
                    name={'gate_code'}
                    id={'signIn_location'}
                    disabled={this.state.is_hotel}
                    onChange={this.handleChange}
                    value={this.state.is_hotel?"":gate_code}
                  />
                  {AddressesStore.errorsOnForm.code && (
                    <span className={'inline_error'}>
                      {AddressesStore.errorsOnForm.code}
                    </span>
                  )}
                  <input
                    type="checkbox"
                    className="form-check-input styled-checkbox"
                    id="this_a_hotel"
                    checked={this.state.is_hotel}
                    onChange={() => {
                      if (!this.state.is_hotel) this.setState({showHotelInformationPopup: true});
                      this.setState({is_hotel: !this.state.is_hotel})
                    }
                    }
                  />
                  <label className="form-check-label" htmlFor="this_a_hotel">
                    This is a hotel
                  </label>
                  {this.state.is_hotel && <div
                    className={'inline_button'}
                    onClick={() => this.setState({ showHotel: true })}
                  >
                    Hotel Information
                  </div>
                  }
                  
                </div>
              )}
              {this.state.showHotel && (
                <HotelForm
                  saveCard={this.backToLocation}
                  onChange={AddressesStore.changeAddressObject}
                />
              )}

              <YellowButton
                onClick={this.registrationDone}
                value={'done'}
                disabled={!address || !zip_code}
                spinner={ProfileStore.isLoading}
              />
            </div>
          </div>
        </div>
        <div className={'col-md-5 col-0 p-0'}>
          <div id={'signIn_steps'}></div>
        </div>
        <Popup
          title={"sorry we don't service this area"}
          id={'dontServiceArea'}
          content={PopupContent.dontServiceArea}
          onHide={() => AddressesStore.showModal('outOfService')}
          show={AddressesStore.modals.outOfService}
        />
        <Popup
          title={'extra fee'}
          id={'extraFee'}
          content={PopupContent.extraFee}
          onHide={this.disagreeFee}
          show={AddressesStore.modals.extraFee}
          showConfirmation
          onClick={this.agreeOnFee}
        />

      <HotelInformationPopup
          id={'hotelInformation'}
          onHide={()=>this.setState({ showHotelInformationPopup: false })}
          show={this.state.showHotelInformationPopup}
        />
      </div>
    );
  }
}
