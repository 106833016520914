import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Redirect, RouterProps } from 'react-router';
import ValetsStore from '../../../Stores/ValetsStore';
import AuthStore from '../../../Stores/AuthStore';
import { IValet } from '../../../Interfaces/ValetInterfaces';
import SelectValetNavbar from './SelectValetNavbar';
import SelectValetComponent from './SelectValetComponent';

@observer
export default class SelectValet extends Component<RouterProps> {
  componentDidMount(): any {
    if (!AuthStore.isLogged) return <Redirect to={'/'} />;
    ValetsStore.getValetsGroupedByCity("LOS ANGELES", false);
  }

  render() {
    return (
      <div className="gray_background rebook">
        <SelectValetNavbar active={0} />
        <div
          className={'back_button_container'}
          onClick={this.props.history.goBack}
        >
          <img
            src={require('../../../assets/icons/arrow_left.svg')}
            alt={'arrow left'}
          />
          Back
        </div>
        <div className="d-flex flex-wrap justify-content-start valet_container">
          {ValetsStore.listOfValetsInCity.map((valet: IValet, index: number) => (
            <SelectValetComponent valet={valet} city={ValetsStore.selectedCityForGroup} key={index} />
          ))}
          {!ValetsStore.isLoading && !ValetsStore.listOfValetsInCity.length && ValetsStore.isFavoriteOnly && (
                <span className={'inline_info'}>
                  <br/>You have no favorite valets yet. Book an appointment and choose your favorites.
                </span>
              )}
          {!ValetsStore.isLoading && !ValetsStore.listOfValetsInCity.length && !ValetsStore.isFavoriteOnly && (
            <span className={'inline_info'}>
              <br/>Unfortunately, there are no available valets for this location at the moment.
            </span>
          )}
        </div>

        
      </div>
    );
  }
}