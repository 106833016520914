import React, {Component, FormEvent} from 'react'
import Modal from "react-bootstrap/Modal";
import YellowButton from "../../Shared/Buttons/YellowButton";
import { Form, FormControlProps } from 'react-bootstrap';
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import Validation from "../../Utils/Validation";
import PromoService from "../../Services/PromoService"

interface IState {
    error?: string;
    valueForm: {
      first_name: string;
      email_address: string;
    };
    isInValidFirstName: boolean;
    isInValidEmailAddress: boolean;
    isLoading: boolean;
    [key: string]:
      | string
      | undefined
      | boolean
      | { first_name: string; email_address: string };
}

interface IProps {
    onHide: () => void;
    onDismiss: (success: boolean) => void;
    show: boolean;
}

class PromoPopup extends Component<IProps, IState> {
    state = {
        error: '',
        valueForm: {
          first_name: '',
          email_address: '',
        },
        isInValidFirstName: false,
        isInValidEmailAddress: false,
        isLoading: false,
      };
      
    emailValidation = (event: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name && value) {
            this.setState({ isInvalidEmail: !Validation[name](value) });
        }
    };

    submitForm = async () => {
        if (
          this.state.valueForm.first_name === '' ||
          this.state.valueForm.email_address === ''
        ) {
          if (this.state.valueForm.first_name === '') {
            this.setState({ isInValidFirstName: true });
          }
          if (!Validation["email"](this.state.valueForm.email_address)) {
            this.setState({ isInValidEmailAddress: true });
          }
          return;
        }
    
        this.setState({ isLoading: true})
        let result = false

        try {
          const data = {
            email: this.state.valueForm.email_address,
            code: "FIRSTTIME"
          };

          const response = await PromoService.registerOneTimePromo(data)
    
          if (response && response.status) {
            result = true
          }
        } catch (e) {
          this.setState({ error: e.message });
        } finally {
          this.setState({ isLoading: false})
          this.props.onDismiss(result)
        }
      };

    changeData = async (
        event: FormEvent<
          ReplaceProps<'input', BsPrefixProps<'input'> & FormControlProps>
        >
      ) => {
        const { id, value = '' } = event.currentTarget;
        if (id === 'first_name') {
          this.setState({ isInValidFirstName: value === '' ? true : false });
          this.setState({
            valueForm: { ...this.state.valueForm, first_name: value },
          });
        }
        if (id === 'email_address') {
          this.setState({isInValidEmailAddress: !Validation["email"](value)})
          this.setState({
            valueForm: { ...this.state.valueForm, email_address: value },
          });
        }
      };

    render(){
        const {show, onHide} = this.props;
        return (
            <Modal
                className='promo-popup-modal'
                show={show}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                keyboard
                backdrop
                onHide={onHide}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className={'modal-title'}>Start feeling amazing! $10 off your first spray tan with us!</h4>
                    <div className={"modal-text"}>You’ll also join the Spray Valet family to get access to limited deals and updates!</div>
                    <div>
                    <Form>
                        <Form.Row>
                            <Form.Group className='w-100 mb-0' controlId="first_name">
                                <Form.Control
                                    type="text"
                                    placeholder="first name"
                                    onChange={this.changeData}
                                    required
                                />
                                {this.state.isInValidFirstName && (
                                    <span className="inline_error">
                                    Please enter a first name.
                                    </span>
                                )}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className='w-100' controlId="email_address">
                                <Form.Control
                                    type="text"
                                    placeholder="email address"
                                    onChange={this.changeData}
                                    required
                                />
                                {this.state.isInValidEmailAddress && (
                                    <span
                                    className="inline_error">
                                    Please enter a valid email.
                                    </span>
                                )}
                                
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <YellowButton
                            onClick={this.submitForm}
                            value={'Show Me The Code'}
                            disabled={!!this.state.error}
                            spinner={this.state.isLoading}
                        />
                        </Form.Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>

        );
    }
}

export default PromoPopup;