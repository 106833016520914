import React, { Component, FormEvent } from 'react';
import SubNavbar from '../../../Shared/Navbars/SubNavbar';
import YellowButton from '../../../Shared/Buttons/YellowButton';
import arrow_left from '../../../assets/icons/arrow_left.svg';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PaymentStore from '../../../Stores/PaymentStore';
import AuthStore from '../../../Stores/AuthStore';
import { Elements } from 'react-stripe-elements';
import CardForm from '../../../Components/CardForm';
import ModalStore from '../../../Stores/ModalStore';
import { Form, FormControlProps } from 'react-bootstrap';
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import Validation from "../../../Utils/Validation";

interface IState {
  error?: string;
  inputComplete?: boolean;
  valueForm: {
    first_name: string;
    last_name: string;
    email_address: string;
    phone_number: string;
  };
  isInvalidFirstName: boolean;
  isInvalidLastName: boolean;
  isInvalidEmail: boolean;
  isInvalidPhone: boolean;
  [key: string]:
    | string
    | undefined
    | boolean
    | { first_name: string; last_name: string, email_address: string, phone_number: string };
}

interface IProps extends RouteComponentProps {
  stripe: any;
}

@observer
class AddCreditCard extends Component<IProps, IState> {
  state = {
    error: '',
    inputComplete: false,
    valueForm: {
      first_name: this.props.location.state.id ? this.props.location.state.id.first_name : '',
      last_name: this.props.location.state.id ? this.props.location.state.id.last_name : '',
      email_address: this.props.location.state.id ? this.props.location.state.id.email : '',
      phone_number: this.props.location.state.id ? this.props.location.state.id.phone : '',
    },
    isInvalidFirstName: false,
    isInvalidLastName: false,
    isInvalidEmail: false,
    isInvalidPhone: false,
  };

  cardForm: React.RefObject<any> = React.createRef();

  emailValidation = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name && value) {
      this.setState({ isInvalidEmail: !Validation[name](value) });
    }
  };

  componentDidMount(): any {
    if (!AuthStore.isLogged) return <Redirect to={'/'} />;
  }

  submitForm = async () => {
    if (
      this.state.valueForm.first_name === '' ||
      this.state.valueForm.last_name === '' ||
      !Validation["email"](this.state.valueForm.email_address) ||
      !Validation["phone"](this.state.valueForm.phone_number)
    ) {
      if (this.state.valueForm.first_name === '') {
        this.setState({ isInvalidFirstName: true });
      }
      if (this.state.valueForm.last_name === '') {
        this.setState({ isInvalidLastName: true });
      }
      if (!Validation["email"](this.state.valueForm.email_address)) {
        this.setState({ isInvalidEmail: true });
      }
      if (!Validation["phone"](this.state.valueForm.phone_number)) {
        this.setState({ isInvalidPhone: true });
      }
      return;
    }

    if (!this.props.location.state.id && !this.state.inputComplete) return;

    PaymentStore.isLoading = true;
    try {
      if (this.props.location.state.id){
        const payment_info = {
          id: this.props.location.state.id.id,
          first_name: this.state.valueForm.first_name,
          last_name: this.state.valueForm.last_name,
          email: this.state.valueForm.email_address,
          phone: this.state.valueForm.phone_number
        }
        
        await PaymentStore.updatePaymentInfo(payment_info);

      }else{
        const response = await this.cardForm.current.wrappedInstance.saveCard();

        if (response && response.error) {
          PaymentStore.errorOnComponent.modalError =
            'Your card was declined. Please try another one.';
          ModalStore.toggleModal('paymentError', true);
        }
        const payment_info = {
          first_name: this.state.valueForm.first_name,
          last_name: this.state.valueForm.last_name,
          email: this.state.valueForm.email_address,
          phone: this.state.valueForm.phone_number
        }
        
        await PaymentStore.savePaymentInfo(payment_info);
      }      
    } catch (e) {
      this.setState({ error: e.message });
    }
    PaymentStore.isRedirect
      ? this.props.history.push('subscription_settings')
      : this.props.history.goBack();
  };

  inputComplete = (e: boolean) => {
    this.setState({ inputComplete: e });
  };

  changeData = async (
    event: FormEvent<
      ReplaceProps<'input', BsPrefixProps<'input'> & FormControlProps>
    >
  ) => {
    const { id, value = '' } = event.currentTarget;
    if (id === 'first_name') {
      this.setState({ isInvalidFirstName: false });
      this.setState({
        valueForm: { ...this.state.valueForm, first_name: value },
      });
    }
    if (id === 'last_name') {
      this.setState({ isInvalidLastName: false });
      this.setState({
        valueForm: { ...this.state.valueForm, last_name: value },
      });
    }
    if (id === 'email_address') {
      this.setState({
        valueForm: { ...this.state.valueForm, email_address: value },
        isInvalidEmail: !Validation["email"](value)
      });
    }
    if (id === 'phone_number') {
      this.setState({
        valueForm: { ...this.state.valueForm, phone_number: value },
        isInvalidPhone: !Validation["phone"](value)
      });
    }
  };

  render() {
    return (
      <div className={'settings_container'}>
        <SubNavbar active={2} />
  
        <div className={'container add_new_card'}>
          <div
            className="back_button"
            onClick={() => this.props.history.goBack()}
          >
            <img src={arrow_left} alt={'arrow_left'} /> Back
          </div>
          <h1>{this.props.location.state.title}</h1>
          <Form>
            <h4>Contact Info</h4>
            <Form.Row className='m-0'>
              <Form.Group className='m-0' controlId="first_name">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="first name"
                  value={this.state.valueForm.first_name}
                  onChange={this.changeData}
                  required
                />
                <span
                  className={`inline_error ${
                    this.state.isInvalidFirstName ? 'visible' : 'invisible'
                  }`}
                >
                  Please enter a first name.
                </span>
              </Form.Group>

              <Form.Group className='m-0' controlId="last_name">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="last name"
                  value={this.state.valueForm.last_name}
                  onChange={this.changeData}
                  required
                />
                <span
                  className={`inline_error ${
                    this.state.isInvalidLastName ? 'visible' : 'invisible'
                  }`}
                >
                  Please enter a last name.
                </span>
              </Form.Group>
            </Form.Row>

            <Form.Row className='m-0'>
              <Form.Group className="m-0" controlId="email_address">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="email"
                  value={this.state.valueForm.email_address}
                  onChange={this.changeData}
                />
                <span
                  className={`inline_error ${
                    this.state.isInvalidEmail ? 'visible' : 'invisible'
                  }`}
                >
                  Please enter valid email
                </span>
              </Form.Group>
              <Form.Group className="m-0" controlId="phone_number">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="phone"
                  onChange={this.changeData}
                  value={this.state.valueForm.phone_number}
                />
                <span
                  className={`inline_error ${
                    this.state.isInvalidPhone ? "visible" : "invisible"
                  }`}
                >
                  Please enter only numbers
                </span>
              </Form.Group>
            </Form.Row>
          </Form>
          <h4>Payment Info</h4>
          {!this.props.location.state.id &&
            <Elements>
              <CardForm
                inputComplete={this.inputComplete}
                ref={this.cardForm}
                showError={(error?: string) => this.setState({ error })}
                valueForm={this.state.valueForm}
              />
            </Elements>
          }
          {this.props.location.state.id &&
            <div className={"card_info d-inline-flex flex-grow-1 align-items-center"}>
            <i className={"fas fa-credit-card"} />
            <div className="profile_item">{`${this.props.location.state.id.card_type} **${this.props.location.state.id.last_4}`}</div>
          </div>
          }
          
          {this.state.error && (
            <span className={'inline_error'}>{this.state.error}</span>
          )}
          <YellowButton
            onClick={this.submitForm}
            value={'save'}
            additionalClass={'w-100'}
            disabled={!!this.state.error}
            spinner={PaymentStore.isLoading}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AddCreditCard);
